import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  deleteMovie,
  getAllMovie,
  getMovieById,
  postMovie,
  updateMovie,
  getMovieDownloadLink,
  postMovieDownloadLink,
  updateMovieDownloadLink,
  deleteMovieDownloadLink,
} from "services/movies/moviesServices";
import toasts from "components/common/toasts/toasts";

export const useMoviesQuery = (page, limit) => {
  return useQuery(
    ["movies", page],
    ({ signal }) => getAllMovie(page, limit, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
export const useMovieQuery = (id) => {
  return useQuery(["movie", id], ({ signal }) => getMovieById(id, signal), {
    enabled: !!id,
    select: (data) => {
      return data.data.data;
    },
  });
};
export const usePostMovieQuery = (title) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(postMovie, {
    onSuccess: () => {
      queryClient.invalidateQueries("movies");
      history.push("/movies");
      toasts.success(`فیلم ${title} با موفقیت ثبت شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای ساخت فیلم پیش آمده");
    },
  });
};
export const usePutMovieQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(updateMovie, {
    onSuccess: () => {
      queryClient.invalidateQueries("movies");
      history.push("/movies");
      toasts.success("فیلم با موفقیت آپدیت شد.");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی فیلم پیش آمده");
    },
  });
};
export const useDeleteMovieQuery = (type, page, title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteMovie, {
    onSuccess: () => {
      queryClient.invalidateQueries([`advanced-search-movie`, page]);
      // queryClient.invalidateQueries("movies");
      if (type === "single") {
        toasts.success(`فیلم ${title} باموفقیت حذف شد.`);
      }
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
//Download Links
export const useMovieDownloadLinkQuery = (id) => {
  return useQuery(
    ["movie-download-link", id],
    ({ signal }) => getMovieDownloadLink(id, signal),
    {
      enabled: !!id,
      select: (data) => {
        return data.data.data.results;
      },
    }
  );
};
export const usePostMovieDownloadLinkQuery = () => {
  return useMutation(postMovieDownloadLink, {
    onError: () => {
      toasts.error("مشکلی برای افزودن لینک های دانلود پیش آمده");
    },
  });
};
export const usePutMovieDownloadLinkQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(updateMovieDownloadLink, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([
        "movie-download-link",
        variables.movie_id,
      ]);
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی لینک های دانلود پیش آمده");
    },
  });
};
export const useDeleteMovieDownloadLinkQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMovieDownloadLink, {
    onSuccess: () => {
      queryClient.invalidateQueries("movie-download-link");
      toasts.success(`لینک موردنظر باموفقیت حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
