import { combineReducers } from "redux";

// information tab
//TODO: change names like dynamic names
const informationState = {
  // picLink: '',
  // showPriority: '',
  // title: '',
  // description: '',
  // hasButton: true,
  // buttonText: '',
  // buttonLink: '',
  //
  // priority: 0,
  show_button: false,
};
export const informationReducer = (state = informationState, action) => {
  switch (action.type) {
    case "GET_SLIDE_INFORMATION":
      return action.payload;
    case "UPDATE_SLIDE_INFORMATION":
      return { ...state, ...action.payload };
    case "RESET_SLIDE_INFORMATION":
      return informationState;
    default:
      return state;
  }
};

// setting tab
const settingState = {
  show: true,
  position: "top",
};
export const settingReducer = (state = settingState, action) => {
  switch (action.type) {
    case "GET_SLIDE_SETTING":
      return action.payload;
    case "UPDATE_SLIDE_SETTING":
      return { ...state, ...action.payload };
    case "RESET_SLIDE_SETTING":
      return settingState;
    default:
      return state;
  }
};

export const slideReducer = combineReducers({
  information: informationReducer,
  setting: settingReducer,
});