import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteCategory,
  getCategories,
  postCategory,
  putCategory,
} from "services/category/categoryServices";
import toasts from "components/common/toasts/toasts";

export const useCategoriesQuery = () => {
  return useQuery(["categories"], ({ signal }) => getCategories(signal), {
    select: (data) => {
      return data.data.data;
    },
  });
};

export const usePostCategoryQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("categories");
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت دسته بندی پیش آمده");
    },
  });
};
export const usePutCategoryQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(putCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("categories");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی دسته بندی پیش آمده");
    },
  });
};
export const useDeleteCategoryQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("categories");
      toasts.success(`${title} دسته بندی حذف شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
