import {
  useDeleteCommentQuery,
  usePatchConfirmCommentQuery,
  usePatchVisibilityCommentQuery,
} from "hooks/query/useCommentQuery";
import { convertToJalaliDateWithOutSpace } from "../../../util/toJalali";
import styles from "./Comment.module.scss";

function Comment({ comment }) {
  const payload = {
    id: comment.id,
    visible: !comment.visible,
  };
  const { mutate: deleteComment } = useDeleteCommentQuery();
  const { mutate: patchConfirmComment } = usePatchConfirmCommentQuery();
  const { mutate: patchVisibilityComment } = usePatchVisibilityCommentQuery(
    payload.visible
  );

  const handleConfirmComment = () => {
    patchConfirmComment(comment.id);
  };
  const handleRejectComment = () => {
    deleteComment(comment.id);
  };
  const handleToggleVisibility = () => {
    patchVisibilityComment(payload);
  };

  return (
    <div className={styles.commentWrapper}>
      <header>
        <div className={`${styles.col} ${styles.userDetail}`}>
          <figure>
            {comment.member.avatar?.length ? (
              <img
                loading="lazy"
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${comment.member.avatar}`}
                alt=""
              />
            ) : (
              <img
                loading="lazy"
                src={`${process.env.PUBLIC_URL}/images/users/user-default.svg`}
                alt=""
              />
            )}
          </figure>

          <div className={styles.info}>
            <span>{comment.member.fullname}</span>
            <span>{convertToJalaliDateWithOutSpace(comment.created_at)}</span>
          </div>
        </div>

        <div className={`${styles.col} ${styles.iconCol}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/commentManagement/video-play.svg`}
            alt=""
          />
          <span>{comment.title}</span>
        </div>

        {/* <div className={`${styles.col} ${styles.iconCol}`}>
					<img
						src={`${process.env.PUBLIC_URL}/images/commentManagement/attach-square.svg`}
						alt=""
					/>
					<a href="#" rel="noopener noreferrer" target="_blank">
						-
					</a>
				</div> */}
      </header>
      <div className={styles.commentContent}>
        <p>{comment.content}</p>
      </div>
      <footer>
        <div
          className={`${styles.status} ${
            comment.confirmed ? styles.success : styles.danger
          }`}>
          {comment.confirmed ? "تایید شده" : "در انتظار بررسی "}
        </div>
        <div className={styles.buttonWrapper}>
          {comment.confirmed ? (
            <button
              className={`${styles.button} `}
              onClick={handleToggleVisibility}>
              <img
                src={`${process.env.PUBLIC_URL}/images/commentManagement/eye-slash.svg`}
                alt=""
              />
              <span>
                {comment.visible ? "غیر فعال کردن نظر" : "فعال کردن نظر"}
              </span>
            </button>
          ) : (
            <>
              <button
                className={`${styles.button}`}
                onClick={handleRejectComment}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/commentManagement/close-square.svg`}
                  alt=""
                />
                <span>عدم تایید نظر</span>
              </button>
              <button
                className={`${styles.button} ${styles.primary}`}
                onClick={handleConfirmComment}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/commentManagement/tick-square.svg`}
                  alt=""
                />
                <span>تایید نظر</span>
              </button>
            </>
          )}
        </div>
      </footer>
    </div>
  );
}

export default Comment;
