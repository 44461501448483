import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateInformationSlide,
	updateSettingSlide,
} from '../../../../../redux/actions/slider';
import DropDown from '../../../../common/dropDown/DropDown';
import SwitchTwoLabel from '../../../../common/switchTwoLabel/SwitchTwoLabel';
import styles from './Settings.module.scss';

// default positionOptions data
const positionOptions = [
	{ value: 'top', title: 'بالا' },
	{ value: 'bottom', title: 'پایین' },
];

export default function Settings() {
	const dispatch = useDispatch();

	const { setting, information } = useSelector(state => state.slideReducer);

	const [position, setPosition] = useState(null);

	useEffect(() => {
		const value = positionOptions.find(item => item.value === setting.position);
		setPosition(value);
	}, [setting.position]);

	const handleChangeDropdown = (option, action) => {
		if (option.value === 'top' && information.poster) {
			/* 
				resetting the value of poster - idk if it's a good approach to reset it or not
			 	i mean maybe needs unique action creator for it
			*/

			dispatch(
				updateInformationSlide({
					poster: null,
				})
			);
		}

		dispatch(
			updateSettingSlide({
				[action.name]: option.value,
			})
		);
	};

	const handleChangeSwitch = bool => {
		dispatch(
			updateSettingSlide({
				show: bool,
			})
		);
	};

	return (
		<section>
			<div className={styles.dropdown}>
				<DropDown
					label="محل قرارگیری اسلایدر"
					placeholder="محل قرارگیری اسلایدر را انتخاب نمایید"
					name="position"
					options={positionOptions}
					value={position}
					getOptionLabel={option => option['title']}
					getOptionValue={option => option['value']}
					onChange={handleChangeDropdown}
					isRequired
				/>
			</div>
			<div className={styles.status}>
				<p>وضعیت نمایش</p>
				<SwitchTwoLabel
					switchState={[setting.show, handleChangeSwitch]}
					rightLabel={'در حال نمایش'}
					leftLabel={'عدم نمایش'}
					theme="colorful"
				/>
			</div>
		</section>
	);
}
