import { useState } from "react";
import toasts from "../../common/toasts/toasts";
import {
  isUserExist,
  loginUserWithVerificationCode,
} from "../../../services/entry/entryService";
import CustomInput from "../../common/customInput/CustomInput";
import { IsValidIranianMobile } from "./../../../util/validMobile";
import { IsValidEmail } from "./../../../util/validEmail";
import styles from "../RegisterAndLogin.module.scss";
const Login = ({ handleRegister, handleEnterPlatform, isOtp, setLoginObj }) => {
  const [type, setType] = useState("regex:^(0)?9\\d{9}$");
  const [text, setText] = useState("");
  const [classValidationInput, setClassValidationInput] = useState("");

  const handleKeyPress = (e) => {
    if (e.charCode === 13 || e.which === 13) submit();
  };

  const checkType = () => {
    let data = text;
    if (!isNaN(parseInt(data))) setType("regex:^(0)?9\\d{9}$");
    else setType("email");
  };
  const setTextInput = (e) => {
    setText(e);
    checkType();
  };
  const isValidInput = () => {
    if (type !== "email") {
      const isvalidPhone = IsValidIranianMobile(text);
      isvalidPhone
        ? setClassValidationInput("verify")
        : setClassValidationInput("danger");
      if (!isvalidPhone) {
        toasts.error("شماره همراه وارد شده صحیح نمی باشد");
      }
      return isvalidPhone;
    } else {
      const isValidEmail = IsValidEmail(text);
      isValidEmail
        ? setClassValidationInput("verify")
        : setClassValidationInput("danger");
      if (!isValidEmail) {
        toasts.error("ایمیل وارد شده صحیح نمی باشد");
      }
      return isValidEmail;
    }
  };

  const checkUser = async () => {
    const temp = type !== "email" ? { mobile: text } : { email: text };
    try {
      const { data } = await isUserExist(temp);

      if (data.data) {
        if (type !== "email") {
          toasts.success("شماره همراه شما تایید شد");
          sendVerificationCode();
          isOtp(true);
          setLoginObj(temp);
        }
        if (type === "email") {
          toasts.success("کلمه عبور را وارد نمایید");
          isOtp(false);
          setLoginObj(temp);
        }
        handleEnterPlatform();
      } else {
        if (window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie") {
          toasts.error("یوزر مورد نظر وجود ندارد");
        } else {
          toasts.error("یوزر مورد نظر وجود ندارد و ثبت نام کنید ");
          handleRegister();
        }
      }
    } catch (error) {
      toasts.error("خطایی پیش آمده است");
    }
  };

  const submit = () => {
    const result = isValidInput();
    if (result) {
      checkUser();
    }
  };

  const sendVerificationCode = async () => {
    const temp = { mobile: text, sms_provider: "magfa" };
    try {
      const { status } = await loginUserWithVerificationCode(temp);
      if (status == 200) {
        toasts.success("کد تایید به شمراه همراه شما ارسال شد");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status == 429) {
          toasts.error("کد تایید برای شما کمی پیش ارسال شده است ");
        }
        if (error.response.status != 429) {
          toasts.error("خطایی پیش آمده است");
        }
      }
    }
  };

  return (
    <>
      <CustomInput
        type="email"
        label="ایمیل یا شماره همراه"
        value={text}
        handleChange={(e) => setTextInput(e)}
        classValidation={classValidationInput}
        onKeyPress={handleKeyPress}
      />
      <button className={`btn ${styles.btn}`} onClick={submit}>
        بررسی
      </button>
    </>
  );
};

export default Login;
