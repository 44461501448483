import { useState } from "react";
import { Modal } from "react-bootstrap";
import DropDown from "../../../../common/dropDown/DropDown";
import Switch from "../../../../common/switch/Switch";
import TagMultiSelect from "../../../../common/tagMultiSelect/TagMultiSelect";
import DeleteModal from "./components/deleteModal/DeleteModal";
import styles from "./Settings.module.scss";

const tagList = [
	{
		id: 1,
		title: "استریمر",
	},
	{
		id: 2,
		title: "موویز",
	},
	{
		id: 3,
		title: "دارک موویز",
	},
];

//TODO: fix all project option

export default function Settings() {
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [selectedItems, setSelectedItems] = useState([]);
	// default yearOptions Options data
	const packageOptions = [
		{ id: 1, title: "بسته روزانه" },
		{ id: 2, title: "بسته ماهانه" },
		{ id: 3, title: "بسته سالانه" },
	];
	const [pack, setPack] = useState(packageOptions[0]);

	return (
		<>
			<section className={styles.section}>
				<div className={styles.wrapperMulti}>
					<TagMultiSelect
						label="پروژه ها"
						placeholder="پروژه هایی که میخواهید این فیلم به آن افزوده شود را انتخاب نمایید"
						type="project"
						options={tagList}
						selectedOptions={selectedItems}
						optionFlags={{ title: "title", value: "id" }}
						getOptionLabel={(option) => option["title"]}
						getOptionValue={(option) => option["id"]}
						onChange={(id) => setSelectedItems(id)}
						handleDelete={() => setShowDeleteModal(true)}
						isPremium
					/>
				</div>
				<div className={styles.wrapper}>
					<DropDown
						placeholder="نوع پرداختی که میخواهید این فیلم از آن پشتیبانی کند را انتخاب نمایید"
						label="بسته ها و پرداخت ها"
						options={packageOptions}
						// value={messagePackage}
						getOptionLabel={(option) => option["title"]}
						getOptionValue={(option) => option["id"]}
						onChange={(e) => setPack(e)}
					/>
					<div className={styles.managePackageBtn}>
						مدیریت بسته ها و پرداخت ها
					</div>
				</div>
				<div className={styles.switchWrapper}>
					<Switch
						label="قابلیت دانلود"
						// switchState={[]}
					/>
					<Switch
						label="قابلیت پخش به صورت استریمینگ"
						// switchState={[]}
					/>
					<Switch
						label="وضعیت انتشار"
						// switchState={[]}
					/>
				</div>
			</section>

			<Modal
				show={showDeleteModal}
				onHide={() => setShowDeleteModal(false)}
				dialogClassName={styles.deleteModal}
			>
				<DeleteModal
					title={"مورد نظر "}
					onHide={() => setShowDeleteModal(false)}
				/>
			</Modal>
		</>
	);
}
