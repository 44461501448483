import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getMyEmailConfig,
  postEmailConfig,
  putEmailConfig,
} from "services/configs/emailConfig";
import toasts from "components/common/toasts/toasts";

export const useEmailConfigQuery = () => {
  return useQuery(["email-config"], getMyEmailConfig, {
    select: (data) => {
      return data.data.data;
    },
  });
};
export const usePostEmailConfigQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postEmailConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries("email-config");
      toasts.success(`تنظیمات ایمیل با موفقیت ثبت شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت تنظیمات ایمیل پیش آمده");
    },
  });
};
export const usePutEmailConfigQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(putEmailConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries("email-config");
      toasts.success(`تنظیمات ایمیل با موفقیت بروزرسانی شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی تنظیمات ایمیل پیش آمده");
    },
  });
};
