import videojs from "video.js";
import "./ageLimiter.css";

const Plugin = videojs.getPlugin("plugin");

class AgeLimiter extends Plugin {
  constructor(player, options) {
    super(player);
    this.div = null;

    this.player.ready(() => {
      if (!options) return;
      this.ageLimiter(options);
    });
  }
  toggleActive() {
    this.div.classList.toggle("hide");
  }
  ageLimiter(options) {
    if (!this.div) {
      this.div = document.createElement("div");
      this.div.className = "vjs-age-limiter";
      this.div.innerHTML = "+" + options.limit;
    } else {
      this.div.innerHTML = "+" + options.limit;
    }
    this.player.el().appendChild(this.div);
  }
}

videojs.registerPlugin("ageLimiter", AgeLimiter);

export default AgeLimiter;
