import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import LableCheckBox from "../../../common/labelCheckBox/LabelCheckBox";
import styles from "./AddProjectModal.module.scss";

export default function AddProjectModal({ onHide }) {
  return (
    <>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>
          نوع پروژه خود را تعیین نمایید، این تنظیمات بعداً قابل تغییر است
        </Modal.Title>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={() => onHide()}
        />
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <LableCheckBox
          label={"فیلم"}
          text={`انواع فیلم های سینمایی، فیلم های کوتاه، مستندهای سینمایی، انیمیشن های سینمایی و تمام ژانرهای تعریف
شده برای فیلم در تمامی ملیت ها (ایرانی و خارجی) و زبان ها در این دسته بندی قرار می گیرد. و نیز فیلم های
مرتبط را میتوان در کالکشن دسته بندی کرد`}
        />
        <LableCheckBox
          label={"سریال"}
          text={`انواع سریال های تلویزیونی، انیمه ها و انیمیشن های سریالی، مستند های سریالی، مینی سریال و تمام ژانرهای
تعریف شده برای سریال در تمامی ملیت ها (ایرانی و خارجی) و زبان ها در این دسته بندی قرار می گیرد`}
        />
        <LableCheckBox
          label={"ویدئو کلیپ"}
          text={`انواع ویدئوها و کلیپ ها در دسته بندی های دلخواه مانند ورزشی، آموزشی و ... تولید شده توسط کاربر یا توسط
،دیگران در این دسته بندی قرار میگیرد. همچنین میتوان ویدئوها را در پلی لیست های دلخواه دسته بندی کرد
به طور مثال در دسته بندی آموزشی میتوانید مباحث مجزا را بصورت پلی لیست ارائه کنید`}
        />
        <LableCheckBox
          label={"پخش زنده"}
          text={`پخش زنده و استریمینگ با قابلیت هایی نظیر دریافت Stream key، دریافت Stream URL، ماشین زمان،
رکورد پخش زنده، مشاهده لحظه ای افراد آنلاین، مجموع بازدید و قابلیت اشتراک گذاری`}
        />
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter} onClick={() => onHide()}>
        <button className={`${styles.btn} ${styles.outline} btn`}>
          انصراف
        </button>
        <Link
          to="/project/newProject"
          className={`${styles.btn} ${styles.primary} btn`}>
          مرحله بعد
        </Link>
      </Modal.Footer>
    </>
  );
}
