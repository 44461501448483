import styles from "./DotsLoading.module.scss";

export default function DotsLoading() {
  return (
    <div className={styles.wrapper}>
      <span className={styles.one}>.</span>
      <span className={styles.two}>.</span>
      <span className={styles.three}>.</span>
    </div>
  );
}
