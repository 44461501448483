import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useAlbumQuery } from "hooks/query/useMusicQuery";
import { fetchAlbumInformations, resetMusicForm, updateMusicCover, updateMusicForm } from "../../redux/actions/musics";
import AudioContainer from "./audioContainer/AudioContainer";
import CustomTab from "./customTab/CustomTab";
import Form from "./form/Form";
import Header from "./header/Header";
import AlbumCover from "./uploaders/albumCover/AlbumCover";
import styles from "./NewMusic.module.scss";

function NewMusic() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { cover } = useSelector((state) => state.musicFormReducer);
  const { data } = useAlbumQuery(id);

  useEffect(() => {
    if (id && data) {
      dispatch(fetchAlbumInformations(data));
      // dispatch(updateMusicCover(data.cover));
    }
  }, [data?.cover, data, dispatch, id]);

  // common usage of fetch
  useEffect(() => {
    return () => {
      dispatch(resetMusicForm());
    };
  }, [dispatch]);

  const handleChangeCover = useCallback(
    (coverFile) => {
      dispatch(updateMusicCover(coverFile));
    },
    [dispatch]
  );

  return (
    <div>
      <Header />

      <div className={styles.formContainer}>
        {!id && <CustomTab />}

        <div className={styles.row}>
          <div className={styles.albumWrapper}>
            <AlbumCover
              initialImage={cover}
              onImageChange={handleChangeCover}
            />
          </div>

          <div className={styles.audioWrapper}>
            <AudioContainer />
          </div>
        </div>

        <Form />
      </div>
    </div>
  );
}

export default NewMusic;
