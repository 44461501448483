import PropTypes from 'prop-types';

import styles from './SwitchTwoLabel.module.scss';

function SwitchTwoLabel({ leftLabel, rightLabel, switchState, theme = 'natural' }) {
	const [toggle, setToggle] = switchState;

	return (
		<div
			className={`${styles.group}  ${styles[theme]} ${
				toggle ? styles.toggleRight : styles.toggleLeft
			}`}
			onClick={() => setToggle(!toggle)}
		>
			<span className={`${styles.rightLabel}`}>{rightLabel}</span>

			<div className={`${styles.switchBtn}`}>
				<div className={`${styles.toggleBtn}`}></div>
			</div>

			<span className={`${styles.leftLabel}`}>{leftLabel}</span>
		</div>
	);
}

SwitchTwoLabel.propTypes = {
	leftLabel: PropTypes.string,
	rightLabel: PropTypes.string,
	theme: PropTypes.oneOf(['natural', 'colorful']),
	switchState: PropTypes.array.isRequired, // tuple of ([PropTypes.bool, PropTypes.func])
};

export default SwitchTwoLabel;
