import {
  usePostStreamQuery,
  usePutStreamQuery,
} from "hooks/query/useStreamQuery";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { postFile } from "services/files/files";
import toasts from "../../../common/toasts/toasts";
import styles from "./TabHeader.module.scss";

function TabHeader() {
  const { id } = useParams();
  const { mutate: postLiveStream } = usePostStreamQuery();
  const { mutate: updateLiveStream } = usePutStreamQuery("update");
  const liveStreaming = useSelector((state) => state.setLiveStreamingReducer);
  const createAttachment = async () => {
    if (liveStreaming.cover_id?.path) {
      try {
        toasts.warn("درحال آپلود کاور پخش زنده");
        const fileResponse = await postFile(liveStreaming.cover_id);
        return fileResponse;
      } catch (error) {
        console.log(error);
        toasts.error("ثبت کاور پخش زنده با مشکل مواجه شد");
      }
    }
  };
  const handleCreateLiveStream = async () => {
    let liveCover = "";
    if (liveStreaming.cover_id.path?.length) {
      const response = await createAttachment();
      if (response.status === 200) {
        liveCover = response.data.data;
      }
    }

    postLiveStream({
      ...liveStreaming,
      ...(liveCover && { cover_id: liveCover }),
    });
  };
  const handleUpdateLiveStream = async () => {
    let liveCover = "";

    if (liveStreaming.cover_id?.path && !liveStreaming.cover_id?.id) {
      const response = await createAttachment();
      if (response.status === 200) {
        liveCover = response.data.data;
      }
    } else if (liveStreaming.cover_id === "") {
      liveCover = "";
    }
    updateLiveStream({
      id: +id,
      ...liveStreaming,
      ...(liveCover && { cover_id: liveCover }),
    });
  };
  const submit = async () => {
    if (liveStreaming.title && liveStreaming.stream_key) {
      if (id) {
        await handleUpdateLiveStream();
      } else {
        await handleCreateLiveStream();
      }
    } else if (!liveStreaming.title) {
      toasts.error("عنوان پخش زنده را وارد نمایید");
    } else if (!liveStreaming.stream_key) {
      toasts.error("اطلاعات مربوط به پخش زنده ثبت نشده است.(دریافت stream key)");
    }
  };

  return (
    <div className={styles.header}>
      <Link to="/stream" className={`${styles.btn} ${styles.danger}`}>
        انصراف
      </Link>
      <div onClick={submit} className={styles.btn}>
        <img src={`${process.env.PUBLIC_URL}/images/icons/save.svg`} alt="" />
        ثبت تغییرات
      </div>
    </div>
  );
}

export default TabHeader;
