import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import styles from "./styles.module.scss";
export const Input = (props) => {
  const [isFocussed, setIsFocussed] = useState(false);
  const [isBlured, setIsBlured] = useState(false);
  useEffect(() => {
    if (!props.value && isBlured && !isFocussed) {
      setIsBlured(false);
      setIsFocussed(false);
    }
  }, [isBlured, isFocussed, props.value]);
  useEffect(() => {
    if (props.value) {
      setIsBlured(true);
    }
  }, [props.value]);

  return (
    <Form.Group
      className={`${styles.group} ${
        isFocussed || isBlured ? styles.group__focussed : ""
      }`}>
      {props.label && <label className={styles.label}>{props.label}</label>}
      {props.title && <span className={styles.title}>{props.title}</span>}
      <input
        type={props.type ? props.type : "text"}
        name={props.name}
        className={`form-control ${styles.formControl}`}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onFocus={() => {
          setIsFocussed(true);
        }}
        onBlur={(e) => {
          if (e.target.value) {
            setIsBlured(true);
          }
          setIsFocussed(false);
        }}
        // onKeyPress={props.onKeyPress}
        value={props.value}
        readOnly={props.isReadonly}
        autoComplete={`${props.autoComplete || "off"}`}
        {...props}
      />
      {props.image?.enable && (
        <img
          className={styles.inputImageIcon}
          src={`${process.env.PUBLIC_URL}${props.image.icon}`}
          alt=""
          onClick={props.image.clickHandler}
        />
      )}
    </Form.Group>
  );
};
export default Input;
