import { useEffect } from "react";
import { useSingleImageDropZone } from "hooks/dropzone";
import styles from "./LogoDropzone.module.scss";

function LogoDropzone({ image, onImageChange }) {
  const { getInputProps, getRootProps, imageFile, imagePreview } =
    useSingleImageDropZone(image);

  useEffect(() => {
    onImageChange(imageFile);
  }, [imageFile, onImageChange]);

  return (
    <div
      {...getRootProps({
        className: styles.dropzone,
        style: {
          background: imagePreview
            ? ` center / cover no-repeat url(${imagePreview})`
            : "#71718e",
        },
      })}>
      <input {...getInputProps()} />
      {!imagePreview && (
        <div className={styles.section}>
          <img
            src={`${process.env.PUBLIC_URL}/images/categories/camera.svg`}
            alt=""
          />
        </div>
      )}
    </div>
  );
}

export default LogoDropzone;