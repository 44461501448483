import { useEffect, useState } from "react";
import { Input } from "components/common/input/Input";
import DropDown from "components/common/dropDown/DropDown";
import styles from "./Resolutions.module.scss";

export default function Resolutions({
  data,
  resolutionsConstants,
  liveConfigData,
  handleAddResolution,
  handleRemoveResolution,
  handleChangeResolution,
  maxLength,
  length,
  index,
}) {
  const [info, setInfo] = useState({
    audio_bitrate: {
      max: "",
      min: "",
      recommended: "",
    },
    video_bitrate: {
      max: "",
      min: "",
      recommended: "",
    },
  });
  const handleChangeFields = (options) => {
    const getNeedData = liveConfigData?.filter(
      (f) => f.resolution === options?.name
    )[0];
    if (options?.name) {
      setInfo({
        audio_bitrate: {
          max: getNeedData?.max_audio_bitrate,
          min: getNeedData?.min_audio_bitrate,
          recommended: getNeedData?.recommended_audio_bitrate,
        },
        video_bitrate: {
          max: getNeedData?.max_video_bitrate,
          min: getNeedData?.min_video_bitrate,
          recommended: getNeedData?.recommended_video_bitrate,
        },
      });
      handleChangeResolution({
        ...data,
        resolution: options,
        audio: {
          max: getNeedData?.max_audio_bitrate,
          min: getNeedData?.min_audio_bitrate,
        },
        video: {
          max: getNeedData?.max_video_bitrate,
          min: getNeedData?.min_video_bitrate,
        },
      });
    } else {
      setInfo({
        audio_bitrate: {
          max: "",
          min: "",
        },
        video_bitrate: {
          max: "",
          min: "",
        },
      });
    }
  };
  const handleChangeInputs = (e) => {
    handleChangeResolution({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (info.audio_bitrate.recommended) {
      handleChangeResolution({
        ...data,
        audio_bitrate: info.audio_bitrate.recommended,
        video_bitrate: info.video_bitrate.recommended,
      });
    } else {
      handleChangeResolution({
        ...data,
        audio_bitrate: "",
        video_bitrate: "",
        video: "",
        audio: "",
        resolution: "",
      });
    }
  }, [info]);

  return (
    <div className={styles.row}>
      <DropDown
        placeholder="کیفیت پخش"
        options={resolutionsConstants}
        value={data.resolution}
        getOptionLabel={(option) => option["name"]}
        getOptionValue={(option) => option["id"]}
        onChange={(options) => handleChangeFields(options)}
        name="resolution"
      />
      <Input
        type="number"
        label={
          info.audio_bitrate.max
            ? `عدد بین ${info.audio_bitrate.min} الی ${info.audio_bitrate.max} مجاز است`
            : "Audio bitrate"
        }
        placeholder="Audio bitrate"
        name="audio_bitrate"
        value={data.audio_bitrate}
        onChange={handleChangeInputs}
      />
      <Input
        type="number"
        label={
          info.video_bitrate.max
            ? `عدد بین ${info.video_bitrate.min} الی ${info.video_bitrate.max} مجاز است`
            : "Video bitrate"
        }
        placeholder="Video bitrate"
        name="video_bitrate"
        value={data.video_bitrate}
        onChange={handleChangeInputs}
      />
      <button
        onClick={handleRemoveResolution}
        className={`btn ${styles.delete}`}>
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/trash-white.svg`}
          alt="remove"
        />
      </button>
      {length === index + 1 ? (
        <button
          disabled={maxLength}
          onClick={handleAddResolution}
          className={`btn ${styles.add}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/add-black.svg`}
            alt="add"
          />
        </button>
      ) : (
        <i></i>
      )}
    </div>
  );
}
