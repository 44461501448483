import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateArticleForm } from "../../../redux/actions/articles";
import { Input } from "../../common/input/Input";
import { useArticleQuery } from "hooks/query/useArticleQuery";
import DropDown from "../../common/dropDown/DropDown";
import TextEditor from "../../textEditor/TextEditor";
import ImageUploader from "../imageUploader/ImageUploader";
import styles from "./Form.module.scss";

const articleStatus = [
  {
    label: "منتشر شده",
    value: "published",
  },
  {
    label: "منتشر نشده",
    value: "unpublished",
  },
];

function Form({ id }) {
  const dispatch = useDispatch();

  const { data } = useArticleQuery(id);
  useEffect(() => {
    if (data) {
      dispatch(
        updateArticleForm({
          image: data.image_id,
          title: data.title,
          subTitle: data.sub_title,
          text: data.text,
          articleStatus: data.article_status,
        })
      );
    }
  }, [data, dispatch]);

  const form = useSelector((state) => state.articleFormReducer);

  const onChangeStatusDropdown = (option, meta) => {
    updateForm(meta.name, option.value);
  };
  const onChangeInput = (e) => {
    updateForm(e.target.name, e.target.value);
  };
  const onEditorChange = (e) => {
    updateForm("text", e);
  };
  const onChangeImage = (file) => {
    updateForm("image", file);
  };
  const updateForm = (field, value) => {
    dispatch(updateArticleForm({ [field]: value }));
  };

  const selectedStatus = articleStatus.find(
    (item) => item.value === form.articleStatus
  );

  return (
    <>
      <div className={styles.uploaderWrapper}>
        <ImageUploader imageState={[form.image, onChangeImage]} />
      </div>

      <div className={styles.flex}>
        <Input
          label="تیتر مقاله"
          placeholder="تیتر مقاله"
          name="title"
          value={form.title}
          onChange={onChangeInput}
          require
        />
        <Input
          label="زیر تیتر"
          placeholder="زیر تیتر"
          name="subTitle"
          value={form.subTitle}
          onChange={onChangeInput}
          require
        />
      </div>

      <div className={styles.richtextWrapper}>
        <TextEditor
          // placeholder="متن مقاله"
          value={form.text}
          onChange={onEditorChange}
        />
      </div>

      <div className={styles.statusWrapper}>
        <div className={styles.title}>وضعیت نمایش</div>

        <div className={styles.flex}>
          <DropDown
            label="وضعیت انتشار"
            placeholder="وضعیت انتشار"
            name="articleStatus"
            options={articleStatus}
            value={selectedStatus}
            onChange={onChangeStatusDropdown}
          />
        </div>
      </div>
    </>
  );
}

export default Form;
