import http from "../httpService";
//Language
export const getAllMyLanguage = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/languages/my`, {
    signal,
  });
export const postUserLanguage = (data) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/languages/new`,
    data
  );
};
export const deleteUserLanguage = (languageId) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/languages/delete?language_id=${languageId}`
  );
};
//Countries
export const getAllMyCountrie = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/countries/my`, {
    signal,
  });
export const postUserCountrie = (data) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/countries/new`,
    data
  );
};
export const deleteUserCountrie = (countryId) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/countries/delete?country_id=${countryId}`
  );
};
