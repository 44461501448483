import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deletePlayList,
  getPlayList,
  getPlayLists,
} from "services/playList/playListServices";
import toasts from "components/common/toasts/toasts";

export const usePlaylistsQuery = (page) => {
  return useQuery(["playlists", page], ({ signal }) => getPlayLists(page,10,signal), {
    keepPreviousData: true,
    select: (data) => {
      return data.data.data;
    },
  });
};
export const usePlaylistQuery = (id) => {
  return useQuery(["playlist", id], ({ signal }) => getPlayList(id,signal), {
    enabled: !!id,
    select: (data) => {
      return data.data.data;
    },
  });
};

export const useDeletePlaylistQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deletePlayList, {
    onSuccess: () => {
      queryClient.invalidateQueries("playlists");
      toasts.success(`پلی لیست ${title} با موفقیت حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
