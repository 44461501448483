import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getEpisodesFromImdb } from "../../../../../services/series/series";
import {
  addNewSesons,
  addSeriesSubtitleToRemoved,
  getSeriesSeasons,
  removeSesons,
} from "../../../../../redux/actions/series";
import { useSerieQuery } from "hooks/query/useSerieQuery";
import { nanoid } from "nanoid";
import toasts from "../../../../common/toasts/toasts";
import Accordion from "../../../../common/accordion/Accordion";
import EpisodeInfo from "./EpisodeInfo/EpisodeInfo";
import styles from "./Seasons.module.scss";

export default function Seasons() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data } = useSerieQuery(id);
  const seasonList = useSelector((state) => state.addNewSeasonEpisodeReducer);
  // state for active episode
  const [episodeData, setEpisodeData] = useState(null);
  const [border, setBorder] = useState(null);

  useEffect(() => {
    if (data?.seasons?.length) {
      dispatch(getSeriesSeasons(data.seasons));
    }
  }, [data?.seasons, dispatch]);

  const handleActiveBorder = (data, seasonId) => {
    if (border !== null && (border === data.imdb_id || border === data.id)) {
      setBorder(null);
      setEpisodeData(null);
    } else {
      setBorder(data.id || data.imdb_id);
      setEpisodeData({
        data: data,
        seasonId: seasonId,
      });
    }
  };

  const handleAddNewSeason = () => {
    const fakeId = nanoid();
    dispatch(
      addNewSesons({
        id: fakeId,
        title: seasonList.length + 1,
      })
    );
  };

  const handleRemoveSeason = (seasonData) => {
    dispatch(
      removeSesons({
        id: seasonData.id,
      })
    );
    if (id) {
      dispatch(addSeriesSubtitleToRemoved(seasonData));
    }
  };

  // sync data from IMDB
  //TODO: think about one button show loading...
  const [showLoading, setShowLoading] = useState(false);
  const handleGetEpisodesFromImdb = async () => {
    if (episodeData) {
      setShowLoading(true);
      try {
        const { status } = await getEpisodesFromImdb(episodeData.data.id);
        if (status === 200) {
          toasts.success(
            `اطلاعات قسمت ${episodeData.data.title} همگام سازی شد.`
          );
          setShowLoading(false);
        }
      } catch (error) {
        setShowLoading(false);
        console.log(error);
        toasts.error("مشکلی پیش آمده است.");
      }
    }
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.right}>
        {seasonList
          ?.sort((a, b) => a.id - b.id)
          ?.map((item, index) => (
            <Accordion
              id={id}
              handleActiveBorder={(data, seasonId) =>
                handleActiveBorder(data, seasonId)
              }
              handleRemoveSeason={() => handleRemoveSeason(item)}
              key={item.id || item.title}
              data={item}
              index={index}
              border={border}
              handleGetEpisodesFromImdb={handleGetEpisodesFromImdb}
              showLoading={showLoading}
              seasonList={seasonList}
            />
          ))}
        <div onClick={handleAddNewSeason} className={styles.addNew}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/add-square.svg`}
            alt="+"
          />
          افزودن فصل {seasonList?.length + 1}
        </div>
      </div>
      <div className={styles.left}>
        <EpisodeInfo data={episodeData} />
      </div>
    </section>
  );
}
