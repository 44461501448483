import { useState } from "react";
import { Input } from "components/common/input/Input";
import { Modal } from "react-bootstrap";
import { CloseButton } from "components/packageAndFeatures/components/Components";
import DropDown from "components/common/dropDown/DropDown";
import TextArea from "components/common/textArea/TextArea";
import styles from "./styles.module.scss";

export const options = [
  {
    id: "بالا",
    title: "بالا",
  },
  {
    id: "متوسط",
    title: "متوسط",
  },
  {
    id: "پایین",
    title: "پایین",
  },
];

export default function NewTicket({ onHide }) {
  const [form, setForm] = useState({
    section: "",
    priority: "",
    title: "",
    description: "",
  });
  const onChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleChangeDropdown = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  return (
    <div className={styles.wrapper}>
      <Modal.Header className={styles.header}>
        <CloseButton position="top" onClick={onHide} />
        <Modal.Title className={styles.header__title}>
          ارسال تیکت جدید
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <DropDown
          label=""
          placeholder="بخش"
          name="section"
          options={options}
          value={form.section}
          getOptionLabel={(option) => option["title"]}
          getOptionValue={(option) => option["id"]}
          onChange={(e) => handleChangeDropdown(e, "section")}
        />
        <DropDown
          label=""
          placeholder="اولویت"
          name="priority"
          options={options}
          value={form.priority}
          getOptionLabel={(option) => option["title"]}
          getOptionValue={(option) => option["id"]}
          onChange={(e) => handleChangeDropdown(e, "priority")}
        />
        <Input
          placeholder="موضوع"
          onChange={onChangeInput}
          value={form.title}
          name="title"
        />
        <TextArea
          placeholder="متن تیکت"
          onChange={onChangeInput}
          value={form.description}
          name="description"
        />
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <button className={`${styles.cancelBtn} btn`} onClick={onHide}>
          انصراف
        </button>
        <button
          className={styles.button}
          // onClick={onSubmit}
        >
          ارسال تیکت
        </button>
      </Modal.Footer>
    </div>
  );
}
