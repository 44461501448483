import videojs from "video.js";
import "./preRollAd.css";

const Plugin = videojs.getPlugin("plugin");

class PreRollAd extends Plugin {
  constructor(player, options) {
    super(player);
    this.div = null;
    this.time = null;
    this.disabled = false;

    this.player.ready(() => {
      if (!options) return;
      this.preRollAd(options);
    });
  }
  dispose() {
    this.disabled = true;
    this.div.remove();
    super.dispose();
  }
  preRollAd(options) {
    const _this = this;
    this.time = options.seconds;
    let isPaused = false;
    if (!this.div) {
      this.div = document.createElement("div");
      this.div.className = "vjs-skip-ad";
      this.div.innerHTML = options.text + "  " + this.time.toString();
    } else {
      this.div.innerHTML = options.text + "  " + this.time.toString();
    }
    let interval = setInterval(() => {
      if (_this.time === 0) {
        _this.div.innerHTML = options.text;
        clearInterval(interval);
      } else {
        if (!isPaused) _this.time -= 1;
        _this.div.innerHTML = options.text + "  " + _this.time.toString();
      }
    }, 1000);
    this.player.on("pause", () => {
      if (!_this.disabled) {
        isPaused = true;
      }
    });
    this.player.on("play", () => {
      if (!_this.disabled) {
        isPaused = false;
      }
    });
    this.div.addEventListener("click", () => {
      if (_this.time === 0) {
        _this.player.trigger("ended");
      }
    });
    this.player.el().appendChild(this.div);
  }
}

videojs.registerPlugin("preRollAd", PreRollAd);

export default PreRollAd;
