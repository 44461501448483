export const loadPaginateOptions =
  (fetcher, name) =>
  async (searchQuery, loadedOptions, { page }) => {
    const { data } = await fetcher(page, 10, name);

    return {
      options: data.data.results,
      hasMore: data.data.pagination.pages > page,
      additional: {
        page: page + 1,
      },
    };
  };
