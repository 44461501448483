import http from "../httpService";

export const getTracks = (page, limit, signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/tracks/get-all?page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    { signal }
  );
};

export const getTrack = (trackId, signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/tracks/get?id=${trackId}`,
    {
      signal,
    }
  );
};

export const postTrack = (trackPayload) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/tracks/new`,
    trackPayload
  );
};

export const putTrack = (trackPayload) => {
  return http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/tracks/update`,
    trackPayload
  );
};

export const deleteTrack = (trackId) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/tracks/delete?id=${trackId}`
  );
};

export const patchAppendSinger = (singerPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/tracks/singers/append`,
    singerPayload
  );
};

export const patchDeleteSinger = (singerPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/tracks/singers/delete`,
    singerPayload
  );
};

// NOT USED
export const getTracksOfAnAlbum = (albumId) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/tracks/get-all-by-album`
  );
};
