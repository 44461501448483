import { data } from "../../../constants/features";
import Card from "./components/Card";
import styles from "./FeaturesSection.module.scss";
export default function FeaturesSection() {
  return (
    <section id="features" className={styles.wrapper}>
      <div className={styles.title}>
        <h2>امکانات و ویژگی ها</h2>
      </div>
      <div className={styles.cardWrapper}>
        {data.map((card, index) => (
          <Card key={index} data={card} />
        ))}
      </div>
    </section>
  );
}