import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import styles from "./CustomDropDown.module.scss";
import NoticeModal from "../../../common/modals/noticeModal/NoticeModal";
import DeleteModal from "../../../common/modals/deleteModal/DeleteModal";
import AddProjectModal from "../AddProjectModal/AddProjectModal";

function CustomDropdown({ id, handleDelete }) {
  const [show, setShow] = useState(false);

  const [showEditNoticeModal, setShowEditNoticeModal] = useState(false);
  const [showDelNoticeModal, setShowDelNoticeModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);

  return (
    <>
      <Dropdown
        className={styles.dropdown}
        show={show}
        onToggle={(e) => setShow(!show)}>
        <Dropdown.Toggle className={styles.dropdownToggle}>
          <img
            src={`${process.env.PUBLIC_URL}/images/productAndServices/dots.svg`}
            alt=""
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.dropdownMenu}>
          <div className={styles.menuNav}>
            تنظیمات پروژه
            <img
              className={styles.icon}
              src={`${process.env.PUBLIC_URL}/images/productAndServices/close.svg`}
              alt=""
              onClick={() => setShow(false)}
            />
          </div>
          <Dropdown.Item
            className={styles.item}
            as={Link}
            onClick={() => setShowAddProjectModal(true)}>
            ایجاد پروژه جدید
            <img
              className={styles.icon}
              src={`${process.env.PUBLIC_URL}/images/productAndServices/add-project.svg`}
              alt=""
            />
          </Dropdown.Item>
          <Dropdown.Item
            className={styles.item}
            as={Link}
            // TODO: give param for edit
            to={`/project`}>
            مشاهده پروژه
            <img
              className={styles.icon}
              src={`${process.env.PUBLIC_URL}/images/productAndServices/show-project.svg`}
              alt=""
            />
          </Dropdown.Item>
          <Dropdown.Item
            className={styles.item}
            as={Link}
            onClick={() => setShowEditNoticeModal(true)}>
            ویرایش پروژه
            <img
              className={styles.icon}
              src={`${process.env.PUBLIC_URL}/images/productAndServices/edit-project.svg`}
              alt=""
            />
          </Dropdown.Item>
          <Dropdown.Item
            className={styles.item}
            onClick={() => setShowDelNoticeModal(true)}>
            خروج از پروژه
            <img
              className={styles.icon}
              src={`${process.env.PUBLIC_URL}/images/productAndServices/del-project.svg`}
              alt=""
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {showEditNoticeModal && (
        <Modal
          dialogClassName={styles.showingModal}
          show={showEditNoticeModal}
          onHide={() => setShowEditNoticeModal(false)}>
          <NoticeModal
            title="ویرایش پروژه"
            onHide={() => setShowEditNoticeModal(false)}
          />
        </Modal>
      )}
      {showDelNoticeModal && (
        <Modal
          dialogClassName={styles.showingModal}
          show={showDelNoticeModal}
          onHide={() => setShowDelNoticeModal(false)}>
          <NoticeModal
            title="حذف پروژه"
            onHide={() => setShowDelNoticeModal(false)}
            onNextStep={() => {
              setShowDeleteProjectModal(true);
              setShowDelNoticeModal(false);
            }}
          />
        </Modal>
      )}
      {showDeleteProjectModal && (
        <Modal
          dialogClassName={styles.showingModal}
          show={showDeleteProjectModal}
          onHide={() => setShowDeleteProjectModal(false)}>
          <DeleteModal onHide={() => setShowDeleteProjectModal(false)} />
        </Modal>
      )}
      {showAddProjectModal && (
        <Modal
          show={showAddProjectModal}
          onHide={() => setShowAddProjectModal(false)}
          dialogClassName={styles.addProjectModal}>
          <AddProjectModal onHide={() => setShowAddProjectModal(false)} />{" "}
        </Modal>
      )}
    </>
  );
}

export default CustomDropdown;