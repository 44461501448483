import { useTranscodeServersQuery } from "hooks/query/useCastsQuery";
import { nanoid } from "nanoid";
import { useDispatch } from "react-redux";
import { postTranscodeFiles } from "services/transcode/transcodeServices";
import { managerUploadTypes } from "../../../constants/uploadManager";
import { useTranscodeWebsocket } from "../../../hooks/transcode";
import {
  addFileToQueue,
  removeFileFromQueue,
  updateVideoProgress,
} from "../../../redux/actions/videoUploadManager";
import { postFile, uploadVideo } from "../../../services/files/files";
import {
  patchAppendEpisodeToSeasonOfSeries,
  patchAppendSubtitles,
  postEpisodesDownloadLink,
  putUpdateEpisodeOfSeason,
} from "../../../services/series/series";
import { createFileQueueBody } from "../../../util/uploadManager";
import toasts from "../../common/toasts/toasts";

export const handlePatchSubtitles = async (files, id) => {
  if (!files.id) {
    toasts.warn("در حال آپلود زیرنویس های سریال...");

    try {
      const response = await postFile(files);
      if (response.status === 200) {
        const { status } = await patchAppendSubtitles({
          episode_id: parseInt(id),
          language_id: files.language_id,
          url: response.data.data,
          title: files.name,
        });
        if (status === 200) {
          toasts.success(`زیرنویس های سریال اضافه شدند.`);
        }
      }
    } catch (error) {
      console.error(error);
      toasts.error("مشکلی در افزودن زیرنویس ها به وجود آمده");
    }
  }
};
export const handlePatchSeriesDownloadLink = async (
  item,
  episodeId,
  seasonId
) => {
  try {
    const { status } = await postEpisodesDownloadLink({
      title: item.title,
      download_link: item.download_link,
      link_screenshot: item.link_screenshot,
      encoder: item.encoder.name,
      episode_id: parseInt(episodeId),
      quality: item.quality.name,
      season_id: parseInt(seasonId),
      size: item.size,
      extra_data: item.extra_data,
    });
    if (status === 200) {
      toasts.success(`لینک های دانلود سریال اضافه شدند.`);
    }
  } catch (error) {
    console.error(error);
    toasts.error("مشکلی در افزودن لینک های دانلود سریال به وجود آمده");
  }
};

export const useUploadingVideos = () => {
  const dispatch = useDispatch();

  const { connectToTranscodeWebsocket } = useTranscodeWebsocket({
    transcodeType: managerUploadTypes.SERIES,
  });

  // GET transcode servers
  const { data: servers } = useTranscodeServersQuery();

  const uploadingVideos = async (episode, seasonData, seasonID) => {
    const fakeId = nanoid();
    const seriesBody = createFileQueueBody({
      id: fakeId,
      title: episode.title,
      size: episode.file.size,
      type: managerUploadTypes.SERIES,
      event: "download",
    });

    dispatch(addFileToQueue(seriesBody));

    try {
      toasts.warn("در حال آپلود ویدیوهای سریال...");
      const videoResponse = await uploadVideo(
        episode.file,
        (progress) => {
          dispatch(updateVideoProgress(fakeId, progress));
        },
        episode.server ? episode.server.link : servers[0].link,
        episode.server ? episode.server.id : servers[0].id
      );

      if (videoResponse.status === 200) {
        // Begin connecting to transcode websocket
        connectToTranscodeWebsocket({
          title: episode.title,
          server: episode.server ? episode.server.link : servers[0].link,
          transcodeId: videoResponse.data.data.transcode_id,
          transcodeResolutions: videoResponse.data.data.resolutions,
        });

        if (episode.id) {
          const { status } = await putUpdateEpisodeOfSeason({
            id: parseInt(episode.id),
            title: String(episode.title),
            imdb_id: String(episode.title),
            content_url: videoResponse.data.data.url,
          });
          if (status === 200) {
            toasts.success(`قسمت ${episode.title} سریال آپدیت شد.`);
            dispatch(removeFileFromQueue(fakeId));
            if (episode.subtitles) {
              episode.subtitles.forEach(async (f) => {
                await handlePatchSubtitles(f, episode.id);
              });
            }
            if (episode.links) {
              episode.links.forEach(async (f) => {
                if (!f.id) {
                  await handlePatchSeriesDownloadLink(
                    f,
                    episode.id,
                    seasonData.id
                  );
                }
              });
            }
          } else {
            dispatch(removeFileFromQueue(fakeId));
            toasts.error("متاسفانه ویدیو شما آپلود نشد.");
          }
        } else {
          const { status, data } = await patchAppendEpisodeToSeasonOfSeries({
            season_id: parseInt(seasonID),
            title: String(episode.title),
            imdb_id: String(episode.title),
            content_url: videoResponse.data.data.url,
          });
          if (status === 200) {
            toasts.success(`قسمت ${episode.title} سریال اضافه شد.`);
            dispatch(removeFileFromQueue(fakeId));
            if (episode.subtitles) {
              episode.subtitles.forEach(async (f) => {
                await handlePatchSubtitles(f, data.data.id);
              });
            }
            if (episode.links) {
              episode.links.forEach(async (f) => {
                if (!f.id) {
                  await handlePatchSeriesDownloadLink(
                    f,
                    data.data.id,
                    data.data.season_id
                  );
                }
              });
            }
          } else {
            dispatch(removeFileFromQueue(fakeId));
            toasts.error("متاسفانه ویدیو شما آپلود نشد.");
          }
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        toasts.error("متاسفانه ویدیو شما آپلود نشد.");
      } else {
        toasts.error("متاسفانه ویدیو شما آپلود نشد.");
      }
      console.log(error);
      dispatch(removeFileFromQueue(fakeId));
    }
  };

  return { uploadingVideos };
};

export const useVideoUrlForTranscode = () => {
  const { connectToTranscodeWebsocket } = useTranscodeWebsocket({
    transcodeType: managerUploadTypes.SERIES,
  });

  // GET transcode servers
  const { data: servers } = useTranscodeServersQuery();

  const transcodingVideos = async (episode, seasonData, seasonID) => {
    try {
      toasts.warn("در حال ترنسکد ویدیوهای سریال...");
      const transcodeResponse = await postTranscodeFiles(episode.server.link, {
        base_url: episode.server.link,
        url: episode.linkURL,
        streamer_url: window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL,
        transcode_server_id: String(episode.server.id),
      });

      if (transcodeResponse.status === 200) {
        // Begin connecting to transcode websocket
        connectToTranscodeWebsocket({
          title: episode.title,
          server: episode.server ? episode.server.link : servers[0].link,
          transcodeId: transcodeResponse.data.data.transcode_id,
          transcodeResolutions: transcodeResponse.data.data.resolutions,
        });

        if (episode.id) {
          const { status } = await putUpdateEpisodeOfSeason({
            id: parseInt(episode.id),
            title: String(episode.title),
            imdb_id: String(episode.title),
            content_url: transcodeResponse.data.data.url,
          });
          if (status === 200) {
            toasts.success(`قسمت ${episode.title} سریال آپدیت شد.`);
            if (episode.subtitles) {
              episode.subtitles.forEach(async (f) => {
                await handlePatchSubtitles(f, episode.id);
              });
            }
            if (episode.links) {
              episode.links.forEach(async (f) => {
                if (!f.id) {
                  await handlePatchSeriesDownloadLink(
                    f,
                    episode.id,
                    seasonData.id
                  );
                }
              });
            }
          } else {
            toasts.error("متاسفانه ویدیو شما آپلود نشد.");
          }
        } else {
          const { status, data } = await patchAppendEpisodeToSeasonOfSeries({
            season_id: parseInt(seasonID),
            title: String(episode.title),
            imdb_id: String(episode.title),
            content_url: transcodeResponse.data.data.url,
          });
          if (status === 200) {
            toasts.success(`قسمت ${episode.title} سریال اضافه شد.`);
            if (episode.subtitles) {
              episode.subtitles.forEach(async (f) => {
                await handlePatchSubtitles(f, data.data.id);
              });
            }
            if (episode.links) {
              episode.links.forEach(async (f) => {
                if (!f.id) {
                  await handlePatchSeriesDownloadLink(
                    f,
                    data.data.id,
                    data.data.season_id
                  );
                }
              });
            }
          } else {
            toasts.error("متاسفانه ویدیو شما آپلود نشد.");
          }
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        toasts.error("متاسفانه ویدیو شما آپلود نشد.");
      } else {
        toasts.error("متاسفانه ویدیو شما آپلود نشد.");
      }
      console.log(error);
    }
  };

  return { transcodingVideos };
};
