import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToJalaliFormat } from "../../../util/toJalali";
import ContentRequestModal from "../contentRequestModal/ContentRequestModal";
import styles from "../ContentRequest.module.scss";

function Row({ data }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <tr className={styles.row}>
        <td>
          <div>
            {data.member.avatar?.length ? (
              <img
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.member.avatar}`}
                alt=""
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/images/users/user-default.svg`}
                alt=""
              />
            )}
            <span>{data.member.fullname}</span>
          </div>
        </td>
        <td>{data.name}</td>
        <td>{data.genre ? data.genre.name : "-"}</td>
        <td>{ToJalaliFormat(data.created_at)}</td>
        <td>
          {data.description}

          <div className={styles.button} onClick={() => setShowModal(true)}>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/common/eye.svg`}
                alt=""
              />
            </div>
          </div>
        </td>
      </tr>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={styles.modal}
        centered>
        <ContentRequestModal data={data} onHide={() => setShowModal(false)} />
      </Modal>
    </>
  );
}

export default Row;
