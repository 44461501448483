import { Modal } from "react-bootstrap";
import { useDeleteRequestsQuery } from "hooks/query/useRequestQuery";
import { CloseButton } from "../../packageAndFeatures/components/Components";
import { Input } from "../../common/input/Input";
import TextArea from "../../common/textArea/TextArea";
import styles from "../ContentRequest.module.scss";

function ContentRequestModal({ data, onHide }) {
  const { mutate: deleteRequestTitle } = useDeleteRequestsQuery(data.name);

  const handleRemoveItem = () => {
    deleteRequestTitle(data.id);
    // onHide();
  };

  return (
    <div className={styles.modalWrapper}>
      <CloseButton position="top" onClick={onHide} />
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>جزئیات درخواست</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.inputWrapper}>
          <Input label="درخواست کننده" value={data.member.fullname} readOnly />
          <Input label="نام فیلم" value={data.name} readOnly />
          <Input
            label="ژانر"
            value={data.genre ? data.genre.name : "-"}
            readOnly
          />
        </div>

        <div className={styles.inputWrapper}>
          <Input
            label="سال ساخت"
            value={data.year ? data.year : "-"}
            readOnly
          />
          <Input
            label="لینک IMDB"
            value={data.imdb_link ? data.imdb_link : "-"}
            readOnly
          />
          <Input label="نوع محصول" value={data.type} readOnly />
        </div>
        {data.type === "series" && (
          <div className={styles.inputWrapper}>
            <Input
              label="تعداد فصل"
              value={data.season_count ? data.season_count : "-"}
              readOnly
            />
          </div>
        )}

        <TextArea label="متن در خواست" value={data.description} readOnly />

        <div className={styles.buttonWrapper}>
          <button
            className={`${styles.btn} ${styles.outline}`}
            onClick={handleRemoveItem}>
            <img
              src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/trash.svg`}
              alt=""
            />
            رد درخواست
          </button>
          {/* <button className={`${styles.btn} ${styles.primary}`} onClick={onHide}>
						<img
							src={`${process.env.PUBLIC_URL}/images/common/add-white-square.svg`}
							alt=""
						/>
						افزودن فیلم
					</button> */}
        </div>
      </Modal.Body>
    </div>
  );
}

export default ContentRequestModal;
