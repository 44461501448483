import http from "../httpService";
export const getCategories = (signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/category/get-all`,
    {
      signal,
    }
  );
};
export const getCategory = (id, signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/category/get?id=${id}`,
    {
      signal,
    }
  );
};
export const postCategory = (data) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/category/new`,
    data
  );
};
export const putCategory = (data) => {
  return http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/category/update`,
    data
  );
};
export const deleteCategory = (id) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/category/delete?id=${id}`
  );
};
