import { useState, useRef } from "react";
import { useHistory } from "react-router";
import { Table, Spinner } from "react-bootstrap";
import { Input } from "components/common/input/Input";
import ActorsRow from "./actorsRow/ActorsRow";
import EmptyState from "components/common/emptyState/EmptyState";
import styles from "./ActorsList.module.scss";

export default function ActorsList({
  data,
  isPreviousData,
  refetch,
  artistName,
  setArtistName,
  currentPage,
}) {
  const [showInput, setShowInput] = useState(false);
  const history = useHistory();

  // Click Outside
  const ref = useRef();
  const handleCloseInput = (e) => {
    if (!ref.current.contains(e.target)) {
      setShowInput(false);
    }
  };

  return (
    <section onClick={handleCloseInput}>
      <div className={styles.headerWrapper}>
        {/* {!selectedList.length && <Filter />} */}
        <div
          onClick={() => {
            history.push("/actors/addActors");
          }}
          className={`btn ${styles.btn} ${styles.primary}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/menu/users.svg`}
            alt=""
            className={styles.logoActive}
          />
          افزودن هنرمند
        </div>
      </div>
      <section className={styles.tableWrapper}>
        <Table className={styles.table} responsive>
          <thead>
            <tr className={styles.THead}>
              <th
                ref={ref}
                onClick={() => setShowInput(true)}
                className={styles.customHeader}>
                {showInput ? (
                  <Input
                    placeholder="جستجو هنرمند"
                    value={artistName}
                    onBlur={refetch}
                    onChange={(e) => setArtistName(e.target.value)}
                    image={{
                      enable: true,
                      icon: "/images/icons/search.svg",
                      clickHandler: refetch,
                    }}
                  />
                ) : (
                  <>
                    هنرمندان و عوامل
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/search.svg`}
                      alt=""
                    />
                  </>
                )}
              </th>
              <th>ردیف ({data?.length} مورد)</th>
              <th>حرفه</th>
              <th>تعداد فیلم</th>
              <th>تعداد سریال ها</th>
            </tr>
          </thead>
          {data?.length > 0 && (
            <tbody>
              {isPreviousData ? (
                <div className="spinnerCenter">
                  <Spinner animation="border" as="i" />
                </div>
              ) : (
                data?.map((item, index) => (
                  <ActorsRow
                    key={item.id}
                    data={item}
                    index={index}
                    currentPage={currentPage}
                  />
                ))
              )}
            </tbody>
          )}
        </Table>
        {!data?.length > 0 && (
          <div className={styles.emptyState}>
            <EmptyState
              subHeader
              buttonOptions={{
                link: "/actors/addActors",
                text: "افزودن هنرمند",
                icon: "/images/productAndServices/newProject.svg",
              }}>
              <p className={styles.emptyStateText}>نتیجه ای یافت نشد!</p>
            </EmptyState>
          </div>
        )}
      </section>
    </section>
  );
}
