import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import { Link, useHistory, useParams } from "react-router-dom";
import { postTranscodeFiles } from "services/transcode/transcodeServices";
import { managerUploadTypes } from "../../../../constants/uploadManager";
import { useTranscodeWebsocket } from "../../../../hooks/transcode";
import {
  addFileToQueue,
  removeFileFromQueue,
  updateVideoProgress,
} from "../../../../redux/actions/videoUploadManager";
import { postFile } from "../../../../services/files/files";
import {
  patchAppendMoviesSubtitles,
  patchCountries,
  patchDirectors,
  patchGenres,
  patchLanguages,
  patchStars,
  patchWriters,
  uploadVideo,
  createKeywords,
  patchAppendMoviesKeywords,
  appendMovieDownloadLinkExtraData,
} from "../../../../services/movies/moviesServices";
import { createFileQueueBody } from "../../../../util/uploadManager";
import {
  usePostMovieDownloadLinkQuery,
  usePostMovieQuery,
  usePutMovieDownloadLinkQuery,
  usePutMovieQuery,
} from "hooks/query/useMovieQuery";
import { resetMovieLinkDownload } from "redux/actions/movies";
import toasts from "../../../common/toasts/toasts";
import styles from "./TabHeader.module.scss";

// subtitle section needs clean up
export default function TabHeader() {
  const { id } = useParams();
  const createMovie = useSelector((state) => state.createMovieReducer);
  const movieDownloadFile = useSelector(
    (state) => state.movieDownloadLinkReducer
  );
  const { mutateAsync: postMovie } = usePostMovieQuery(createMovie.title);
  const { mutateAsync: updateMovie } = usePutMovieQuery();
  const { mutateAsync: postMovieDownloadFile } =
    usePostMovieDownloadLinkQuery();
  const { mutateAsync: updateMovieDownloadFile } =
    usePutMovieDownloadLinkQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const { sourceFile, server, subtitlesFile, linkURL } = useSelector(
    (state) => state.movieFileUploadReducer
  );
  const { connectToTranscodeWebsocket } = useTranscodeWebsocket({
    transcodeType: managerUploadTypes.MOVIE,
  });

  // attach movie cover and banner
  const uploadCover = async (type) => {
    try {
      toasts.warn(`درحال آپلود ${type === "cover" ? "کاور" : "پوستر"} فیلم`);
      const { data } = await postFile(
        type === "cover" ? createMovie.cover : createMovie.banner
      );
      return data.data;
    } catch (error) {
      console.log(error);
      toasts.error(
        `ثبت ${type === "cover" ? "کاور" : "پوستر"} فیلم با مشکل مواجه شد`
      );
    }
  };

  // KEYWORDS
  const keywordsWithId = createMovie.keywords.filter(
    (i) => i.hasOwnProperty("label") && i.hasOwnProperty("id")
  );
  const keywordsWithOutId = createMovie.keywords.filter(
    (i) => i.hasOwnProperty("label") && !i.hasOwnProperty("id")
  );
  let keywordsPayload = [...keywordsWithId];

  // update Movie
  const handleUpdateMovie = async (moviePayload, subtitlesArray) => {
    const newPayload = { ...moviePayload };

    const { status } = await updateMovie({ id: parseInt(id), ...newPayload });

    if (status === 200) {
      createAppend("languages", id, createMovie.languages);
      createAppend("countries", id, createMovie.countries);
      createAppend("genres", id, createMovie.genres);
      createAppend("stars", id, createMovie.stars);
      createAppend("directors", id, createMovie.directors);
      createAppend("writers", id, createMovie.writers);
      createAppend("keywords", id, keywordsPayload);
      handlePatchSubtitles(subtitlesArray, id);
      handleMovieDownloadLink(id);
    }
  };

  // create Movie
  const handleCreateMovie = async (moviePayload, subtitlesArray) => {
    const newPayload = { ...moviePayload };

    const {
      data: { data: movieData },
      status,
    } = await postMovie(newPayload);

    if (status === 200) {
      createAppend("languages", movieData.id, createMovie.languages);
      createAppend("countries", movieData.id, createMovie.countries);
      createAppend("genres", movieData.id, createMovie.genres);
      createAppend("stars", movieData.id, createMovie.stars);
      createAppend("directors", movieData.id, createMovie.directors);
      createAppend("writers", movieData.id, createMovie.writers);
      createAppend("keywords", movieData.id, keywordsPayload);
      handlePatchSubtitles(subtitlesArray, movieData.id);
      handleMovieDownloadLink(movieData.id);
    }
  };

  // submit and validation
  const handleFormValidation = () => {
    let isValid = true;

    const isMovieFormValid =
      createMovie.title.length && createMovie.summary.length;

    const isUploadMovieFormValid =
      sourceFile?.length > 0 && !server?.link?.length;

    if (!isMovieFormValid) {
      toasts.error("فیلد های الزامی را پر کنید.");
      isValid = false;
    }
    if (isUploadMovieFormValid) {
      toasts.error("لطفا سرور آپلود فیلم را انتخاب نمایید");
      isValid = false;
    }

    return isValid;
  };
  const handleSubmit = async () => {
    // Validation
    if (!handleFormValidation()) return;

    const moviePayload = {
      cover: createMovie.cover,
      title: createMovie.title,
      movie_url: createMovie.movie_url,
      title_in_persian: createMovie.title_in_persian,
      imdb_id: createMovie.imdb_id,
      rate_value: createMovie.rate_value,
      rate_total_votes: createMovie.rate_total_votes,
      meta_rate_value: +createMovie.meta_rate_value,
      content_rating: createMovie.content_rating.title,
      summary: createMovie.summary,
      duration: Number(createMovie.duration),
      category_id: createMovie.category_id,
      ...(createMovie.release.length && {
        release: createMovie.release,
      }),
    };

    await handlePostKeywords();

    //* NOTE: check that we have movie source and upload it
    if (sourceFile.length && sourceFile[0].hasOwnProperty("path")) {
      const { movie_url } = await uploadMovieFile(
        sourceFile[0],
        createMovie.title,
        server.link,
        server.id
      );
      moviePayload["movie_url"] = movie_url;
    }

    if (linkURL.length) {
      toasts.warn("درحال ارسال فایل برای ترنسکد...");
      try {
        const { data, status } = await postTranscodeFiles(server.link, {
          base_url: server.link,
          streamer_url: window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL,
          transcode_server_id: String(server.id),
          url: linkURL,
        });
        if (status === 200) {
          moviePayload["movie_url"] = data.data.url;
          // Begin connecting to transcode websocket
          connectToTranscodeWebsocket({
            title: createMovie.title,
            server: server.link,
            transcodeId: data.data.transcode_id,
            transcodeResolutions: data.data.resolutions,
          });
        }
      } catch (error) {
        if (error.response.status === 400) {
          toasts.error("مشکلی پیش آمده است!");
          // return;
        } else {
          toasts.error("مشکلی پیش آمده است!");
        }
        console.log(error);
      }
    }

    //* NOTE: if cover was selected from system files upload it and store cover_id in movie payload
    if (createMovie.cover.hasOwnProperty("path")) {
      const cover_id = await uploadCover("cover");
      moviePayload["cover"] = cover_id;
    }
    if (createMovie.banner.hasOwnProperty("path")) {
      const banner_id = await uploadCover("banner");
      moviePayload["banner"] = banner_id;
    }
    // Subtitle
    let subtitlesArray = [];
    if (subtitlesFile.length) {
      for (const item of subtitlesFile) {
        if (item.file.name?.length) {
          subtitlesArray = [...subtitlesArray, item];
        }
      }
    }

    if (id) {
      // update
      await handleUpdateMovie(moviePayload, subtitlesArray);
    } else {
      // create
      await handleCreateMovie(moviePayload, subtitlesArray);
    }
  };

  // appended data patches
  const handlePostKeywords = async () => {
    try {
      for (const item of keywordsWithOutId) {
        const { data, status } = await createKeywords(item);
        if (status === 200) {
          keywordsPayload.push({
            id: data.data.id,
            label: data.data.label,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleMovieDownloadLink = async (movieId) => {
    try {
      for (const item of movieDownloadFile) {
        if (item.download_link.length) {
          if (item.hasOwnProperty("movie_id")) {
            const newExtraData = item.extra_data.filter((i) => !i.id);
            await updateMovieDownloadFile({
              id: parseInt(item.id),
              movie_id: parseInt(movieId),
              download_link: item.download_link,
              encoder: item.encoder.name,
              link_screenshot: item.link_screenshot,
              quality: item.quality.name,
              size: item.size,
              // extra_data: item.extra_data,
            });
            if (newExtraData?.length) {
              await appendMovieDownloadLinkExtraData({
                extra_data: newExtraData,
                id: parseInt(item.id),
              });
            }
          } else {
            await postMovieDownloadFile({
              movie_id: parseInt(movieId),
              download_link: item.download_link,
              encoder: item.encoder.name,
              link_screenshot: item.link_screenshot,
              quality: item.quality.name,
              size: item.size,
              extra_data: item.extra_data,
            });
          }
        }
        dispatch(resetMovieLinkDownload());
      }
    } catch (error) {
      console.error(error);
    }
  };
  const createAppend = async (endpoint, movieId, list) => {
    const mappedAPIs = {
      languages: patchLanguages,
      countries: patchCountries,
      genres: patchGenres,
      stars: patchStars,
      directors: patchDirectors,
      writers: patchWriters,
      keywords: patchAppendMoviesKeywords,
    };

    if (!list.length) return;

    try {
      const payload = {
        id: parseInt(movieId),
        [endpoint]: list.map((i) => i.id),
      };

      await mappedAPIs[endpoint](payload);
    } catch (err) {
      console.error(err);
    }
  };
  const handlePatchSubtitles = async (files, id) => {
    try {
      if (files.length) {
        toasts.warn("در حال آپلود زیرنویس فیلم...");
        for (const item of files) {
          const response = await postFile(item.file);
          if (response.status === 200) {
            const { status } = await patchAppendMoviesSubtitles({
              movie_id: parseInt(id),
              language_id: item.language.id,
              url: response.data.data,
              title: item.file.name,
            });
            if (status === 200) {
              toasts.success(`زیرنویس فیلم اضافه شد.`);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      toasts.error("مشکلی در افزودن زیرنویس به وجود آمده");
    }
  };

  //! movie file uploader (TODO: Clean up needed)
  const uploadMovieFile = async (source, movieTitle, server, serverId) => {
    // step: create payload for video queue dispatch
    const fakeId = nanoid();

    const videoPayload = createFileQueueBody({
      id: fakeId,
      title: movieTitle,
      size: source.size,
      type: managerUploadTypes.MOVIE,
      event: "download",
    });

    dispatch(addFileToQueue(videoPayload));
    // step: adding warn and redirection
    history.push("/movies");

    // step: calling file uploader for video
    const progressCb = (progress) => {
      //* NOTE: If needed can pass progress event data instead of calculated progress in percentage
      dispatch(updateVideoProgress(fakeId, progress));
    };

    try {
      toasts.warn("در حال آپلود ویدیو فیلم...");

      const {
        data: { data: fileData },
      } = await uploadVideo(source, progressCb, server, serverId);

      // Begin connecting to transcode websocket
      connectToTranscodeWebsocket({
        title: movieTitle,
        server: server,
        transcodeId: fileData.transcode_id,
        transcodeResolutions: fileData.resolutions,
      });

      // step: after success or fail of video uploading dispatch remove video from queue
      dispatch(removeFileFromQueue(fakeId));

      return { movie_url: fileData.url };
    } catch (error) {
      if (error.response.status === 400) {
        toasts.error("متاسفانه ویدیو شما آپلود نشد.");
      } else {
        toasts.error("متاسفانه ویدیو شما آپلود نشد.");
      }
      console.log(error);
      dispatch(removeFileFromQueue(fakeId));
      return { movie_url: "" };
    }
  };

  return (
    <div className={styles.container}>
      <Link to="/movies" className={`btn ${styles.btn} ${styles.removeDeal}`}>
        انصراف
      </Link>
      <div
        onClick={handleSubmit}
        className={`btn ${styles.btn} ${styles.addDeal}`}>
        <img
          src={`${process.env.PUBLIC_URL}/images/common/save.svg`}
          alt="save"
        />
        ثبت تغییرات
      </div>
    </div>
  );
}
