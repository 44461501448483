import { Modal } from "react-bootstrap";
import styles from "./NeedPremium.module.scss";

export default function NeedPremium({onHide}) {
  return (
    <>
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
          کاربر گرامی، برای افزودن یک محتوا به بیش از یک پروژه نیاز به{" "}
          <span className={styles.bold}>ارتقاء عضویت</span> دارید
        </Modal.Title>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Footer className={styles.footer}>
        <div className={`${styles.btn} ${styles.primary}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/topBar/crown.svg`}
            alt="Premium"
          />
          مشاهده امکانات ویژه
        </div>
      </Modal.Footer>
    </>
  );
}
