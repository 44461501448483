import http from "../httpService";
export const getMySmsConfig = () => {
  return http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/sms-configs/my`);
};
export const postSmsConfig = (data) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/sms-configs/new`,
    data
  );
};
export const putSmsConfig = (data) => {
  return http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/sms-configs/update`,
    data
  );
};
