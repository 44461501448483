import { useEffect, useState } from "react";
import { useArtistQuery } from "hooks/query/useArtistQuery";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addArtistOccupation,
  removeArtistOccupation,
  updateArtistInfo,
} from "../../../../../redux/actions/artists";
import { days, months, years } from "../../../../../util/dateNumber";
import { getNumberOnly } from "../../../../../util/number";
import { Input } from "../../../../common/input/Input";
import { gender, occupation } from "constants/actors";
import DropDown from "../../../../common/dropDown/DropDown";
import SingleFileUploader from "../../../../common/SingleFileUploader/SingleFileUploader";
import TextArea from "../../../../common/textArea/TextArea";
import styles from "./ActorsInformation.module.scss";

export default function ActorsInformation({ id }) {
  const dispatch = useDispatch();

  const { data } = useArtistQuery(id);
  useEffect(() => {
    if (data) {
      dispatch(updateArtistInfo(data));
    }
  }, [data, dispatch]);

  const artistInfo = useSelector((store) => store.artistReducer);

  //Birthday
  const [birthDate, setBirthDate] = useState({
    year: null,
    month: null,
    day: null,
  });
  useEffect(() => {
    if (birthDate.year && birthDate.month && birthDate.day) {
      const { year, month, day } = birthDate;
      dispatch(
        updateArtistInfo({
          birthday: `${year.id}-${month.id}-${day.id}`,
        })
      );
    }
  }, [birthDate, dispatch]);
  useEffect(() => {
    if (id && data?.birthday) {
      const [year, month, day] = data?.birthday.split("-");

      setBirthDate({
        ...birthDate,
        year: { id: year, title: year },
        month: { id: month, title: month },
        day: { id: day, title: day },
      });
    }
  }, [data?.birthday]);
  const handleChangeBirthDate = (option, action) => {
    setBirthDate({ ...birthDate, [action.name]: option });
  };

  //Died
  const [diedAt, setDiedAt] = useState({
    year: null,
    month: null,
    day: null,
  });
  useEffect(() => {
    if (diedAt.year && diedAt.month && diedAt.day) {
      const { year, month, day } = diedAt;
      dispatch(
        updateArtistInfo({
          death_date: `${year.id}-${month.id}-${day.id}`,
        })
      );
    }
  }, [diedAt, dispatch]);
  useEffect(() => {
    if (id && data?.death_date) {
      const [year, month, day] = data?.death_date.split("-");

      setDiedAt({
        ...diedAt,
        year: { id: year, title: year },
        month: { id: month, title: month },
        day: { id: day, title: day },
      });
    }
  }, [data?.death_date]);
  const handleChangeDiedAt = (option, action) => {
    setDiedAt({ ...diedAt, [action.name]: option });
  };

  // change handler
  const handleArtistOccupation = (artistField) => {
    if (artistInfo.occupation.includes(artistField.en)) {
      dispatch(removeArtistOccupation(artistField.en));
    } else {
      dispatch(addArtistOccupation(artistField.en));
    }
  };
  const handleUpdateInputs = (e) => {
    dispatch(
      updateArtistInfo({
        [e.target.name]: e.target.value,
      })
    );
  };
  const handleChangeGender = (e) => {
    dispatch(
      updateArtistInfo({
        gender: e.value,
      })
    );
  };
  const handleChangeBio = (e) => {
    dispatch(updateArtistInfo({ bio: e.target.value }));
  };
  const handleChangeAvatar = (file) => {
    dispatch(updateArtistInfo({ avatar: file[0] }));
  };

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <SingleFileUploader
          title="بارگذاری تصویر هنرمند"
          defaultImg="/images/common/default-pic.svg"
          imageState={[artistInfo.avatar, handleChangeAvatar]}
        />
      </div>

      <div>
        {/* 
				className={styles.wrapper} for div
				<Input
					label="آی دی TMDB و یا IMDB"
					placeholder="به طور مثال: nm4880301"
					name="sourceId"
					value={artistInfo.sourceId}
					errors={errors}
					errorsText={errors && 'آی دی وارد شده معتبر نیست!'}
					onChange={handleUpdateInputs}
				/>
				<div className={styles.getInformationBtn}>دریافت اطلاعات</div> */}
      </div>

      <div className={styles.flexWrapper}>
        <Input
          placeholder="نام لاتین و رسمی هنرمند را وارد نمایید: Hailee Steinfeld"
          label="نام هنرمند (لاتین) "
          name="name"
          value={artistInfo.name}
          onChange={handleUpdateInputs}
          require
        />
        <Input
          placeholder="نام فارسی هنرمند را در صورت نیاز وارد نمایید "
          label="نام هنرمند (فارسی)"
          name="name_in_persian"
          value={artistInfo.name_in_persian}
          onChange={handleUpdateInputs}
        />
      </div>

      <div className={styles.groupWrapper}>
        <TextArea
          label="بیوگرافی"
          placeholder="بیوگرافی فارسی و یا انگلیسی هنرمند را وارد نمایید "
          name="bio"
          value={artistInfo.bio}
          onChange={handleChangeBio}
          isRequired
        />
      </div>

      <div className={styles.wrapper}>
        <DropDown
          placeholder="جنسیت هنرمند را انتخاب نمایید"
          label="جنسیت"
          options={gender}
          value={gender.find((item) => item.value === artistInfo.gender)}
          getOptionLabel={(option) => option["title"]}
          getOptionValue={(option) => option["value"]}
          onChange={handleChangeGender}
          name="gender"
        />

        <Input
          placeholder="کشور محل تولد هنرمند را وارد نمایید"
          label="ملیت"
          value={artistInfo.nationality}
          onChange={handleUpdateInputs}
          name="nationality"
        />

        <Input
          placeholder="شهر محل تولد هنرمند را وارد نمایید"
          label="شهر"
          value={artistInfo.city_of_birth}
          onChange={handleUpdateInputs}
          name="city_of_birth"
        />
      </div>

      <div className={styles.movieYearWrapper}>
        <label>
          تاریخ تولد (میلادی) <span>*</span>
        </label>
        <div className={styles.dropdownWrapper}>
          <div>
            <DropDown
              placeholder="روز"
              options={days}
              value={birthDate.day}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={handleChangeBirthDate}
              name="day"
            />
          </div>
          <div>
            <DropDown
              placeholder="ماه"
              options={months}
              value={birthDate.month}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={handleChangeBirthDate}
              name="month"
            />
          </div>
          <div>
            <DropDown
              placeholder="سال"
              options={years}
              value={birthDate.year}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={handleChangeBirthDate}
              name="year"
              isRequired
            />
          </div>
        </div>{" "}
      </div>
      <div className={styles.movieYearWrapper}>
        <label>
          تاریخ وفات (میلادی) <span>*</span>
        </label>
        <div className={styles.dropdownWrapper}>
          <div>
            <DropDown
              placeholder="روز"
              options={days}
              value={diedAt.day}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={handleChangeDiedAt}
              name="day"
            />
          </div>
          <div>
            <DropDown
              placeholder="ماه"
              options={months}
              value={diedAt.month}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={handleChangeDiedAt}
              name="month"
            />
          </div>
          <div>
            <DropDown
              placeholder="سال"
              options={years}
              value={diedAt.year}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={handleChangeDiedAt}
              name="year"
              isRequired
            />
          </div>
        </div>{" "}
      </div>

      <div className={styles.wrapper}>
        <Input
          placeholder="قد هنرمند را به سانتی متر وارد نمایید"
          label="قد"
          name="height"
          value={artistInfo.height}
          onChange={handleUpdateInputs}
          onKeyPress={getNumberOnly}
        />
      </div>

      <div className={styles.artistFiled}>
        <label>حرفه</label>
        <div className={styles.checkWrapper}>
          {occupation.map((item) => (
            <div
              key={item.en}
              className={styles.checkbox}
              onClick={() => handleArtistOccupation(item)}>
              <Form.Check
                type="checkbox"
                checked={artistInfo.occupation.includes(item.en)}
                readOnly
              />
              <span>{item.fa}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
