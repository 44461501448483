import Row from "../row/Row";

export default function Albums({ data }) {
  return (
    <>
      {data?.map((item) => (
        <Row key={item.id} data={item} tab="album" />
      ))}
    </>
  );
}
