import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { managerUploadTypes } from "../../../constants/uploadManager";
import { convertToGigabyte } from "../../../util/changeByteToKByte";
import Progress from "../progress/Progress";

import styles from "./UploaderPreview.module.scss";

//TODO: check error ui for uploading video +++ in TabHeader
export default function UploaderPreview() {
	const videoManager = useSelector((state) => state.uploadVideoManagerReducer);

	const [showMenu, setShowMenu] = useState(false);

	const checkIcon = useCallback((type) => {
		switch (type) {
			case managerUploadTypes.MOVIE || managerUploadTypes.SERIES:
				return "/images/menu/uploader.svg";
			case managerUploadTypes.MUSIC:
				return "/images/icons/music-download.svg";
			case "success":
				return "/images/icons/tick-square-green.svg";
			default:
				return "/images/menu/uploader.svg";
		}
	}, []);

	const limitString = (string, char) => {
		return string.length > char ? string.slice(0, char) + "..." : string;
	};

	return videoManager.map((file) => (
		<div key={file.id} className={styles.uploader}>
			<div className={styles.imageContainer}>
				<img
					src={`${process.env.PUBLIC_URL}${checkIcon(
						file.progress === 100 ? "success" : file.type
					)}`}
					alt="uploading"
				/>
			</div>

			<div className={styles.detailContainer}>
				<div className={styles.row}>
					<span>{limitString(file.title)}</span>

					<div className={styles.buttonWrapper}>
						{/* <div className={styles.iconWrapper}>
							<img
								src={`${process.env.PUBLIC_URL}/images/menu/dots.svg`}
								alt="."
							/>
						</div> */}

						{/* make dotsWrapper with react-bootstrap dropdown */}
						<div className={styles.dotsWrapper}>
							<div
								className={styles.iconWrapper}
								onClick={() => setShowMenu(!showMenu)}
							>
								<img
									className={styles.dotsIcon}
									src={`${process.env.PUBLIC_URL}/images/menu/dots.svg`}
									alt="."
								/>
							</div>
							{/* NEED STYLE FOR IT */}
							{showMenu && (
								<div className={styles.menu}>
									<button className="btn">مشاهده</button>
									<button className="btn">لغو بارگذاری</button>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className={styles.row}>
					<div>
						<span className={styles.gray}>
							{file.progress === 100 ? "تکمیل شده" : `%${file.progress}`}
						</span>
					</div>
					<div className={styles.progress}>
						<span>
							{convertToGigabyte((file.progress * file.size) / 100)} /
							{convertToGigabyte(file.size)}
						</span>{" "}
						<span className={styles.small}>Gb</span>
					</div>
				</div>

				<Progress progress={file.progress} />
			</div>
		</div>
	));
}
