import { useState } from "react";
import { useDeleteStreamQuery } from "hooks/query/useStreamQuery";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import DeleteMultiModal from "components/common/modals/deleteMultiModal/DeleteMultiModal";
import styles from "./styles.module.scss";
export default function Row({ data }) {
  const { mutate: deleteLiveStream } = useDeleteStreamQuery(
    "single",
    data.title
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteLive = () => {
    deleteLiveStream(data.id);
  };
  return (
    <>
      <div className={styles.row}>
        <div className={styles.colOne}>
          <img
            loading="lazy"
            src={`${process.env.PUBLIC_URL}/images/new-icons/live-placeholder.svg`}
            alt={data.title}
          />
          <div>
            <span className={styles.bold}>{data.title}</span>
            <span className={styles.normal}>نامک پخش زنده</span>
          </div>
        </div>
        <div className={styles.colTwo}>
          <img
            loading="lazy"
            src={`${process.env.PUBLIC_URL}/images/new-icons/watermark-placeholder.svg`}
            alt={data.title}
          />
          <div>
            <span className={styles.bold}>واتر مارک:</span>
            <span className={styles.normal}>بالا چپ</span>
          </div>
        </div>
        <div className={styles.colThree}>
          <div>
            <span className={styles.bold}>ورودی:</span>
            <span className={styles.normal}>برودکست(Push)</span>
          </div>
        </div>
        <div className={styles.colFour}>
          <div>
            <span className={styles.bold}>FPS:</span>
            <span className={styles.normal}>30</span>
          </div>
        </div>
        <div className={styles.actions}>
          <Link to={`/live-stream/details/${data.id}`}>
            <button className={`${styles.edit} btn`}>
              <img
                src={`${process.env.PUBLIC_URL}/images/new-icons/edit1.svg`}
                alt="edit"
              />
            </button>
          </Link>
          <button
            onClick={() => setShowDeleteModal(true)}
            className={`${styles.delete} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/delete.svg`}
              alt="delete"
            />
          </button>
        </div>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={styles.modals}>
        <DeleteMultiModal
          titleData={{
            deleteObject: data.title,
            isMoreThanOne: false,
            placeName: "پخش زنده",
          }}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={handleDeleteLive}
        />
      </Modal>
    </>
  );
}