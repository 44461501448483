import { useState } from "react";
import Header from "./header";
import Lists from "./lists";
export default function Tickets() {
  const [activeTab, setActiveTab] = useState("all"); // we can use this for sorting data from backend with react-query like contentRequest page

  return (
    <>
      <Header tabState={[activeTab, setActiveTab]} />
      <Lists />
    </>
  );
}