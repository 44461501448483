import { useEffect, useState } from "react";
import {
  useCurrenciesQuery,
  usePatchAppendFeatureItemToPackageQuery,
  usePatchRemoveFeatureItemFromPackageQuery,
  usePostPackageQuery,
  usePutPackageQuery,
} from "hooks/query/usePackageQuery";
import {
  CardDivider,
  CloseButton,
  FeatureInput,
  InputWithUnit,
  Switch,
} from "../components/Components";
import { Input } from "../../common/input/Input";
import { expireUnits } from "constants/packages";
import toasts from "../../common/toasts/toasts";
import styles from "./PackageModal.module.scss";

function PackageModal({ data, onHide }) {
  const { mutateAsync: postPackage } = usePostPackageQuery();
  const { mutateAsync: putPackage } = usePutPackageQuery();
  const { mutateAsync: patchAppendFeatureItemToPackage } =
    usePatchAppendFeatureItemToPackageQuery();
  const { mutateAsync: patchRemoveFeatureItemFromPackage } =
    usePatchRemoveFeatureItemFromPackageQuery();
  const { data: currencies } = useCurrenciesQuery();

  const [name, setName] = useState("");
  const [price, setPrice] = useState({ value: "", currency: null });
  const [expire, setExpire] = useState({ value: "", unit: expireUnits[0] });
  const [featureList, setFeatureList] = useState([]);
  const [switches, setSwitches] = useState({
    isActive: true,
    isPremium: false,
  });

  useEffect(() => {
    if (data) {
      setName(data.name);
      setPrice({ value: data.price, currency: data.currency });
      setExpire({
        value: data.expiry_time,
        unit: expireUnits.find((item) => item.id === data.expiry_type),
      });
      setFeatureList(data.features);
      setSwitches({ isActive: data.active, isPremium: data.premium });
    }

    if (!data && currencies?.length) {
      setPrice({ value: "", currency: currencies[0] });
    }
  }, [data, currencies]);

  // Create & Update Package
  const handleSubmit = () => {
    const condition = name.length && price.value && expire;

    const payload = {
      expiry_time: expire.value,
      expiry_type: expire.unit.id,
      name: name,
      price: price.value,
      active: switches.isActive,
      premium: switches.isPremium,
      currency_id: price.currency.id,
    };

    if (condition) {
      if (data) {
        updatePackage(payload);
      } else {
        createPackage(payload);
      }
    } else {
      toasts.error("فیلدهای مورد نظر رو پرکنید");
    }
  };
  const createPackage = async (payload) => {
    try {
      const { data, status } = await postPackage(payload);
      await handlePatchFeatureItems(data.data.id);
      if (status === 200) {
        onHide();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const updatePackage = async (payload) => {
    try {
      const { status } = await putPackage({ ...payload, id: data.id });
      await handlePatchFeatureItems(data.id);
      if (status === 200) {
        onHide();
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Patch & Remove Features
  const handlePatchFeatureItems = async (packageId) => {
    const trimmedFeatureList = featureList.filter(
      (item) => item.text !== "" && !item.id
    );

    for (const feature of trimmedFeatureList) {
      try {
        const payload = {
          package_id: packageId,
          text: feature.text,
        };

        await patchAppendFeatureItemToPackage(payload);
      } catch (err) {
        console.error(err);
      }
    }
  };
  const handleRemoveFeature = (data) => {
    if (data.id) {
      handleRemove(data);
    } else {
      const newArr = featureList.filter((item) => item.text !== data.text);
      setFeatureList(newArr);
    }
  };
  const handleRemove = async (data) => {
    try {
      await patchRemoveFeatureItemFromPackage({
        feature_id: data.id,
        package_id: data.package_id,
      });

      const newArr = featureList.filter((item) => item.id !== data.id);

      setFeatureList(newArr);
    } catch (err) {
      console.error(err);
    }
  };

  // Handle Change
  const handleChangeFeature = (e, index) => {
    const mappedArr = featureList.map((item, i) => {
      if (i === index) {
        return { text: e.target.value };
      } else {
        return item;
      }
    });

    setFeatureList(mappedArr);
  };

  return (
    <div className={styles.wrapper}>
      <CloseButton position="right" onClick={onHide} />

      <div className={styles.headerFields}>
        <Input
          placeholder="نام بسته"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />

        <InputWithUnit
          placeholder="قیمت بسته"
          units={currencies}
          onChange={(e) =>
            setPrice({ ...price, value: Number(e.target.value) })
          }
          value={price.value}
          unitState={[
            price.currency,
            (e) => setPrice({ ...price, currency: e }),
          ]}
        />

        <InputWithUnit
          placeholder="مدت زمان بسته"
          units={expireUnits}
          onChange={(e) =>
            setExpire({ ...expire, value: Number(e.target.value) })
          }
          value={expire.value}
          unitState={[expire.unit, (e) => setExpire({ ...expire, unit: e })]}
        />
        {/* <Input
          type="number"
          placeholder="مدت زمان بسته"
          onChange={(e) => setExpire(parseInt(e.target.value))}
          value={expire.toString()}
        /> */}
      </div>

      <CardDivider position="top" fill="#808080" isModal />

      <div className={styles.bodyFields}>
        {featureList.map((item, index) => (
          <FeatureInput
            key={index}
            value={item.text}
            readOnly={Boolean(item.id)}
            onChange={(e) => handleChangeFeature(e, index)}
            handleRemove={() => handleRemoveFeature(item)}
          />
        ))}

        <div
          className={styles.addField}
          onClick={() => setFeatureList([...featureList, { text: "" }])}>
          <img
            src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/add-square.svg`}
            alt=""
          />
          افزودن ویژگی جدید
        </div>
      </div>

      <CardDivider position="bottom" fill="#808080" isModal />

      <div className={styles.footerFields}>
        <div className={styles.switchContainer}>
          <Switch
            text="بسته ویژه"
            toggleState={[
              switches.isPremium,
              (e) => setSwitches({ ...switches, isPremium: e }),
            ]}
          />
          <Switch
            text="بسته فعال"
            toggleState={[
              switches.isActive,
              (e) => setSwitches({ ...switches, isActive: e }),
            ]}
          />
        </div>

        <button onClick={handleSubmit}>ثبت بسته</button>
      </div>
    </div>
  );
}

export default PackageModal;
