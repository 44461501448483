import List from "./list";
import Send from "./send";
import styles from './styles.module.scss'
export default function Messages() {
  return (
    <div className={styles.wrapper}>
      <List />
      <Send />
    </div>
  );
}