import { useState } from "react";
import { Modal } from "react-bootstrap";
import LableCheckBox from "../../../common/labelCheckBox/LabelCheckBox";
import TypeProjectModal from "../../typeProjectModal/TypeProjectModal";
import styles from "./ProjectType.module.scss";

export default function ProjectType() {
  const [showTypeModalMovies, setShowTypeModalMovies] = useState(false);
  const [showTypeModalLiveStream, setShowTypeModalLiveStream] = useState(false);

  return (
    <>
      <section className={styles.wrapper}>
        <div onClick={() => setShowTypeModalMovies(true)}>
          <LableCheckBox
            label={"فیلم"}
            text={`انواع فیلم های سینمایی، فیلم های کوتاه، مستندهای سینمایی، انیمیشن های سینمایی و تمام ژانرهای تعریف
شده برای فیلم در تمامی ملیت ها (ایرانی و خارجی) و زبان ها در این دسته بندی قرار می گیرد. و نیز فیلم های
مرتبط را میتوان در کالکشن دسته بندی کرد`}
          />
        </div>
        <div>
          <LableCheckBox
            label={"سریال"}
            text={`انواع سریال های تلویزیونی، انیمه ها و انیمیشن های سریالی، مستند های سریالی، مینی سریال و تمام ژانرهای
تعریف شده برای سریال در تمامی ملیت ها (ایرانی و خارجی) و زبان ها در این دسته بندی قرار می گیرد`}
          />
        </div>
        <div>
          <LableCheckBox
            label={"ویدئو کلیپ"}
            text={`انواع ویدئوها و کلیپ ها در دسته بندی های دلخواه مانند ورزشی، آموزشی و ... تولید شده توسط کاربر یا توسط
،دیگران در این دسته بندی قرار میگیرد. همچنین میتوان ویدئوها را در پلی لیست های دلخواه دسته بندی کرد
به طور مثال در دسته بندی آموزشی میتوانید مباحث مجزا را بصورت پلی لیست ارائه کنید`}
          />
        </div>
        <div onClick={() => setShowTypeModalLiveStream(true)}>
          <LableCheckBox
            label={"پخش زنده"}
            text={`پخش زنده و استریمینگ با قابلیت هایی نظیر دریافت Stream key، دریافت Stream URL، ماشین زمان،
رکورد پخش زنده، مشاهده لحظه ای افراد آنلاین، مجموع بازدید و قابلیت اشتراک گذاری`}
          />
        </div>
      </section>
      {showTypeModalMovies && (
        <Modal
          show={showTypeModalMovies}
          onHide={() => setShowTypeModalMovies(false)}
          dialogClassName={styles.typeModal}>
          <TypeProjectModal
            type={"فیلم"}
            onHide={() => setShowTypeModalMovies(false)}
          />
        </Modal>
      )}
      {showTypeModalLiveStream && (
        <Modal
          show={showTypeModalLiveStream}
          onHide={() => setShowTypeModalLiveStream(false)}
          dialogClassName={styles.typeModal}>
          <TypeProjectModal
            type={"پخش زنده"}
            onHide={() => setShowTypeModalLiveStream(false)}
          />
        </Modal>
      )}
    </>
  );
}
