import { Modal } from "react-bootstrap";
import styles from "./DeleteModal.module.scss";

// later we can get title of project And detail

export default function DeleteModal({ onHide }) {
  return (
    <>
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
          آیا نسبت به حذف پروژه <span className={styles.bold}> مورد نظر </span>{" "}
          اطمینان دارید؟ در صورت حذف، 3500 محتوای وارد شده بصورت دائمی از دسترس
          خارج خواهد شد
        </Modal.Title>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Footer className={styles.footer}>
        <div className={`${styles.btn} ${styles.outline}`}>انصراف</div>
        <div className={`${styles.btn} ${styles.primary}`}>حذف</div>
      </Modal.Footer>
    </>
  );
}
