import { useMemo } from "react";
import { useHistory } from "react-router";
import { useDeleteArticleQuery } from "hooks/query/useArticleQuery";
import { ToJalaliFormat } from "../../../util/toJalali";
import styles from "./Row.module.scss";

function Row({ data }) {
  const { mutate: deleteArticle } = useDeleteArticleQuery(data.title);
  const history = useHistory();

  const statusInfo = useMemo(() => {
    if (data.article_status === "published") {
      return {
        context: "منتشر شده",
        icon: "/images/articles/tick-square.svg",
        style: styles.publish,
      };
    }

    if (data.article_status === "unpublished") {
      return {
        context: "منتشر نشده",
        icon: "/images/articles/close-square.svg",
        style: styles.unPublished,
      };
    }
  }, [data.article_status]);

  const pushToEditUrl = () => {
    history.push(`/articles/article/${data.id}`);
  };

  return (
    <tr className={styles.row}>
      <td className={styles.imageCol}>
        <div className={styles.imageWrapper}>
          <img
            src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.image_id}`}
            alt=""
          />
        </div>
      </td>
      <td>{data.id}</td>
      <td>{ToJalaliFormat(data.created_at)}</td>
      <td>{data.title}</td>
      <td className={styles.statusCol}>
        <div className={`${styles.statusWrapper} ${statusInfo.style}`}>
          <img src={`${process.env.PUBLIC_URL}${statusInfo.icon}`} alt="" />
          <span>{statusInfo.context}</span>
        </div>
      </td>
      <td className={styles.buttonCol}>
        <div className={styles.buttonWrapper}>
          {/* <button className={styles.iconButton}>
						<img
							src={`${process.env.PUBLIC_URL}/images/articles/eye.svg`}
							alt=""
						/>
					</button> */}
          <button className={styles.iconButton} onClick={pushToEditUrl}>
            <img
              src={`${process.env.PUBLIC_URL}/images/articles/edit.svg`}
              alt=""
            />
          </button>
          <button
            className={styles.iconButton}
            onClick={() => deleteArticle(data.id)}>
            <img
              src={`${process.env.PUBLIC_URL}/images/articles/trash.svg`}
              alt=""
            />
          </button>
        </div>
      </td>
    </tr>
  );
}

export default Row;
