import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styles from "./LandingHeader.module.scss";

export default function LandingHeader() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setHeight("0vh");
  }, [location]);
  // open MobileNav from Top
  const [height, setHeight] = useState("0vh");
  const closeNav = () => {
    setHeight("0vh");
  };
  const openNav = () => {
    setHeight("100vh");
  };

  return (
    <>
      <nav className={`${styles.headerWrapper}`}>
        <div className={styles.navbar}>
          <div className={styles.burgerBar} onClick={openNav}>
            <img
              src={`${process.env.PUBLIC_URL}/images/landing/burger-bar-icon.svg`}
              alt=""
            />
          </div>
          <Link to="/registerAndLogin" className={styles.btnLogin}>
            <span>ورود یا ثبت نام</span>
          </Link>
          <ul className={styles.largeNavigation}>
            <li className={styles.navItem}>
              <NavLink activeClassName={styles.navActive} to="/#trait">
                قابلیت ها
              </NavLink>
            </li>
            <li className={styles.navItem}>
              <NavLink activeClassName={styles.navActive} to="/#features">
                امکانات و ویژگی ها
              </NavLink>
            </li>
            <li className={styles.navItem}>
              <NavLink activeClassName={styles.navActive} to="/contact">
                تماس با ما
              </NavLink>
            </li>
          </ul>
          <Link to="/" className={styles.logo}>
            <img
              src={`${process.env.PUBLIC_URL}/images/landing/logo.svg`}
              alt="Streamer"
            />
          </Link>
        </div>
      </nav>
      <div className={styles.overlay} style={{ height: height }}>
        <div className={styles.mobileNavigation}>
          <div className={styles.innerMenu}>
            <div className={styles.close} onClick={closeNav}>
              <img
                src={`${process.env.PUBLIC_URL}/images/landing/close-icon.svg`}
                alt=""
              />
            </div>
            {/* <div>
              <Link to="/" className={styles.mobileLogo}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/landing/logo.svg`}
                  alt="Streamer"
                />
              </Link>
            </div> */}
          </div>
          <ul className={styles.mobileMenu}>
            <li className={styles.navItem}>
              <NavLink activeClassName={styles.navActive} to="/#trait">
                قابلیت ها
              </NavLink>
            </li>
            <li className={styles.navItem}>
              <NavLink activeClassName={styles.navActive} to="/#features">
                امکانات و ویژگی ها
              </NavLink>
            </li>
            <li className={styles.navItem}>
              <NavLink activeClassName={styles.navActive} to="/contact">
                تماس با ما
              </NavLink>
            </li>
            <li className={styles.navItem}>
              <Link
                to="/registerAndLogin"
                className={styles.mobileMenuLoginBtn}>
                <span>ورود یا ثبت نام</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
