import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import styles from "./SingleFileUploader.module.scss";

const SingleFileUploader = ({ title, defaultImg, imageState }) => {
  const [image, setImage] = imageState;

  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const hadImage =
      image && image.length
        ? `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${image}`
        : "";

    setSelectedImage(hadImage);
  }, [image]);

  const onDropAccepted = useCallback((acceptedFiles) => {
    setImage(acceptedFiles);

    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    fileReader.addEventListener(
      "load",
      () => {
        setSelectedImage(fileReader.result);
      },
      false
    );

    fileReader.readAsDataURL(file);
  }, []);

  const onDropRejected = (rejectedFiles) => {
    // NOTE: Maybe need to correct toast error
    toast.error("شما فقط می توانید از فرمت های PNG و JPG استفاده کنید");
  };

  // TODO: Only get jpg not other jpg formats like jpeg etc
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
    noDrag: true,
  });

  return (
    <section className={styles.wrapper}>
      {selectedImage.length ? (
        <img src={selectedImage} alt="" className={styles.previewImage} />
      ) : (
        <img
          src={`${process.env.PUBLIC_URL}${defaultImg}`}
          alt=""
          className={styles.previewImage}
        />
      )}

      <div
        {...getRootProps({
          className: styles.dropZone,
        })}>
        <input {...getInputProps()} />

        <img
          src={`${process.env.PUBLIC_URL}/images/icons/upload.svg`}
          alt=""
          className={styles.uploadSign}
        />
        {title}
      </div>
    </section>
  );
};

SingleFileUploader.propTypes = {
  title: PropTypes.string.isRequired,
  defaultImg: PropTypes.string.isRequired,
  images: PropTypes.func,
};

export default SingleFileUploader;
