import Details from "./details";
import Messages from "./messages";
import styles from './styles.module.scss'
export default function Room() {
  return (
    <div className={styles.wrapper}>
      <Details />
      <Messages />
    </div>
  );
}