import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Select, { components } from "react-select";
// import { NameFromEmail } from "./../../../util/name";
import { defaultSelectStyle } from "util/reactSelect";
import styles from "./DropDown.module.scss";

function DropDown({
	label,
	isRequired,
	options,
	classSelect,
	placeholder,
	prefix,
	type,
	...props
}) {
	const createLabel = (email, label) => {
		if (email) {
			return `NameFromEmail(email)`;
		} else {
			return label;
		}
	};

	const Option = ({ data, ...props }) => (
		<components.Option className={styles.option} {...props}>
			{data.color && (
				<span
					className={styles.circle}
					style={{ backgroundColor: data.color }}
				></span>
			)}
			{data.icon && (
				<span className={`${styles.imageWrapper} imageWrapper`}>
					<img
						className={styles.icon}
						src={`${process.env.PUBLIC_URL}${data.icon}`}
						alt=""
					/>
				</span>
			)}
			{/* {data.avatar !== '' && (
				<span className={styles.pic}>
					<img src={`${process.env.PUBLIC_URL}${data.avatar}`} />
				</span>
			)} */}
			{/*todo:change pic with default pic */}
			{/* {(data.avatar || data.avatar === "") && (
				<span className={`${styles.imageWrapper} imageWrapper`}>
					{data.avatar.length ? (
						<img
							className={styles.pic}
							src={`${config.imageApi}${data.avatar}`}
							alt=""
						/>
					) : (
						<img
							className={styles.pic}
							src={`${process.env.PUBLIC_URL}/images/person.svg`}
							alt=""
						/>
					)}
				</span>
			)} */}

			<span className={styles.title}>
				{/* {type === "server" && " سرور "} */}

				{createLabel(data.email, props.label)}
				{data.isActive && (
					<span
						className={styles.active}
						style={{ color: data.titleColor ? data.titleColor : "#112df1" }}
					>
						{data.activeTitle}
					</span>
				)}
			</span>
		</components.Option>
	);

	const SingleValue = ({ data, ...props }) => (
		<components.SingleValue {...props} className={styles.selectValue}>
			{data.color && (
				<span
					className={styles.circle}
					style={{ backgroundColor: data.color }}
				></span>
			)}

			{data.icon && (
				<span className={`${styles.imageWrapper} imageWrapper`}>
					<img
						className={styles.icon}
						src={`${process.env.PUBLIC_URL}${data.icon}`}
						alt=""
					/>
				</span>
			)}

			{/* {data.avatar !== '' && (
				<span className={styles.pic}>
					<img src={`${process.env.PUBLIC_URL}${data.avatar}`} />
				</span>
			)} */}
			{/*todo:change pic with default pic */}

			{/* {(data.avatar || data.avatar === "") && (
				<span className={`${styles.imageWrapper} imageWrapper`}>
					{data.avatar.length ? (
						<img
							className={styles.pic}
							src={`${config.imageApi}${data.avatar}`}
							alt=""
						/>
					) : (
						<img
							className={styles.pic}
							src={`${process.env.PUBLIC_URL}/images/person.svg`}
							alt=""
						/>
					)}
				</span>
			)} */}

			<span className={styles.title}>
				{/* {type === "server" && " سرور "} */}
				{createLabel(data.email, props.children)}

				{data.isActive && (
					<span
						className={styles.active}
						style={{ color: data.titleColor ? data.titleColor : "#112df1" }}
					>
						{data.activeTitle}
					</span>
				)}
			</span>
		</components.SingleValue>
	);

	return (
		<Form.Group
			className={
				classSelect ? `${classSelect} ${styles.group}` : `${styles.group}`
			}
		>
			{label && (
				<Form.Label className={styles.label}>
					{label} {isRequired && <span className={styles.asterisked}>*</span>}
				</Form.Label>
			)}
			<Select
				styles={defaultSelectStyle}
				placeholder={placeholder}
				options={options}
				components={{
					Option,
					SingleValue,
				}}
				classNamePrefix={prefix ? prefix : "select"}
				hideSelectedOptions
				isRtl
				isClearable
				// menuIsOpen
				{...props}
			/>
		</Form.Group>
	);
}

DropDown.propTypes = {
	label: PropTypes.string,
	classSelect: PropTypes.string,
	placeholder: PropTypes.string,
	prefix: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object),
};

export default DropDown;
