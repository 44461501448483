import { useQuery } from "react-query";
import {
  getAdvancedSearch,
  getArtistsBySearch,
} from "services/search/searchService";

export const useSearchArtistsQuery = (page, limit, name) => {
  return useQuery(
    ["artists-by-search", page],
    ({ signal }) => getArtistsBySearch(page, limit, name, signal),
    {
      keepPreviousData: true,
      // enabled: false,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
export const useAdvancedSearchQuery = (
  page,
  limit,
  name,
  type,
  enable = true
) => {
  return useQuery(
    [`advanced-search-${type}`, page],
    ({ signal }) => getAdvancedSearch(page, limit, name, type, signal),
    {
      keepPreviousData: true,
      enabled: enable,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
