import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  addNewEpisode,
  getSeriesSeasons,
  updateSesons,
} from "../../../redux/actions/series";
import { nanoid } from "nanoid";
import { Input } from "../input/Input";
import { getNumberOnly } from "util/number";
import { putUpdateSeason } from "services/series/series";
import Item from "./item/Item";
import toasts from "../toasts/toasts";
import styles from "./Accordion.module.scss";

//! Used for Series

export default function Accordion({
  data,
  handleActiveBorder,
  handleRemoveSeason,
  border,
  index,
  handleGetEpisodesFromImdb,
  showLoading,
  id,
  seasonList,
}) {
  const dispatch = useDispatch();
  const contentRef = useRef();
  const [active, setActive] = useState(false);
  const [accordionHeight, setAccordionHeight] = useState("0px");
  const handleToggleAccordion = () => {
    setActive((prevActive) => !prevActive);
    setAccordionHeight(
      active ? "0px" : `${contentRef.current.scrollHeight + 50}px`
    );
  };

  const handleAddEpisode = () => {
    const fakeId = nanoid();
    dispatch(
      addNewEpisode({
        id: fakeId,
        seasonId: data.title,
      })
    );
    setAccordionHeight(`${contentRef.current.scrollHeight + 100}px`);
  };

  const [showInput, setShowInput] = useState(false);

  const handleShowInput = (event) => {
    event.stopPropagation();
    setShowInput(true);
  };
  const stopToggle = (e) => {
    if (showInput) {
      e.stopPropagation();
    }
  };
  const [seasonTitle, setSeasonTitle] = useState(
    `${data.title.length ? data.title : index + 1}`
  );

  const handleUpdateSeasonTitle = async () => {
    if (seasonTitle.length) {
      if (
        seasonList
          ?.map((i) => i.title == seasonTitle)
          .every((f) => f === false) ||
        data.title == seasonTitle
      ) {
        try {
          const { status } = await putUpdateSeason({
            id: parseInt(data.seasonID),
            title: String(seasonTitle),
          });
          if (status === 200) {
            dispatch(
              updateSesons({
                id: data.id,
                title: seasonTitle,
              })
            );
            setShowInput(false);
            toasts.success("عنوان فصل باموفقیت بروز شد");
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toasts.error("فصلی با نام مشابه وجود دارد!");
      }
    } else {
      toasts.error("لطفا نام فصل را پر کنید");
    }
  };

  return (
    <section className={styles.section}>
      <i
        style={{ opacity: active ? "0.2" : "0" }}
        className={styles.redLine}></i>
      <div className={styles.accordion_section}>
        <button
          className={`${styles.accordionBtn} btn`}
          onClick={handleToggleAccordion}>
          <div onClick={stopToggle} className={styles.accordion_title}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/dots.svg`}
              alt="name"
            />
            {showInput ? (
              <Input
                name="title"
                value={seasonTitle}
                onChange={(e) => setSeasonTitle(e.target.value)}
                onKeyPress={(e) => getNumberOnly(e)}
                sign
                signIcon="فصل"
                image={{
                  enable: true,
                  icon: "/images/articles/tick-square.svg",
                  clickHandler: handleUpdateSeasonTitle,
                }}
              />
            ) : (
              <span>
                فصل <span className={styles.bold700}>{seasonTitle}</span>
              </span>
            )}
            {!showInput && id && data?.seasonID && (
              <button
                onClick={(e) => handleShowInput(e)}
                className={`${styles.editBtn} btn`}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/articles/edit.svg`}
                  alt=""
                />
              </button>
            )}
          </div>
          <div className={styles.episodesNumber}>
            <span className={styles.bold600}>
              {data.episodes?.length} قسمت
              {/* <span className={styles.line}>-</span> */}
            </span>
            {/* <span className={styles.gray}>{data.time} دقیقه</span> */}
          </div>
          <div className={styles.accordionActions}>
            <button onClick={handleRemoveSeason} className="btn">
              <img
                src={`${process.env.PUBLIC_URL}/images/articles/trash.svg`}
                alt=""
              />
            </button>
            <img
              className={active ? styles.rotate : ""}
              src={`${process.env.PUBLIC_URL}/images/icons/accordion.svg`}
              alt="close"
            />
          </div>
        </button>
      </div>
      <div
        className={styles.accordion_body}
        ref={contentRef}
        style={{
          maxHeight: `${accordionHeight}`,
          marginBottom: active ? "24px" : "0",
        }}>
        {data.episodes?.map((item, index) => (
          <Item
            key={item.id || item.imdb_id}
            data={item}
            index={index}
            activeBorder={item.imdb_id === border || item.id === border}
            handleActiveBorder={() => handleActiveBorder(item, data.title)}
            handleGetEpisodesFromImdb={handleGetEpisodesFromImdb}
            showLoading={showLoading}
          />
        ))}
        <div onClick={handleAddEpisode} className={styles.addNew}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/add-square.svg`}
            alt="+"
          />
          افزودن قسمت {data.episodes?.length + 1}
        </div>
      </div>
    </section>
  );
}
