import throttle from "lodash-es/throttle";
import videojs from "video.js";

const Plugin = videojs.getPlugin("plugin");

class AudioSwitch extends Plugin {
  constructor(player, options) {
    super(player);
    this.audio = null;

    this.player.ready(() => {
      this.audioSwitch(options);
    });
  }

  syncTime(player, audio) {
    if (!audio.src) return;
    audio.currentTime = player.currentTime();
  }

  dispose() {
    const audioTracks = this.player.audioTracks();
    let i = 0;
    let count = audioTracks.length;
    while (i < count) {
      let track = audioTracks[0];
      audioTracks.removeTrack(track);
      i++;
    }
    if (this.audio) {
      this.audio.disposed = true;
      this.audio.pause();
    }
    super.dispose();
  }

  audioSwitch(options) {
    if (!options) return;
    const _this = this;
    const { audioTracks, debugInterval, syncInterval } = options;
    const player = this.player;

    const audio = (this.audio = new Audio());
    audio.disposed = false;
    audio.currentTime = 0;

    const OnAudioTrackChange = () => {
      if (audio.disposed) return;
      const audioTrackList = player.audioTracks();

      let enabledTrack;
      for (let i = 0; i < audioTrackList.length; i++) {
        let track = audioTrackList[i];
        if (track.enabled) {
          enabledTrack = track;
          break;
        }
      }

      enabledTrack = enabledTrack || audioTrackList[0];

      if (enabledTrack) {
        const isPlaying = !player.paused();
        if (isPlaying) player.pause();
        audio.src = audioTracks.find(
          (audioTrack) => audioTrack.language === enabledTrack.language
        )?.url;
        _this.syncTime(player, audio);
        if (isPlaying) player.play();
      }
    };

    var audioTrackList = player.audioTracks();
    audioTrackList.addEventListener("change", OnAudioTrackChange);

    if (audioTracks.length > 0) {
      audioTracks.forEach((track) =>
        audioTrackList.addTrack(new videojs.AudioTrack(track))
      );
      audio.src = audioTracks[0].url;
    }

    player.on("play", () => {
      if (audio.disposed) return;
      _this.syncTime(player, audio);
      if (audio.paused) audio.play();
    });

    player.on("pause", () => {
      if (audio.disposed) return;
      _this.syncTime(player, audio);
      if (!audio.paused) audio.pause();
    });

    player.on("seeked", () => {
      if (audio.disposed) return;
      if (!player.paused()) player.pause();
      player.one("canplay", () => {
        const sync = () => {
          if (audio.disposed) return;
          _this.syncTime(player, audio);
          audio.removeEventListener("canplay", sync);
          if (player.paused()) player.play();
        };
        audio.addEventListener("canplay", sync);
      });
    });

    player.on("volumechange", () => {
      if (audio.disposed) return;
      if (player.muted()) {
        audio.muted = true;
      } else {
        audio.muted = false;
        audio.volume = player.volume();
      }
    });

    if (syncInterval) {
      const syncOnInterval = throttle(() => {
        if (audio.disposed) return;
        _this.syncTime(player.audio);
      }, syncInterval);
      player.on("timeupdate", syncOnInterval);
    }

    if (debugInterval) {
      const debugOnInterval = throttle(() => {
        if (audio.disposed) return;
        const _audioBefore = audio.currentTime;
        const _videoBefore = player.currentTime();
        console.log("debug", {
          audio: _audioBefore,
          video: _videoBefore,
          diff: _videoBefore - _audioBefore,
        });
      }, debugInterval);
      player.on("timeupdate", debugOnInterval);
    }
  }
}

videojs.registerPlugin("audioSwitch", AudioSwitch);

export default AudioSwitch;
