import { useCollectionQuery } from "hooks/query/useCollectionQuery";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  resetCollectionInformation,
  updateCollectionInformation,
} from "../../../../../redux/actions/collections";
import { Input } from "../../../../common/input/Input";
import CategoryDropdown from "../../../../common/categoryDropdown/CategoryDropdown";
import TextArea from "../../../../common/textArea/TextArea";
import styles from "./CollectionIormation.module.scss";

export default function CollectionIormation() {
  const { id } = useParams();
  const { data } = useCollectionQuery(id);
  const dispatch = useDispatch();

  const createCollection = useSelector(
    (state) => state.createCollectionReducer
  );

  useEffect(() => {
    if (data) {
      dispatch(
        updateCollectionInformation({
          title: data.title,
          title_in_persian: data.title_in_persian,
          summary: data.summary,
          cover: data.cover,
        })
      );
    }
  }, [data, dispatch]);

  // collection information states
  const handleChangeInput = (e) => {
    dispatch(
      updateCollectionInformation({
        [e.target.name]: e.target.value,
      })
    );
  };
  const changeCategory = (categoryId) => {
    dispatch(
      updateCollectionInformation({
        category_id: categoryId,
      })
    );
  };

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <Input
          placeholder="عنوان کالکشن را بصورت لاتین و بدون سال اکران وارد نمایید، به طور مثال: Till Death"
          label="عنوان کالکشن"
          name="title"
          value={createCollection.title}
          onChange={handleChangeInput}
          require
        />
        <Input
          placeholder="عنوان فارسی کالکشن را در صورت نیاز و بدون سال اکران وارد نمایید، به طور مثال: ارباب حلقه ها"
          label="عنوان کالکشن (فارسی)"
          name="title_in_persian"
          value={createCollection.title_in_persian}
          onChange={handleChangeInput}
        />
      </div>
      <div className={styles.groupWrapper}>
        <TextArea
          label="خلاصه کالکشن"
          name="summary"
          placeholder="توضیحات و یا خلاصه داستانی مربوط به کالکشن را وارد نمایید "
          value={createCollection.summary}
          onChange={handleChangeInput}
          isRequired
        />

        <CategoryDropdown
          label={"دسته بندی"}
          value={createCollection}
          updateCb={changeCategory}
        />
      </div>
    </section>
  );
}
