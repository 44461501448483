// import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Breadcrumb.module.scss";
// Need add dynmaic breadCrumb

export default function Breadcrumb({ links }) {
  // Use for responsive
  // const lastBreadCrumb = links[links.length - 1];

  return (
    <nav aria-label="breadcrumb" className={styles.breadCrumbContainer}>
      <ol className={`breadcrumb ${styles.breadcrumb}`}>
        {/* {createBreadcrumb()} */}
        {window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie" ? (
          "پنل سرمووی"
        ) : (
          <>
            {links.map((link, index) => (
              <li className={styles.breadcrumbItem} key={index}>
                <Link to={link.path ? link.path : "/overView"}>
                  {link.name}
                </Link>
              </li>
            ))}
          </>
        )}
      </ol>

      {/* <div className={styles.breadcrumbControls}>
				<button className={`${styles.controller} ${styles.prevControls}`}>
					<img
						src={`${process.env.PUBLIC_URL}/images/topBar/chevron-right.svg`}
						alt=""
					/>
				</button>
				<button className={`${styles.controller} ${styles.nextControls}`}>
					<img
						src={`${process.env.PUBLIC_URL}/images/topBar/chevron-left.svg`}
						alt=""
					/>
				</button>
			</div> */}
    </nav>
  );
}
