export const getNumberOnly = (e) => {
  const code = e.keyCode ? e.keyCode : e.which;
  const NUMBER_CODES = code >= 48 && code <= 57;

  if (!(NUMBER_CODES || code === 46)) e.preventDefault();
};

export const splitNumberToCardFormat = (string) => {
  return string.match(/\d{4}/g);
};

// separate number 3 by 3 with ","
export const convertNumber = (number) => {
  return Number(number.toFixed(3)).toLocaleString().split(/\s/).join(",");
};

// Complete Number Formater
export const numberFormater = (number, decimals, decPoint, thousandsSep) => {
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
  const dec = typeof decPoint === "undefined" ? "." : decPoint;
  let s = "";
  const toFixedFix = function (n, prec) {
    if (("" + n).indexOf("e") === -1) {
      return +(Math.round(n + "e+" + prec) + "e-" + prec);
    } else {
      const arr = ("" + n).split("e");
      let sig = "";
      if (+arr[1] + prec > 0) {
        sig = "+";
      }
      return (+(
        Math.round(+arr[0] + "e" + sig + (+arr[1] + prec)) +
        "e-" +
        prec
      )).toFixed(prec);
    }
  };
  s = (prec ? toFixedFix(n, prec).toString() : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};
