import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteComment,
  getComments,
  patchConfirmComment,
  patchVisibilityComment,
} from "services/comments/commentsService";
import toasts from "components/common/toasts/toasts";

export const useCommentsQuery = (page) => {
  return useQuery(["comments", page], ({ signal }) => getComments(page,signal), {
    keepPreviousData: true,
    select: (data) => {
      return data.data.data;
    },
  });
};

export const usePatchVisibilityCommentQuery = (bool) => {
  const queryClient = useQueryClient();
  return useMutation(patchVisibilityComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      toasts.success(`کامنت مورد نظر ${bool ? "فعال" : "غیر فعال"} شد.`);
    },
    onError: () => {
      toasts.error("مشکلی پیش آمده");
    },
  });
};
export const usePatchConfirmCommentQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(patchConfirmComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      toasts.success("کامنت مورد نظر تایید شد.");
    },
    onError: () => {
      toasts.error("مشکلی برای تایید پیش آمده.");
    },
  });
};
export const useDeleteCommentQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      toasts.success("کامنت مورد نظر رد شد.");
    },
    onError: () => {
      toasts.error("مشکلی برای رد پیش آمده.");
    },
  });
};
