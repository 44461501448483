import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  addMovieLinkDownload,
  updateMovieLinkDownload,
  deleteMovieLinkDownload,
  getMovieLinkDownload,
} from "redux/actions/movies";
import {
  useDeleteMovieDownloadLinkQuery,
  useMovieDownloadLinkQuery,
} from "hooks/query/useMovieQuery";
import { Input } from "components/common/input/Input";
import { encoderOptions, qualityOptions } from "constants/movies";
import { deleteMovieDownloadLinkExtraData } from "services/movies/moviesServices";
import toasts from "components/common/toasts/toasts";
import DropDown from "components/common/dropDown/DropDown";
import TagInput from "components/common/tagInput/TagInput";
import styles from "./UploadDownloadLinks.module.scss";

export default function UploadDownloadLinks() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data } = useMovieDownloadLinkQuery(id);
  const { mutate: deleteMovieDownloadLink } = useDeleteMovieDownloadLinkQuery();
  useEffect(() => {
    if (data?.length) {
      dispatch(getMovieLinkDownload(data?.flatMap((item) => item.links)));
    }
  }, [data, dispatch]);
  const handleDeleteExtraData = async (extraID) => {
    try {
      const { status } = await deleteMovieDownloadLinkExtraData(extraID);
      if (status === 200) {
        toasts.success("ویژگی مورد نظر حذف شد.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // redux
  const downloadList = useSelector((state) => state.movieDownloadLinkReducer);
  const handleDelete = (data, index) => {
    if (data.hasOwnProperty("movie_id")) {
      deleteMovieDownloadLink(data.id);
    } else {
      dispatch(deleteMovieLinkDownload({ index: index }));
    }
  };
  const handleUpdate = (e, index) => {
    dispatch(
      updateMovieLinkDownload({
        index,
        data: {
          [e.target.name]: e.target.value,
        },
      })
    );
  };
  const handleChangeDropdown = (option, action, index) => {
    dispatch(
      updateMovieLinkDownload({
        index,
        data: {
          [action.name]: option,
        },
      })
    );
  };

  return (
    <>
      <h4>افزودن فایل های دانلود</h4>
      {downloadList
        .sort((a, b) => {
          if (a.id && b.id) {
            return (
              Number(b.quality.name?.slice(0, 4).replace(/\D/g, "")) -
              Number(a.quality.name?.slice(0, 4).replace(/\D/g, ""))
            );
          } else {
            return;
          }
        })
        .map((item, index) => (
          <div key={item.id || index} className={styles.flexPill}>
            <div className={styles.firstRow}>
              <div>
                <DropDown
                  placeholder="کیفیت"
                  name="quality"
                  options={qualityOptions}
                  value={item.quality}
                  getOptionLabel={(option) => option["name"]}
                  getOptionValue={(option) => option["id"]}
                  onChange={(e, action) =>
                    handleChangeDropdown(e, action, index)
                  }
                />
              </div>
              <div>
                <DropDown
                  placeholder="انکودر"
                  name="encoder"
                  options={encoderOptions}
                  value={item.encoder}
                  getOptionLabel={(option) => option["name"]}
                  getOptionValue={(option) => option["id"]}
                  onChange={(e, action) =>
                    handleChangeDropdown(e, action, index)
                  }
                />
              </div>
              <div>
                <Input
                  placeholder="حجم (mb)"
                  name="size"
                  value={item.size}
                  onChange={(e) => handleUpdate(e, index)}
                />
              </div>
              <div>
                <Input
                  placeholder="لینک دانلود"
                  name="download_link"
                  value={item.download_link}
                  onChange={(e) => handleUpdate(e, index)}
                  // disabled={item.hasOwnProperty("movie_id")}
                />
              </div>
              <div>
                <Input
                  placeholder="لینک اسکرین شات"
                  name="link_screenshot"
                  value={item.link_screenshot}
                  onChange={(e) => handleUpdate(e, index)}
                />
              </div>
              {downloadList.length === index + 1 ? (
                <div
                  onClick={() => dispatch(addMovieLinkDownload())}
                  className={styles.addBtn}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/common/plus.svg`}
                    alt="+"
                  />
                </div>
              ) : (
                <div
                  onClick={() => handleDelete(item, index)}
                  className={`${styles.addBtn} ${styles.deleteBtn}`}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/articles/trash.svg`}
                    alt="+"
                  />
                </div>
              )}
            </div>
            <div>
              <TagInput
                propState={[
                  item.extra_data,
                  (e) =>
                    dispatch(
                      updateMovieLinkDownload({
                        index,
                        data: {
                          extra_data: e,
                        },
                      })
                    ),
                ]}
                handleDelete={handleDeleteExtraData}
                label="ویژگی ها"
                type="extra_data"
              />
            </div>
          </div>
        ))}
    </>
  );
}
