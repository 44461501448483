import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Pagination from "rc-pagination";
import styles from "./Paginate.module.scss";

export default function Paginate({ type, total, paginateState, name }) {
  const [current, setCurrent] = paginateState;

  // url query
  const history = useHistory();
  const location = useLocation();
  const pageNumber = location?.search?.match(/\d+/)
    ? location?.search?.match(/\d+/)[0]
    : 1;
  useEffect(() => {
    if (pageNumber) {
      setCurrent(+pageNumber);
    }
  }, []);
  useEffect(() => {
    if (current) {
      if (type === "actors") {
        history.replace(`/${type}?page=${current}&name=${name}`);
      } else if (type === "movies") {
        history.replace(`/${type}?page=${current}&name=${name}`);
      } else if (type === "series") {
        history.replace(`/${type}?page=${current}&name=${name}`);
      } else {
        history.replace(`/${type}?page=${current}`);
      }
    }
  }, [current, history, name, type]);

  return (
    <Pagination
      className={styles.paginate}
      defaultCurrent={1}
      current={current}
      // defaultPageSize={10}
      total={total}
      onChange={(current) => setCurrent(current)}
      jumpNextIcon="..."
      jumpPrevIcon="..."
      showTitle={false}
      prevIcon={() => (
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/paginate-prev.svg`}
          alt=">"
        />
      )}
      nextIcon={() => (
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/paginate-next.svg`}
          alt="<"
        />
      )}
    />
  );
}
