import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Input } from "components/common/input/Input";
import {
  addEpisodeDownloadLink,
  deleteEpisodeDownloadLinkByIndex,
} from "redux/actions/series";
import { encoderOptions, qualityOptions } from "constants/movies";
import { useDeleteEpisodesDownloadLinkQuery } from "hooks/query/useSerieQuery";
import { copyToClipboard } from "util/copyToClipboard";
import { deleteEpisodesDownloadLinkExtraData } from "services/series/series";
import DropDown from "components/common/dropDown/DropDown";
import toasts from "components/common/toasts/toasts";
import TagInput from "components/common/tagInput/TagInput";
import styles from "./EpisodeInfo.module.scss";
import { getNumberOnly } from "util/number";

export default function DownloadLinkPart({ data }) {
  const { mutate: deleteEpisodesDownloadLink } =
    useDeleteEpisodesDownloadLinkQuery();
  const dispatch = useDispatch();
  const [downloadList, setDownloadList] = useState([]);
  useEffect(() => {
    if (data) {
      setDownloadList(data?.data.links ? data?.data.links : []);
    } else {
      setDownloadList([]);
    }
  }, [data]);
  const [form, setForm] = useState({
    title: "",
    quality: "",
    size: "",
    encoder: "",
    download_link: "",
    link_screenshot: "",
    extra_data: [],
  });
  const [showForm, setShowForm] = useState(false);
  const handleChangeInputs = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeDropdown = (option, action) => {
    setForm({
      ...form,
      [action.name]: option,
    });
  };

  // ADD
  useEffect(() => {
    if (data?.data?.downloadFile === null) {
      setDownloadList([]);
    } else {
      setDownloadList(data?.data?.downloadFile);
    }
  }, [data?.data?.downloadFile]);
  const addSeriesDownloadLinkToReducer = () => {
    dispatch(
      addEpisodeDownloadLink({
        seasonId: data?.seasonId,
        episodeId: data?.data.imdb_id,
        data: form,
      })
    );
    setDownloadList([...downloadList, form]);
    setShowForm(false);
    setForm({
      title: "",
      quality: "",
      size: "",
      encoder: "",
      download_link: "",
      extra_data: [],
    });
  };

  //DELETE
  const handleDeleteDownloadFile = (itemIndex, itemId) => {
    if (itemId) {
      deleteEpisodesDownloadLink(itemId);
    }
    setDownloadList(downloadList.filter((_, index) => index !== itemIndex));
    dispatch(
      deleteEpisodeDownloadLinkByIndex({
        seasonId: data?.seasonId,
        episodeId: data?.data.imdb_id,
        index: itemIndex,
      })
    );
  };
  const handleDeleteExtraData = async (extraID) => {
    try {
      const { status } = await deleteEpisodesDownloadLinkExtraData(extraID);
      if (status === 200) {
        const newList = downloadList.map((item) => {
          return {
            ...item,
            extra_data: item.extra_data.filter((e) => e.id !== extraID),
          };
        });
        setDownloadList(newList);
        toasts.success("ویژگی مورد نظر حذف شد.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>فایل دانلود</span>
      {downloadList?.length > 0 && (
        <div className={styles.downloadFileList}>
          {downloadList
            ?.sort((a, b) => {
              if (a.id && b.id) {
                return (
                  Number(b.quality.name?.slice(0, 4).replace(/\D/g, "")) -
                  Number(a.quality.name?.slice(0, 4).replace(/\D/g, ""))
                );
              } else {
                return;
              }
            })
            .map((item, index) => (
              <div key={index} className={styles.downloadFileItem}>
                <img
                  onClick={() => handleDeleteDownloadFile(index, item.id)}
                  src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/close.svg`}
                  alt="x"
                />
                <div>
                  <div className={styles.boxItem}>
                    <span>عنوان:</span>
                    <span>{item.title ? item.title : "-"}</span>
                  </div>
                </div>
                <div className={styles.firstRow}>
                  <div className={styles.boxItem}>
                    <span>کیفیت:</span>
                    <span>
                      {item.quality?.name
                        ? item.quality.name
                        : item.quality
                        ? item.quality
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.boxItem}>
                    <span>حجم:</span>
                    <span>{item.size ? item.size : "-"} Mb</span>
                  </div>
                </div>
                <div className={styles.boxItem}>
                  <span>انکودر:</span>
                  <span>
                    {item.encoder?.name
                      ? item.encoder.name
                      : item.encoder
                      ? item.encoder
                      : "-"}
                  </span>
                </div>
                <div className={styles.boxItem}>
                  <span>لینک دانلود:</span>
                  <span
                    title="بر روی لینک مورد نظر دوبار کلیک کنید تا کپی شود"
                    onDoubleClick={() =>
                      copyToClipboard(
                        item.download_link,
                        "لینک دانلود با موفقیت کپی شد"
                      )
                    }>
                    {item.download_link
                      ? item.download_link.slice(0, 25) + "..."
                      : "-"}
                  </span>
                </div>
                <div className={styles.boxItem}>
                  <span>لینک اسکرین شات:</span>
                  <span
                    title="بر روی لینک مورد نظر دوبار کلیک کنید تا کپی شود"
                    onDoubleClick={() =>
                      copyToClipboard(
                        item.link_screenshot,
                        "لینک اسکرین شات با موفقیت کپی شد"
                      )
                    }>
                    {item.link_screenshot
                      ? item.link_screenshot.slice(0, 25) + "..."
                      : "-"}
                  </span>
                </div>
                <div className={`${styles.boxItem} ${styles.features}`}>
                  <span>ویژگی ها:</span>
                  <div>
                    {item.extra_data?.map((f, index) => (
                      <span key={index || f.id}>
                        {f.id && (
                          <img
                            onClick={() => handleDeleteExtraData(f.id)}
                            src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/close.svg`}
                            alt="x"
                          />
                        )}
                        {f.extra_data || f}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      {showForm && (
        <div className={styles.downloadFileForm}>
          <div className={styles.downloadFileFormBottom}>
            <Input
              placeholder="عنوان(به طور مثال: قسمت 1)"
              name="title"
              value={form.title}
              onChange={handleChangeInputs}
            />
          </div>
          <div className={styles.downloadFileFormTop}>
            <DropDown
              placeholder="کیفیت"
              name="quality"
              options={qualityOptions}
              value={form.quality}
              getOptionLabel={(option) => option["name"]}
              getOptionValue={(option) => option["id"]}
              onChange={(e, action) => handleChangeDropdown(e, action)}
            />
            <Input
              placeholder="حجم (mb)"
              name="size"
              value={form.size}
              onChange={handleChangeInputs}
              onKeyPress={(e) => getNumberOnly(e)}
            />
          </div>
          <div className={styles.downloadFileFormBottom}>
            <DropDown
              placeholder="انکودر"
              name="encoder"
              options={encoderOptions}
              value={form.encoder}
              getOptionLabel={(option) => option["name"]}
              getOptionValue={(option) => option["id"]}
              onChange={(e, action) => handleChangeDropdown(e, action)}
            />
            <Input
              placeholder="لینک دانلود"
              name="download_link"
              value={form.download_link}
              onChange={handleChangeInputs}
            />
            <Input
              placeholder="لینک اسکرین شات"
              name="link_screenshot"
              value={form.link_screenshot}
              onChange={handleChangeInputs}
            />
            <TagInput
              propState={[
                form.extra_data,
                (e) =>
                  setForm({
                    ...form,
                    extra_data: e,
                  }),
              ]}
              label="ویژگی ها"
              type="extra_data"
              isSeries
            />

            <button
              onClick={addSeriesDownloadLinkToReducer}
              className={`${styles.blackBtn} btn`}>
              ثبت فایل
            </button>
          </div>
        </div>
      )}
      {!showForm && (
        <button
          onClick={() => setShowForm(true)}
          className={`${styles.blackBtn} btn`}>
          افزودن فایل دانلود
        </button>
      )}
    </div>
  );
}
