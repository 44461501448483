import { Modal } from "react-bootstrap";
import styles from "./DeleteModal.module.scss";

export default function DeleteModal({ onHide, title }) {
  return (
    <>
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
          امکان حذف <span className={styles.bold}>{title}</span> از پروژه های
          این فیلم وجود ندارد زیرا محتوای مورد نظر حداقل باید به یک پروژه تعلق
          داشته باشد!
        </Modal.Title>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Footer className={styles.footer}>
        <div className={`${styles.btn} ${styles.primary}`}>تایید</div>
      </Modal.Footer>
    </>
  );
}