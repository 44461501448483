import { useCallback, useEffect, useState } from "react";
import { Input } from "components/common/input/Input";
import {
  usePostCategoryQuery,
  usePutCategoryQuery,
} from "hooks/query/useCategoryQuery";
import { Modal } from "react-bootstrap";
import { uploadFile } from "util/uploadFile";
import { CloseButton } from "components/packageAndFeatures/components/Components";
import LogoDropzone from "../logoDropzone/LogoDropzone";
import TextArea from "components/common/textArea/TextArea";
import styles from "./AddCategoryModal.module.scss";

function AddCategoryModal({ data, onHide, type }) {
  const { mutate: postCategory } = usePostCategoryQuery();
  const { mutate: updateCategory } = usePutCategoryQuery();

  const [form, setForm] = useState({ avatar: null, name: "", description: "" });
  useEffect(() => {
    if (data || type === "update") {
      setForm({
        avatar: data.avatar,
        name: data.name,
        description: data.description,
      });
    }
  }, [data, type]);

  const onChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeLogo = useCallback((image) => {
    setForm((prev) => ({ ...prev, avatar: image }));
  }, []);

  const handleCreate = (payload) => {
    postCategory(payload);
  };
  const handleUpdate = (payload) => {
    updateCategory({ ...payload, id: data.id });
  };
  const onSubmit = async () => {
    if (form.avatar?.hasOwnProperty("path")) {
      const fileId = await uploadFile({ file: form.avatar });
      form["avatar"] = fileId;
    }

    if (type === "create") {
      handleCreate(form);
    }

    if (type === "update") {
      handleUpdate(form);
    }

    onHide();
  };

  return (
    <div className={styles.wrapper}>
      <Modal.Header className={styles.header}>
        <CloseButton position="top" onClick={onHide} />
        <Modal.Title className={styles.header__title}>
          افزودن دسته بندی
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <div className={styles.row}>
          <LogoDropzone image={form.avatar} onImageChange={onChangeLogo} />
          <Input
            placeholder="نام دسته بندی"
            onChange={onChangeInput}
            value={form.name}
            name="name"
          />
        </div>
        <TextArea
          placeholder="توضیحات"
          onChange={onChangeInput}
          value={form.description}
          name="description"
        />
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <button className={styles.button} onClick={onSubmit}>
          ثبت دسته بندی
        </button>
      </Modal.Footer>
    </div>
  );
}

export default AddCategoryModal;
