import { uniqueId } from "lodash-es";

// SET NEW STREAM
const liveStreamState = {
  title: "",
  active: true,
  cover_id: "",
  hls_url: "",
  server_url: "",
  stream_key: "",
  resolutions: [
    {
      uniqueId: uniqueId(),
      audio_bitrate: null,
      resolution: "",
      video_bitrate: null,
      audio: null, // {min,max}
      video: null, // {min,max}
    },
  ],
  fps: 30,
  watermark_id: "",
  watermark_position: "",
};
export const setLiveStreamingReducer = (state = liveStreamState, action) => {
  switch (action.type) {
    case "SET_STREAM_KEYS":
      return {
        ...state,
        hls_url: action.payload.hls_url,
        server_url: action.payload.server_url,
        stream_key: action.payload.stream_key,
        // resolutions: action.payload.resolutions,
        // watermark_position: action.payload.watermark_position,
        // fps: action.payload.fps,
      };

    case "ADD_LIVE_RESOLUTIONS":
      return {
        ...state,
        resolutions: [
          ...state.resolutions,
          {
            uniqueId: uniqueId(),
            audio_bitrate: null,
            resolution: "",
            video_bitrate: null,
          },
        ],
      };

    case "CHANGE_LIVE_RESOLUTIONS":
      return {
        ...state,
        resolutions: state.resolutions.map((item, index) => {
          if (index === action.payload.id) {
            return action.payload.value;
          } else {
            return item;
          }
        }),
      };

    case "REMOVE_LIVE_RESOLUTIONS":
      return {
        ...state,
        resolutions: state.resolutions.filter(
          (item) => item.uniqueId !== action.payload.uniqueId
        ),
      };

    case "UPDATE_LIVE_STREAM":
      return { ...state, ...action.payload };

    case "RESET_LIVE_STREAM":
      return liveStreamState;

    default:
      return state;
  }
};

// helper function for remove item
const removeFromArrayWithID = (array, id) =>
  array.filter((item) => item.id !== id);

// GET ALL STREAM
const streamListState = {
  selectedStream: [],
  isAllSelected: false,
};
export const getAllLiveStreamingReducer = (state = streamListState, action) => {
  switch (action.type) {
    case "ADD_SELECTED_STREAM_LIST":
      return {
        ...state,
        selectedStream: [...state.selectedStream, action.payload],
      };
    // case "REMOVE_SELECTED_STREAM_LIST":
    //   return {
    //     ...state,
    //     selectedStream: removeFromArrayWithID(
    //       state.selectedStream,
    //       action.payload
    //     ),
    //     isAllSelected:
    //       removeFromArrayWithID(state.selectedStream, action.payload) === [],
    //   };
    case "UPDATE_SELECT_ALL_STREAM":
      return {
        ...state,
        selectedStream: action.payload.allChecked
          ? action.payload.streamList
          : [],
        isAllSelected: action.payload.allChecked,
      };
    default:
      return state;
  }
};
