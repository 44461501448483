import { useState } from "react";
import { useStreamsQuery } from "hooks/query/useStreamQuery";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeadTitle from "new-components/head-title";
import Row from "./row";
import Paginate from "components/common/paginate/Paginate";
import styles from "./styles.module.scss";
export default function LiveStreamLists() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = useStreamsQuery(currentPage);
  return (
    <section className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.head}>
          <HeadTitle title="لیست پخش ها" />
          <Link to="/live-stream/new">
            <button className="btn">
              <img
                src={`${process.env.PUBLIC_URL}/images/new-icons/video-add.svg`}
                alt="add +"
              />
              <span>ایجاد پخش زنده</span>
            </button>
          </Link>
        </div>
        <div className={styles.table}>
          {isPreviousData ? (
            <div className="spinnerCenter">
              <Spinner animation="border" as="i" />
            </div>
          ) : (
            data?.results?.map((movie) => <Row key={movie.id} data={movie} />)
          )}
        </div>
        <Paginate
          type="live-stream"
          paginateState={[currentPage, setCurrentPage]}
          total={data?.pagination.count}
        />
      </div>
    </section>
  );
}