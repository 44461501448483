import { useState, useEffect } from "react";
import { useTranscodeServersQuery } from "hooks/query/useCastsQuery";
import { useDeleteTranscodedFileQuery } from "hooks/query/useFileQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  unSelectMovieFile,
  selectMovieFile,
  selectMovieServer,
  addLinkURLToMovie,
} from "../../../../../../redux/actions/movies";
import {
  getTranscodedFileInfo,
  getTranscoderFileInfo,
} from "../../../../../../services/files/files";
import { convertToGigabyte } from "../../../../../../util/changeByteToKByte";
import DropDown from "../../../../../common/dropDown/DropDown";
import MovieFileUploaderCard from "../../../../../common/movieFileUploader/movieFileUploaderCard/MovieFileUploaderCard";
// import ProgressBar from "../../../../../common/progressBar/ProgressBar";
import styles from "./UploadMovie.module.scss";

export default function UploadMovie() {
  const dispatch = useDispatch();
  const { mutateAsync: deleteTranscodedFile } =
    useDeleteTranscodedFileQuery("movie");
  // GET transcode servers
  const { data: servers } = useTranscodeServersQuery();

  const { sourceFile, linkURL } = useSelector(
    (store) => store.movieFileUploadReducer
  );
  const movieInfo = useSelector((store) => store.createMovieReducer);

  const handleSetMovie = (e) => {
    dispatch(selectMovieFile(e));
  };

  const handleClose = () => {
    // the below condition work becuz of we only can store one movie file
    if (sourceFile.every((item) => item["path"])) {
      // const array = sourceFile.filter(item => item.path === files.path);
      dispatch(unSelectMovieFile());
    }
    if (linkURL.length > 0) {
      dispatch(addLinkURLToMovie(""));
    }
  };

  // const handleClickDownload = () => {};

  // GET movie transcode ID
  const [transcodeID, setTranscodeID] = useState("");
  const [transcodeServer, setTranscodeServer] = useState("");
  useEffect(() => {
    if (movieInfo) {
      setTranscodeServer(movieInfo?.movie_url?.split("/")[2]);
      setTranscodeID(movieInfo?.movie_url?.split("/")[4]);
    }
  }, [movieInfo]);
  // GET movie file
  const [transcodeInfo, setTranscodeInfo] = useState("");
  const handleGetTranscodedFileInfo = async () => {
    try {
      const { data, status } = await getTranscodedFileInfo(transcodeID);
      if (status === 200) {
        setTranscodeInfo(data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status == 404) {
          const { data, status } = await getTranscoderFileInfo(
            transcodeServer,
            transcodeID
          );
          if (status === 200) {
            setTranscodeInfo(data.data);
          } else {
            setTranscodeInfo("");
          }
        }
      } else {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (transcodeID) {
      handleGetTranscodedFileInfo();
    }
  }, [transcodeID]);
  // DELETE movie file
  const handleDeleteFile = async () => {
    const { status } = await deleteTranscodedFile({
      server: transcodeServer,
      transcodeId: transcodeID,
    });
    if (status === 200) {
      setTranscodeInfo("");
    }
  };

  if (transcodeInfo?.id?.length || transcodeInfo?.transcode_id?.length) {
    return (
      <>
        <h4>فایل آپلود شده فیلم</h4>
        <div className={`${styles.uploadingMovie} ${styles.pill}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/movies/movie.svg`}
            alt={transcodeInfo.name}
          />
          <div className={styles.info}>
            <p>{transcodeInfo.name}</p>
          </div>
          <button
            onClick={handleDeleteFile}
            className={`${styles.btn} ${styles.remove} btn`}>
            حذف فایل
          </button>
          <a
            href={movieInfo.movie_url}
            className={`${styles.btn} ${styles.dl}`}>
            دانلود فایل
          </a>
        </div>
      </>
    );
  } else {
    return (
      <>
        <DropDown
          placeholder="نوع سروری که میخواهید این فیلم از آن ترنسکد کنید را انتخاب نمایید"
          label="سرور ترنسکد"
          options={servers}
          type="server"
          // value={messagePackage}
          getOptionLabel={(option) => option["server_name"]}
          getOptionValue={(option) => option["id"]}
          onChange={(e) => dispatch(selectMovieServer(e))}
          isRequired
        />
        <h4 className={styles.title}>
          بارگذاری فیلم
          <span>(کیفیت های قابل قبول: 240p ,360p ,480p ,720p ,1080p ,4K)</span>
        </h4>
        <div className={styles.pill}>
          <MovieFileUploaderCard
            text="برای بارگذاری فیلم، آنرا به اینجا کشیده و یا با کلیک کردن
		از سیستم خود انتخاب نمایید، فرمت مورد قبول mkv. و mp4.  است
		همچنین میتوانید با استفاده از لینک، فیلم خود را اضافه نمایید"
            maxFiles={1}
            btnPlace={"top"}
            type={"movie"}
            fileState={[sourceFile, handleSetMovie]}
          />
        </div>
        {sourceFile?.length > 0 && (
          <div className={`${styles.uploadingMovie} ${styles.pill}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/movies/movie.svg`}
              alt={sourceFile[0].name}
            />
            <div className={styles.info}>
              <p>{sourceFile[0].name}</p>
              <span>({`${convertToGigabyte(sourceFile[0].size)}GB`})</span>
            </div>
            {/* <div>
				<ProgressBar
					fileName={sourceFile[0].path}
					type={'first'}
					progressed={0}
					totalVolume={convertToGigabyte(sourceFile[0].size)}
					totalFilled={0}
				/>
			</div> */}
            <button
              onClick={handleClose}
              className={`${styles.btn} ${styles.remove}`}>
              حذف فایل
            </button>
          </div>
        )}
        {linkURL?.length > 0 && (
          <div className={`${styles.uploadingMovie} ${styles.pill}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/movies/movie.svg`}
              alt={""}
            />
            <div className={styles.info}>
              <p>
                {" "}
                لینک وارد شده:{" "}
                {linkURL.length > 100 ? linkURL.slice(0, 100) + "..." : linkURL}
              </p>
            </div>
            <button
              onClick={handleClose}
              className={`${styles.btn} ${styles.remove}`}>
              حذف لینک
            </button>
          </div>
        )}
      </>
    );
  }
}
