import React from "react";

import styles from "./RedSwitch.module.scss";

function RedSwitch({ label, isActive, onToggle }) {
	return (
		<div className={styles.wrapper}>
			<div
				className={`${styles.switch} ${
					isActive ? styles.active : styles.notActive
				}`}
				onClick={onToggle}
			>
				<div className={styles.circle} />
			</div>
			<label>{label}</label>
		</div>
	);
}

export default RedSwitch;
