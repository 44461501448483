import { Link } from "react-router-dom";
import styles from "./MainSection.module.scss";
export default function MainSection() {
  return (
    <section className={styles.wrapper}>
      <div>
        <div className={styles.info}>
          <h1 className="text__black">
            با پلتفرم ویدیوئی استریمر، کسب و کار ویدیوئی خود را راه اندازی کنید
          </h1>
          <p className="text__black">
            پلتفرم پخش زنده و Live Streaming استریمر، پلتفرمی دانش بنیان است که
            این امکان را به کاربران میدهد تا پخش زنده خود را بصورت اختصاصی و بر
            روی دامنه های دلخواه خود انجام دهند. امکاناتی نظیر شخصی سازی Player،
            استفاده از لوگوی دلخواه، پهنای باند مصرفی نامحدود و ده ها قابلیت
            دیگر، ایمن آریا را از سایر پلتفرم های داخلی متمایز میکند.
          </p>
          <div className={styles.actions}>
            <Link to="/registerAndLogin" className={styles.Btn}>
              شروع کن
            </Link>
            <Link to="/#features" className={`${styles.Btn} ${styles.outline}`}>
              مشاهده امکانات
            </Link>
          </div>
        </div>
        <figure className="image-cover">
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/main.svg`}
            alt="Main Section"
          />
        </figure>
      </div>
    </section>
  );
}