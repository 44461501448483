import videojs from "video.js";

const MenuButton = videojs.getComponent("MenuButton");
const Component = videojs.getComponent("Component");

class AdaptiveResButton extends MenuButton {
  constructor(player, options) {
    // console.log(options);
    super(player, options);

    this.el_.setAttribute("aria-label", "Settings Menu");
    this.el_
      .querySelector(".vjs-icon-placeholder")
      .classList.add("vjs-icon-cog");
  }

  buildCSSClass() {
    return `vjs-settings-menu ${super.buildCSSClass()}`;
  }
}

Component.registerComponent("AdaptiveResButton", AdaptiveResButton);
export default AdaptiveResButton;
