import http from "../httpService";
//REGISTER
export const registerUser = (register) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/register`,
    JSON.stringify(register)
  );
};
export const registerConfirmCode = (code) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/register/confirm`,
    JSON.stringify(code)
  );
};
//LOGIN
export const isUserExist = (userInfo) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/isUserExist`,
    JSON.stringify(userInfo)
  );
};
export const loginUserWithPassword = (login) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/login`,
    JSON.stringify(login)
  );
};
export const loginUserWithVerificationCode = (login) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/loginWithVerificationCode`,
    JSON.stringify(login)
  );
};
export const loginConfirmCode = (login) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/login/confirm`,
    JSON.stringify(login)
  );
};
// Reset Password
export const resetPasswordWithMobile = (data) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/recoveryPasswordWithVerificationCode`,
    data
  );
};
export const resetPasswordWithMobileConfirm = (data) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/recoveryPassword/confirm`,
    data
  );
};
export const setNewRecoveryPassword = (data) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/recoveryPassword/new`,
    data
  );
};
// Refresh Token
export const refreshAccessToken = (refreshToken) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/refresh`, {
    token: refreshToken,
  });
