import { usePostSyncBoxOfficeQuery } from "hooks/query/useBoxOfficeQuery";
import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
// import { fetchBoxOffice } from "../../../redux/actions/boxOffice";
import ConfirmModal from "../confirmModal/ConfirmModal";
import styles from "./Header.module.scss";

function Header() {
  const { mutateAsync: postSyncBoxOffice } = usePostSyncBoxOfficeQuery();
  const [show, setShow] = useState(false);

  const onHide = () => {
    setShow(false);
  };

  const onShow = () => {
    setShow(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const handleCheckLatestBoxOffice = async () => {
    try {
      setIsLoading(true);
      const { data } = await postSyncBoxOffice();
      setData(data.data);
      setIsLoading(false);
      onShow();
    } catch (err) {
      console.error(err);
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <button
          className={`${styles.button} ${styles.black}`}
          onClick={handleCheckLatestBoxOffice}
          disabled={isLoading}>
          بروز رسانی فیلم ها از IMDB
          {isLoading && <Spinner animation="border" as="i" />}
        </button>
      </div>

      <Modal
        show={show}
        onHide={onHide}
        dialogClassName={styles.modal}
        centered>
        <ConfirmModal onHide={onHide} latestBoxOfficeMovies={data} />
      </Modal>
    </>
  );
}

export default Header;
