import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toasts from "../components/common/toasts/toasts";
// AUDIO DROPZONE
// this dropzone below only works with single dropzone
//* NOTE: custom hook to handle single image file zone
export const useAudioDropzone = (initialAudio = null) => {
  // const emptyInitialValue = isMulti ? [] : null;
  //* NOTE: if need to make it select more than one file change false values with dynamic value
  const [audioFiles, setAudioFiles] = useState(initialAudio);
  const [audioUrl, setAudioUrl] = useState("");

  const onDropAccepted = useCallback((acceptedFiles) => {
    if (false) {
    } else {
      const [file] = acceptedFiles;
      setAudioFiles(file);
      setAudioUrl(URL.createObjectURL(file));
    }
  }, []);

  // maybe need to do something about rejection
  const onDropRejected = (rejectedFiles) => {
    toasts.error(
      "شما فقط می توانید از فرمت های MP4 ، MPEG و AIFF استفاده کنید"
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["audio/mpeg", "audio/mp4", "audio/x-aiff"],
    maxFiles: false ? 1 : 100,
    onDropAccepted,
    onDropRejected,
    // noDrag: true,
  });

  const audioPreview = audioFiles?.hasOwnProperty("path")
    ? audioUrl
    : "backend url";

  return {
    getRootProps,
    getInputProps,
    audioFiles,
    audioPreview,
  };
};

// SINGLE IMAGE DROPZONE
//* NOTE: custom hook to handle single image file zone
export const useSingleImageDropZone = (initialImage) => {
  const [imageFile, setImageFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    if (initialImage) {
      const previewUrl = initialImage.hasOwnProperty("path")
        ? URL.createObjectURL(initialImage)
        : `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${initialImage}`;

      setImageFile(initialImage);
      setPreviewUrl(previewUrl);
    } else {
      // resetting states if data turn to null state
      setImageFile(null);
      setPreviewUrl("");
    }
  }, [initialImage]);

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      const [file] = acceptedFiles;
      setImageFile(file);

      setPreviewUrl(URL.createObjectURL(file));
    },
    [setImageFile]
  );

  // maybe need to do something about rejection
  const onDropRejected = (rejectedFiles) => {
    toasts.error("شما فقط می توانید از فرمت های PNG و JPG استفاده کنید");
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/jpeg", "image/png"],
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
    // noDrag: true,
  });

  return {
    getRootProps,
    getInputProps,
    imageFile,
    imagePreview: previewUrl,
  };
};
