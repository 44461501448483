import { useState } from "react";
import {
  useDeleteUserCountriesQuery,
  useDeleteUserLanguageQuery,
  useMyCountriesQuery,
  useMyLanguagesQuery,
  usePostUserCountriesQuery,
  usePostUserLanguageQuery,
} from "hooks/query/useSettingsQuery";
import {
  useCountriesQuery,
  useLanguagesQuery,
} from "hooks/query/useCastsQuery";
import SearchDropdown from "./searchDropdown/SearchDropdown";
import styles from "./Setting.module.scss";

export default function Setting() {
  // Langusages
  const { data: allLanguages } = useLanguagesQuery();
  const { data: allMyLanguages } = useMyLanguagesQuery();
  const { mutate: postLanguage } = usePostUserLanguageQuery();
  const { mutate: deleteLanguage } = useDeleteUserLanguageQuery();
  const [selectLanguage, setSelectLanguage] = useState([]);
  const [seeMoreLanguage, setSeeMoreLanguage] = useState(false);

  // Countries
  const { data: allCountries } = useCountriesQuery();
  const { data: allMyCountries } = useMyCountriesQuery();
  const { mutate: postCountry } = usePostUserCountriesQuery();
  const { mutate: deleteCountry } = useDeleteUserCountriesQuery();
  const [selectCountry, setSelectCountry] = useState([]);
  const [seeMoreCountry, setSeeMoreCountry] = useState(false);

  return (
    <section className={styles.wrapper}>
      <span>تنظیمات فیلم</span>
      <div className={styles.dropdowns}>
        <div>
          <SearchDropdown
            placeholder="زبان فیلم ها"
            searchPlaceholder="جستجوی زبان"
            stateProps={[selectLanguage, setSelectLanguage]}
            data={allLanguages}
            handlePost={postLanguage}
            type="languages"
          />
          <div
            className={`${styles.selectedBox} ${
              seeMoreLanguage ? styles.seeMore : ""
            }`}>
            {allMyLanguages?.length > 0 &&
              allMyLanguages.map((item) => (
                <div key={item.id} className={styles.selectedItem}>
                  <div>
                    {/* <span>فارسی</span> */}
                    <span>{item.name}</span>
                  </div>
                  <img
                    onClick={() => deleteLanguage(item.id)}
                    src={`${process.env.PUBLIC_URL}/images/icons/rounded-close.svg`}
                    alt="x"
                  />
                </div>
              ))}
          </div>
          <button
            onClick={() => setSeeMoreLanguage(!seeMoreLanguage)}
            className={`${styles.seeMoreBtn} btn`}>
            {seeMoreLanguage ? "مشاهده کمتر" : "مشاهده بیشتر"}
          </button>
        </div>
        <div>
          <SearchDropdown
            placeholder="کشور سازنده"
            searchPlaceholder="جستجوی کشور"
            stateProps={[selectCountry, setSelectCountry]}
            data={allCountries}
            handlePost={postCountry}
            type="countries"
          />
          <div
            className={`${styles.selectedBox} ${
              seeMoreCountry ? styles.seeMore : ""
            }`}>
            {allMyCountries?.length > 0 &&
              allMyCountries.map((item) => (
                <div key={item.id} className={styles.selectedItem}>
                  <div>
                    {/* <span>فارسی</span> */}
                    <span>{item.name}</span>
                  </div>
                  <img
                    onClick={() => deleteCountry(item.id)}
                    src={`${process.env.PUBLIC_URL}/images/icons/rounded-close.svg`}
                    alt="x"
                  />
                </div>
              ))}
          </div>
          <button
            onClick={() => setSeeMoreCountry(!seeMoreCountry)}
            className={`${styles.seeMoreBtn} btn`}>
            {seeMoreCountry ? "مشاهده کمتر" : "مشاهده بیشتر"}
          </button>
        </div>
      </div>
    </section>
  );
}
