import {
  useDeleteAlbumQuery,
  useDeleteTrackQuery,
} from "hooks/query/useMusicQuery";
import { useHistory } from "react-router";
import { musicTypes } from "../../../constants/musics";
import AudioPlayer from "../../newMusic/audioPlayer/AudioPlayer";
import styles from "./Row.module.scss";

function Row({ data, tab }) {
  const history = useHistory();
  const { mutate: deleteTrack } = useDeleteTrackQuery(data.name);
  const { mutate: deleteAlbum } = useDeleteAlbumQuery(data.name);

  const handleDelete = () => {
    if (tab === musicTypes.album) {
      deleteAlbum(data.id);
    } else {
      deleteTrack(data.id);
    }
  };

  const createGenreAndKindCol = () => {
    if (!data.kind) {
      return "-";
    } else if (data.kind && !data.genre) {
      return data.kind.name;
    } else {
      return `${data.kind.name} - ${data.genre.name}`;
    }
  };

  const trackUrl =
    data?.tracks?.length &&
    data?.tracks[0]?.url.length &&
    `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.tracks[0].url}`;

  return (
    <tr className={styles.row}>
      <td className={styles.firstCol} width={280}>
        <div className={styles.imageWrapper}>
          <img
            loading="lazy"
            src={
              data.cover
                ? `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`
                : `${process.env.PUBLIC_URL}/images/test/music-poster-test.png`
            }
            alt=""
          />
        </div>
        <div className={styles.title}>
          <span>{data.name}</span>
          <span>{data.name_in_persian ? data.name_in_persian : "-"}</span>
        </div>
      </td>
      <td>
        {data.singers.length
          ? data.singers.map((i) => i.name).join(" , ")
          : "-"}
      </td>
      <td
      // colSpan={tab === musicTypes.album ? 2 : 1}
      >
        {createGenreAndKindCol()}
      </td>
      {/* <td className={styles.statusCol}>
				<div
					className={`${styles.statusWrapper} ${
						isPublished ? styles.publish : styles.unPublished
					}`}
				>
					<img
						src={
							isPublished
								? `${process.env.PUBLIC_URL}/images/articles/tick-square.svg`
								: `${process.env.PUBLIC_URL}/images/articles/close-square.svg`
						}
						alt=""
					/>
					<span> {isPublished ? "منتشر شده" : "منتشر نشده"}</span>
				</div>
			</td> */}

      <td width="280px">
        <AudioPlayer size="sm" trackUrl={trackUrl} />
      </td>

      <td className={styles.buttonCol}>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.iconButton}
            onClick={() => history.push(`/musics/music/${data.id}`)}>
            <img
              src={`${process.env.PUBLIC_URL}/images/articles/edit.svg`}
              alt=""
            />
          </button>
          <button className={styles.iconButton} onClick={handleDelete}>
            <img
              src={`${process.env.PUBLIC_URL}/images/articles/trash.svg`}
              alt=""
            />
          </button>
        </div>
      </td>
    </tr>
  );
}

export default Row;
