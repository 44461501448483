import { useState } from "react";
import { Modal } from "react-bootstrap";
import { CardDivider } from "../components/Components";
import PackageModal from "../packageModal/PackageModal";
import styles from "./AddPackageCard.module.scss";

function AddPackageCard() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={styles.card} onClick={() => setShowModal(true)}>
        <CardDivider position="top" fill="#f5f5f5" />

        <div className={styles.context}>
          <img
            src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/add-square.svg`}
            alt=""
          />
          افزودن بسته جدید
        </div>

        <CardDivider position="bottom" fill="#f5f5f5" />
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={styles.modal}>
        <PackageModal onHide={() => setShowModal(false)} />
      </Modal>
    </>
  );
}

export default AddPackageCard;
