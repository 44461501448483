import { useRef, useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./VideoJs.css";
//Playlist Plugin
import "./plugins/playlist";
import "videojs-playlist-ui";
import "videojs-playlist-ui/dist/videojs-playlist-ui.vertical.css";
// Age Limiter Plugin
import "./plugins/ageLimiter";
// Airplay Plugin
import "./plugins/airPlay"; //
// AudioSwitch Plugin
import "./plugins/audioSwitch"; //
// ChromeCast Plugin
import chromecast from "@silvermine/videojs-chromecast"; //
// PreRollAd Plugin
import "./plugins/preRollAd"; //
// Sprite Thumbnails Plugin
import "./plugins/spriteThumbnails"; //
// Watermark plugin
import "./plugins/watermark"; //
// import './plugins/res-selector'
import "./plugins/res-selector/components/settings-menu-button";
import "./plugins/res-selector/components/adaptive-res-button";
import "./plugins/res-selector/components/settings-menu-button";

import "videojs-contrib-quality-levels";
import "videojs-vr";

import tools from "./tools";

let tsFiles = 0;

function VideoJs(props) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  let currentTextTracks = [];

  // Options for Videojs set by props given (Some props will be used later in code to init some plugins)
  const options = {
    autoplay: (props.autoplay && "muted") || false,
    controls: props.controls || false,
    responsive: props.responsive || false,
    fluid: props.fluid || false,
    fill: props.fill || false,
    controlBar: {
      pictureInPictureToggle: props.pictureInPicture || false,
    },
  };

  const disposeStuff = () => {
    const player = playerRef.current;
    player.controlBar.removeChild("SettingsMenuButton");
    if (player.lastWasAd) {
      player.lastWasAd = false;
    } else {
      if (player.playlist.currentItem() !== 0) {
        if (props.sources[player.playlist.currentItem() - 1].thumbnail)
          player.spriteThumbnails().disable();

        if (props.sources[player.playlist.currentItem() - 1].audioTracks.length)
          player.audioSwitch().dispose();

        currentTextTracks.forEach((currentTextTrack) => {
          player.removeRemoteTextTrack(currentTextTrack);
        });
        currentTextTracks = [];
      }
    }
  };

  const handlePlayingVideo = () => {
    const player = playerRef.current;
    disposeStuff();
    /**
     * In this part we add the features based on user selection to current video
     * audioTracks,subtitles,thumbnails
     */
    const resolutions = props.sources[player.playlist.currentItem()].video;
    if (resolutions.res && resolutions.res.length) {
      let reses = resolutions.res;
      reses.push({ ...resolutions.sources[0], default: true });
      player.controlBar.addChild("SettingsMenuButton", {
        sources: reses,
        playlistItem: player.playlist.currentItem(),
      });
    }

    const thumbnail = props.sources[player.playlist.currentItem()].thumbnail;
    if (thumbnail) player.spriteThumbnails(thumbnail);

    const audioTracks =
      props.sources[player.playlist.currentItem()].audioTracks;
    if (audioTracks.length) player.audioSwitch({ audioTracks: audioTracks });

    const subtitles = props.sources[player.playlist.currentItem()].subtitles;
    if (subtitles) {
      subtitles.forEach((subtitle) => {
        const textTrack = player.addRemoteTextTrack(subtitle, true);
        currentTextTracks.push(textTrack);
      });
    }
  };

  const handlePlayListChange = (videoList) => {
    const player = playerRef.current;
    const currentVideo = videoList[player.playlist.currentItem()];
    const currentItem = player.playlist.currentItem();
    if (currentVideo.ad && !currentVideo.adsDone) {
      disposeStuff();

      if (!player.paused()) player.pause();
      player.src(currentVideo.ad);
      player.one("ended", () => {
        currentVideo.adsDone = true;
        player.controls(true);
        player.lastWasAd = true;
        player.playlist.currentItem(currentItem);
        if (currentVideo.ad.skipable) {
          player.preRollAd().dispose();
        }
      });
      player.one("play", () => {
        player.controls(false);
        if (currentVideo.ad.skipable) {
          player.preRollAd({
            seconds: currentVideo.ad.skipTime,
            text: currentVideo.ad.skipText,
          });
        }
      });
    } else {
      handlePlayingVideo();
    }
  };

  const handleMultipleVideos = () => {
    const player = playerRef.current;
    const videoList = props.sources.map((source) => {
      return source.video;
    });
    videoList.forEach((video) => {
      video.sources.forEach((source) => {
        if (
          source.type === "application/x-mpegURL" &&
          source.decryptKey &&
          source.iv &&
          source.baseUrl
        ) {
          tools.readFileAsText(source.src).then((res) => {
            let raw = tools.decryptFile(res, source.decryptKey, source.iv);
            const lines = raw.split("\n");
            for (let line = 0; line < lines.length; line++) {
              if (lines[line].indexOf(".ts") !== -1) {
                lines[line] = lines[line].replace(/^/gm, source.baseUrl);
              }
            }
            raw = lines.join("\r\n");
            const b = new Blob([raw], {
              type: "application/x-mpegURL",
            });
            source.src = URL.createObjectURL(b);
          });
        }
      });
    });
    // Add the videos to playlist
    player.playlist(videoList);
    // automatically switches to next video when video is finished
    if (props.autoadvance) {
      player.main_sources = props.sources;
      player.playlist.autoadvance(0);
    }
    // this will occur when the video changes inside playlist
    player.on("playlistitem", handlePlayListChange.bind(this, videoList));
  };

  const handleSingleVideo = async () => {
    const player = playerRef.current;
    let source = props.sources;
    if (
      source.type === "application/x-mpegURL" &&
      source.decryptKey &&
      source.iv &&
      source.baseUrl
    ) {
      let res = await tools.readFileAsText(source.src);
      let raw = tools.decryptFile(res, source.decryptKey, source.iv);
      const lines = raw.split("\n");
      for (let line = 0; line < lines.length; line++) {
        if (lines[line].indexOf(".ts") !== -1) {
          lines[line] = lines[line].replace(/^/gm, source.baseUrl);
        }
      }
      raw = lines.join("\r\n");
      const b = new Blob([raw], {
        type: "application/x-mpegURL",
      });
      props.sources.src = URL.createObjectURL(b);
    }
    player.src(props.sources);
    if (props.thumbnail) player.spriteThumbnails(props.thumbnail);
    if (props.reses) {
      let reses = [...props.reses];
      reses.push(props.sources);
      player.controlBar.addChild("SettingsMenuButton", {
        sources: reses,
        playlistItem: null,
      });
    }
    if (props.adaptive) {
      const qualities = player.qualityLevels();
    }

    if (props.audioTracks && props.audioTracks.length)
      player.audioSwitch({ audioTracks: props.audioTracks });

    if (props.ad) {
      if (!player.paused()) player.pause();
      let currentSrc = player.src();
      player.src(props.ad.src);
      player.one("ended", () => {
        player.controls(true);
        player.src(currentSrc);
        if (props.ad.skipable) {
          player.preRollAd().dispose();
        }
        player.play();
      });
      player.one("play", () => {
        player.controls(false);
        if (props.ad.skipable) {
          player.preRollAd({
            seconds: props.ad.skipTime,
            text: props.ad.skipText,
          });
        }
      });
    }
  };

  const handleGlobalPlugins = () => {
    const player = playerRef.current;

    if (props.vr360) player.vr();

    if (props.watermark) player.watermark(props.watermark);

    if (props.airplay) player.airplayButton();

    if (props.chromecast) {
      chromecast(videojs);
      player.chromecast();
    }
    if (props.ageLimiter) player.ageLimiter({ limit: props.ageLimiter });
  };

  useEffect(async () => {
    if (!playerRef.current) {
      const videoElem = videoRef.current;

      if (!videoElem) return;
      // Init the player
      const player = (playerRef.current = videojs(
        videoElem,
        options,
        async () => {
          if (props.multiple) {
            handleMultipleVideos();
          } else {
            await handleSingleVideo();
          }

          handleGlobalPlugins();

          videoRef.current.addEventListener("enterpictureinpicture", () => {
            if (props.watermark) {
              player.watermark().toggleActive();
            }
            if (props.ageLimiter) {
              player.ageLimiter().toggleActive();
            }
          });
          videoRef.current.addEventListener("leavepictureinpicture", () => {
            if (props.watermark) {
              player.watermark().toggleActive();
            }
            if (props.ageLimiter) {
              player.ageLimiter().toggleActive();
            }
          });

          player.reportData = {
            agent: navigator.userAgent,
            time: 0,
            loadedTs: 0,
          };

          setInterval(() => {
            player.reportData.time = player.currentTime();

            const data = { ...player.reportData, tsFiles };
            // TODO: send data through api
            // console.log(data);
          }, 10000);
          videojs.Hls.xhr.beforeRequest = (options) => {
            if (options.uri.indexOf(".ts") !== -1) {
              tsFiles++;
            }
            return options;
          };
        }
      ));
    }
  }, [props]);
  return (
    <>
      <div data-vjs-player style={{ width: props.width, height: props.height }}>
        <video ref={videoRef} className="video-js vjs-big-play-centered" />
      </div>
    </>
  );
}

export default VideoJs;
