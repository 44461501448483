import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLiveResolution,
  changeLiveResolution,
  removeLiveResolution,
  updateLiveStreamField,
} from "../../../../../redux/actions/stream";
import { postCreateKeyStream } from "../../../../../services/stream/stream";
import { Modal } from "react-bootstrap";
import { Input } from "../../../../common/input/Input";
import { copyToClipboard } from "../../../../../util/copyToClipboard";
import {
  fpsConstants,
  resolutionsConstants,
  watermarkPositionConstants,
} from "constants/stream";
import { postFile } from "services/files/files";
import { useLiveConfigsQuery } from "hooks/query/useStreamQuery";
import SwitchSmall from "../../../../common/switchSmall/SwitchSmall";
import VideoJs from "../../../../common/videojs/VideoJs";
import toasts from "../../../../common/toasts/toasts";
import DetailModal from "../../detailModal/DetailModal";
import SingleImageUploader from "components/common/singleImageUploader/SingleImageUploader";
// import MultiTag from "components/common/multiTag/MultiTag";
import DropDown from "components/common/dropDown/DropDown";
import Resolutions from "./resolutions/Resolutions";
import styles from "./MainSetting.module.scss";

function MainSetting({ data, id }) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(
        updateLiveStreamField({
          title: data.title,
          cover_id: data.cover_id,
          stream_key: data.stream_key,
          hls_url: data.hls_url,
          server_url: data.server_url,
          active: data.active,
        })
      );
    }
  }, [data, dispatch]);
  const liveStreaming = useSelector((state) => state.setLiveStreamingReducer);

  const createAttachment = async () => {
    if (liveStreaming.watermark_id?.path) {
      try {
        toasts.warn("درحال آپلود واتر مارک پخش زنده");
        const fileResponse = await postFile(
          liveStreaming.watermark_id,
          undefined,
          `${window.__RUNTIME_CONFIG__.REACT_APP_LIVE_API}/files/upload`
        );
        return fileResponse;
      } catch (error) {
        console.log(error);
        toasts.error("ثبت واتر مارک پخش زنده با مشکل مواجه شد");
      }
    }
  };

  const handleReceiveStreamKey = async () => {
    if (
      liveStreaming.resolutions.length &&
      !!liveStreaming.resolutions[0]?.resolution?.name
    ) {
      for (const item of liveStreaming.resolutions) {
        if (item.resolution.name) {
          if (
            liveStreaming.resolutions.filter(
              (f) => f.resolution.name === item.resolution.name
            )?.length > 1
          ) {
            toasts.error("کیفیت پخش های تکراری قابل قبول نمی باشد!");
            return false;
          } else {
            if (
              +item.audio_bitrate >= item.audio.min &&
              +item.audio_bitrate <= item.audio.max
            ) {
              if (
                +item.video_bitrate >= item.video.min &&
                +item.video_bitrate <= item.video.max
              ) {
              } else {
                toasts.error(
                  `Video bitrate کیفیت ${item.resolution.name} باید در محدوده ${item.video.min} و ${item.video.max} باشد!`
                );
                return false;
              }
            } else {
              toasts.error(
                `Audio bitrate کیفیت ${item.resolution.name} باید در محدوده ${item.audio.min} و ${item.audio.max} باشد!`
              );
              return false;
            }
          }
        } else {
          toasts.error("کیفیت پخش فاقد مقدار است!");
          return false;
        }
      }
      try {
        toasts.warn("در حال دریافت stream key...");
        let watermarkId = "";
        if (liveStreaming.watermark_id.path?.length) {
          const response = await createAttachment();
          if (response.status === 200) {
            watermarkId = response.data.data;
          }
        }
        const { data, status } = await postCreateKeyStream({
          resolution_configs: liveStreaming.resolutions.map((f) => {
            return {
              audio_bitrate: +f.audio_bitrate,
              resolution: f.resolution.name,
              video_bitrate: +f.video_bitrate,
            };
          }),
          ...(watermarkId.length && { watermark_id: watermarkId }),
          watermark_position: liveStreaming.watermark_position.id,
          fps: liveStreaming.fps?.id ? +liveStreaming.fps.id : 30,
        });

        if (status === 200) {
          const { hls_url, server_url, stream_key } = data.data;

          dispatch({
            type: "SET_STREAM_KEYS",
            payload: { hls_url, server_url, stream_key },
          });
          toasts.success("stream key با موفقیت دریافت شد.");
          setShowModal(true);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      toasts.error("لطفا کیفیت پخش را تعیین کنید!");
    }
  };

  const handleChangeInput = (e) => {
    dispatch(updateLiveStreamField({ [e.target.name]: e.target.value }));
  };
  const handleChangeImage = (file, name) => {
    dispatch(
      updateLiveStreamField({
        [name]: file,
      })
    );
  };
  const handleChangeActive = (bool) => {
    dispatch(updateLiveStreamField({ active: bool }));
  };
  const handleChangeFields = (options, name) => {
    dispatch(
      updateLiveStreamField({
        [name]: options,
      })
    );
  };

  // Resolutions
  const handleRemoveResolution = (data) => {
    if (data.id) {
      //   handleRemove(data);
      return false;
    } else {
      if (liveStreaming.resolutions.length === 1) {
        return false;
      } else {
        dispatch(removeLiveResolution(data));
      }
    }
  };
  const { data: liveConfigData } = useLiveConfigsQuery();

  return (
    <>
      <section className={styles.wrapper}>
        <div className={styles.details}>
          <div className={styles.inputWrapper}>
            <Input
              type="text"
              label=" عنوان پخش زنده"
              placeholder="جهت دریافت Stream Key، عنوان پخش زنده را وارد نمایید"
              name="title"
              value={liveStreaming.title}
              onChange={handleChangeInput}
              require
            />
            <button
              className={styles.streamKeyBtn}
              onClick={handleReceiveStreamKey}
              disabled={liveStreaming.title?.length === 0 || id}>
              دریافت stream key
            </button>
          </div>
          {!id && (
            <>
              {liveStreaming.resolutions?.map((item, index) => (
                <Resolutions
                  key={item.uniqueId}
                  data={item}
                  liveConfigData={liveConfigData}
                  resolutionsConstants={resolutionsConstants}
                  handleChangeResolution={(e) =>
                    dispatch(
                      changeLiveResolution({
                        id: index,
                        value: e,
                      })
                    )
                  }
                  handleAddResolution={() => dispatch(addLiveResolution())}
                  handleRemoveResolution={() => handleRemoveResolution(item)}
                  length={liveStreaming.resolutions.length}
                  index={index}
                  maxLength={liveStreaming.resolutions.length === 5}
                />
              ))}
              <DropDown
                placeholder="fps (فریم بر ثانیه)"
                options={fpsConstants}
                value={liveStreaming.fps}
                getOptionLabel={(option) => option["name"]}
                getOptionValue={(option) => option["id"]}
                onChange={(options) => handleChangeFields(options, "fps")}
                name="fps"
              />
              <DropDown
                placeholder="مکان واتر مارک"
                options={watermarkPositionConstants}
                value={liveStreaming.watermark_position}
                getOptionLabel={(option) => option["name"]}
                getOptionValue={(option) => option["id"]}
                onChange={(options) =>
                  handleChangeFields(options, "watermark_position")
                }
                name="watermark_position"
              />
              <div className={styles.fileUploader}>
                <p className={styles.fileUploaderTitle}>افزودن واتر مارک</p>
                <SingleImageUploader
                  placeholder={liveStreaming.watermark_id?.name}
                  dropzonePlaceholder="تصویر واتر مارک پخش زنده را بکشید و اینجا رها کنید"
                  imageState={[
                    liveStreaming.watermark_id,
                    (file) => handleChangeImage(file, "watermark_id"),
                  ]}
                />
              </div>
            </>
          )}

          {id && (
            <div className={styles.details}>
              <div className={styles.sharing}>
                <Input
                  type="text"
                  label="Stream Key"
                  value={liveStreaming.stream_key}
                  isReadonly
                />
                <div className={styles.absoluteIcons}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/live/clipboard.svg`}
                    alt=""
                    onClick={() =>
                      copyToClipboard(
                        liveStreaming.stream_key,
                        "کد شما با موفقیت کپی شد"
                      )
                    }
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/images/live/share.svg`}
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.sharing}>
                <Input
                  type="text"
                  label="Stream URL"
                  value={liveStreaming.hls_url}
                  isReadonly
                />
                <div className={styles.absoluteIcons}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/live/clipboard.svg`}
                    alt=""
                    onClick={() =>
                      copyToClipboard(
                        liveStreaming.hls_url,
                        "کد شما با موفقیت کپی شد"
                      )
                    }
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/images/live/share.svg`}
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.sharing}>
                <Input
                  type="text"
                  label="Server URL"
                  value={liveStreaming.server_url}
                  isReadonly
                />
                <div className={styles.absoluteIcons}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/live/clipboard.svg`}
                    alt=""
                    onClick={() =>
                      copyToClipboard(
                        liveStreaming.server_url,
                        "کد شما با موفقیت کپی شد"
                      )
                    }
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/images/live/share.svg`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}

          <div className={styles.fileUploader}>
            <p className={styles.fileUploaderTitle}>کاور پخش زنده</p>
            <SingleImageUploader
              placeholder={liveStreaming.cover_id?.name}
              dropzonePlaceholder="تصویر کاور پخش زنده را بکشید و اینجا رها کنید"
              imageState={[
                liveStreaming.cover_id,
                (file) => handleChangeImage(file, "cover_id"),
              ]}
              bigSize
            />
          </div>

          <div className={styles.switchWrapper}>
            <SwitchSmall
              label="فعالسازی پخش زنده"
              switchState={[liveStreaming.active, handleChangeActive]}
            />
          </div>
        </div>
        <div className={styles.live}>
          <div className={styles.liveFrame}>
            {id && data?.hls_url ? (
              <VideoJs
                adaptive
                sources={{
                  src: `${data.hls_url}`,
                  type: "application/x-mpegURL",
                }}
                controls
                fluid
              />
            ) : (
              <img
                className={styles.liveIcon}
                src={`${process.env.PUBLIC_URL}/images/live/live-orange.svg`}
                alt="live"
              />
            )}
          </div>
        </div>
      </section>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <DetailModal data={liveStreaming} onHide={() => setShowModal(false)} />
      </Modal>
    </>
  );
}

export default MainSetting;
