import Select, { components } from "react-select";
import { projectCurrencyStyle } from "../../../util/reactSelect";
import { Form } from "react-bootstrap";
import styles from "./MultiTag.module.scss";
import { useState } from "react";

//* using this component for Multi selecting in placeholder

function MultiTag({
  label,
  placeholder,
  options,
  selectedOptions,
  onChange,
  isRequired,
  hasMovingLabel,
  whiteTheme = false,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);

  const Option = ({ children, ...rest }) => {
    return (
      <components.Option className={styles.option} {...rest}>
        <span>{children}</span>
        {/* {selectedOption.length >= 1 && (
          <div className={styles.crown}>
            <img
              src={`${process.env.PUBLIC_URL}/images/projects/crown.svg`}
              alt=""
            />
          </div>
        )} */}
      </components.Option>
    );
  };

  const MultiValueRemove = (props) => {
    if (props.data.isDeleteAble === undefined) {
      return <components.MultiValueRemove {...props} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <Form.Group
        className={`${styles.group} ${
          hasMovingLabel ? styles.groupMoving : ""
        }`}>
        <Form.Label
          className={`${hasMovingLabel ? whiteTheme ? styles.whiteMovingLabel : styles.movingLabel : styles.label} ${
            hasMovingLabel &&
            (selectedOptions?.length || isFocused ? styles.focused : "")
          }`}>
          {label} {isRequired && <span className={styles.asterisk}>*</span>}
        </Form.Label>
        <Select
          options={options}
          value={selectedOptions}
          styles={projectCurrencyStyle}
          components={{ Option, MultiValueRemove }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          onChange={onChange}
          placeholder={hasMovingLabel ? "" : placeholder}
          isClearable={false}
          backspaceRemovesValue={false}
          classNamePrefix="select"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          isFocused={isFocused}
          // name="category"
          isMulti
          // menuIsOpen
          {...props}
        />
      </Form.Group>
    </>
  );
}

export default MultiTag;
