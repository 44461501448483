import { useState } from "react";
import { useCommentsQuery } from "hooks/query/useCommentQuery";
import { Spinner } from "react-bootstrap";
import EmptyState from "../common/emptyState/EmptyState";
import Comment from "./comment/Comment";
import Paginate from "components/common/paginate/Paginate";
import styles from "./CommentManagement.module.scss";

function CommentManagement() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = useCommentsQuery(currentPage);

  return (
    <>
      <div className={styles.wrapper}>
        {isPreviousData ? (
          <div className="spinnerCenter">
            <Spinner animation="border" as="i" />
          </div>
        ) : data?.results?.length ? (
          <div className={styles.bgWrapper}>
            {data?.results.map((comment) => (
              <Comment key={comment.id} comment={comment} />
            ))}
          </div>
        ) : (
          <EmptyState>
            <p className={styles.emptyState}>کامنتی وجود ندارد.</p>
          </EmptyState>
        )}
      </div>
      <div className="paginationWrapper">
        {data?.results?.length > 0 && (
          <Paginate
            type="commentManagement"
            paginateState={[currentPage, setCurrentPage]}
            total={data?.pagination.count}
          />
        )}
      </div>
    </>
  );
}

export default CommentManagement;
