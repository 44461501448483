import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { postFile } from "../../../../services/files/files";
import {
  deleteSeasonFromSeeries,
  patchAppendEpisodeToSeasonOfSeries,
  patchAppendSeasonToSeries,
  putUpdateEpisodeOfSeason,
  putUpdateSeason,
} from "../../../../services/series/series";
import { usePutSeriesQuery } from "hooks/query/useSerieQuery";
import { createAppend } from "../../helpers/createAppend";
import {
  handlePatchSeriesDownloadLink,
  handlePatchSubtitles,
  useUploadingVideos,
  useVideoUrlForTranscode,
} from "../../helpers/useUploadingVideos";
import { resetSeriesSubtitleToRemoved } from "redux/actions/series";
import { createKeywords } from "services/movies/moviesServices";
import toasts from "../../../common/toasts/toasts";
import styles from "./TabHeader.module.scss";

// UPDATE Series Component

export default function TabHeader() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { mutateAsync: putUpdateOneSeries } = usePutSeriesQuery();
  const createSeries = useSelector((state) => state.createSeriesReducer);
  const { pendRemovableSubtitle } = useSelector(
    (state) => state.seriesListReducer
  );
  const seasonsEpisodes = useSelector(
    (state) => state.addNewSeasonEpisodeReducer
  );
  const { uploadingVideos } = useUploadingVideos();
  const { transcodingVideos } = useVideoUrlForTranscode();

  // KEYWORDS
  const keywordsWithId = createSeries.keywords?.filter(
    (i) => i.hasOwnProperty("label") && i.hasOwnProperty("id")
  );
  const keywordsWithOutId = createSeries.keywords?.filter(
    (i) => i.hasOwnProperty("label") && !i.hasOwnProperty("id")
  );
  let keywordsPayload = [...keywordsWithId];

  const handlePostKeywords = async () => {
    try {
      for (const item of keywordsWithOutId) {
        const { data, status } = await createKeywords(item);
        if (status === 200) {
          keywordsPayload.push({
            id: data.data.id,
            label: data.data.label,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Update & Create Series
  const handleFormValidation = () => {
    let isValid = true;

    const submitCondition =
      createSeries.title.length && createSeries.summary.length;

    if (!submitCondition) {
      toasts.error("فیلد های الزامی را پر کنید.");
      isValid = false;
    }

    return isValid;
  };
  const handleCreateSeries = async (moviePayload) => {
    await handlePostKeywords();
    const { status } = await putUpdateOneSeries(moviePayload);

    if (status === 200) {
      handlePatchSeasons(id);
      handleDeleteSeason();
      createAppend("languages", id, createSeries.languages);
      createAppend("countries", id, createSeries.countries);
      createAppend("genres", id, createSeries.genres);
      createAppend("stars", id, createSeries.stars);
      createAppend("directors", id, createSeries.directors);
      createAppend("writers", id, createSeries.writers);
      createAppend("keywords", id, keywordsPayload);
    }
  };
  const handleUpdateSeries = async () => {
    // Validation
    if (!handleFormValidation()) return;

    try {
      let coverFileId = "";
      let bannerFileId = "";

      if (createSeries.cover?.path) {
        toasts.warn("در حال آپلود کاور سریال");
        const { data, status } = await postFile(createSeries.cover);
        if (status === 200) {
          coverFileId = data.data;
        }
      }
      if (createSeries.banner?.path) {
        toasts.warn("در حال آپلود پوستر سریال");
        const { data, status } = await postFile(createSeries.banner);
        if (status === 200) {
          bannerFileId = data.data;
        }
      }

      const payload = {
        id: parseInt(id),
        title: createSeries.title,
        title_in_persian: createSeries.title_in_persian,
        imdb_id: createSeries.imdb_id,
        rate_value: createSeries.rate_value,
        rate_total_votes: createSeries.rate_total_votes,
        meta_rate_value: +createSeries.meta_rate_value,
        summary: createSeries.summary,
        distributor_id: createSeries.distributor?.id,
        status: createSeries.status,
        content_rating: createSeries.content_rating.title,
        ...(createSeries.start_date.length && {
          start_date: createSeries.start_date,
        }),
        ...(createSeries.status !== "active" && {
          end_date: createSeries.end_date,
        }),
        ...(coverFileId.length && {
          cover: coverFileId,
        }),
        ...(bannerFileId.length && {
          banner: bannerFileId,
        }),
      };

      await handleCreateSeries(payload);
    } catch (error) {
      toasts.error("مشکلی پیش آمده است.");
      console.log(error);
    }
  };

  // Patch and Post/Put data to Series
  const handleDeleteSeason = async () => {
    try {
      if (pendRemovableSubtitle.length) {
        for (const item of pendRemovableSubtitle) {
          const { status } = await deleteSeasonFromSeeries({
            season_id: +item.seasonID,
            series_id: +id,
          });
          if (status === 200) {
            toasts.success(`فصل ${item.id} با موفقیت حذف شد.`);
          }
        }
      }
      dispatch(resetSeriesSubtitleToRemoved());
    } catch (error) {
      console.log(error);
    }
  };
  const handlePatchSeasons = async (seriesID) => {
    seasonsEpisodes.forEach(async (item) => {
      try {
        //! CREATE new Season
        if (Number.isFinite(item.title)) {
          const seasonResponse = await patchAppendSeasonToSeries({
            series_id: parseInt(seriesID),
            title: String(item.title),
          });
          if (seasonResponse.status === 200) {
            toasts.success(`فصل ${item.title} سریال اضافه شد.`);
            for (const e of item.episodes) {
              try {
                // uploading video
                if (e.file) {
                  await uploadingVideos(e, seasonResponse.data.data, seasonResponse.data.data.id);
                } else if (e.linkURL.length) {
                  await transcodingVideos(e, seasonResponse.data.data, seasonResponse.data.data.id);
                }
                // upload episode Without video
                else {
                  if (e.title.length) {
                    const { status, data } =
                      await patchAppendEpisodeToSeasonOfSeries({
                        season_id: parseInt(seasonResponse.data.data.id),
                        title: String(e.title),
                        imdb_id: String(e.title),
                        // content_url: "",
                      });
                    if (status === 200) {
                      toasts.success(`قسمت ${e.title} سریال اضافه شد.`);
                      // uploadng subtitles
                      if (e.subtitles) {
                        e.subtitles.forEach(async (f) => {
                          await handlePatchSubtitles(f, data.data.id);
                        });
                      }
                      if (e.links) {
                        e.links.forEach(async (f) => {
                          if (!f.id) {
                            await handlePatchSeriesDownloadLink(
                              f,
                              data.data.id,
                              data.data.season_id
                            );
                          }
                        });
                      }
                    }
                  }
                }
              } catch (error) {
                console.error(error);
                toasts.error("مشکلی در افزودن قسمت های سریال به وجود آمده");
              }
            }
          }
        }

        //! UPDATE the Season
        else {
          const seasonResponse = await putUpdateSeason({
            id: parseInt(seriesID),
            title: String(item.title),
          });

          if (seasonResponse.status === 200) {
            // toasts.success(`فصل ${item.title} سریال آپدیت شد.`);
            for (const e of item.episodes) {
              try {
                //! IF we had the episodes
                if (e.id) {
                  // uploading video
                  if (e.file) {
                    await uploadingVideos(e, item, item.seasonID);
                  } else if (e.linkURL?.length) {
                    await transcodingVideos(
                      e,
                      seasonResponse.data.data,
                      item.seasonID
                    );
                  }
                  // upload episode Without video
                  else {
                    if (e.title.length) {
                      const { status } = await putUpdateEpisodeOfSeason({
                        id: parseInt(e.id),
                        title: String(e.title),
                        imdb_id: String(e.title),
                        // content_url: "",
                      });
                      if (status === 200) {
                        // toasts.success(`قسمت ${e.title} سریال آپدیت شد.`);
                        // uploadng subtitles
                        if (e.subtitles) {
                          e.subtitles.forEach(async (f) => {
                            await handlePatchSubtitles(f, e.id);
                          });
                        }
                        if (e.links) {
                          e.links.forEach(async (f) => {
                            // await handlePatchSubtitles(f, e.id);
                            if (!f.id) {
                              await handlePatchSeriesDownloadLink(
                                f,
                                e.id,
                                item.seasonID
                              );
                            }
                          });
                        }
                      }
                    }
                  }
                }
                //! IF we want to create new episodes
                else {
                  // uploading video
                  if (e.file) {
                    await uploadingVideos(e, item, item.seasonID);
                  } else if (e.linkURL?.length) {
                    await transcodingVideos(
                      e,
                      seasonResponse.data.data,
                      item.seasonID
                    );
                  }
                  // upload episode Without video
                  else {
                    if (e.title.length) {
                      const { status, data } =
                        await patchAppendEpisodeToSeasonOfSeries({
                          season_id: parseInt(item.seasonID),
                          title: String(e.title),
                          imdb_id: String(e.title),
                          // content_url: "",
                        });
                      if (status === 200) {
                        toasts.success(`قسمت ${e.title} سریال اضافه شد.`);
                        // uploadng subtitles
                        if (e.subtitles) {
                          e.subtitles.forEach(async (f) => {
                            if (!f.url) {
                              await handlePatchSubtitles(f, data.data.id);
                            }
                          });
                        }
                        if (e.links) {
                          e.links.forEach(async (f) => {
                            if (!f.id) {
                              await handlePatchSeriesDownloadLink(
                                f,
                                data.data.id,
                                data.data.season_id
                              );
                            }
                          });
                        }
                      }
                    }
                  }
                }
              } catch (error) {
                console.error(error);
                toasts.error("مشکلی در آپدیت کردن قسمت های سریال به وجود آمده");
              }
            }
          }
        }
      } catch (err) {
        console.error(err);
        toasts.error("مشکلی به وجود آمده");
      }
    });
  };

  return (
    <div className={styles.container}>
      <Link to="/series" className={`btn ${styles.btn} ${styles.removeDeal}`}>
        انصراف
      </Link>
      <div
        onClick={handleUpdateSeries}
        className={`btn ${styles.btn} ${styles.addDeal}`}>
        ثبت تغییرات
      </div>
    </div>
  );
}
