import React, { useCallback } from "react";
import AudioPreview from "../../audioPreview/AudioPreview";
import AudioDropzone from "../../uploaders/audioDropzone/AudioDropzone";

function DropzoneCondition({ audioFile, onChangeAudioFile, isReadonly }) {
	const handleChangeAudio = useCallback(
		(audioFile) => {
			onChangeAudioFile(audioFile);
		},
		[onChangeAudioFile]
	);

	const handleDeleteAudio = useCallback(() => {
		onChangeAudioFile(null);
	}, [onChangeAudioFile]);

	if (!!audioFile) {
		return (
			<AudioPreview
				data={audioFile}
				audioChangeCb={handleChangeAudio}
				audioDeleteCb={handleDeleteAudio}
				isReadonly={isReadonly}
			/>
		);
	} else {
		return (
			<AudioDropzone
				audioChangeCb={handleChangeAudio}
				isReadonly={isReadonly}
			/>
		);
	}
}

export default DropzoneCondition;
