import styles from "./Progress.module.scss";

// Change the component name to progressBar
export default function Progress({ progress }) {
	return (
		<div className={styles.progressBar}>
			<div
				style={{ width: `${progress}%` }}
				className={`${styles.ProgressBarInner} ${
					progress === 100
						? styles.ProgressBarInnerGreen
						: styles.ProgressBarInnerRed
				} ${progress === 100 ? "" : "progress-bar-animated"}`}
			></div>
		</div>
	);
}
