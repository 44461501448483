import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { managerUploadTypes } from "../../../constants/uploadManager";
import Scrollbars from "rc-scrollbars";
import Progress from "../progress/Progress";
import styles from "./TranscodePreview.module.scss";

function TranscodePreview() {
  const transcodeManager = useSelector(
    (state) => state.transcodeManagerReducer
  );

  const checkIcon = useCallback((type) => {
    switch (type) {
      case managerUploadTypes.MOVIE || managerUploadTypes.SERIES:
        return "/images/menu/uploader.svg";
      case managerUploadTypes.MUSIC:
        return "/images/icons/music-download.svg";
      case "success":
        return "/images/icons/tick-square-green.svg";
      default:
        return "/images/menu/uploader.svg";
    }
  }, []);

  const calcTotalProgress = (array) => {
    const sumProgresses = array.reduce((acc, curr) => {
      return (acc += Math.round(curr.progress));
    }, 0);

    return sumProgresses / array.length;
  };

  return (
    // Work on scrollbar
    <Scrollbars style={{ height: "calc(100vh - 62px)" }} autoHide>
      {transcodeManager.map((file) => (
        <div key={file.id || file} className={styles.transcodeWrapper}>
          <div className={styles.detailContainer}>
            <div className={styles.imageContainer}>
              <img
                src={`${process.env.PUBLIC_URL}${checkIcon(
                  calcTotalProgress(file.resolutions) === 100
                    ? "success"
                    : file.type
                )}`}
                alt="transcoding"
              />
            </div>
            <div className={styles.detailWrapper}>
              <p>{file.title}</p>
              <p>
                {file.event === "download"
                  ? "در حال دانلود فایل ..."
                  : "در حال تغییر فرمت به ..."}
              </p>
            </div>
          </div>

          <div className={styles.progressWrapper}>
            {file.event === "download" ? (
              <div className={styles.progress}>
                <div className={styles.progressDetail}>
                  {/* <span>کیفیت: - </span> */}
                  <span>%{Math.round(file.downloadInfo.progress)}</span>
                </div>
                <Progress progress={Math.round(file.downloadInfo.progress)} />
              </div>
            ) : (
              file.resolutions.map((item,index) => (
                <div key={`${item.resolution}-${index}`} className={styles.progress}>
                  <div className={styles.progressDetail}>
                    <span>کیفیت: {item.resolution} </span>
                    <span>%{Math.round(item.progress)}</span>
                  </div>
                  <Progress progress={Math.round(item.progress)} />
                </div>
              ))
            )}
          </div>
        </div>
      ))}
    </Scrollbars>
  );
}

export default TranscodePreview;
