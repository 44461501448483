import { nanoid } from "nanoid";
// helper function for remove item
const removeFromArrayWithID = (array, id) =>
  array.filter((item) => item.id !== id);

const seriesListState = {
  selectedSeries: [],
  pendRemovableSubtitle: [],
  isAllSelected: false,
};
export const seriesListReducer = (state = seriesListState, action) => {
  switch (action.type) {
    case "ADD_SELECTED_SERIES_LIST":
      return {
        ...state,
        selectedSeries: [...state.selectedSeries, action.payload],
      };
    case "REMOVE_SELECTED_SERIES_LIST":
      return {
        ...state,
        selectedSeries: removeFromArrayWithID(
          state.selectedSeries,
          action.payload
        ),
        isAllSelected:
          removeFromArrayWithID(state.selectedSeries, action.payload) === [],
      };
    case "UPDATE_SELECT_ALL_SERIES":
      return {
        ...state,
        selectedSeries: action.payload.allChecked
          ? action.payload.seriesList
          : [],
        isAllSelected: action.payload.allChecked,
      };
    case "RESET_SERIES_LIST":
      return seriesListState;

    case "PEND_SERIES_SUBTITLE_TO_REMOVED":
      return {
        ...state,
        pendRemovableSubtitle: [...state.pendRemovableSubtitle, action.payload],
      };
    case "RESET_PEND_SERIES_SUBTITLE_TO_REMOVED":
      return {
        ...state,
        pendRemovableSubtitle: [],
      };
    default:
      return state;
  }
};

// Create and GET Series reducer
const initState = {
  imdb_id: "",
  id: null,
  additional_id: "",
  cover: "",
  banner: "",
  title: "",
  title_in_persian: "",
  summary: "",
  stars: [],
  directors: [],
  writers: [],
  genres: [],
  countries: [],
  languages: [],
  keywords: [],
  distributor: "",
  status: "active",
  start_date: "",
  end_date: "",
  rate_value: "",
  rate_total_votes: "",
  meta_rate_value: "",
  metacritic: "",
  rotten: "",
  budget: "",
  revenue: "",
  content_rating: "",
  awards: [],
  movie_url: "",
  seasons: [],
  category_id: null,
};
export const createSeriesReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_SERIES_INFO":
      return {
        imdb_id: action.payload.imdb_id,
        id: action.payload.id,
        additional_id: action.payload.additional_id,
        cover: action.payload.cover,
        banner: action.payload.banner,
        title: action.payload.title,
        title_in_persian: action.payload.title_in_persian,
        rate_value: action.payload.rate_value,
        rate_total_votes: action.payload.rate_total_votes,
        meta_rate_value: action.payload.meta_rate_value,
        summary: action.payload.summary,
        stars: action.payload.stars,
        directors: action.payload.directors,
        keywords: action.payload.keywords,
        writers: action.payload.writers,
        genres: action.payload.genres,
        countries: action.payload.countries,
        languages: action.payload.languages,
        distributor: action.payload.distributor,
        status: action.payload.status,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        movie_url: action.payload.movie_url,
        seasons: action.payload.seasons,
        category_id: action.payload.category_id,
        content_rating: {
          id: action.payload.content_rating,
          title: action.payload.content_rating,
        },
      };
    case "UPDATE_SERIES_INFO":
      return { ...state, ...action.payload };
    case "RESET_SERIES_INFO":
      return initState;
    case "ADD_SERIES_AWARD":
      return {
        ...state,
        awards: [...state.awards, action.payload],
      };
    case "REMOVE_SERIES_AWARD":
      return {
        ...state,
        awards: state.awards.filter((_, index) => index !== action.payload),
      };
    case "REMOVE_SERIES_KEYWORDS":
      return {
        ...state,
        keywords: removeFromArrayWithID(state.keywords, action.payload),
      };
    default:
      return state;
  }
};

// ADD Episodes and Seasons
const fakeId = nanoid();
const states = [
  {
    id: fakeId,
    title: 1,
    episodes: [
      {
        imdb_id: fakeId,
        title: "",
        server: "",
        content_url: "",
        file: "",
        linkURL: "",
        subtitles: [],
        links: [],
      },
    ],
  },
];

export const addNewSeasonEpisodeReducer = (state = states, action) => {
  switch (action.type) {
    case "GET_SEASONS":
      return action.payload?.length
        ? action.payload?.map((item) => ({
            seasonID: item.id,
            id: +item.title,
            title: item.title,
            episodes: item.episodes,
          }))
        : null;

    case "ADD_NEW_SEASON":
      return [
        ...state,
        {
          id: action.payload.id,
          title: action.payload.title,
          episodes: [
            {
              imdb_id: action.payload.id,
              title: "",
              content_url: "",
              file: "",
              linkURL: "",
              subtitles: [],
              links: [],
            },
          ],
        },
      ];
    case "UPDATE_SEASON":
      return state.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            title: action.payload.title,
          };
        } else {
          return item;
        }
      });

    case "REMOVE_SEASON":
      return removeFromArrayWithID(state, action.payload.id);

    case "ADD_NEW_EPISODE":
      return state.map((item) => {
        if (item.title === action.payload.seasonId) {
          return {
            ...item,
            episodes: [
              ...item.episodes,
              {
                imdb_id: action.payload.id,
                title: "",
                content_url: "",
                file: "",
                linkURL: "",
                subtitles: [],
                links: [],
              },
            ],
          };
        } else {
          return item;
        }
      });

    case "UPDATE_EPISODE":
      return state?.length ? state.map((item) => {
        if (item.title === action.payload.seasonId) {
          const final = item.episodes.map((e) => {
            if (e.imdb_id === action.payload.episodeId) {
              return {
                ...e,
                ...action.payload,
              };
            } else {
              return e;
            }
          });
          return {
            ...item,
            episodes: final,
          };
        } else {
          return item;
        }
      }) : states;

    case "UPDATE_EPISODE_SUBTITLE":
      return state?.length ? state.map((item) => {
        if (item.title === action.payload.seasonId) {
          const final = item.episodes.map((e) => {
            if (e.imdb_id === action.payload.episodeId) {
              const sub = e.subtitles.map((s, index) => {
                if (index === action.payload.index) {
                  return {
                    ...s,
                    language_id: action.payload.language_id,
                  };
                } else {
                  return s;
                }
              });
              return {
                ...e,
                subtitles: sub,
              };
            } else {
              return e;
            }
          });
          return {
            ...item,
            episodes: final,
          };
        } else {
          return item;
        }
      }) : states;

    case "RESET_SEASONS_EPISODE":
      return states;

    case "ADD_EPISODE_DOWNLOADLINK":
      return state.map((item) => {
        if (item.title === action.payload.seasonId) {
          const final = item.episodes.map((e) => {
            if (e.imdb_id === action.payload.episodeId) {
              return {
                ...e,
                links: [...e.links, action.payload.data],
              };
            } else {
              return e;
            }
          });
          return {
            ...item,
            episodes: final,
          };
        } else {
          return item;
        }
      });

    case "DELETE_EPISODE_DOWNLOADLINK_BY_INDEX":
      return state.map((item) => {
        if (item.title === action.payload.seasonId) {
          const final = item.episodes.map((e) => {
            if (e.imdb_id === action.payload.episodeId) {
              return {
                ...e,
                links: e.links.filter(
                  (_, index) => index !== action.payload.index
                ),
              };
            } else {
              return e;
            }
          });
          return {
            ...item,
            episodes: final,
          };
        } else {
          return item;
        }
      });

    default:
      return state;
  }
};
