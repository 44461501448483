import { useMutation, useQueryClient } from "react-query";
import { deleteTranscodedFile } from "services/files/files";
import { deleteSubtitle } from "services/movies/moviesServices";
import toasts from "components/common/toasts/toasts";

export const useDeleteTranscodedFileQuery = (type) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTranscodedFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(type);
      toasts.success("فایل با موفقیت حذف شد.");
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
export const useDeleteSubtitleQuery = (type) => {
  const queryClient = useQueryClient();
  return useMutation(deleteSubtitle, {
    onSuccess: () => {
      queryClient.invalidateQueries(type);
      toasts.success("زیرنویس با موفقیت حذف شد.");
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
