import http from "../httpService";
import axios from "axios";

// maybe move it to another file like files
export const uploadVideo = (file, progressCallback, serverUrl, serverId) => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("transcode_server", serverUrl);
  formData.append(
    "streamer_server",
    window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL
  );
  formData.append("transcode_server_id", serverId);

  return axios({
    method: "POST",
    url: `${window.__RUNTIME_CONFIG__.REACT_APP_UPLOAD_API}/files/upload`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (e) => {
      const percentCompleted = Math.round((e.loaded * 100) / e.total);
      progressCallback(percentCompleted);
    },
  });
};

// create Movie Information
export const postMovie = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/new`, data);
export const patchCountries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/countries/append`,
    data
  );
export const patchLanguages = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/languages/append`,
    data
  );
export const patchGenres = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/genres/append`,
    data
  );
export const patchDirectors = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/directors/append`,
    data
  );
export const patchStars = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/stars/append`,
    data
  );
export const patchWriters = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/writers/append`,
    data
  );
export const patchAppendMoviesSubtitles = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/subtitles/append`,
    data
  );
export const patchAppendMoviesKeywords = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/keywords/append`,
    data
  );

// get movie
export const getAllMovie = (page, limit, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/get-all?page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    { signal }
  );
export const getMovieById = (id, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/get?id=${id}`,
    {
      signal,
    }
  );

// get movie from IMDB
export const getMovieByImdb = (id) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/imdb/get?id=${id}`
  );

// delete movie
export const deleteMovie = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/delete?id=${id}`
  );
export const deleteMoviesKeywords = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/keywords/delete`,
    data
  );

// delete subtitle
export const deleteSubtitle = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/subtitles/delete?id=${id}`
  );

// update movie
export const updateMovie = (data) =>
  http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/movies/update`,
    data
  );

// keywords
export const createKeywords = (data) =>
  http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/keywords/new`,
    data
  );

// download links
export const postMovieDownloadLink = (data) =>
  http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/movies/links/new`,
    data
  );
export const getMovieDownloadLink = (id) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/movies/links/list-by-movie?movie_id=${id}`
  );
export const deleteMovieDownloadLink = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/movies/links/delete?id=${id}`
  );
export const updateMovieDownloadLink = (data) =>
  http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/movies/links/update`,
    data
  );

export const deleteMovieDownloadLinkExtraData = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/movies/links/extras/delete?id=${id}`
  );
export const appendMovieDownloadLinkExtraData = (data) =>
  http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/movies/links/extras/append-to-movie-link`,
    data
  );
