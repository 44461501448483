export const IsValidMobile = (val) => {
  var regex = new RegExp("^(\\+98|0)?9\\d{9}$");
  var result = regex.test(val);
  return result;
};

// using for iranian mobile number
export const IsValidIranianMobile = (val) => {
  let regex = new RegExp("^[0][9][0-9][0-9]{8,8}$"); // test for Shorthand: ^09\d{9}$
  let result = regex.test(val);
  return result;
};
