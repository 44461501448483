import React, { useCallback, useEffect, useMemo, useState } from "react";
import { nanoid } from "nanoid";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addMusicAudioToAlbum,
  updateMusicAudioForAlbum,
} from "../../../redux/actions/musics";
 import { Input } from "../../common/input/Input";
import { useArtistsQuery } from "hooks/query/useArtistQuery";
import MultiTag from "../../common/multiTag/MultiTag";
import RedSwitch from "../../common/redSwitch/RedSwitch";
import TextArea from "../../common/textArea/TextArea";
import toasts from "../../common/toasts/toasts";
import { CloseButton } from "../../packageAndFeatures/components/Components";
import AlbumCover from "../uploaders/albumCover/AlbumCover";
import DropzoneCondition from "./dropzoneCondition/DropzoneCondition";
import SearchTrackDropdown from "./searchTrackDropdown/SearchTrackDropdown";
import styles from "./AlbumModal.module.scss";
 
// if needed replace select track data into album modal
// need to has condition on create or update mode
function AlbumModal({ data, onHide }) {
  const dispatch = useDispatch();

  const { data: artists } = useArtistsQuery();

  const [selectTrack, setSelectTrack] = useState(null);
  const [trackCover, setTrackCover] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [isFromSingleTracks, setIsFromSingleTracks] = useState(false);
  const [singers, setSingers] = useState([]);
  const [form, setForm] = useState({
    persianName: "",
    englishName: "",
    lyrics: "",
  });

  useEffect(() => {
    if (data) {
      setIsFromSingleTracks(Boolean(data.id));
      setTrackCover(data.cover);
      setAudioFile(data.audio);
      setSingers(data.singers);
      setForm({
        persianName: data.name_in_persian,
        englishName: data.name,
        lyrics: data.lyrics,
      });
      setSelectTrack(data);
    }
  }, [data]);

  const singer = useMemo(
    () => artists?.results.filter((item) => item.occupation.includes("singer")),
    [artists]
  );

  const handleSelectTrack = (selectedTrack) => {
    setSelectTrack(selectedTrack);
  };

  const onChangeSwitch = () => {
    setIsFromSingleTracks(!isFromSingleTracks);
  };

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeMulti = (options) => {
    setSingers(options);
  };

  // temporary added callbacks
  const handleChangeCover = useCallback((coverFile) => {
    setTrackCover(coverFile);
  }, []);

  const onChangeAudioFile = useCallback((audioFile) => {
    setAudioFile(audioFile);
  }, []);

  const onSubmit = () => {
    // NOTE: this submission only works when creating track or implementing before any send to data base
    if (!isFormValid()) return;
    // dispatching data to redux
    const body = {
      audio: audioFile,
      cover: trackCover,
      lyrics: form.lyrics,
      name: form.englishName,
      name_in_persian: form.persianName,
      singers,
    };

    if (data) {
      if (isFromSingleTracks) {
        dispatch(updateMusicAudioForAlbum(selectTrack, data.uuid));
      } else {
        dispatch(updateMusicAudioForAlbum(body, data.uuid));
      }
    } else {
      const uuid = nanoid();

      if (isFromSingleTracks) {
        dispatch(addMusicAudioToAlbum({ ...selectTrack, uuid }));
      } else {
        body["uuid"] = uuid;
        dispatch(addMusicAudioToAlbum(body));
      }
    }

    onHide();
  };

  const isFormValid = () => {
    let isValid = true;

    if (isFromSingleTracks && !selectTrack) {
      toasts.error("از تک آهنگ ها انتخاب کنید");
      isValid = false;
    }

    if (!isFromSingleTracks && !form.englishName) {
      toasts.error("نامی برای تک آهنگ وارد کنید");
      isValid = false;
    }

    if (!isFromSingleTracks && !audioFile) {
      toasts.error("تک آهنگ وارد کنید");
      isValid = false;
    }

    return isValid;
  };

  return (
    <div className={styles.wrapper}>
      <CloseButton position="top" onClick={onHide} />

      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>افزودن آهنگ</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.row}>
          <div className={styles.rightCol}>
            <AlbumCover
              initialImage={trackCover}
              onImageChange={handleChangeCover}
              isDisable={isFromSingleTracks || data?.id}
            />
          </div>
          <div className={styles.leftCol}>
            {!data?.id && (
              <RedSwitch
                label="انتخاب از تک آهنگ ها"
                isActive={isFromSingleTracks}
                onToggle={onChangeSwitch}
              />
            )}

            {isFromSingleTracks ? (
              <SearchTrackDropdown
                placeholder="انتخاب آهنگ از لیست"
                value={selectTrack}
                onChange={handleSelectTrack}
                // tracks={tracks}
              />
            ) : (
              <DropzoneCondition
                audioFile={audioFile}
                onChangeAudioFile={onChangeAudioFile}
                isReadonly={isFromSingleTracks || data?.id}
              />
            )}
          </div>
        </div>

        {/* the form wrapper is similar to Form in NewMusic */}
        <div className={styles.formWrapper}>
          <div className={styles.formTitle}>اطلاعات موسیقی</div>

          <div className={styles.form}>
            <Input
              placeholder="نام آهنگ (فارسی)"
              name="persianName"
              onChange={onChangeForm}
              value={form.persianName}
              disabled={isFromSingleTracks || data?.id}
            />
            <Input
              placeholder="نام آهنگ (انگلیسی)"
              name="englishName"
              onChange={onChangeForm}
              value={form.englishName}
              disabled={isFromSingleTracks || data?.id}
            />
            <MultiTag
              placeholder="خواننده ها"
              options={singer}
              onChange={onChangeMulti}
              value={singers}
              isDisabled={isFromSingleTracks || data?.id}
            />
            <TextArea
              placeholder="متن آهنگ"
              name="lyrics"
              onChange={onChangeForm}
              value={form.lyrics}
              disabled={isFromSingleTracks || data?.id}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <button className={`btn ${styles.reject}`} onClick={onHide}>
          <img
            src={`${process.env.PUBLIC_URL}/images/commentManagement/close-square.svg`}
            alt=""
          />
          <span>انصراف</span>
        </button>
        {(!data?.id || data?.uuid) && (
          <button className={`btn ${styles.success}`} onClick={onSubmit}>
            <img
              src={`${process.env.PUBLIC_URL}/images/commentManagement/tick-square.svg`}
              alt=""
            />
            <span>ثبت موسیقی</span>
          </button>
        )}
      </Modal.Footer>
    </div>
  );
}

export default AlbumModal;
