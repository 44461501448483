const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const generateRandomKey = () => {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = "";

  for (let index = 0; index < randomIntFromInterval(16, 24); index++) {
    const rendomValueFromCharacters =
      characters[Math.floor(Math.random() * characters.length)];
    password += rendomValueFromCharacters;
  }

  return password;
};

const reduceArrToString = (arr, separator) => {
  if (arr?.length) {
    return arr?.reduce(
      (prev, current) => `${prev} ${separator || ","} ${current}`
    );
  } else {
    return null;
  }
};

export const handleGenerateStringWithComma = (arr, filteredName) => {
  const result = arr?.map((i) => i[filteredName]);
  return reduceArrToString(result, ",");
};
