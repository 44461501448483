import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDeleteCollectionQuery } from "hooks/query/useCollectionQuery";
import AddMovieCollectionModal from "../addMovieCollectionModal/AddMovieCollectionModal";
import DeleteMultiModal from "../../../common/modals/deleteMultiModal/DeleteMultiModal";
import styles from "./Header.module.scss";

function Header({ id, title }) {
  const history = useHistory();
  const { mutate: deleteCollection } = useDeleteCollectionQuery(title);

  // Modals State
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleDeleteCollection = () => {
    deleteCollection(id);
    history.push("/collections");
  };

  return (
    <>
      <div className={styles.headerWrapper}>
        <Link
          to={`/collections/addCollection/${id}`}
          className={`btn ${styles.btn} ${styles.primary}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/movies/chose-all.svg`}
            alt=""
          />
          اطلاعات کالکشن
        </Link>
        <div
          onClick={() => setShowAddModal(true)}
          className={`btn ${styles.btn} ${styles.primary} ${styles.plus}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/plus-black.svg`}
            alt="+"
          />
          افزودن فیلم
        </div>
        <div
          onClick={() => setShowDeleteModal(true)}
          className={`btn ${styles.btn} ${styles.danger}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
            alt=""
          />
          حذف کالکشن
        </div>
      </div>
      <Modal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        dialogClassName={styles.modal}>
        <AddMovieCollectionModal
          id={id}
          onHide={() => setShowAddModal(false)}
        />
      </Modal>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={styles.modal}>
        <DeleteMultiModal
          titleData={{
            deleteObject: "",
            isMoreThanOne: false,
            placeName: "کالکشن",
          }}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={handleDeleteCollection}
        />
      </Modal>
    </>
  );
}

export default Header;
