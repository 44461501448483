import NewProject from "../components/newProject/NewProject";
import Project from "../components/project/Project";

import ProductAndServices from "../components/productAndServices/ProductAndServices";

import LiveStreaming from "../components/stream/liveStreaming/LiveStreaming";
// import Stream from "../components/stream/Stream";

import MyOrders from "../components/myOrders/MyOrders";
import OverView from "../components/overView/OverView";
import Support from "../components/support/Support";
import Wallet from "../components/wallet/Wallet";

import PackageAndFeatures from "../components/packageAndFeatures/PackageAndFeatures";

import ContactUs from "../components/Landing/contactUs/ContactUs";
import Landing from "../components/Landing/Landing";

import AddMovies from "../components/movies/AddMovies/AddMovies";
import Movies from "../components/movies/Movies";

import Actors from "../components/actors/Actors";
import AddActors from "../components/actors/addActors/AddActors";

import AddSeries from "../components/series/addSeries/AddSeries";
import Series from "../components/series/Series";
// import SeasonsList from "../components/series/seasonsList/SeasonsList";
// import AddSeasons from "../components/series/addSeasons/AddSeasons";

import AddCollection from "../components/collections/AddCollection/AddCollection";
import Collections from "../components/collections/Collections";

import RegisterAndLogin from "../components/registerAndLogin/RegisterAndLogin";
import ResetPassword from "../components/resetPassword/ResetPassword";

import AddSlider from "../components/sliders/addSlider/AddSlider";
import Sliders from "../components/sliders/Sliders";

import VideoClip from "../components/videoClip/VideoClip";

import CollectionMovies from "../components/collections/collectionMovies/CollectionMovies";

import Experts from "components/exprets/Experts";
import Articles from "../components/articles/Articles";
import BackUp from "../components/backup/BackUp";
import BoxOffice from "../components/boxOffice/BoxOffice";
import Categories from "../components/categories/Categories";
import CommentManagement from "../components/commentManagement/CommentManagement";
import ContentRequest from "../components/contentRequest/ContentRequest";
import GetApi from "../components/getApi/GetApi";
import Members from "../components/members/Members";
import Music from "../components/music/Music";
import NewArticles from "../components/newArticles/NewArticles";
import NewMusic from "../components/newMusic/NewMusic";
import PlaylistInfo from "../components/playlists/playlistInfo/PlaylistInfo";
import Playlists from "../components/playlists/Playlists";
import Profile from "../components/profile/Profile";
import VideoPlay from "components/videoPlay/VideoPlay";
import Television from "components/television/Television";
import Recommended from "components/recommended/Recommended";
import Setting from "components/settings/Setting";
import Events from "components/events/Events";
import LiveStreamDetails from "pages/live-stream/details";
import LiveStreamLists from "pages/live-stream/lists";
import Tickets from "pages/tickets";
import Room from "pages/tickets/room";

// we can split it later on layout when it's get bigger
/*
  also we can edit struct to 
  routes = [
    {
      layout: PanelLayout, // component
      accessLevel: 'public' | 'private' | 'etc',
      routes: [
         {
            path: '/login',
            component: Login,
            exact?: bool
          }
      ]
    }
  ]
*/

let versionType = window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE;

export const routes = [
  // Landing - START
  versionType !== "sermovie" && {
    component: Landing,
    path: "/",
    exact: true,
    layout: "landing",
  },
  {
    component: ContactUs,
    path: "/contact",
    exact: true,
    layout: "landing",
  },
  // Landing - END

  // Empty - START
  versionType === "sermovie" && {
    component: RegisterAndLogin,
    path: "/",
    exact: true,
    layout: "empty",
  },
  {
    component: RegisterAndLogin,
    path: "/registerAndLogin",
    exact: true,
    layout: "empty",
  },
  {
    component: ResetPassword,
    path: "/resetPassword",
    exact: true,
    layout: "empty",
  },
  // Empty - END

  // Panel - START
  {
    component: Profile,
    path: "/profile",
    exact: true,
    layout: "panel",
  },
  {
    component: Project,
    path: "/project",
    exact: true,
    layout: "panel",
  },
  {
    component: NewProject,
    path: "/project/newProject",
    exact: false,
    layout: "panel",
  },
  {
    component: ProductAndServices,
    path: "/productAndServices",
    exact: true,
    layout: "panel",
  },
  // {
  //   component: Stream,
  //   path: "/stream",
  //   exact: true,
  //   layout: "panel",
  // },
  {
    component: LiveStreamLists,
    path: "/live-stream",
    exact: true,
    layout: "panel",
  },
  {
    component: LiveStreamDetails,
    path: "/live-stream/new",
    exact: false,
    layout: "panel",
  },
  {
    component: LiveStreamDetails,
    path: "/live-stream/details/:id",
    exact: false,
    layout: "panel",
  },
  // {
  //   component: LiveStreaming,
  //   path: "/stream/liveStreaming",
  //   exact: false,
  //   layout: "panel",
  // },
  // {
  //   component: LiveStreaming,
  //   path: "/stream/liveStreaming/:id",
  //   exact: false,
  //   layout: "panel",
  // },
  {
    component: Wallet,
    path: "/wallet",
    exact: true,
    layout: "panel",
  },
  {
    component: OverView,
    path: "/overView",
    exact: true,
    layout: "panel",
  },
  {
    component: MyOrders,
    path: "/myOrders",
    exact: true,
    layout: "panel",
  },
  {
    component: Support,
    path: "/support",
    exact: true,
    layout: "panel",
  },
  {
    component: PackageAndFeatures,
    path: "/packageAndFeatures",
    exact: true,
    layout: "panel",
  },
  {
    component: LiveStreaming,
    path: "/packageAndFeatures/liveStreaming",
    exact: false,
    layout: "panel",
  },
  {
    component: Movies,
    path: "/movies",
    exact: true,
    layout: "panel",
  },
  {
    component: AddMovies,
    path: "/movies/addMovies",
    exact: false,
    layout: "panel",
  },
  {
    component: AddMovies,
    path: "/movies/addMovies/:id",
    exact: false,
    layout: "panel",
  },
  {
    component: Series,
    path: "/series",
    exact: true,
    layout: "panel",
  },
  {
    component: AddSeries,
    path: "/series/addSeries",
    exact: false,
    layout: "panel",
  },
  {
    component: AddSeries,
    path: "/series/addSeries/:id",
    exact: false,
    layout: "panel",
  },
  // {
  //   component: SeasonsList,
  //   path: "/series/seasonsList",
  //   exact: false,
  //
  //   layout: "panel",
  // },
  // {
  //   component: AddSeasons,
  //   path: "/series/addSeasons",
  //   exact: false,
  //
  //   layout: "panel",
  // },
  {
    component: Sliders,
    path: "/sliders",
    exact: true,
    layout: "panel",
  },
  {
    component: AddSlider,
    path: "/sliders/addSlider",
    exact: false,
    layout: "panel",
  },
  {
    component: AddSlider,
    path: "/sliders/addSlider/:id",
    exact: false,
    layout: "panel",
  },

  {
    component: Actors,
    path: "/actors",
    exact: true,
    layout: "panel",
  },
  {
    component: AddActors,
    path: "/actors/addActors",
    exact: false,
    layout: "panel",
  },
  {
    component: AddActors,
    path: "/actors/addActors/:id",
    exact: false,
    layout: "panel",
  },

  {
    component: Collections,
    path: "/collections",
    exact: true,
    layout: "panel",
  },
  {
    component: CollectionMovies,
    path: "/collections/:id/collectionMovies",
    exact: false,
    layout: "panel",
  },
  {
    component: AddCollection,
    path: "/collections/addCollection",
    exact: false,
    layout: "panel",
  },
  {
    component: AddCollection,
    path: "/collections/addCollection/:id",
    exact: false,
    layout: "panel",
  },
  {
    component: Members,
    path: "/members",
    exact: false,
    layout: "panel",
  },
  {
    component: ContentRequest,
    path: "/contentRequest",
    exact: false,
    layout: "panel",
  },
  {
    component: CommentManagement,
    path: "/commentManagement",
    exact: false,
    layout: "panel",
  },
  {
    component: Playlists,
    path: "/playlists",
    exact: true,
    layout: "panel",
  },
  {
    component: PlaylistInfo,
    path: "/playlists/:id",
    exact: true,
    layout: "panel",
  },
  {
    component: Setting,
    path: "/settings",
    exact: true,
    layout: "panel",
  },
  {
    component: Events,
    path: "/events",
    exact: true,
    layout: "panel",
  },
  {
    component: Articles,
    path: "/articles",
    exact: true,
    layout: "panel",
  },
  {
    component: NewArticles,
    path: "/articles/article/:id",
    exact: false,
    layout: "panel",
  },
  {
    component: NewArticles,
    path: "/articles/addArticle",
    exact: false,
    layout: "panel",
  },
  {
    component: BoxOffice,
    path: "/boxOffice",
    exact: true,
    layout: "panel",
  },

  // Music
  {
    component: Music,
    path: "/musics",
    exact: true,
    layout: "panel",
  },
  {
    component: NewMusic,
    path: "/musics/music/:id",
    exact: true,
    layout: "panel",
  },
  {
    component: NewMusic,
    path: "/musics/addMusic",
    exact: true,
    layout: "panel",
  },

  {
    component: VideoPlay,
    path: "/video-play",
    exact: true,
    layout: "panel",
  },
  {
    component: Television,
    path: "/television",
    exact: true,
    layout: "panel",
  },

  // Recommend
  {
    component: Recommended,
    path: "/recommended",
    exact: true,
    layout: "panel",
  },

  // tickets
  {
    component: Tickets,
    path: "/tickets",
    exact: true,
    layout: "panel",
  },
  {
    component: Room,
    path: "/tickets/:id",
    exact: true,
    layout: "panel",
  },

  // Temporarily
  {
    component: VideoClip,
    path: "/videoclip",
    exact: false,
    layout: "panel",
  },
  {
    component: BackUp,
    path: "/backup",
    exact: false,
    layout: "panel",
  },
  {
    component: GetApi,
    path: "/api",
    exact: false,
    layout: "panel",
  },
  {
    component: Categories,
    path: "/categories",
    exact: false,
    layout: "panel",
  },
  {
    component: Experts,
    path: "/experts",
    exact: false,
    layout: "panel",
  },

  // Panel - END
];
