import { Table, Spinner } from "react-bootstrap";
import CollectionRow from "./collectionRow/CollectionRow";
import styles from "./CollectionList.module.scss";

function CollectionList({ data, isPreviousData }) {
  return (
    <div className={styles.tableWrapper}>
      <Table className={styles.table} responsive>
        <thead>
          <tr className={styles.THead}>
            <th className={styles.customHeader}>پوستر و عنوان کالکشن</th>
            <th>ردیف ({data.length} مورد)</th>
            <th>سال انتشار</th>
            <th>تعداد فیلم</th>
            <th>میانگین امتیازات IMDB</th>
            <th>زمان افزودن کالکشن به پروژه</th>
            <th>وضعیت انتشار</th>
            <th>عملیات</th>
          </tr>
        </thead>
        <tbody>
          {isPreviousData ? (
            <div className="spinnerCenter">
              <Spinner animation="border" as="i" />
            </div>
          ) : (
            data.map((item, index) => (
              <CollectionRow key={item.id} data={item} index={index} />
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default CollectionList;
