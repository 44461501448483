// Slide form
export const setSlide = (data) => (dispatch) => {
  const { show, position, ...informationData } = data;
  dispatch({ type: "GET_SLIDE_INFORMATION", payload: informationData });
  dispatch({ type: "GET_SLIDE_SETTING", payload: { show, position } });
};

// information tab
export const updateInformationSlide = (data) => ({
  type: "UPDATE_SLIDE_INFORMATION",
  payload: data,
});
export const resetInformationSlide = (data) => ({
  type: "RESET_SLIDE_INFORMATION",
  payload: data,
});

// setting tab
export const updateSettingSlide = (data) => ({
  type: "UPDATE_SLIDE_SETTING",
  payload: data,
});
export const resetSettingSlide = (data) => ({
  type: "RESET_SLIDE_SETTING",
  payload: data,
});