import { getProfile } from "../../services/profile/profileServices";

export const getMyProfile = () => async (dispatch) => {
  try {
    const { data } = await getProfile();
    dispatch({
      type: "GET_PROFILE",
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateContactInfo = (data) => ({
  type: "UPDATE_PROFILE_INFO",
  payload: data,
});

export const updatePasswordChange = (data) => ({
  type: "UPDATE_PROFILE_PASSWORD_CHANGE",
  payload: data,
});

export const resetPasswordChange = () => ({
  type: "RESET_PROFILE_PASSWORD_CHANGE",
});

export const resetProfile = () => ({
  type: "RESET_PROFILE",
});