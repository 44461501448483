import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteLiveEvent,
  getLiveEvents,
  postLiveEvent,
} from "services/events/eventService";
import toasts from "components/common/toasts/toasts";

export const useLiveEventsQuery = () => {
  return useQuery(["live-events"], ({ signal }) => getLiveEvents(signal), {
    select: (data) => {
      return data.data.data;
    },
  });
};
export const usePostLiveEventQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(postLiveEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries("live-events");
      toasts.success(`ایونت ${title} ثبت شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت ایونت پیش آمده");
    },
  });
};
export const useDeleteLiveEventQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteLiveEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries("live-events");
      toasts.success(`ایونت ${title} حذف شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
