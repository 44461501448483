import http from "../httpService";

export const getProfile = () =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/profile`);

export const updateProfile = (data) =>
  http.put(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/update`, data);

export const changePassword = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/change-password`,
    data
  );

export const generateApiKey = () =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/users/gen-api-key`);
