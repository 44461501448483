import { Table } from "react-bootstrap";
import Row from "./row";
import styles from "./styles.module.scss";

export default function Lists() {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.pill}>
          <Table className={styles.table} responsive>
            <thead>
              <tr>
                <th>شماره تیکت</th>
                <th>بخش</th>
                <th>موضوع</th>
                <th>وضعیت</th>
                <th>آخرین بروز رسانی</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* {isPreviousData ? (
                <div className="spinnerCenter">
                  <Spinner animation="border" as="i" />
                </div>
              ) : (
                data?.results?.map((item) => <Row key={item.id} data={item} />)
              )} */}
              <Row />
              <Row />
              <Row />
            </tbody>
          </Table>
        </div>
      </div>
      {/* <div className="paginationWrapper">
        {data?.results.length > 0 && (
          <Paginate
            type="support"
            paginateState={[currentPage, setCurrentPage]}
            total={data?.pagination.count}
          />
        )}
      </div> */}
    </>
  );
}
