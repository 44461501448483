import toasts from "components/common/toasts/toasts";
import { postFile } from "services/files/files";

const defaultToastText = {
	success: "آپلود فایل باموفقیت انجام شد.",
	error: "مشکلی برای آپلود پش آمده.",
	warn: "فایل در حال آپلود است.",
};

export const uploadFile = async ({ file, toastText = defaultToastText }) => {
	try {
		toastText.warn && toasts.warn(toastText.warn);

		const { data } = await postFile(file);

		toastText.success && toasts.success(toastText.success);

		return data.data;
	} catch (err) {
		console.error(err);
		toastText.error && toasts.error(toastText.error);
	}
};
