import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteMovieQuery } from "hooks/query/useMovieQuery";
import { Link } from "react-router-dom";
import {
  addSelectedMovies,
  removeSelectedMovies,
} from "../../../../redux/actions/movies";
import DeleteMultiModal from "../../../common/modals/deleteMultiModal/DeleteMultiModal";
import TableCheckBox from "../../../common/TableCheckBox/TableCheckBox";
import styles from "../MovieList.module.scss";

export default function MovieRow({ data, index, currentPage }) {
  const { mutate: deleteMovie } = useDeleteMovieQuery(
    "single",
    currentPage,
    data.title
  );
  const dispatch = useDispatch();
  const { selectedMovies, isAllSelected } = useSelector(
    (state) => state.moviesListReducer
  );
  const [checked, setChecked] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (!isAllSelected && selectedMovies.length) {
      setChecked(checked);
    } else {
      setChecked(isAllSelected);
    }
  }, [isAllSelected]);

  const handleSelectMovie = (checked) => {
    if (checked) {
      dispatch(addSelectedMovies(data));
    } else {
      dispatch(removeSelectedMovies(data.id));
    }
    setChecked(checked);
  };

  const handleDeleteMovie = () => {
    deleteMovie(data.id);
  };

  return (
    <>
      <tr className={checked ? styles.active : styles.rowCell}>
        <td
          onClick={() => handleSelectMovie(!checked)}
          className={checked ? styles.typeCell : ""}>
          {selectedMovies.length > 0 && (
            <TableCheckBox
              checkBoxState={[checked, setChecked]}
              place="tableRow"
            />
          )}
          {data.cover?.length ? (
            <img
              loading="lazy"
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
              alt=""
              className={`${styles.moviePoster}`}
            />
          ) : (
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
              alt=""
              className={`${styles.moviePoster} ${
                checked && styles.moviePosterActive
              }`}
            />
          )}
          {data.title.length > 20
            ? data.title.slice(0, 20) + "..."
            : data.title}
        </td>
        <td onClick={() => handleSelectMovie(!checked)}>{index + 1}</td>
        <td onClick={() => handleSelectMovie(!checked)}>
          {data.release ? data.release : "-"}
        </td>
        <td onClick={() => handleSelectMovie(!checked)}>-</td>
        {data.release ? (
          <td
            onClick={() => handleSelectMovie(!checked)}
            className={styles.green}>
            منتشر شده
          </td>
        ) : (
          <td
            onClick={() => handleSelectMovie(!checked)}
            className={styles.orange}>
            منتشر نشده
          </td>
        )}
        <td>
          <Link
            to={`/movies/addMovies/${data.id}`}
            className={`${styles.seeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/movies/see.svg`}
              alt="see"
            />
            مشاهده و ویرایش
          </Link>
          <button
            onClick={() => setShowDeleteModal(true)}
            className={`${styles.removeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt="see"
            />
            حذف فیلم
          </button>
        </td>
      </tr>
      {showDeleteModal && (
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          dialogClassName={styles.deleteMovieModal}>
          <DeleteMultiModal
            titleData={{
              deleteObject: data.title,
              isMoreThanOne: false,
              placeName: "فیلم",
            }}
            onHide={() => setShowDeleteModal(false)}
            handleDelete={handleDeleteMovie}
          />
        </Modal>
      )}
    </>
  );
}
