import { useState, useCallback } from "react";
import {
  useLiveEventsQuery,
  usePostLiveEventQuery,
} from "hooks/query/useLiveEventsQuery";
import { uploadFile } from "util/uploadFile";
import { Input } from "components/common/input/Input";
import {
  convertISOStringToObject,
  convertJalaliObjectToMiladiDate,
  convertJalaliToMiladiISO,
} from "util/toJalali";
import toasts from "components/common/toasts/toasts";
import SingleDayDatePicker from "components/common/singleDayDatePicker/SingleDayDatePicker";
import SingleImageUploader from "components/common/singleImageUploader/SingleImageUploader";
import TextArea from "components/common/textArea/TextArea";
import styles from "./Events.module.scss";
import EventsSlider from "./eventSlider/EventsSlider";

export default function Events() {
  const [information, setInformation] = useState({
    title: "",
    url: "",
    description: "",
    poster: null,
    // description_url: "",
  });
  const [eventDate, setEventDate] = useState(""); // date
  const [eventReleaseDate, setEventReleaseDate] = useState(""); // publish_date
  const handleChangeInput = (e) => {
    setInformation({
      ...information,
      [e.target.name]: e.target.value,
    });
  };
  const hanldeChangeEventDate = (e) => {
    setEventDate(convertJalaliObjectToMiladiDate(e));
  };
  const hanldeChangeEventReleaseDate = (e) => {
    setEventReleaseDate(convertJalaliObjectToMiladiDate(e));
  };
  const onChangeLogo = useCallback((image) => {
    setInformation((prev) => ({ ...prev, poster: image }));
  }, []);

  const { data } = useLiveEventsQuery();
  const { mutateAsync: postLiveEvent } = usePostLiveEventQuery(
    information.title
  );
  const onSubmit = async () => {
    const isFormValid = [
      information.title.length,
      information.url.length,
      information.description.length,
      information.poster?.path.length,
      eventDate.length,
      eventReleaseDate.length,
    ].every(Boolean);

    if (isFormValid) {
      if (information.poster?.hasOwnProperty("path")) {
        const fileId = await uploadFile({ file: information.poster });
        information["poster"] = fileId;
      }
      let formatedDate = convertISOStringToObject(eventDate);
      let formatedPublishDate = convertISOStringToObject(eventReleaseDate);
      await postLiveEvent({
        ...information,
        date: convertJalaliToMiladiISO(formatedDate),
        publish_date: convertJalaliToMiladiISO(formatedPublishDate),
      });
      setInformation({ title: "", url: "", description: "", poster: null });
      setEventDate("");
      setEventReleaseDate("");
    } else {
      toasts.error("لطفا تمامی فیلد های لازم را پر کنید");
    }
  };

  return (
    <section className={styles.sectionWrapper}>
      <div className={styles.slider}>
        <EventsSlider data={data?.results} />
      </div>
      <p className={styles.title}>افزودن ایونت جدید</p>
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>
          <SingleImageUploader
            dropzonePlaceholder="افزودن تصویر"
            imageState={[information.poster, (file) => onChangeLogo(file)]}
            hasSpanText={false}
            eventPosterStyles
          />
        </div>
        <div className={styles.groupWrapper}>
          <Input
            placeholder="تیتر ایونت"
            name="title"
            value={information.title}
            onChange={handleChangeInput}
          />
          <SingleDayDatePicker
            placeholder="تاریخ ایونت"
            selectedDateState={[
              eventDate?.length ? convertISOStringToObject(eventDate) : "",
              hanldeChangeEventDate,
            ]}
          />
          <SingleDayDatePicker
            placeholder="تاریخ انتشار ایونت"
            selectedDateState={[
              eventReleaseDate?.length
                ? convertISOStringToObject(eventReleaseDate)
                : "",
              hanldeChangeEventReleaseDate,
            ]}
          />
        </div>
        <div className={styles.groupWrapper}>
          <Input
            placeholder="لینک ایونت"
            name="url"
            value={information.url}
            onChange={handleChangeInput}
          />
          {/* <Input
            placeholder="لینک صفحه توضیحات"
            name="description_link"
            value={information.description_link}
            onChange={handleChangeInput}
          /> */}
        </div>
        <div className={styles.textareaWrapper}>
          <TextArea
            name="description"
            placeholder="اطلاعات ایونت"
            value={information.description}
            onChange={handleChangeInput}
          />
        </div>
        <button onClick={onSubmit} className={`${styles.addBtn} btn`}>
          ثبت ایونت
        </button>
      </div>
    </section>
  );
}
