import { Helmet } from "react-helmet";
export default function HeadTag() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
            ? "سرمووی"
            : "Streamer"}
        </title>

        <link
          rel="icon"
          href={`${
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              ? "/sermovie-logo.svg"
              : "/streamer.ico"
          } `}
        />
        <link
          rel="apple-touch-icon"
          href={`${
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              ? "/sermovie-logo.svg"
              : "/streamer.ico"
          } `}
        />

        <meta
          name="description"
          content={`${
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              ? "سر(سریال) مووی(فیلم) - دانلود رایگان فیلم و سریال بدون سانسور"
              : "پلتفرم پخش زنده و Live Streaming استریمر، پلتفرمی دانش بنیان است که این امکان را به کاربران میدهد تا پخش زنده خود را بصورت اختصاصی و بر روی دامنه های دلخواه خود انجام دهند. امکاناتی نظیر شخصی سازی Player، استفاده از لوگوی دلخواه، پهنای باند مصرفی نامحدود و ده ها قابلیت دیگر، ایمن آریا را از سایر پلتفرم های داخلی متمایز میکند."
          }`}
        />
      </Helmet>
    </>
  );
}
