import styles from "./LandingFooter.module.scss";

export default function LandingFooter() {
  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  return (
    <footer className={styles.footer}>
      <button onClick={scrollToTop} className={`${styles.scrollTopBtn}`}>
        <img
          src={`${process.env.PUBLIC_URL}/images/landing/scrollTop.svg`}
          alt=""
        />
      </button>
      <div className={styles.copyright}>
        <p>
          <span className={styles.copyrightDate}> 2022 &#169; </span> تمام حقوق
          مادی و معنوی این سایت متعلق به شرکت <strong>فناوران ایمن آریا</strong>{" "}
          می باشد
        </p>
      </div>
    </footer>
  );
}
