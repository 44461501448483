import styles from "./Card.module.scss";
export default function Card({ data }) {
  return (
    <>
      <div className={styles.card}>
        <figure style={{ width: data.width }} className={styles.imageFigure}>
          <img className="image-cover" src={data.image} alt={data.caption} />
        </figure>
        <div className={styles.text}>
          <h3>{data.caption}</h3>
        </div>
      </div>
    </>
  );
}