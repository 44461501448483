import { useCallback } from "react";
import { usePatchDeleteTrackQuery } from "hooks/query/useMusicQuery";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { musicTypes } from "../../../constants/musics";
import {
  removeMusicAudioWithUuid,
  setMusicAudioToSingleTrack,
} from "../../../redux/actions/musics";
import toasts from "../../common/toasts/toasts";
import AudioPreview from "../audioPreview/AudioPreview";
import AudioDropzone from "../uploaders/audioDropzone/AudioDropzone";
import styles from "./AudioContainer.module.scss";

function AudioContainer() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { type, audio } = useSelector((state) => state.musicFormReducer);

  // temps
  const dropzoneShowCondition =
    type === musicTypes.album ? audio.length <= 100 : audio.length < 1;

  const handleChangeAudio = useCallback(
    (audioFile) => {
      // This func only works in single album type not album
      if (audioFile && type === musicTypes.single) {
        // TODO: need to change here for update mode and create
        if (audioFile.hasOwnProperty("id")) {
          dispatch(setMusicAudioToSingleTrack(audioFile));
        } else {
          const updateModel = {
            id: +id,
            name: audioFile.name,
            audio: audioFile,
          };

          dispatch(setMusicAudioToSingleTrack(id ? updateModel : audioFile));
        }
      }
    },
    [dispatch, type, id]
  );

  const { mutate: patchDeleteTrack } = usePatchDeleteTrackQuery();
  const handleDeleteAudio = (item) => {
    patchDeleteTrack({
      id: +id,
      tracks: [item.id],
    });
  };

  const audioDeleteCb = async (item) => {
    if (item.hasOwnProperty("id")) {
      handleDeleteAudio(item);
    } else {
      dispatch(removeMusicAudioWithUuid(item.uuid));
      toasts.success(`موزیک ${item.name} باموفقیت حذف شد`);
    }
  };

  return (
    <div className={styles.audioContainer}>
      {audio.map((item) => (
        <AudioPreview
          key={item.name}
          data={item}
          isAlbum={type === musicTypes.album}
          audioChangeCb={handleChangeAudio}
          audioDeleteCb={audioDeleteCb}
        />
      ))}

      {dropzoneShowCondition && (
        <AudioDropzone
          currentAudioLength={audio.length}
          isAlbum={type === musicTypes.album}
          audioChangeCb={handleChangeAudio}
        />
      )}
    </div>
  );
}

export default AudioContainer;
