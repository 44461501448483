import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Tab, Tabs } from "react-bootstrap";
import { restArtist } from "../../../redux/actions/artists";
import TabHeader from "./TabHeader/TabHeader";
import ActorsInformation from "./Tabs/actorsInformation/ActorsInformation";
import styles from "./AddActors.module.scss";

export default function AddActors() {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    return () => dispatch(restArtist());
  }, [dispatch]);

  return (
    <>
      <TabHeader id={id} />
      <div className={`filter ${styles.filterBody}`}>
        <Tabs defaultActiveKey="ActorsInformation" className={styles.tab}>
          <Tab
            eventKey="ActorsInformation"
            title="اطلاعات هنرمند"
            className={styles.tabItem}>
            <ActorsInformation id={id} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
