import { getMovieByImdb } from "../../services/movies/moviesServices";

export const addSelectedMovies = (data) => ({
  type: "ADD_SELECTED_MOVIES_LIST",
  payload: data,
});
export const removeSelectedMovies = (movieID) => ({
  type: "REMOVE_SELECTED_MOVIES_LIST",
  payload: movieID,
});
export const updateSelectAllMovies = (data) => ({
  type: "UPDATE_SELECT_ALL_MOVIES",
  payload: data,
});

// Add Movie State by IMDB ID
export const fetchMoviesByImdb = (imdbId) => async (dispatch) => {
  dispatch({ type: "FETCH_MOVIE_BY_IMDB_ID" });

  try {
    const { status, data } = await getMovieByImdb(imdbId);

    if (status === 200) {
      dispatch(fetchMoviesByImdbSuccess(data.data));
    }
  } catch (error) {
    console.log(error);
    dispatch(fetchMoviesByImdbFailed(error.message));
  }
};
export const fetchMoviesByImdbSuccess = (imdbMovie) => ({
  type: "FETCH_MOVIE_BY_IMDB_ID_SUCCESS",
  payload: { imdbMovie },
});
export const fetchMoviesByImdbFailed = (errorMsg) => ({
  type: "FETCH_MOVIE_BY_IMDB_ID_FAILED",
  payload: { errorMsg },
});
export const resetMovieByImdb = () => ({
  type: "RESET_MOVIE_BY_IMDB_ID",
});

// Movie Information tab
export const updateMovieInformation = (data) => ({
  type: "UPDATE_MOVIE_INFO",
  payload: data,
});
export const resetMovieInformation = () => ({
  type: "RESET_MOVIE_INFO",
});

// Movie File Upload tab
export const selectMovieFile = (file) => ({
  type: "SELECT_MOVIE_FILE",
  payload: file,
});
export const selectMovieServer = (data) => ({
  type: "SELECT_MOVIE_SERVER",
  payload: data,
});
export const unSelectMovieFile = (path) => ({
  type: "UN_SELECT_MOVIE_FILE",
  payload: path,
});
export const resetMovieFile = () => ({ type: "RESET_MOVIE_FILE" });

// MOVIE AWARDS tab
export const addMovieAwards = (data) => ({
  type: "ADD_MOVIE_AWARD",
  payload: data,
});
export const removeMovieAwards = (data) => ({
  type: "REMOVE_MOVIE_AWARD",
  payload: data,
});

// Movies Subtitles
export const addSubtitleToMovie = (data) => ({
  type: "ADD_SUBTITLE_TO_MOVIE",
  payload: data,
});
export const removeSubtitleFromMovie = (id) => ({
  type: "REMOVE_SUBTITLE_FROM_MOVIE",
  payload: id,
});
export const resetMovideSubtitle = () => ({ type: "RESET_MOVIE_SUBTITLE" });

// LinkURL
export const addLinkURLToMovie = (data) => ({
  type: "ADD_MOVIE_LINKURL",
  payload: data,
});

// keywords
export const removeMoviesKeywords = (keywordID) => ({
  type: "REMOVE_MOVIE_KEYWORDS",
  payload: keywordID,
});

// Download Links
export const getMovieLinkDownload = (data) => ({
  type: "GET_MOVIE_DOWNLOADLINK",
  payload: data,
});

export const addMovieLinkDownload = () => ({ type: "ADD_MOVIE_DOWNLOADLINK" });

export const updateMovieLinkDownload = (data) => ({
  type: "UPDATE_MOVIE_DOWNLOADLINK",
  payload: data,
});

export const deleteMovieLinkDownload = (data) => ({
  type: "DELETE_MOVIE_DOWNLOADLINK_BY_INDEX",
  payload: data.index,
});

export const resetMovieLinkDownload = () => ({
  type: "RESET_MOVIE_DOWNLOADLINK",
});
