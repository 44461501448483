import { useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./LableCheckBox.module.scss";

// later we can get check state as props

function LableCheckBox({ label, text }) {
  const [checked, setChecked] = useState(false);

  return (
    <div
      className={styles.checklistWrapper}
      onClick={() => {
        setChecked(!checked);
      }}>
      <Form.Check
        type="checkbox"
        className={styles.checkBox}
        onChange={() => setChecked(!checked)}
        checked={checked}
        // custom
      />
      <div>
        <p>{label}</p>
        <span>{text}</span>
      </div>
    </div>
  );
}

export default LableCheckBox;
