import {
  usePostAlbumQuery,
  usePostTrackQuery,
  usePutAlbumQuery,
  usePutTrackQuery,
} from "hooks/query/useMusicQuery";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { musicTypes } from "../../../constants/musics";
import { managerUploadTypes } from "../../../constants/uploadManager";
import {
  addFileToQueue,
  removeFileFromQueue,
  updateVideoProgress,
} from "../../../redux/actions/videoUploadManager";
import { postFile } from "../../../services/files/files";
import * as albumApi from "../../../services/musics/albums";
import * as trackApi from "../../../services/musics/tracks";
import { createFileQueueBody } from "../../../util/uploadManager";
import toasts from "../../common/toasts/toasts";
import styles from "./Header.module.scss";

//! TODO: need to have valid field to append posted tracks id to track - the url not working
//TODO: fix duration bug

function Header() {
  const { id } = useParams();
  const { mutateAsync: postAlbum } = usePostAlbumQuery();
  const { mutate: putAlbum } = usePutAlbumQuery();
  const { mutateAsync: postTrack } = usePostTrackQuery();
  const { mutate: putTrack } = usePutTrackQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const { type, cover, form, appendedPeople, audio } = useSelector(
    (state) => state.musicFormReducer
  );

  const isFormValid = () => {
    let isValid = true;

    if (!audio.length) {
      toasts.error("فایل صوتی را وارد کنید");
      isValid = false;
    }

    if (!form.name) {
      // || !form.name_in_persian
      toasts.error("نامی برای آلبوم یا تک آهنگ خود انتخاب کنید");
      isValid = false;
    }

    if (!form.release) {
      toasts.error("تاریخ انتشار آلبوم را مشخص کنید");
      isValid = false;
    }

    return isValid;
  };

  const submit = async () => {
    if (!isFormValid()) return;

    // maybe need to clean it up
    const { lyrics, genre, kind, producer, ...rest } = form;
    const payload = {
      ...rest,
      producer_id: producer ? producer.id : null,
      genre_id: genre?.id,
      kind_id: kind?.id,
      type,
    };

    if (cover?.hasOwnProperty("path")) {
      const image_id = await handlePostFile(cover);
      payload["cover"] = image_id;
    }

    const faMusicTypes = musicTypes.album === type ? "آلبوم" : "تک آهنگ";

    if (id) {
      toasts.warn(`در حال به روزرسانی ${faMusicTypes} ، منتظر بمانید...`);
      handlePutMusic(payload);
    } else {
      toasts.warn(`در حال ساخت ${faMusicTypes} ، منتظر بمانید...`);
      handlePostMusic(payload);
    }

    history.push("/musics"); // redirection
  };

  const handlePostMusic = async (payload) => {
    try {
      // waiting for result of track creation
      const tracks = await handleConnectTrackToAlbum(payload);
      // then create album
      const { data: albumResponse } = await postAlbum(payload);
      const appendOptions = { endpoint: "album", id: albumResponse.data.id };
      // after creating album and tracks its time to append them
      await handleAppends({
        ...appendOptions,
        appendSectionName: "tracks",
        appendedIds: mapArrayId(filterArrayOfNotExistProperty(tracks)),
      });
      await handleConnectPeopleToAlbum(appendOptions);

        toasts.success(
          albumResponse.data.type === musicTypes.album
            ? `${albumResponse.data.name} آلبوم باموفقیت ثبت شد`
            : `${albumResponse.data.name} تک آهنگ با موفقیت ثبت شد`
        );
      //   dispatch(fetchAlbums());
    } catch (err) {
      console.error(err);
      toasts.error("مشکلی پیش آمده.");
    }
  };

  const handlePutMusic = async (payload) => {
    try {
      const appendOptions = { endpoint: "album", id: +id };
      // attach appended api calls
      const tracks = await handleConnectTrackToAlbum(payload);
      putAlbum({ ...payload, id: +id });

      await handleAppends({
        ...appendOptions,
        appendSectionName: "tracks",
        appendedIds: mapArrayId(filterArrayOfNotExistProperty(tracks)),
      });
      await handleConnectPeopleToAlbum(appendOptions);

        toasts.success(
          payload.type === musicTypes.album
            ? `${payload.name} آلبوم باموفقیت آپدیت شد`
            : `${payload.name} تک آهنگ با موفقیت آپدیت شد`
        );
      //   dispatch(fetchAlbums());
    } catch (err) {
      console.error(err);
    }
  };

  // related funcs
  //* NOTE: handler to to shortify the process of appending people
  const handleConnectPeopleToAlbum = async (basicAppendOption) => {
    // appending condition on type of music selected type
    if (type === musicTypes.album) {
      await handleAppends({
        ...basicAppendOption,
        appendSectionName: "artists",
        appendedIds: mapArrayId(
          filterArrayOfNotExistProperty(appendedPeople.artists)
        ),
      });
    } else {
      await handleAppends({
        ...basicAppendOption,
        appendSectionName: "singers",
        appendedIds: mapArrayId(
          filterArrayOfNotExistProperty(appendedPeople.singers)
        ),
      });
      await handleAppends({
        ...basicAppendOption,
        appendSectionName: "songwriters",
        appendedIds: mapArrayId(
          filterArrayOfNotExistProperty(appendedPeople.songwriters)
        ),
      });
      await handleAppends({
        ...basicAppendOption,
        appendSectionName: "arrangements",
        appendedIds: mapArrayId(
          filterArrayOfNotExistProperty(appendedPeople.arrangements)
        ),
      });
    }
  };

  const handleConnectTrackToAlbum = async (albumPayload) => {
    if (type === musicTypes.album) {
      return await handlePostAlbumTracks(albumPayload);
    } else {
      // in single type in update mode we only have put not post anymore
      if (id) {
        await handlePutSingleTrack(albumPayload);
      } else {
        return await handlePostSingleTrack(albumPayload);
      }
    }
  };

  const handlePutSingleTrack = async (albumPayload) => {
    try {
      const { name, name_in_persian, cover } = albumPayload;
      const { lyrics } = form;
      const { singers } = appendedPeople;
      const audioObj = audio[0];

      const singleTackPayload = {
        id: audioObj.id,
        name,
        name_in_persian,
        cover,
        lyrics,
      };

      if (audioObj.audio?.hasOwnProperty("path")) {
        const audioId = await handlePostAudio(
          audioObj.audio,
          singleTackPayload
        );
        singleTackPayload["url"] = audioId;
      }

      putTrack(singleTackPayload);

      await handleAppends({
        endpoint: "track",
        id: audioObj.id,
        appendSectionName: "singers",
        appendedIds: mapArrayId(filterArrayOfNotExistProperty(singers)),
      });
    } catch (err) {
      console.error(err);
    }
  };

  //* NOTE: handler to decide to append which endpoints
  const handleAppends = async (options) => {
    // endpoint: album | track (backend endpoints)
    // appendSections: artists | singers | tracks | songwriters | arrangements
    const { endpoint, id, appendSectionName, appendedIds } = options;

    if (!appendedIds?.length) return;

    const payload = {
      id: id,
      [appendSectionName]: appendedIds,
    };

    if (endpoint === "album") {
      await handleAlbumAppends(appendSectionName, payload);
    } else {
      await handleTrackAppends(payload);
    }
  };

  const handleAlbumAppends = async (appendSectionName, payload) => {
    const {
      patchAppendArtist,
      patchAppendSinger,
      patchAppendTrack,
      patchAppendArrangements,
      patchAppendSongwriter,
    } = albumApi;
    // appendSections: artists | singers | tracks | songwriters | arrangements
    const mappedApiObject = {
      artists: patchAppendArtist,
      singers: patchAppendSinger,
      tracks: patchAppendTrack,
      songwriters: patchAppendSongwriter,
      arrangements: patchAppendArrangements,
    };

    try {
      await mappedApiObject[appendSectionName](payload);
    } catch (err) {
      console.error(err);
    }
  };

  const handleTrackAppends = async (payload) => {
    //* NOTE: no need for appendSectionName because of track api only has one append
    const { patchAppendSinger } = trackApi;

    try {
      await patchAppendSinger(payload);
    } catch (err) {
      console.error(err);
    }
  };

  const handlePostSingleTrack = async (albumPayload) => {
    try {
      const { name, name_in_persian, cover } = albumPayload;
      const { lyrics } = form;
      const { singers } = appendedPeople;

      const singleTackPayload = {
        name,
        name_in_persian,
        cover,
        lyrics,
      };

      // if (audio[0].hasOwnProperty("path")) {
      const audioId = await handlePostAudio(audio[0], singleTackPayload);
      singleTackPayload["url"] = audioId;
      // }

      const { data: trackResponse } = await postTrack(singleTackPayload);

      await handleAppends({
        endpoint: "track",
        id: trackResponse.data.id,
        appendSectionName: "singers",
        appendedIds: mapArrayId(singers),
      });

      return [trackResponse.data];
    } catch (err) {
      console.error(err);
    }
  };

  const handlePostAlbumTracks = async (albumPayload) => {
    // filtering file systems to post them
    const readyToSendTracks = audio.filter(
      (item) => !item.hasOwnProperty("id")
    );
    // filtering existed tracks but not appended to album
    const existedTracksToAppend = audio.filter(
      (item) =>
        item.hasOwnProperty("id") && !item.hasOwnProperty("alreadyAppended")
    );

    const trackList = [...existedTracksToAppend];

    // creating list for parallel sending tracks
    const createParallelPosts = readyToSendTracks.map(async (track) => {
      try {
        const { cover, audio, singers, ...rest } = track;
        const payload = {
          ...rest,
        };
        // uploading file systems if not stored
        if (cover?.hasOwnProperty("path")) {
          const coverId = await handlePostFile(cover);
          payload["cover"] = coverId;
        } else {
          payload["cover"] = albumPayload.cover;
        }

        if (audio.hasOwnProperty("path")) {
          const audioId = await handlePostAudio(audio, payload);
          payload["url"] = audioId;
        }

        // create track
        const { data: trackResponse } = await trackApi.postTrack(payload);

        // appending singer to track
        handleAppends({
          endpoint: "track",
          id: trackResponse.data.id,
          appendSectionName: "singers",
          appendedIds: mapArrayId(singers),
        });

        trackList.push(trackResponse.data);
      } catch (err) {
        console.error(err);
      }
    });

    await Promise.all(createParallelPosts);

    return trackList;
  };

  // used for image covers
  const handlePostFile = async (file) => {
    try {
      const { data } = await postFile(file);

      return data.data;
    } catch (err) {
      toasts.error("error");
    }
  };

  // only for handling audio or things that took huge time to upload
  const handlePostAudio = async (file, audioInfo) => {
    const { addToQueue, removeFromQueue, updateProgress } =
      handleUploadManagement(file, audioInfo);

    try {
      toasts.warn(`موزیک ${audioInfo.name} به لیست آپلود اضافه شد`);
      addToQueue();
      const { data } = await postFile(file, updateProgress);
      toasts.success(`موزیک ${audioInfo.name} به با موفقیت اضافه شد`);
      return data.data;
    } catch (err) {
      console.error(err);
      toasts.error(`مشکلی برای آپلود ${audioInfo.name} پیش آمده`);
    } finally {
      removeFromQueue();
    }
  };

  //* NOTE: this can be hook or util func
  const handleUploadManagement = (audioFile, audioPayload) => {
    const fakeId = nanoid();

    //! TODO: the naming convention need to fixed
    const audioBody = createFileQueueBody({
      id: fakeId,
      title: audioPayload.name,
      size: audioFile.size,
      type: managerUploadTypes.MUSIC,
      event: "download",
    });

    return {
      addToQueue: () => dispatch(addFileToQueue(audioBody)),
      removeFromQueue: () => dispatch(removeFileFromQueue(fakeId)),
      updateProgress: (_, progress) =>
        dispatch(updateVideoProgress(fakeId, progress)),
    };
  };

  // helpers funcs
  const filterArrayOfNotExistProperty = (arr, property = "alreadyAppended") =>
    arr?.filter((item) => !item[property]);

  const mapArrayId = (arr) => arr?.map((i) => i.id);

  return (
    <header className={styles.wrapperHeader}>
      <button
        className={`${styles.button} ${styles.danger}`}
        onClick={() => history.push("/musics")}>
        انصراف
      </button>
      <button className={`${styles.button} ${styles.success}`} onClick={submit}>
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/white-save.svg`}
          alt=""
        />
        <span>ثبت تغییرات</span>
      </button>
    </header>
  );
}

export default Header;
