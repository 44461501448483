import { useState } from "react";
import { useMoviesQuery } from "hooks/query/useMovieQuery";
import { usePatchAppendMovieToCollectionQuery } from "hooks/query/useCollectionQuery";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import DropDown from "../../../common/dropDown/DropDown";
import styles from "./AddMovieCollectionModal.module.scss";

export default function AddMovieCollectionModal({ onHide, id }) {
  const { data: moviesList } = useMoviesQuery();
  const [pack, setPack] = useState();

  const { mutate: patchAppendMovieToCollection } =
    usePatchAppendMovieToCollectionQuery(pack?.title);

  const handleAddMovieToCollection = () => {
    patchAppendMovieToCollection({
      id: parseInt(id),
      movies: [pack.id],
    });
    onHide();
  };

  return (
    <>
      {moviesList?.results.length > 0 ? (
        <>
          <Modal.Header className={styles.modalHeader}>
            <Modal.Title className={styles.modalTitle}>
              برای <span className={styles.bold}> افزودن فیلم </span> به کالکشن،
              عنوان آنرا از میان فیلم هایی که قبلا اضافه کرده اید جستجو نمایید
            </Modal.Title>
            <img
              className={styles.icon}
              src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
              alt="Close"
              onClick={onHide}
            />
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            <DropDown
              placeholder="به طور مثال: The lord of the rings"
              options={moviesList?.results}
              // value={pack}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={(e) => setPack(e)}
            />
          </Modal.Body>
          <Modal.Footer className={styles.modalFooter}>
            <div
              onClick={onHide}
              className={`${styles.btn} ${styles.outline} btn`}>
              انصراف
            </div>
            <button
              onClick={handleAddMovieToCollection}
              className={`${styles.btn} ${styles.primary} btn`}>
              {pack?.title.length ? "افزودن" : "جستجو"}
            </button>
          </Modal.Footer>{" "}
        </>
      ) : (
        <>
          <Modal.Header className={styles.modalHeader}>
            <Modal.Title className={styles.modalTitle}>
              شما هنوز فیلمی به پروژه{" "}
              <span className={styles.bold}> مورد نظر </span> اضافه نکرده اید که
              آنرا در کالکشن قرار دهید!
            </Modal.Title>
            <img
              className={styles.icon}
              src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
              alt="Close"
              onClick={onHide}
            />
          </Modal.Header>
          <Modal.Footer className={styles.modalFooter}>
            <Link
              to="/movies"
              className={`${styles.btn} ${styles.outline} btn`}>
              افزودن فیلم
            </Link>
            <button
              onClick={onHide}
              className={`${styles.btn} ${styles.primary} btn`}>
              متوجه شدم
            </button>
          </Modal.Footer>
        </>
      )}
    </>
  );
}
