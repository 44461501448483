import http from "../httpService";
export const postSyncBoxOffice = () => {
  return http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/boxoffice/sync`);
};
export const postGenerateBoxOffice = (boxOfficePayload) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/boxoffice/generate`,
    boxOfficePayload
  );
};
export const putBoxOfficeMovie = (moviePayload) => {
  return http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/boxoffice/update`,
    moviePayload
  );
};
export const deleteBoxOfficeMovie = (id) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/boxoffice/delete?id=${id}`
  );
};
export const getBoxOfficeMovies = (signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/boxoffice/get-all`,
    {
      signal,
    }
  );
};
