import { ToJalaliFormat } from "util/toJalali";
import styles from "../Members.module.scss";

function MemberRow({ data }) {
  return (
    <>
      <tr className={styles.userRow}>
        <td>
          {data.avatar ? (
            <img
              loading="lazy"
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.avatar}`}
              alt={data.fullname}
            />
          ) : (
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/images/users/user-default.svg`}
              alt={data.fullname}
            />
          )}
          {data.fullname || data.username}
        </td>
        <td>{data.email}</td>
        <td>{data.mobile_number}</td>
        <td>{data.package_name ? data.package_name : "-"}</td>
        <td>
          {data.package_expiry_time > 0
            ? `${data.package_expiry_time} روز مانده`
            : "0"}
        </td>
        <td className={styles.status}>
          {ToJalaliFormat(data.created_at)}
          {/* {data.status === "online" ? (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/images/users/online.svg`}
                alt="online"
              />
              آنلاین
            </>
          ) : (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/images/users/offline.svg`}
                alt="offline"
              />
              آفلاین
            </>
          )} */}
        </td>
        <td>
          <img src="/images/icons/dots.svg" alt="..." />
        </td>
      </tr>
    </>
  );
}
export default MemberRow;
