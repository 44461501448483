import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { postFile } from "../../../../services/files/files";
import {
  usePostSliderQuery,
  usePutSliderQuery,
} from "hooks/query/useSliderQuery";
import toasts from "../../../common/toasts/toasts";
import styles from "./TabHeader.module.scss";

export default function TabHeader() {
  const { mutate: postSlider } = usePostSliderQuery();
  const { mutate: updateSlider } = usePutSliderQuery();
  const { id } = useParams();

  const { information, setting } = useSelector((store) => store.slideReducer);

  const handleSubmit = () => {
    if (!!information?.cover) {
      if (id) {
        // update
        handleUpdateSlide();
      } else {
        // create
        handleCreateSlide();
      }
    } else {
      toasts.error("فیلد های اجباری را وارد کنید");
    }
  };

  const handleCreateSlide = async () => {
    const { cover, poster, ...rest } = information;

    // post cover image
    const posterCondition = setting.position === "bottom" && information.poster;

    toasts.warn("درحال آپلود تصاویر");

    const coverId = await handlePostFile(information.cover);
    const posterId = posterCondition
      ? await handlePostFile(information.poster)
      : "";

    const payload = {
      ...rest,
      ...setting,
      cover: coverId,
      poster: posterId,
    };

    postSlider(payload);
  };

  const handleUpdateSlide = async () => {
    const { cover, poster, ...rest } = information;

    const coverCondition = typeof information.cover === "object";
    const posterCondition =
      setting.position === "bottom" &&
      information.poster &&
      typeof information.poster === "object";

    if (coverCondition || posterCondition) {
      toasts.warn("درحال آپلود تصاویر");
    }

    const coverId = coverCondition
      ? await handlePostFile(information.cover)
      : information.cover;
    const posterId = posterCondition
      ? await handlePostFile(information.poster)
      : information.poster;

    const payload = {
      ...rest,
      ...setting,
      cover: coverId,
      poster: posterId,
    };

    updateSlider(payload);
  };

  const handlePostFile = async (file) => {
    try {
      const { data } = await postFile(file);
      return data.data;
    } catch (err) {
      toasts.error("مشلکی در آپلود تصاویر بوجود آمده است!");
    }
  };

  return (
    <div className={styles.container}>
      <Link to="/sliders" className={`btn ${styles.btn} ${styles.removeDeal}`}>
        انصراف
      </Link>
      <div
        className={`btn ${styles.btn} ${styles.addDeal}`}
        onClick={handleSubmit}>
        <img src={`${process.env.PUBLIC_URL}/images/common/save.svg`} alt="" />
        ثبت تغییرات
      </div>
    </div>
  );
}
