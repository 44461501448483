import { useEffect, useRef, useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getSeriesByImdb,
  getSeriesSeasons,
  resetSeriesInformation,
  resetsSeasonsAndEpisodes,
} from "../../../redux/actions/series";
import { postFile } from "../../../services/files/files";
import {
  getSerieByImdb,
  patchAppendEpisodeToSeasonOfSeries,
  patchAppendSeasonToSeries,
} from "../../../services/series/series";
import { createAppend } from "../helpers/createAppend";
import {
  handlePatchSubtitles,
  handlePatchSeriesDownloadLink,
  useUploadingVideos,
  useVideoUrlForTranscode,
} from "../helpers/useUploadingVideos";
import { createKeywords } from "services/movies/moviesServices";
import { usePostSeriesQuery } from "hooks/query/useSerieQuery";
import Stepper from "../../common/stepper/Stepper";
import toasts from "../../common/toasts/toasts";
// import Awards from "./steps/awards/Awards";
import Imdb from "./steps/imdb/Imdb";
import Information from "./steps/information/Information";
import Seasons from "./steps/seasons/Seasons";
import TabHeader from "./tabHeader/TabHeader";
import InformationTab from "./tabs/InformationTab";
import SeasonTab from "./tabs/SeasonTab";
import styles from "./AddSeries.module.scss";

export default function AddSeries() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const createSeries = useSelector((state) => state.createSeriesReducer);
  const seasonsEpisodes = useSelector(
    (state) => state.addNewSeasonEpisodeReducer
  );
  const [isLoading, setIsLoading] = useState(false);

  const { uploadingVideos } = useUploadingVideos();
  const { transcodingVideos } = useVideoUrlForTranscode();

  // Query
  const { mutateAsync: postSeries } = usePostSeriesQuery(createSeries.title);

  // STEPS
  const refs = useRef();
  const [step, setStep] = useState(1);
  const nextStep = async () => {
    if (step === 1) {
      if (imdbId.length) {
        await handleSearchImdb();
      } else {
        toasts.error("لطفا آی دی TMDB و یا IMDB را وارد نمایید.");
      }
    } else if (step < 3) {
      setStep((prevStep) => prevStep + 1);
    }
    if (step === 3) {
      handlePostSeries();
    }
  };
  const prevStep = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
    if (step === 1) {
      setStep((prevStep) => prevStep + 1);
    }
  };
  useEffect(() => {
    refs.current?.scrollIntoView({ behavior: "smooth" });
  }, [step]);

  // Search series from IMDB
  const [imdbId, setImdbId] = useState("");
  const handleSearchImdb = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await getSerieByImdb(imdbId);
      if (status === 200) {
        dispatch(getSeriesByImdb(data.data));
        dispatch(getSeriesSeasons(data.data.seasons));
        setStep(2);
        setIsLoading(false);
      } else {
        toasts.error("خطایی رخ داده است.");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toasts.error("مشکلی پیش آمده است.");
    }
  };

  // KEYWORDS
  const keywordsWithId = createSeries.keywords?.filter(
    (i) => i.hasOwnProperty("label") && i.hasOwnProperty("id")
  );
  const keywordsWithOutId = createSeries.keywords?.filter(
    (i) => i.hasOwnProperty("label") && !i.hasOwnProperty("id")
  );
  let keywordsPayload = keywordsWithId?.length && [...keywordsWithId];

  const handlePostKeywords = async () => {
    try {
      for (const item of keywordsWithOutId) {
        const { data, status } = await createKeywords(item);
        if (status === 200) {
          keywordsPayload.push({
            id: data.data.id,
            label: data.data.label,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Post & Create Series
  const handleFormValidation = () => {
    let isValid = true;

    const submitCondition =
      createSeries.title.length && createSeries.summary.length;

    if (!submitCondition) {
      toasts.error("فیلد های الزامی را پر کنید.");
      isValid = false;
    }

    return isValid;
  };
  const handleCreateSeries = async (moviePayload) => {
    await handlePostKeywords();

    const { status, data } = await postSeries(moviePayload);

    if (status === 200) {
      handlePatchSeasons(data.data.id);
      createAppend("languages", data.data.id, createSeries.languages);
      createAppend("countries", data.data.id, createSeries.countries);
      createAppend("genres", data.data.id, createSeries.genres);
      createAppend("stars", data.data.id, createSeries.stars);
      createAppend("directors", data.data.id, createSeries.directors);
      createAppend("writers", data.data.id, createSeries.writers);
      createAppend("keywords", data.data.id, keywordsPayload);
    }
  };
  const handlePostSeries = async () => {
    // Validation
    if (!handleFormValidation()) return;

    try {
      let coverFileId = "";
      let bannerFileId = "";

      if (createSeries.cover?.path) {
        toasts.warn("در حال آپلود کاور سریال");

        const { data, status } = await postFile(createSeries.cover);

        if (status === 200) {
          coverFileId = data.data;
        }
      }
      if (createSeries.banner?.path) {
        toasts.warn("در حال آپلود پوستر سریال");

        const { data, status } = await postFile(createSeries.banner);

        if (status === 200) {
          bannerFileId = data.data;
        }
      }

      const payload = {
        cover: coverFileId || createSeries.cover,
        banner: bannerFileId || createSeries.banner,
        title: createSeries.title,
        title_in_persian: createSeries.title_in_persian,
        imdb_id: createSeries.imdb_id,
        rate_value: createSeries.rate_value,
        rate_total_votes: createSeries.rate_total_votes,
        meta_rate_value: +createSeries.meta_rate_value,
        summary: createSeries.summary,
        distributor_id: createSeries.distributor?.id,
        status: createSeries.status,
        category_id: createSeries.category_id,
        content_rating: createSeries.content_rating.title,
        ...(createSeries.start_date.length && {
          start_date: createSeries.start_date,
        }),
        ...(createSeries.status !== "active" && {
          end_date: createSeries.end_date,
        }),
      };

      await handleCreateSeries(payload);
    } catch (error) {
      toasts.error("مشکلی پیش آمده است.");
      console.log(error);
    }
  };

  // Patch data to Series
  const handlePatchSeasons = async (seriesID) => {
    seasonsEpisodes.forEach(async (item) => {
      try {
        const seasonResponse = await patchAppendSeasonToSeries({
          series_id: parseInt(seriesID),
          title: String(item.title),
        });
        if (seasonResponse.status === 200) {
          toasts.success(`فصل ${item.title} سریال اضافه شد.`);
          for (const e of item.episodes) {
            try {
              // uploading video
              if (e.file) {
                await uploadingVideos(e, seasonResponse.data.data,seasonResponse.data.data.id);
              } else if (e.linkURL?.length) {
                await transcodingVideos(e, seasonResponse.data.data,seasonResponse.data.data.id);
              }
              // upload episode Without video
              else {
                if (String(e.title).length) {
                  const { status, data } =
                    await patchAppendEpisodeToSeasonOfSeries({
                      season_id: parseInt(seasonResponse.data.data.id),
                      title: String(e.title),
                      imdb_id: e.imdb_id ? String(e.imdb_id) : String(e.title),
                      // content_url: "",
                    });
                  if (status === 200) {
                    toasts.success(`قسمت ${e.title} سریال اضافه شد.`);
                    // uploadng subtitles
                    //? if we dont have any video, why we should upload subtitles!!
                    if (e.subtitles) {
                      e.subtitles.forEach(async (f) => {
                        await handlePatchSubtitles(f, data.data.id);
                      });
                    }
                    if (e.links) {
                      e.links.forEach(async (f) => {
                        await handlePatchSeriesDownloadLink(
                          f,
                          data.data.id,
                          data.data.season_id
                        );
                      });
                    }
                  }
                }
              }
            } catch (error) {
              console.error(error);
              toasts.error("مشکلی در افزودن قسمت های سریال به وجود آمده");
            }
          }
        }
      } catch (err) {
        console.error(err);
        toasts.error("مشکلی به وجود آمده");
      }
    });
  };

  // CleanUp informations
  useEffect(() => {
    return () => {
      dispatch(resetSeriesInformation());
      dispatch(resetsSeasonsAndEpisodes());
    };
  }, [dispatch]);

  return (
    <section ref={refs} className={styles.section}>
      <>
        {id ? (
          <>
            <TabHeader />
            <div className="filter">
              <Tabs defaultActiveKey="information" className={styles.tab}>
                <Tab
                  eventKey="information"
                  title="جزییات سریال"
                  className={styles.tabItem}>
                  <InformationTab />
                </Tab>
                <Tab
                  eventKey="season"
                  title="فصل و قسمت"
                  className={styles.tabItem}>
                  <SeasonTab />
                </Tab>
              </Tabs>
            </div>
          </>
        ) : (
          <>
            <Stepper
              stepNow={step}
              steps={[
                {
                  img: "/images/stepper/enter.svg",
                  title: "مرحله 1",
                  content: "ورود اطلاعات سریال از IMDB",
                },
                {
                  img: "/images/stepper/info.svg",
                  title: "مرحله 2",
                  content: "افزودن و ویرایش جزئیات سریال",
                },
                // {
                //   img: "/images/stepper/awards.svg",
                //   title: "مرحله 3",
                //   content: "امتیاز و جوایز سریال",
                // },
                {
                  img: "/images/stepper/folder.svg",
                  title: "مرحله 3",
                  content: "افزودن فصل ها و قسمت ها",
                },
              ]}
            />
            <div className={styles.wrapper}>
              {step === 1 && <Imdb inputState={[imdbId, setImdbId]} />}
              {step === 2 && <Information />}
              {/* {step === 3 && <Awards />} */}
              {step === 3 && <Seasons />}
            </div>
            <div className={styles.footer}>
              <div>
                <button
                  disabled={isLoading}
                  onClick={nextStep}
                  className={`${styles.nextBtn} btn`}>
                  {step === 3
                    ? "ثبت نهایی سریال و شروع بارگذاری فایل ها"
                    : "ادامه"}
                  {isLoading && (
                    <Spinner
                      style={{
                        marginRight: "16px",
                      }}
                      animation="border"
                      as="i"
                    />
                  )}
                </button>
                <button onClick={prevStep} className={`${styles.prevBtn} btn`}>
                  {step === 1 ? "رد کردن" : "بازگشت"}
                </button>
              </div>
            </div>
          </>
        )}
      </>
    </section>
  );
}
