const artistState = {
  avatar: "",
  bio: "",
  birthday: null, //2006-01-25
  death_date: null,
  city_of_birth: "",
  gender: null, // enum: male, female
  height: "",
  name: "",
  name_in_persian: "",
  nationality: "",
  occupation: [],
};
export const artistReducer = (state = artistState, action) => {
  switch (action.type) {
    case "UPDATE_ARTIST_INFO":
      return { ...state, ...action.payload };

    case "ADD_ARTIST_OCCUPATION":
      return { ...state, occupation: [...state.occupation, action.payload] };
    case "REMOVE_ARTIST_OCCUPATION":
      return {
        ...state,
        occupation: state.occupation.filter((item) => item !== action.payload),
      };

    case "RESET_ARTIST":
      return artistState;

    default:
      return state;
  }
};

// helper func
const removeFromArrayWithId = (array, id) =>
  array.filter((item) => item.id !== id);

const artistListState = {
  selectedList: [],
  isAllSelected: false,
};

export const artistListReducer = (state = artistListState, action) => {
  switch (action.type) {
    case "ADD_SELECTED_ARTISTS_LIST":
      return {
        ...state,
        selectedList: [...state.selectedList, action.payload],
      };
    case "REMOVE_SELECTED_ARTISTS_LIST":
      return {
        ...state,
        selectedList: removeFromArrayWithId(state.selectedList, action.payload),
        isAllSelected:
          removeFromArrayWithId(state.selectedList, action.payload) === [],
      };
    case "UPDATE_SELECT_ALL_ARTISTS":
      return {
        ...state,
        selectedList: action.payload.allChecked
          ? action.payload.artistsList
          : [],
        isAllSelected: action.payload.allChecked,
      };
    default:
      return state;
  }
};
