import Flickity from "react-flickity-component";
import EventCard from "./EventCard";
import styles from "./EventSlider.module.scss";
// static data
const flickityOptions = {
  cellAlign: "right",
  rightToLeft: true,
  pageDots: false,
  groupCells: true,
  dragThreshold: 10,
};
const EmptyCard = () => {
  return <div className={styles.emptyCard}>ایونتی وجود ندارد</div>;
};
export default function EventsSlider({ data }) {
  return (
    <>
      <Flickity
        className={`packagesCarousel ${styles.carousel}`}
        options={flickityOptions}>
        {data?.length > 0 ? (
          data?.map((item) => <EventCard key={item.id} data={item} />)
        ) : (
          <EmptyCard />
        )}
      </Flickity>
    </>
  );
}