import {
  usePostArtistQuery,
  usePutArtistQuery,
} from "hooks/query/useArtistQuery";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { postFile } from "../../../../services/files/files";
import toasts from "../../../common/toasts/toasts";
import styles from "./TabHeader.module.scss";

export default function TabHeader({ id }) {
  const artistInfo = useSelector((store) => store.artistReducer);
  const { mutate: postNewArtist } = usePostArtistQuery(artistInfo.name);
  const { mutate: putArtist } = usePutArtistQuery(artistInfo.name);

  const handleCreateArtist = async () => {
    const { avatar, ...rest } = artistInfo;
    let avatarFileId = "";

    if (avatar?.path) {
      toasts.warn("در حال آپلود عکس پروفایل");

      const { data, status } = await postFile(avatar);

      if (status === 200) {
        avatarFileId = data.data;
      }
    }

    const payload = {
      ...rest,
      avatar: avatarFileId,
      height: parseInt(rest.height),
    };

    postNewArtist(payload);
  };
  const handleUpdateArtist = async () => {
    const { avatar, ...rest } = artistInfo;
    let avatarFileId = "";

    if (avatar?.path) {
      toasts.warn("در حال آپلود عکس پروفایل");

      const { data, status } = await postFile(avatar);

      if (status === 200) {
        avatarFileId = data.data;
      }
    }

    const payload = {
      ...rest,
      height: parseInt(rest.height),
      ...(avatarFileId.length && {
        avatar: avatarFileId,
      }),
    };

    putArtist(payload);
  };
  const handleSubmit = () => {
    const fieldRequirement = !!artistInfo.name && !!artistInfo.bio;

    if (fieldRequirement) {
      if (id) {
        handleUpdateArtist();
      } else {
        handleCreateArtist();
      }
    } else {
      toasts.error("فیلد های مورد نیاز را پر کنید");
    }
  };

  return (
    <div className={styles.container}>
      <Link to="/actors" className={`btn ${styles.btn} ${styles.removeDeal}`}>
        انصراف
      </Link>
      <div
        className={`btn ${styles.btn} ${styles.addDeal}`}
        onClick={handleSubmit}>
        <img src={`${process.env.PUBLIC_URL}/images/common/save.svg`} alt="" />
        ثبت تغییرات
      </div>
    </div>
  );
}
