import { useState } from "react";
import { setNewRecoveryPassword } from "services/entry/entryService";
import { useHistory } from "react-router";
import toasts from "components/common/toasts/toasts";
import CustomInput from "../../common/customInput/CustomInput";
import styles from "../ResetPassword.module.scss";

const ResetPasswordSection = () => {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const submit = async () => {
    if (newPassword === confirmNewPassword) {
      try {
        const { status, data } = await setNewRecoveryPassword({
          new_password: newPassword,
        });
        if (status === 200 && data.data) {
          toasts.success("رمزعبور شما باموفقیت تغییر کرد");
          history.push("/registerAndLogin");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toasts.error("رمزعبور جدید و تکرار آن با هم تطابق ندارند");
    }
  };

  return (
    <>
      <div className={styles.inputWrapper}>
        <CustomInput
          label="رمز عبور جدید"
          type="password"
          handleChange={setNewPassword}
          value={newPassword}
        />
        <CustomInput
          label="تکرار رمز عبور جدید"
          type="password"
          handleChange={setConfirmNewPassword}
          value={confirmNewPassword}
        />
      </div>

      <div className={`btn ${styles.btn}`} onClick={submit}>
        بازیابی رمز عبور
      </div>
    </>
  );
};

export default ResetPasswordSection;
