import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MenuDropdown from "../menuDropdown/MenuDropdown";
import MenuLink from "../menuLink/MenuLink";
const MainMenu = ({ menuOpen }) => {
  const { pathname } = useLocation();
  const { contactInfo } = useSelector((store) => store.profileReducer);
  // themes ==> movie | education | music | ""
  const [movieTheme, setMovieTheme] = useState(false);
  const [musicTheme, setMusicTheme] = useState(false);
  useEffect(() => {
    if (contactInfo?.theme === "movie" || contactInfo?.theme === "") {
      setMovieTheme(true);
    }
    if (contactInfo?.theme === "music" || contactInfo?.theme === "") {
      setMusicTheme(true);
    }
  }, [contactInfo?.theme]);
  // temporarily
  useEffect(() => {
    if (contactInfo?.theme === "" || contactInfo?.theme === null) {
      console.log("حساب کاربری شما فاقد تم می باشد!");
    }
  }, [contactInfo?.theme]);
  // const movieTheme =
  //   contactInfo?.theme === "movie" || contactInfo?.theme === "";
  // const musicTheme =
  //   contactInfo?.theme === "music" || contactInfo?.theme === "";

  const [dropdown, setDropdown] = useState({
    products: false,
    others: false,
  });

  return (
    <>
      <MenuLink
        title="نمایش کلی"
        linkUrl="/overView"
        iconUrl="/images/menu/full-view.svg"
        menuIsOpen={menuOpen}
        isLinkActive={pathname.includes("/overView")}
      />

      {movieTheme && (
        <MenuLink
          title="پخش زنده"
          linkUrl="/live-stream"
          iconUrl="/images/menu/liveStream.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/live-stream")}
        />
      )}
      {/* dropdown products - start */}
      <MenuDropdown
        title="محصولات"
        iconUrl="/images/menu/products.svg"
        dropdownState={[
          dropdown.products,
          () => setDropdown({ ...dropdown, products: !dropdown.products }),
        ]}
        menuIsOpen={menuOpen}>
        <MenuLink
          title="فیلم ها"
          linkUrl="/movies"
          iconUrl="/images/menu/movies.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/movies")}
          changedBg={
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
          }
        />

        {movieTheme && (
          <>
            <MenuLink
              title="سریال ها"
              linkUrl="/series"
              iconUrl="/images/menu/series.svg"
              menuIsOpen={menuOpen}
              isLinkActive={pathname.includes("/series")}
              changedBg={
                window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              }
            />

            {window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "NO" && (
              <MenuLink
                title="کالکشن ها"
                linkUrl="/collections"
                iconUrl="/images/menu/collections.svg"
                menuIsOpen={menuOpen}
                isLinkActive={pathname.includes("/collections")}
                changedBg={
                  window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE ===
                  "sermovie"
                }
              />
            )}
          </>
        )}

        {/* <MenuLink
          title="ویدئو کلیپ"
          linkUrl="/videoclip"
          iconUrl="/images/menu/videoclip.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/videoclip")}
          changedBg={window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"}
        /> */}

        <MenuLink
          title="هنرمندان و عوامل"
          linkUrl="/actors"
          iconUrl="/images/menu/actors.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/actors")}
          changedBg={
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
          }
        />

        {musicTheme && (
          <MenuLink
            title="موسیقی ها"
            linkUrl="/musics"
            iconUrl="/images/menu/music-playlist.svg"
            menuIsOpen={menuOpen}
            isLinkActive={pathname.includes("/musics")}
            changedBg={
              window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
            }
          />
        )}

        <MenuLink
          title="پیشنهاد ویژه"
          linkUrl="/recommended"
          iconUrl="/images/menu/recommend.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/recommended")}
          changedBg={
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
          }
        />

        {/* <MenuLink
					title="تماشای آنلاین"
					linkUrl="/video-play"
					iconUrl="/images/menu/video-play.svg"
					menuIsOpen={menuOpen}
					isLinkActive={pathname.includes("/video-play")}
					changedBg={window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"}
				/> */}
        <MenuLink
          title="شبکه ها"
          linkUrl="/television"
          iconUrl="/images/menu/full-view.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/television")}
          changedBg={
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
          }
        />
      </MenuDropdown>
      {/* dropdown products - end */}

      {/* dropdown others - start */}
      <MenuDropdown
        title="سایر امکانات"
        iconUrl="/images/menu/others.svg"
        dropdownState={[
          dropdown.others,
          () => setDropdown({ ...dropdown, others: !dropdown.others }),
        ]}
        menuIsOpen={menuOpen}>
        <MenuLink
          title="درخواست ها"
          linkUrl="/contentRequest"
          iconUrl="/images/menu/requests.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/contentRequest")}
          changedBg={
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
          }
        />

        <MenuLink
          title="اسلایدرها"
          linkUrl="/sliders"
          iconUrl="/images/menu/sliders.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/sliders")}
          changedBg={
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
          }
        />

        <MenuLink
          title="بسته ها و پرداخت ها"
          linkUrl="/packageAndFeatures"
          iconUrl="/images/menu/packages.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/packageAndFeatures")}
          changedBg={
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
          }
        />

        {/* <MenuLink
          title="دریافت API"
          linkUrl="/api"
          iconUrl="/images/menu/api.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/api")}
          changedBg={window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"}
        /> */}

        <MenuLink
          title="مقالات"
          linkUrl="/articles"
          iconUrl="/images/menu/articles.svg"
          menuIsOpen={menuOpen}
          isLinkActive={pathname.includes("/articles")}
          changedBg={
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
          }
        />

        {window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "NO" && (
          <MenuLink
            title="مدیریت ایونت ها"
            linkUrl="/events"
            iconUrl="/images/menu/medal-star.svg"
            menuIsOpen={menuOpen}
            isLinkActive={pathname.includes("/events")}
          />
        )}

        {movieTheme && (
          <>
            <MenuLink
              title="پلی لیست"
              linkUrl="/playlists"
              iconUrl="/images/menu/collections.svg"
              menuIsOpen={menuOpen}
              isLinkActive={pathname.includes("/playlists")}
              changedBg={
                window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              }
            />

            <MenuLink
              title="تنظیمات پایه"
              linkUrl="/settings"
              iconUrl="/images/menu/setting.svg"
              menuIsOpen={menuOpen}
              isLinkActive={pathname.includes("/settings")}
              changedBg={
                window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              }
            />

            <MenuLink
              title="باکس آفیس"
              linkUrl="/boxOffice"
              iconUrl="/images/menu/box-office.svg"
              menuIsOpen={menuOpen}
              isLinkActive={pathname.includes("/boxOffice")}
              changedBg={
                window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              }
            />
          </>
        )}
      </MenuDropdown>
      {/* dropdown others - end */}

      <MenuLink
        title="پشتیبانی"
        linkUrl="/support"
        iconUrl="/images/menu/support.svg"
        menuIsOpen={menuOpen}
        isLinkActive={pathname.includes("/support")}
      />

      {/* <MenuLink
        title="Tickets"
        linkUrl="/tickets"
        iconUrl="/images/menu/support.svg"
        menuIsOpen={menuOpen}
        isLinkActive={pathname.includes("/tickets")}
      /> */}

      <MenuLink
        title="کاربران"
        linkUrl="/members"
        iconUrl="/images/menu/people.svg"
        menuIsOpen={menuOpen}
        isLinkActive={pathname.includes("/members")}
      />

      <MenuLink
        title="کارشناسان"
        linkUrl="/experts"
        iconUrl="/images/menu/experts.svg"
        menuIsOpen={menuOpen}
        isLinkActive={pathname.includes("/experts")}
      />

      <MenuLink
        title="دسته بندی ها"
        linkUrl="/categories"
        iconUrl="/images/menu/category.svg"
        menuIsOpen={menuOpen}
        isLinkActive={pathname.includes("/categories")}
      />

      <MenuLink
        title="مدیریت کامنت ها"
        linkUrl="/commentManagement"
        iconUrl="/images/menu/comment.svg"
        menuIsOpen={menuOpen}
        isLinkActive={pathname.includes("/commentManagement")}
      />

      {/* <MenuLink
				title="پیام ها و گزارشات"
				linkUrl="/messages"
				iconUrl="/images/menu/messages.svg"
				menuIsOpen={menuOpen}
				isLinkActive={pathname.includes('/messages')}
			/> */}
    </>
  );
};

export default MainMenu;
