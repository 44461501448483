import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getMySmsConfig,
  postSmsConfig,
  putSmsConfig,
} from "services/configs/smsConfig";
import toasts from "components/common/toasts/toasts";

export const useSmsConfigQuery = () => {
  return useQuery(["sms-config"], getMySmsConfig, {
    select: (data) => {
      return data.data.data;
    },
  });
};
export const usePostSmsConfigQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postSmsConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries("sms-config");
      toasts.success(`تنظیمات sms با موفقیت ثبت شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت تنظیمات sms پیش آمده");
    },
  });
};
export const usePutSmsConfigQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(putSmsConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries("sms-config");
      toasts.success(`تنظیمات sms با موفقیت بروزرسانی شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی تنظیمات sms پیش آمده");
    },
  });
};
