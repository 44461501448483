import { useState } from "react";
import { Table, Spinner } from "react-bootstrap";
import { useRequestsQuery } from "hooks/query/useRequestQuery";
import Row from "./row/Row";
import Paginate from "components/common/paginate/Paginate";
import styles from "./ContentRequest.module.scss";

function ContentRequest() {
  const [sortType, setSortType] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = useRequestsQuery(sortType, currentPage);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sortWrapper}>
        <div
          className={sortType === "all" ? styles.active : ""}
          onClick={() => setSortType("all")}>
          همه درخواست ها
        </div>
        <div
          className={sortType === "movie" ? styles.active : ""}
          onClick={() => setSortType("movie")}>
          فیلم ها
        </div>
        <div
          className={sortType === "series" ? styles.active : ""}
          onClick={() => setSortType("series")}>
          سریال ها
        </div>
        <div
          className={sortType === "collection" ? styles.active : ""}
          onClick={() => setSortType("collection")}>
          کالکشن
        </div>
      </div>
      <div className={styles.pill}>
        <Table className={styles.table} responsive>
          <thead>
            <tr>
              <th>درخواست کننده</th>
              <th>نام فیلم</th>
              <th>ژانر</th>
              <th>تاریخ درخواست</th>
              <th>متن درخواست</th>
            </tr>
          </thead>
          <tbody>
            {isPreviousData ? (
              <div className="spinnerCenter">
                <Spinner animation="border" as="i" />
              </div>
            ) : (
              data?.results?.map((item) => <Row key={item.id} data={item} />)
            )}
          </tbody>
        </Table>
      </div>
      {data?.results?.length > 0 && (
        <Paginate
          type="contentRequest"
          paginateState={[currentPage, setCurrentPage]}
          total={data?.pagination.count}
        />
      )}
    </div>
  );
}

export default ContentRequest;
