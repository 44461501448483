import { Modal } from "react-bootstrap";
import styles from "./DeleteMultiModal.module.scss";

export default function DeleteMultiModal({ onHide, titleData, handleDelete }) {
  return (
    <>
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
          آیا نسبت به حذف {titleData.placeName}
          {titleData.isMoreThanOne ? (
            ` های انتخاب شده `
          ) : (
            <span className={styles.bold}> {titleData.deleteObject ? titleData.deleteObject : "انتخاب شده"} </span>
          )}
          اطمینان دارید؟
        </Modal.Title>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Footer className={styles.footer}>
        <div onClick={onHide} className={`${styles.btn} ${styles.outline}`}>
          انصراف
        </div>
        <div
          onClick={handleDelete}
          className={`${styles.btn} ${styles.primary}`}>
          حذف
        </div>
      </Modal.Footer>
    </>
  );
}