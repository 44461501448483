import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./TextEditor.module.scss";

function TextEditor({ label, value, ...props }) {
  return (
    <div className={styles.wrapper}>
      <label>{label}</label>
      <ReactQuill modules={TextEditor.modules} value={value} {...props} />
    </div>
  );
}

TextEditor.modules = {
  toolbar: [
    [{ header: "2" }, { header: "1" }],
    [{ size: [] }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    // ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    // ["link", "image", "video"],
    // ["clean"],
  ],
};

export default TextEditor;
