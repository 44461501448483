import {
  usePostArticleQuery,
  usePutArticleQuery,
} from "hooks/query/useArticleQuery";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { postFile } from "../../../services/files/files";
import toasts from "../../common/toasts/toasts";
import styles from "./Header.module.scss";

function Header({ id }) {
  const history = useHistory();
  const form = useSelector((state) => state.articleFormReducer);
  const { mutate: postArticle } = usePostArticleQuery(form.title);
  const { mutate: updateArticle } = usePutArticleQuery(form.title);

  const submit = async () => {
    const payload = {
      article_status: form.articleStatus,
      sub_title: form.subTitle,
      text: form.text,
      title: form.title,
    };

    const isValid = isValidateForm();
    if (!isValid) return;

    // POST
    if (!id && form.image) {
      const fileId = await handlePostArticleImage(form.image);
      payload["image_id"] = fileId;
    }
    // UPDATE
    if (id && form.image.hasOwnProperty("path")) {
      const fileId = await handlePostArticleImage(form.image);
      payload["image_id"] = fileId;
    }

    if (id) {
      updateArticle({ ...payload, id: parseInt(id) });
    } else {
      postArticle(payload);
    }
  };

  const isValidateForm = () => {
    let isValid = true;

    if (!Boolean(form.image)) {
      toasts.error("عکس مقاله را وارد کنید.");
      isValid = false;
    }

    if (!form.title.length) {
      toasts.error("عنوان مقاله را وارد کنید.");
      isValid = false;
    }

    if (!form.subTitle.length) {
      toasts.error("زیر عنوان مقاله را وارد کنید.");
      isValid = false;
    }

    if (!form.text.length) {
      toasts.error("متن مقاله را وارد کنید");
      isValid = false;
    }

    return isValid;
  };

  const handlePostArticleImage = async (file) => {
    try {
      toasts.warn("درحال آپلود پوستر...");

      const { data } = await postFile(file);

      toasts.success("عکس پوستر باموفقیت آپلود شد.");
      return data.data;
    } catch (err) {
      console.error(err);
      toasts.error("مشکلی برای آپلود پیش آمده");
    }
  };

  return (
    <header className={styles.wrapperHeader}>
      <button
        className={`${styles.button} ${styles.danger}`}
        onClick={() => history.push("/articles")}>
        انصراف
      </button>
      <button className={`${styles.button} ${styles.success}`} onClick={submit}>
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/white-save.svg`}
          alt=""
        />
        <span>ثبت تغییرات</span>
      </button>
    </header>
  );
}

export default Header;
