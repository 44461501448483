// RC: React-Select
export const actions = {
	REMOVE_VALUE: "remove-value",
	CLEAR: "clear",
	SELECT_OPTION: "select-option",
	SET_VALUE: "set-value",
	CREATE_OPTION: "create-option",
	POP_VALUE: "pop-value",
	DESELECT_OPTION: "deselect-option",
};
