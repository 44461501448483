const data = [
  {
    image: "/images/features/band.svg",
    caption: "پهنای باند نامحدود",
    width: 45,
  },
  {
    image: "/images/features/view.svg",
    caption: "تماشگران نامحدود",
    width: 52,
  },
  {
    image: "/images/features/comment.svg",
    caption: "گفتگو و تبادل نظر",
    width: 58,
  },
  {
    image: "/images/features/resolution.svg",
    caption: "HD پخش با کیفیت",
    width: 54,
  },
  {
    image: "/images/features/speed.svg",
    caption: "تاخیر حداقلی پخش زنده",
    width: 48,
  },
  {
    image: "/images/features/customize.svg",
    caption: "امکان شخصی سازی پلیر",
    width: 54,
  },
  {
    image: "/images/features/domain.svg",
    caption: "دامنه های اختصاصی",
    width: 84,
  },
  {
    image: "/images/features/statistics.svg",
    caption: "ارائه آمار دقیق",
    width: 46,
  },
];

export { data };
