import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Input } from "components/common/input/Input";
import { CloseButton } from "components/packageAndFeatures/components/Components";
import { useAdvancedSearchQuery } from "hooks/query/useSearchQuery";
import {
  usePostRecommendedMoviesQuery,
  usePostRecommendedSeriesQuery,
} from "hooks/query/useRecommendedQuery";
import styles from "./RecommendModal.module.scss";

export default function RecommendModal({ onHide }) {
  const [name, setName] = useState("");
  const { mutate: postRecommendedMovies } = usePostRecommendedMoviesQuery();
  const { mutate: postRecommendedSeries } = usePostRecommendedSeriesQuery();
  const { data, refetch, isFetching } = useAdvancedSearchQuery(
    "",
    name === "" ? 30 : "",
    name,
    "",
    false
  );

  const handlePostRecommended = (type, id) => {
    if (type === "movie") {
      postRecommendedMovies({
        movie_id: +id,
      });
    } else {
      postRecommendedSeries({
        series_id: +id,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <Modal.Header className={styles.header}>
        <CloseButton position="top" onClick={onHide} />
        <Modal.Title className={styles.header__title}>
          افزودن فیلم و سریال
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <div className={styles.input}>
          <Input
            placeholder="جستجوی فیلم و سریال"
            onChange={(e) => setName(e.target.value)}
            value={name}
            image={{
              enable: true,
              icon: "/images/icons/search.svg",
              clickHandler: refetch,
            }}
          />
        </div>
        {isFetching && (
          <div className={styles.loading}>
            <Spinner animation="border" as="i" />
          </div>
        )}
        <div className={styles.cardWrapper}>
          {data?.results?.length > 0 &&
            data.results.map((item) => (
              <div key={item.id} className={styles.card}>
                {item.cover.length ? (
                  <img
                    loading="lazy"
                    src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${item.cover}`}
                    alt=""
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
                    alt=""
                  />
                )}
                <div>
                  <span>{item.title}</span>
                  <span>سال انتشار: {item.release.slice(0, 4)}</span>
                </div>
                <button
                  onClick={() =>
                    handlePostRecommended(
                      item.search_type === "movie" ? "movie" : "series",
                      item.id
                    )
                  }
                  className="btn">
                  انتخاب {item.search_type === "movie" ? "فیلم" : "سریال"}
                </button>
              </div>
            ))}
        </div>
      </Modal.Body>
    </div>
  );
}
