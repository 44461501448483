import videojs from "video.js";

const MenuItem = videojs.getComponent("MenuItem");
const Component = videojs.getComponent("Component");

class SettingsMenuItem extends MenuItem {
  constructor(player, options) {
    options.label = options.source.res.toString() + "p";
    options.selectable = true;
    options.selected = options.source.default;
    super(player, options);
    this.item_source = options.source;
    this.playlistItem = options.playlistItem;
  }

  handleClick(e) {
    super.handleClick(e);
    let source = this.item_source;
    const currentTime = this.player().currentTime();
    this.player().pause();
    this.player().src(source);
    this.player().currentTime(currentTime);
    this.player().play();
    this.parentComponent_.children_.forEach((child) => {
      child.selected(false);
    });
    this.selected(true);
    if (this.playlistItem !== null) {
      this.player().one("ended", () => {
        this.player().playlist.currentItem(this.playlistItem + 1);
      });
    }
  }

  dispose() {
    this.item_source = null;
    super.dispose();
  }
}

Component.registerComponent("SettingsMenuItem", SettingsMenuItem);
export default SettingsMenuItem;
