import { useState } from "react";
import { uniqueId } from "lodash-es";
import { Modal, Table, Spinner } from "react-bootstrap";
import { useExpertsQuery } from "hooks/query/useExpertQuery";
import AddExpertModal from "./addExpertModal/AddExpertModal";
import Row from "./row/Row";
import Paginate from "components/common/paginate/Paginate";
import styles from "./Experts.module.scss";

function Experts() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = useExpertsQuery(currentPage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onOpen = () => {
    setIsModalOpen(true);
  };
  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles[`wrapper`]}>
        <Table className={styles[`table`]}>
          <thead>
            <tr className={styles[`head-row`]}>
              <th>نام کارشناسان</th>
              <th>سمت</th>
              <th>تحصیلات</th>
              <th>
                شبکه های اجتماعی
                <button
                  className={`${styles[`button`]} ${styles[`button--primary`]}`}
                  onClick={onOpen}>
                  <img src={"/images/categories/add-square.svg"} alt="" />
                  <span>افزودن کارشناس</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {isPreviousData ? (
              <div className="spinnerCenter">
                <Spinner animation="border" as="i" />
              </div>
            ) : (
              data?.results?.map((item) => (
                <Row key={uniqueId("expert-")} data={item} />
              ))
            )}
          </tbody>
        </Table>
      </div>
      <div className="paginationWrapper">
        {data?.results.length > 0 && (
          <Paginate
            type="experts"
            paginateState={[currentPage, setCurrentPage]}
            total={data?.pagination.count}
          />
        )}
      </div>
      <Modal
        show={isModalOpen}
        onHide={onClose}
        dialogClassName={styles[`modal`]}
        centered>
        <AddExpertModal onHide={onClose} type="create" />
      </Modal>
    </>
  );
}

export default Experts;
