import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const TooltipMenu = ({ children, text }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="menu">
      {text}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  );
};

export default TooltipMenu;
