import http from "../httpService";
export const postArticle = (articlePayload) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/articles/new`,
    articlePayload
  );
};
export const putArticle = (articlePayload) => {
  return http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/articles/update`,
    articlePayload
  );
};
export const deleteArticle = (articleId) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/articles/delete?id=${articleId}`
  );
};
export const getArticle = (articleId, signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/articles/get-by-token?id=${articleId}`,
    {
      signal,
    }
  );
};
export const getArticles = (page, signal) => {
  return http.get(
    `${
      window.__RUNTIME_CONFIG__.REACT_APP_API
    }/articles/get-all-by-token?page=${page ? page : ""}&limit=10`,
    { signal }
  );
};
