import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import styles from './Components.module.scss';

export const CloseButton = ({ position, onClick }) => (
	<div className={`${styles.close} ${styles[position]}`} onClick={onClick}>
		<img
			src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/close.svg`}
			alt=""
		/>
	</div>
);

export const CardDivider = ({ position, fill, isModal }) => (
	<div
		className={`${isModal ? styles.modalDivider : styles.divider} ${
			styles[position]
		}`}
	>
		<div className={styles.imageWrapper}>
			<svg
				width="300"
				height="3"
				viewBox="0 0 300 3"
				fill={fill}
				xmlns="http://www.w3.org/2000/svg"
			>
				<line
					x1="1.64209"
					y1="1.5"
					x2="358.642"
					y2="1.50002"
					stroke={fill}
					strokeWidth="3"
					strokeLinecap="round"
					strokeDasharray="25 10"
				/>
			</svg>
		</div>
	</div>
);

export const Switch = ({ text, toggleState }) => {
	const [toggle, setToggle] = toggleState;

	return (
		<div className={styles.switchWrapper} onClick={() => setToggle(!toggle)}>
			<div className={`${styles.switch} ${toggle ? styles.on : styles.off}`}>
				<div className={styles.switchDot} />
			</div>

			<label>{text}</label>
		</div>
	);
};

export const FeatureInput = ({ data, handleRemove, ...textareaProps }) => {
	return (
		<div className={styles.featureInput}>
			<textarea placeholder="ویژگی بسته را وارد کنید" {...textareaProps} />

			<button onClick={handleRemove}>
				<img
					src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/trash.svg`}
					alt=""
				/>
			</button>
		</div>
	);
};

export const InputWithUnit = ({ units, unitState, ...inputProps }) => {
	const [isToggled, setIsToggled] = useState(false);
	const [selectedUnit, setSelectedUnit] = unitState;

	return (
		<div className={styles.inputWrapper}>
			<input type="number" {...inputProps} />

			<Dropdown
				className={styles.dropdown}
				show={isToggled}
				onToggle={e => setIsToggled(e)}
			>
				<Dropdown.Toggle
					className={`${styles.toggle} ${isToggled ? styles.open : styles.closed}`}
				>
					{selectedUnit?.name}
					<img
						src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/arrow.svg`}
						alt=""
					/>
				</Dropdown.Toggle>
				<Dropdown.Menu className={styles.menu}>
					{units?.map(item => (
						<Dropdown.Item
							as="div"
							key={item.id}
							onClick={() => setSelectedUnit(item)}
						>
							{item.name}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};
