import { useParams } from "react-router";
import { useCollectionQuery } from "hooks/query/useCollectionQuery";
import { Table } from "react-bootstrap";
import Header from "./header/Header";
import CollectionMoviesRow from "./collectionMoviesRow/CollectionMoviesRow";
import EmptyState from "../../common/emptyState/EmptyState";
import styles from "./CollectionMovies.module.scss";

function CollectionMovies() {
  const { id } = useParams();
  const { data } = useCollectionQuery(id);

  return (
    <>
      <Header title={data?.title} id={id} />
      {data?.movies.length > 0 ? (
        <div className={styles.tableWrapper}>
          <Table className={styles.table} responsive>
            <thead>
              <tr className={styles.THead}>
                <th className={styles.customHeader}>پوستر و عنوان کالکشن</th>
                <th>ردیف ({data.movies.length} مورد)</th>
                <th>سال انتشار</th>
                <th>زمان افزودن فیلم به کالکشن</th>
                <th>وضعیت انتشار</th>
                <th>عملیات</th>
              </tr>
            </thead>
            <tbody>
              {data.movies.map((item, index) => (
                <CollectionMoviesRow key={item.id} data={item} index={index} />
              ))}
            </tbody>
          </Table>
          {/* <button className={`${styles.showMoreBtn} btn`}>
				<img src={`${process.env.PUBLIC_URL}/images/common/plus.svg`} alt="+" />
				مشاهده بیشتر
			</button> */}
        </div>
      ) : (
        <EmptyState
          subHeader
          // buttonOptions={{
          //   link: "/collections/addCollection",
          //   text: "افزودن فیلم",
          //   icon: "/images/common/plus.svg",
          //   class: true,
          // }}
        >
          <p>شما هنوز فیلمی به کالکشن {data?.title} اضافه نکرده اید</p>
        </EmptyState>
      )}
    </>
  );
}

export default CollectionMovies;
