import { useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Notification.module.scss";

export default function Notification() {
  // const [notificationClassName, setNotificationClassName] = useState(true);
  // const mouseOn = () => {
  //   setNotificationClassName(false);
  // };
  // const mouseOut = () => {
  //   setNotificationClassName(true);
  // };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="header">
      اعلان ها
    </Tooltip>
  );

  return (
    <Dropdown>
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}>
        <Dropdown.Toggle
          variant="link"
          id="dropdown-basic"
          className={styles.Btn}>
          <img
            src={`${process.env.PUBLIC_URL}/images/topBar/notification.svg`}
            alt=""
            className={styles.notificationImg}
          />
        </Dropdown.Toggle>
      </OverlayTrigger>
      <Dropdown.Menu className={styles.notificationMenu}>
        <Dropdown.Item className={styles.notificationItem}>
          <span className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/topBar/alert-active.svg`}
              alt="notification-sign"
            />
          </span>
          <span className={styles.description}>
            شما یک دعوتنامه به پروژه جدید دریافت کرده اید
          </span>
        </Dropdown.Item>
        <Dropdown.Item className={styles.notificationItem}>
          <span className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/topBar/alert-active.svg`}
              alt="notification-sign"
            />
          </span>
          <span className={styles.description}>پروژه طرفداری ایجاد شد</span>
        </Dropdown.Item>
        <Dropdown.Item className={`${styles.notificationItem} ${styles.read}`}>
          <span className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/topBar/alert.svg`}
              alt="notification-sign"
            />
          </span>
          <span className={styles.description}>
            محتوای مورد نظر شما بارگذاری شد
          </span>
        </Dropdown.Item>
        <Dropdown.Item className={`${styles.notificationItem} ${styles.read}`}>
          <span className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/topBar/alert.svg`}
              alt="notification-sign"
            />
          </span>
          <span className={styles.description}>
            یک دعوتنامه به پروژه سی نما دریافت کرده اید
          </span>
        </Dropdown.Item>
        <Dropdown.Item className={`${styles.notificationItem} ${styles.read}`}>
          <span className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/topBar/alert.svg`}
              alt="notification-sign"
            />
          </span>
          <span className={styles.description}> تمدید بسته کاربری</span>
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to="/overView"
          // to="/notification"
          className={styles.notificationItemLast}>
          <span className={styles.descriptionCustom}>مشاهده همه پیام ها</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
