import styles from "./Wallet.module.scss";

export default function Wallet() {

  return (
    <div className={styles.wrapper}>
      Wallet
    </div>
  );
}
