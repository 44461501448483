import { useState } from "react";
import { useCollectionsQuery } from "hooks/query/useCollectionQuery";
import EmptyState from "../common/emptyState/EmptyState";
import CollectionList from "./collectionList/CollectionList";
import Header from "./header/Header";
import Paginate from "components/common/paginate/Paginate";
import styles from "./Collections.module.scss";

function Collections() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = useCollectionsQuery(currentPage, 10);

  return (
    <>
      <section className={data?.results?.length ? styles.section : ""}>
        {data?.results?.length ? (
          <>
            <Header data={data?.results} />
            <CollectionList
              data={data?.results}
              isPreviousData={isPreviousData}
            />
          </>
        ) : (
          <EmptyState
            buttonOptions={{
              link: "/collections/addCollection",
              text: "افزودن کالکشن",
              icon: "/images/menu/collections.svg",
              class: true,
            }}>
            <p>شما هنوز کالکشنی به پروژه خود اضافه نکرده اید</p>
          </EmptyState>
        )}
        <div className="paginationWrapper">
          {data?.results?.length > 0 && (
            <Paginate
              type="collections"
              paginateState={[currentPage, setCurrentPage]}
              total={data?.pagination.count}
            />
          )}
        </div>
      </section>
    </>
  );
}

export default Collections;
