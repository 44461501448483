import { useState } from "react";
import { Modal } from "react-bootstrap";
import NewTicket from "../new-ticket";
import styles from "./styles.module.scss";
export default function Header({ tabState }) {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = tabState;
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.tabs}>
          <div
            onClick={() => setActiveTab("all")}
            className={`${styles.tab} ${
              activeTab === "all" ? styles.activeTab : ""
            }`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/directbox-default.svg`}
              alt=""
            />
            <p>
              <span>همه تیکت ها</span>
              <span>15 تیکت</span>
            </p>
          </div>
          <div
            onClick={() => setActiveTab("open")}
            className={`${styles.tab} ${
              activeTab === "open" ? styles.activeTab : ""
            }`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/messages.svg`}
              alt=""
            />
            <p>
              <span>تیکت های باز</span>
              <span>8 تیکت</span>
            </p>
          </div>
          <div
            onClick={() => setActiveTab("in-progress")}
            className={`${styles.tab} ${
              activeTab === "in-progress" ? styles.activeTab : ""
            }`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/sms-tracking.svg`}
              alt=""
            />
            <p>
              <span>در حال بررسی</span>
              <span>3 تیکت</span>
            </p>
          </div>
          <div
            onClick={() => setActiveTab("answered")}
            className={`${styles.tab} ${
              activeTab === "answered" ? styles.activeTab : ""
            }`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/messages-2.svg`}
              alt=""
            />
            <p>
              <span>پاسخ داده شده</span>
              <span>2 تیکت</span>
            </p>
          </div>
          <div
            onClick={() => setActiveTab("closed")}
            className={`${styles.tab} ${
              activeTab === "closed" ? styles.activeTab : ""
            }`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/close-square.svg`}
              alt=""
            />
            <p>
              <span>بسته شده</span>
              <span>2 تیکت</span>
            </p>
          </div>
        </div>
        <button
          onClick={() => setShowModal(true)}
          className={`${styles.greenBtn} btn`}>
          ارسال تیکت جدید
        </button>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={styles.modal}
        centered>
        <NewTicket onHide={() => setShowModal(false)} />
      </Modal>
    </>
  );
}