import { useState } from 'react';
import { Form } from 'react-bootstrap';
import SingleFileUploader from '../../../common/SingleFileUploader/SingleFileUploader';
import TextArea from '../../../common/textArea/TextArea';
import styles from './GeneralInfo.module.scss';

export default function GeneralInfo() {
	const [textAreaState, setTextAreaState] = useState('');

	return (
		<section className={styles.section}>
			<SingleFileUploader
				title="بارگذاری تصویر پروژه"
				defaultImg="/images/common/blue-default.svg"
				// imageState={[reduxData.file, this.setImages]}
			/>
			<Form.Group className={styles.group}>
				<label className={styles.label}>
					عنوان پروژه <span className={styles.asterisk}>*</span>
				</label>
				<input
					type="text"
					className={`form-control ${styles.formControl}`}
					placeholder="عنوان پروژه را وارد نمایید"
					name="fullname"
					//   value={reduxData.title}
					//   onChange={(e) => this.handleTitleChange(e)}
					//   onBlur={this.blurInput}
				/>
			</Form.Group>

			<TextArea
				label="توضیحات"
				placeholder="توضیحات کلی مربوط به پروژه را در صورت نیاز وارد نمایید"
				onChange={e => setTextAreaState(e.target.value)}
				// textAreaState={[
				//   reduxData.description,
				//   (e) => this.handleTextAreaChange(e),
				// ]}
			/>
		</section>
	);
}
