import styles from "./LiveSection.module.scss";
export default function LiveSection() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.wrapperContent}>
        <figure className="image-cover">
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/live.svg`}
            alt="Live Section"
          />
        </figure>
        <div className={styles.info}>
          <h1 className="text__black">پخش بدون وقفه، بدون توجه به لوکیشن</h1>
          <p className="text__black">
            پلتفرم پخش زنده ایمن آریا بصورت یکپارچه و بر بستر CDN پیاده سازی شده
            است، بنابراین بدون اثرپذیری از فاکتور مکانی و با کمترین تأخیر و در
            سریع ترین حالت ممکن، پخش زنده را نمایش میدهد
          </p>
        </div>
      </div>
    </section>
  );
}