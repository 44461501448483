import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMoviesQuery } from "hooks/query/useMovieQuery";
import { useHistory } from "react-router";
import { fetchMovieFromImdb } from "../../../redux/actions/boxOffice";
import { usePutBoxOfficeMovieQuery } from "hooks/query/useBoxOfficeQuery";
import toasts from "../../common/toasts/toasts";
import CDropdown from "../../common/dropDown/DropDown";
import RedSwitch from "../../common/redSwitch/RedSwitch";
import DotDropdown from "../dotDropdown/DotDropdown";
import styles from "./Card.module.scss";

function Card({ data, isModal }) {
  const history = useHistory();
  const { data: moviesList } = useMoviesQuery();
  const { mutate: putBoxOfficeMovie } = usePutBoxOfficeMovieQuery();
  const dispatch = useDispatch();

  const [isMovieAvailable, setIstAvailable] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(null);

  useEffect(() => {
    if (data && moviesList?.results.length) {
      setIstAvailable(!!data.movie_id);
      setDropDownValue(
        !!data?.movie_id
          ? moviesList?.results.find((item) => item.id === data.movie_id)
          : null //* NOTE: falsy value for resetting movie_id in backend
      );
    }
  }, [data, moviesList]);

  const onChangeSwitch = () => {
    //* NOTE: if the switch turns from available (true) to not available (false) reset the movie id to null
    if (isMovieAvailable) {
      setDropDownValue(null);
      handlePutMovieId(null);
    }

    setIstAvailable(!isMovieAvailable);
  };

  const onChangeDropDown = (option) => {
    setDropDownValue(option);
    handlePutMovieId(option.id);
  };

  const handlePutMovieId = (movieId) => {
    const { directors, genres, movie_id, ...rest } = data;

    const payload = {
      ...rest,
      directors: directors,
      genres: genres,
      movie_id: movieId,
    };

    putBoxOfficeMovie(payload);
    dropDownValue &&
      toasts.success(`فیلم باکس آفیس ${data.title} به فیلم مورد نظر متصل شد.`);
  };

  const handleAddMovieFromBoxOffice = () => {
    const redirectionCb = () => history.push("/movies/addMovies");
    dispatch(fetchMovieFromImdb(data.imdb_id, redirectionCb));
  };

  return (
    <div className={styles.card}>
      <div
        className={`${styles.col} ${
          isModal ? styles.smallImage : styles.largeImage
        }`}>
        <img
          loading="lazy"
          src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
          alt=""
        />
      </div>
      <div className={styles.col}>
        <div className={styles.flexRow}>
          <h2>{data.title}</h2>

          {!isModal && <DotDropdown data={data} />}
        </div>

        <div className={styles.sellInfoWrapper}>
          <div>
            کل فروش: <span>{data.gross}</span>
          </div>
          <div>
            فروش آخر هفته: <span>{data.weekend}</span>
          </div>
          <div>
            هفته: <span>{data.weeks}</span>
          </div>
          <div>
            کارگردان:{" "}
            <span>{data.directors?.map((item) => item.name).join(" ,")}</span>
          </div>
        </div>

        {!isModal && (
          <div className={styles.flexRow}>
            <RedSwitch
              label={
                isMovieAvailable ? "در سایت وجود دارد" : "در سایت وجود ندارد"
              }
              isActive={isMovieAvailable}
              onToggle={onChangeSwitch}
            />

            {isMovieAvailable ? (
              <CDropdown
                placeholder="انتخاب از لیست فیلم ها"
                options={moviesList?.results}
                value={dropDownValue}
                getOptionLabel={(option) => option["title"]}
                getOptionValue={(option) => option["id"]}
                onChange={onChangeDropDown}
              />
            ) : (
              <button
                className={`btn ${styles.button}`}
                onClick={handleAddMovieFromBoxOffice}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/white-add-square.svg`}
                  alt=""
                />
                <span>افزودن به سایت</span>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Card;
