import { useDispatch, useSelector } from "react-redux";
import {
  resetPasswordChange,
  resetProfile,
  updatePasswordChange,
} from "../../../../redux/actions/profile";
import { useHistory } from "react-router-dom";
import { changePassword } from "../../../../services/profile/profileServices";
import { Input } from "../../../common/input/Input";
import cookie from "react-cookies";
import toasts from "../../../common/toasts/toasts";
import styles from "./ChangePass.module.scss";

function ChangePass() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { passwordInfo } = useSelector((state) => state.profileReducer);

  const setPassword = (e) =>
    dispatch(updatePasswordChange({ newPass: e.target.value }));
  const setCurrentPassword = (e) =>
    dispatch(updatePasswordChange({ currentPass: e.target.value }));
  const setRepass = (e) =>
    dispatch(updatePasswordChange({ rePass: e.target.value }));
  const skipKeyPressSpace = (e) => {
    if (e.charCode === 32 || e.which === 32) {
      e.preventDefault();
    }
  };

  const handleChangePassword = async () => {
    if (passwordInfo.currentPass.length) {
      if (passwordInfo.newPass === passwordInfo.rePass) {
        try {
          const passObj = {
            old: passwordInfo.currentPass,
            new: passwordInfo.newPass,
          };

          const { status } = await changePassword(passObj);

          if (status === 200) {
            toasts.success("رمز عبور شما بروز شد");
            dispatch(resetPasswordChange());
          }
        } catch (err) {
          if (err.response?.status === 400) {
            toasts.error("رمز عبور فعلی شما صحیح وارد نشده است");
          } else {
            toasts.error("مشکلی برای تغییر رمز عبور پیش آمده");
          }
          console.error(err);
        }
      } else {
        toasts.error("رمز جدید با تکرار آن مطابقت ندارد");
      }
    }
  };

  const handleLogout = () => {
    cookie.remove("token");
    dispatch(resetProfile());
    history.replace("/");
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.top}>
        <Input
          label="رمز عبور فعلی"
          placeholder="رمز عبور فعلی خود را وارد کنید."
          type="password"
          require
          value={passwordInfo.currentPass}
          onChange={(e) => setCurrentPassword(e)}
          onKeyPress={(e) => skipKeyPressSpace(e)}
          autoComplete="new-password"
        />
        <Input
          label="رمز عبور جدید"
          placeholder="رمز عبور جدید را وارد کنید."
          type="password"
          require
          value={passwordInfo.newPass}
          onChange={(e) => setPassword(e)}
          onKeyPress={(e) => skipKeyPressSpace(e)}
          autoComplete="new-password"
        />
        <Input
          label="تکرار رمز عبور جدید"
          placeholder="تکرار رمز عبور جدید را وارد کنید."
          type="password"
          require
          value={passwordInfo.rePass}
          onChange={(e) => setRepass(e)}
          onKeyPress={(e) => skipKeyPressSpace(e)}
          autoComplete="new-password"
        />
      </div>
      <div className={styles.bottom}>
        <div className={styles.save} onClick={handleChangePassword}>
          تغییر رمز عبور
        </div>
        <div className={styles.logout} onClick={handleLogout}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/exit.svg`}
            alt="خروج"
          />
          خروج از پروفایل
        </div>
      </div>
    </section>
  );
}

export default ChangePass;
