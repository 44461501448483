import { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  useDeleteRecommendedMoviesQuery,
  useDeleteRecommendedSeriesQuery,
  useRecommendedMoviesQuery,
  useRecommendedSeriesQuery,
} from "hooks/query/useRecommendedQuery";
import RecommendModal from "./recommendModal/RecommendModal";
import styles from "./Recommended.module.scss";

export default function Recommended() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: moviesList } = useRecommendedMoviesQuery();
  const { data: seriesList } = useRecommendedSeriesQuery();
  const { mutate: deleteRecommendedMovies } = useDeleteRecommendedMoviesQuery();
  const { mutate: deleteRecommendedSeries } = useDeleteRecommendedSeriesQuery();

  return (
    <>
      <section className={styles.wrapper}>
        {moviesList?.length > 0 &&
          moviesList.map((item) => (
            <div key={item.id} className={styles.card}>
              <div className={styles.firstCol}>
                {item.movie?.cover?.length ? (
                  <img
                    loading="lazy"
                    src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${item.movie.cover}`}
                    alt=""
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
                    alt=""
                  />
                )}
                <span>
                  {item.movie?.title}
                  <br />
                  {item.movie?.title_in_persian}
                </span>
              </div>
              <div className={styles.secondCol}>
                <span>فیلم</span>
              </div>
              <img
                onClick={() => deleteRecommendedMovies(item.id)}
                className={styles.trash}
                src={`${process.env.PUBLIC_URL}/images/articles/trash.svg`}
                alt=""
              />
            </div>
          ))}
        {seriesList?.length > 0 &&
          seriesList.map((item) => (
            <div key={item.id} className={styles.card}>
              <div className={styles.firstCol}>
                {item.series.cover?.length ? (
                  <img
                    loading="lazy"
                    src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${item.series.cover}`}
                    alt=""
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
                    alt=""
                  />
                )}
                <span>
                  {item.series.title}
                  <br />
                  {item.series.title_in_persian}
                </span>
              </div>
              <div className={styles.secondCol}>
                <span>سریال</span>
              </div>
              <img
                onClick={() => deleteRecommendedSeries(item.id)}
                className={styles.trash}
                src={`${process.env.PUBLIC_URL}/images/articles/trash.svg`}
                alt=""
              />
            </div>
          ))}
        <div onClick={() => setIsModalOpen(true)} className={styles.addCard}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/video-add.svg`}
            alt=""
          />
          <span>افزودن فیلم و سریال</span>
        </div>
      </section>
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        dialogClassName={styles.modal}
        centered>
        <RecommendModal onHide={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );
}
