export const addTranscodeToQueue = (data) => ({
	type: "ADD_TRANSCODE_QUEUE",
	payload: data,
});

export const removeTranscodeFromQueue = (id) => ({
	type: "REMOVE_TRANSCODE_FROM_QUEUE",
	payload: id,
});

export const updateTranscode = ({ id, resolution, transcodeResponse }) => ({
	type: "UPDATE_TRANSCODE",
	payload: { id, resolution, transcodeResponse },
});
