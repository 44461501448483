import http from "../httpService";

//Movies
export const getRecommendedMovies = (signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/recommended/movies/list`,
    {
      signal,
    }
  );
export const postRecommendedMovies = (data) =>
  http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/recommended/movies/new`,
    data
  );
export const deleteRecommendedMovies = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/recommended/movies/delete?id=${id}`
  );

// Series
export const getRecommendedSeries = (signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/recommended/series/list`,
    {
      signal,
    }
  );
export const postRecommendedSeries = (data) =>
  http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/recommended/series/new`,
    data
  );
export const deleteRecommendedSeries = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/recommended/series/delete?id=${id}`
  );
