import { useState } from "react";
import { useSupportsQuery } from "hooks/query/useSupportQuery";
import { Spinner, Table } from "react-bootstrap";
import Paginate from "components/common/paginate/Paginate";
import Row from "./row/Row";
import styles from "./Support.module.scss";

function Support() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = useSupportsQuery(currentPage);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.pill}>
          <Table className={styles.table} responsive>
            <thead>
              <tr>
                <th>نام کاربر</th>
                <th>شماره تماس</th>
                <th>ایمیل</th>
                <th>سطح اهمیت</th>
                <th>تاریخ</th>
                <th>عنوان</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isPreviousData ? (
                <div className="spinnerCenter">
                  <Spinner animation="border" as="i" />
                </div>
              ) : (
                data?.results?.map((item) => <Row key={item.id} data={item} />)
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="paginationWrapper">
        {data?.results.length > 0 && (
          <Paginate
            type="support"
            paginateState={[currentPage, setCurrentPage]}
            total={data?.pagination.count}
          />
        )}
      </div>
    </>
  );
}

export default Support;
