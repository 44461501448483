import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import styles from "./TableCheckBox.module.scss";

function TableCheckBox({ checkBoxState, place }) {
  const [checked, setChecked] = checkBoxState;

  const placeClassName = (place) => {
    switch (place) {
      case "tableHeader":
        return styles.tableHeader;
      case "tableRow":
        return styles.tableRow;

      case "coloredTableHeader":
        return styles.coloredTableHeader;
      case "coloredTableRow":
        return styles.coloredTableRow;

      default:
        return styles.default;
    }
  };

  return (
    <Form.Group className={styles.group} onClick={() => setChecked(!checked)}>
      <Form.Check
        className={`${styles.checkBox} ${placeClassName(place)}`}
        onChange={() => setChecked(!checked)}
        checked={checked}
        type="checkbox"
        // custom
      />
    </Form.Group>
  );
}

TableCheckBox.propType = {
  checkBoxState: PropTypes.array.isRequired,
  place: PropTypes.oneOf([
    "tableHeader",
    "tableRow",
    "coloredTableHeader",
    "coloredTableRow",
  ]),
};

export default TableCheckBox;
