import React, { Fragment } from "react";
import { ToastContainer, Slide } from "react-toastify";
import HeadTag from "./HeadTag";

function EmptyLayout({ children, ...props }) {
  // temporary
  const wrapper = {
    width: "100%",
    height: "100vh",
    background: "#F6F9FB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Fragment>
      <HeadTag />
      <section style={wrapper}>{children}</section>
      <ToastContainer
        position="top-center"
        hideProgressBar
        transition={Slide}
        closeOnClick={false}
      />
    </Fragment>
  );
}

export default EmptyLayout;
