import Select, { components } from "react-select";
import { newReactSelectStyle } from "util/reactSelect";
import Form from "react-bootstrap/Form";
import styles from "./styles.module.scss";

function DropDown({ label, options, placeholder, ...props }) {
  const Option = ({ data, ...props }) => (
    <components.Option {...props}>
      <span>{props.label}</span>
    </components.Option>
  );
  const SingleValue = ({ data, ...props }) => (
    <components.SingleValue {...props}>
      <span>{props.children}</span>
    </components.SingleValue>
  );
  return (
    <Form.Group>
      {label && <Form.Label className={styles.label}>{label}</Form.Label>}
      <Select
        styles={newReactSelectStyle}
        placeholder={placeholder}
        options={options}
        components={{
          Option,
          SingleValue,
        }}
        classNamePrefix="select"
        hideSelectedOptions
        isRtl
        isClearable
        {...props}
      />
    </Form.Group>
  );
}
export default DropDown;