import React, { useEffect } from "react";
import { useSingleImageDropZone } from "../../../../hooks/dropzone";

import styles from "./AlbumCover.module.scss";

function AlbumCover({ initialImage, onImageChange, isDisable }) {
	const { getRootProps, getInputProps, imageFile, imagePreview } =
		useSingleImageDropZone(initialImage);

	useEffect(() => {
		onImageChange(imageFile);
	}, [imageFile, onImageChange]);

	const rootPropsConfig = {
		className: styles.dropzoneWrapper,
	};

	return (
		<div
			className={`${styles.uploaderWrapper} ${
				imagePreview ? styles.hasImage : styles.noImage
			}`}
		>
			{imagePreview ? (
				<>
					<div {...getRootProps(rootPropsConfig)}>
						<img src={imagePreview} alt="" />
						<input {...getInputProps({ disabled: isDisable })} />

						{!isDisable && (
							<button className={`btn ${styles.editButton}`}>
								ویرایش تصویر
							</button>
						)}
					</div>
				</>
			) : (
				<div {...getRootProps(rootPropsConfig)}>
					<input {...getInputProps({ disabled: isDisable })} />

					<div className={styles.section}>
						<img
							src={`${process.env.PUBLIC_URL}/images/common/gallery.svg`}
							alt=""
						/>
						<p>افزودن تصویر کاور</p>
					</div>
				</div>
			)}
		</div>
	);
}

export default AlbumCover;
