export const resolutionsConstants = [
  {
    id: "240p",
    name: "240p",
  },
  {
    id: "360p",
    name: "360p",
  },
  {
    id: "480p",
    name: "480p",
  },
  {
    id: "720p",
    name: "720p",
  },
  {
    id: "1080p",
    name: "1080p",
  },
];
export const watermarkPositionConstants = [
  {
    id: "up_left",
    name: "بالا چپ",
  },
  {
    id: "up_center",
    name: "بالا وسط",
  },
  {
    id: "up_right",
    name: "بالا راست",
  },
  {
    id: "bottom_right",
    name: "پایین راست",
  },
  {
    id: "bottom_center",
    name: "پایین وسط",
  },
  {
    id: "bottom_left",
    name: "پایین چپ",
  },
];
export const fpsConstants = [
  {
    id: "24",
    name: "24",
  },
  {
    id: "25",
    name: "25",
  },
  {
    id: "29",
    name: "29",
  },
  {
    id: "30",
    name: "30",
  },
  {
    id: "60",
    name: "60",
  },
  {
    id: "120",
    name: "120",
  },
];
export const liveStreamSteps = [
  {
    text: "اطلاعات پخش",
  },
  {
    text: "خروجی تصویر",
  },
  {
    text: "بررسی نهایی",
  },
];