import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import {
  calculateNumberOfRows,
  createMultiSelectStyle,
} from "../../../util/reactSelect";
import NeedPremium from "../modals/needPremium/NeedPremium";
import styles from "./TagMultiSelect.module.scss";

//TODO: find better names for types

//* using types for 3 kind of selecting

function TagMultiSelect({
  label,
  options,
  placeholder,
  selectedOptions,
  handleDelete,
  optionFlags: { title, value },
  type = "first",
  isPremium,
  ...props
}) {
  const selectRef = useRef(null);

  const [rows, setRows] = useState(0);

  const [showPremiumModal, setShowPremiumModal] = useState(false);

  useEffect(() => {
    const { current } = selectRef;

    const hasSelectedOptions = selectedOptions ? true : false;
    const isSelectedOptionEmpty =
      selectedOptions === [] || selectedOptions === null;

    if (current) {
      const totalWidthOfContainer = current.querySelector(
        ".select__value-container"
      ).offsetWidth;

      const calculationCondition = hasSelectedOptions && !isSelectedOptionEmpty;

      const calculatedRows =
        calculationCondition &&
        calculateNumberOfRows(
          selectedOptions.length,
          totalWidthOfContainer,
          210
        );

      setRows(calculatedRows);
    }
  }, [selectRef, selectedOptions]);

  // Premium
  const handleIsPremium = () => {
    if (selectedOptions.length > 1 && isPremium) {
      setShowPremiumModal(true);
    } else {
      return;
    }
  };
  useEffect(() => {
    handleIsPremium();
  }, [selectedOptions]);

  // react select Components
  const Option = ({ data, ...props }) => (
    <components.Option {...props} className={styles.option}>
      {data.avatar && (
        <span className={styles.pic}>
          {data.avatar.length ? (
            <img
              loading="lazy"
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.avatar}`}
              alt={data.name}
            />
          ) : (
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/images/person.svg`}
              alt={data.name}
            />
          )}
        </span>
      )}

      <span className={styles.optionTitle}>
        {data[title]}{" "}
        {type === "project" && !data?.avatar && (
          <img
            className={styles.project}
            src={`${process.env.PUBLIC_URL}/images/movies/movie2.svg`}
            alt=""
          />
        )}
      </span>
      {type === "project" && !data?.avatar && (
        <div>
          <img
            className={styles.crown}
            src={`${process.env.PUBLIC_URL}/images/topBar/crown.svg`}
            alt="Crown"
          />
        </div>
      )}

      {data.year && (
        <button className={`${styles.selectAddBtn} btn`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/addPlus.svg`}
            alt=""
          />
        </button>
      )}

      {data.color && (
        <span
          className={styles.circle}
          style={{ backgroundColor: data.color }}></span>
      )}
    </components.Option>
  );
  const MultiValueRemove = ({ ...props }) => (
    <components.MultiValueRemove {...props}>
      {(type === "first" || type === "project") && (
        <img
          onClick={() => (handleDelete ? handleDelete(value) : null)} // just for preventing error if we didn't pass any delete func
          src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
          alt=""
          className={styles.trash}
        />
      )}
      {type === "second" && (
        <img
          onClick={() => (handleDelete ? handleDelete(value) : null)} // just for preventing error if we didn't pass any delete func
          src={`${process.env.PUBLIC_URL}/images/common/close-blue.svg`}
          alt=""
          className={styles.remove}
        />
      )}
    </components.MultiValueRemove>
  );
  const MultiValueLabel = ({ data, ...props }) => (
    <components.MultiValueLabel {...props}>
      {data.color && (
        <span
          className={styles.circle}
          style={{ backgroundColor: data.color }}></span>
      )}

      {data.avatar && (
        <span className={styles.pic}>
          {data.avatar.length ? (
            <img
              loading="lazy"
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.avatar}`}
              alt={data.name}
            />
          ) : (
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/images/person.jpg`}
              alt={data.name}
            />
          )}
        </span>
      )}

      <span
        className={
          type === "first" || type === "project"
            ? `${styles.title}`
            : `${styles.title} ${styles.blue}`
        }>
        {type === "project" && !data?.avatar && (
          <img
            className={styles.project}
            src={`${process.env.PUBLIC_URL}/images/movies/movie2.svg`}
            alt=""
          />
        )}
        {data[title]}
      </span>
    </components.MultiValueLabel>
  );

  // add new option for type: Project
  const allProjects = [
    {
      id: 0,
      title: "همه پروژه ها",
      avatar: "images/movies/allProjects.svg",
    },
  ];

  return (
    <>
      <div className={styles.wrapper} ref={selectRef}>
        <label className={styles.label}>{label}</label>
        <Select
          placeholder={placeholder}
          styles={createMultiSelectStyle(
            rows,
            type === "first" || type === "project" ? 210 : ""
          )}
          options={type == "project" ? [...allProjects, ...options] : options}
          value={selectedOptions}
          components={{
            Option,
            MultiValueRemove,
            MultiValueLabel,
          }}
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          isRtl
          {...props}
        />
      </div>
      {showPremiumModal && (
        <Modal
          show={showPremiumModal}
          onHide={() => setShowPremiumModal(false)}
          dialogClassName={styles.premiumModal}>
          <NeedPremium onHide={() => setShowPremiumModal(false)} />
        </Modal>
      )}
    </>
  );
}

export default TagMultiSelect;
