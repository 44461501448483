import { Fragment, useEffect, useState } from "react";
import { convertMilliSecondToMinutes } from "../../../util/millisecondConvertor";
import { getNumberOnly } from "../../../util/number";
import { resetPasswordWithMobileConfirm } from "services/entry/entryService";
import CustomInput from "../../common/customInput/CustomInput";
import toasts from "components/common/toasts/toasts";
import styles from "../ResetPassword.module.scss";

const BASE_MILLISECOND = 120;

function OtpSection({ showNextSection, RecoverEntity }) {
  const [timeCounter, setTimeCounter] = useState(BASE_MILLISECOND);
  const [classValidationInput, setClassValidationInput] = useState("");
  const [otp, setOtp] = useState("");

  const isValidInput = () => {
    if (otp !== "") {
      setClassValidationInput("verify");
      return true;
    }
    setClassValidationInput("danger");
    toasts.error("کد را وارد کنید");
    return false;
  };
  useEffect(() => {
    const myTimeOut = setTimeout(() => {
      setTimeCounter((prev) => prev - 1);
    }, 1000);

    if (timeCounter <= 0) {
      setTimeCounter(0);
      clearTimeout(myTimeOut);
    }

    // NOTE: I think it's not clearing the setTimeOut

    return () => {
      clearTimeout(myTimeOut);
    };
  }, [timeCounter]);

  const handleOTP = () => {
    if (timeCounter === 0) {
      // re-send the request
      sendCodeToUser();
      setTimeCounter(BASE_MILLISECOND);
    } else {
      return;
    }
  };
  const sendCodeToUser = async () => {
    try {
      const { status } = await resetPasswordWithMobileConfirm(RecoverEntity);

      if (status === 200) {
        toasts.success("کد به شماره همراه شما ارسال شد");
      } else {
        toasts.error("مشکلی پیش آمده");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const submit = async () => {
    let isValid = isValidInput();
    if (isValid) {
      let temp = { code: otp, mobile: RecoverEntity.mobile };
      try {
        const { status } = await resetPasswordWithMobileConfirm(temp);

        if (status === 200) {
          toasts.success("کد ارسالی شما تایید شد");
          showNextSection();
        }
      } catch (err) {
        if (err.response.status === 400) {
          toasts.error("کد وارد شده صحیح نمی باشد");
        } else {
          toasts.error("خطایی پیش آمد");
        }
      }
    }
  };

  return (
    <Fragment>
      <CustomInput
        type="tel"
        label="کد تأیید"
        handleChange={setOtp}
        value={otp}
        onKeyPress={(e) => getNumberOnly(e)}
        classValidation={classValidationInput}
      />

      <div className={`btn ${styles.btn}`} onClick={submit}>
        بررسی
      </div>

      <div className={styles.greyBtn} onClick={handleOTP}>
        ارسال مجدد:
        <span className={styles.primary}>
          {convertMilliSecondToMinutes(timeCounter)}
        </span>
      </div>
    </Fragment>
  );
}

export default OtpSection;
