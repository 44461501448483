import axios from "axios";
import cookie from "react-cookies";
import { refreshAccessToken } from "./entry/entryService";
import { parseJWTPayload } from "util/tokenParser";
import { cookieOptions } from "util/auth";

axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use((config) => {
  const token = cookie.load("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const handleSuccessRequest = (config) => {
  const token = cookie.load("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
};

const handleErrorRequest = async (error) => {
  const originalRequest = error.config;
  const expectedErrors =
    error.response &&
    (error.response.status === 401 || error.response.status === 500) &&
    !originalRequest._retry;
  if (expectedErrors) {
    originalRequest._retry = true;
    const refreshToken = cookie.load("panel-refreshToken");
    return await refreshAccessToken(refreshToken).then(
      ({ data: { data: token }, status }) => {
        try {
          if (status === 200) {
            const { exp: expToken } = parseJWTPayload(token);
            cookie.save("token", token, cookieOptions(expToken * 1000));
            axios.defaults.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          } else {
            window.location = "/registerAndLogin";
            cookie.remove("token");
            cookie.remove("panel-refreshToken");
          }
        } catch (error) {
          if (error.response.status === 400) {
            window.location = "/registerAndLogin";
            cookie.remove("token");
            cookie.remove("panel-refreshToken");
          }
        }
      }
    );
  }
  return Promise.reject(error);
};

axios.interceptors.response.use(handleSuccessRequest, handleErrorRequest);

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};
export default http;
