const articleInitialState = {
  image: null,
  title: "",
  subTitle: "",
  text: "",
  articleStatus: "unpublished",
};

export const articleFormReducer = (state = articleInitialState, action) => {
  switch (action.type) {
    case "UPDATE_ARTICLE_FROM":
      return { ...state, ...action.payload };
    case "RESET_ARTICLE_FORM":
      return articleInitialState;
    default:
      return state;
  }
};