import { useState } from "react";
import styles from "./AddPerson.module.scss";

export default function AddPerson() {
  const [email, setEmail] = useState("");

  const condition = email.length;

  return (
    <div className={styles.wrapper}>
      <label className={styles.lbl}>دعوت به وسیله ی ایمیل</label>
      <input
        className={`form-control ${styles.formControl}`}
        type="text"
        placeholder="ایمیل فرد مورد نظر را وارد نمایید"
        onChange={(e) => setEmail(e.target.value)}
      />
      <div
        className={`${styles.btn} ${
          condition ? styles.btnSendActive : styles.btnSendDisabled
        }`}>
        ارسال دعوتنامه
      </div>
    </div>
  );
}
