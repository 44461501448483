import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDeleteExpertQuery } from "hooks/query/useExpertQuery";
import { Link } from "react-router-dom";
import AddExpertModal from "../addExpertModal/AddExpertModal";
import styles from "./Row.module.scss";

function Row({ data }) {
  const { mutate: deleteExpert } = useDeleteExpertQuery(data.name);

  const handleDeleteExpert = () => {
    deleteExpert(data.id);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onOpen = () => {
    setIsModalOpen(true);
  };
  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <tr className={styles[`row`]}>
        <td>
          <div className={styles[`user-wrapper`]}>
            <div className={styles[`user-image`]}>
              {data.avatar?.length ? (
                <img
                  loading="lazy"
                  src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.avatar}`}
                  alt=""
                />
              ) : (
                <img
                  loading="lazy"
                  src={`${process.env.PUBLIC_URL}/images/users/user-default.svg`}
                  alt=""
                />
              )}
            </div>
            <span>{data.name}</span>
          </div>
        </td>
        <td>{data.position}</td>
        <td>{data.education}</td>
        <td>
          <div className={styles[`button-wrapper`]}>
            <div className={styles[`social-media`]}>
              {data.youtube && (
                <Link
                  to={data.youtube}
                  target="_blank"
                  referrerPolicy="no-referrer">
                  <img src={"/images/experts/youtube.svg"} alt="" />
                </Link>
              )}
              {data.twitter && (
                <Link
                  to={data.twitter}
                  target="_blank"
                  referrerPolicy="no-referrer">
                  <img src={"/images/experts/twitter.svg"} alt="" />
                </Link>
              )}
              {data.telegram && (
                <Link
                  to={data.telegram}
                  target="_blank"
                  referrerPolicy="no-referrer">
                  <img src={"/images/experts/telegram.svg"} alt="" />
                </Link>
              )}
              {data.linkedin && (
                <Link
                  to={data.linkedin}
                  target="_blank"
                  referrerPolicy="no-referrer">
                  <img src={"/images/experts/linkedin.svg"} alt="" />
                </Link>
              )}
              {data.instagram && (
                <Link
                  to={data.instagram}
                  target="_blank"
                  referrerPolicy="no-referrer">
                  <img src={"/images/experts/instagram.svg"} alt="" />
                </Link>
              )}
              {data.facebook && (
                <Link
                  to={data.facebook}
                  target="_blank"
                  referrerPolicy="no-referrer">
                  <img src={"/images/experts/facebook.svg"} alt="" />
                </Link>
              )}
            </div>

            <div className={styles[`button-wrapper`]}>
              <button
                className={`${styles[`button`]} ${styles[`button--icon`]}`}
                onClick={onOpen}>
                <img src={"/images/categories/edit.svg"} alt="" />
              </button>
              <button
                className={`${styles[`button`]} ${styles[`button--icon`]}`}
                onClick={handleDeleteExpert}>
                <img src={"/images/categories/trash.svg"} alt="" />
              </button>
            </div>
          </div>
        </td>
      </tr>

      <Modal
        show={isModalOpen}
        onHide={onClose}
        dialogClassName={styles[`modal`]}
        centered>
        <AddExpertModal onHide={onClose} type="update" data={data} />
      </Modal>
    </>
  );
}

export default Row;
