import Information from "../steps/information/Information";
import Awards from "../steps/awards/Awards";

export default function InformationTab() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <Information />
      {/* <Awards /> */}
    </div>
  );
}
