import TooltipMenu from "../toolTipMenu/ToolTipMenu";

import styles from "../styles.module.scss";

function ExtendMenuButton({
	title,
	iconUrl,
	menuIsOpen,
	isExtendMenuOpen,
	onToggleButton,
	sectionName,
}) {
	return (
		<li>
			<button
				className={`${styles.extendMenuButton} ${
					isExtendMenuOpen ? styles.open : styles.close
				}`}
				onClick={() => onToggleButton(sectionName)}
			>
				{menuIsOpen ? (
					<>
						<img src={`${process.env.PUBLIC_URL}${iconUrl}`} alt="" />
						<span>{title}</span>
					</>
				) : (
					<TooltipMenu text={title}>
						<img src={`${process.env.PUBLIC_URL}${iconUrl}`} alt="" />
					</TooltipMenu>
				)}
			</button>
		</li>
	);
}

export default ExtendMenuButton;
