import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { isObject } from "lodash-es";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

function SingleUploader({
  imageState,
  maxFiles,
  accept,
  dropzonePlaceholder,
  placeholder,
  // eventPosterStyles,
  // bigSize,
}) {
  const [imageFile, setImageFile] = imageState;
  const [selectedImage, setSelectedImage] = useState("");
  const fileReadering = (file) => {
    const fileReader = new FileReader();
    fileReader.addEventListener(
      "load",
      () => {
        setSelectedImage(fileReader.result);
      },
      false
    );
    fileReader.readAsDataURL(file);
  };
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const file = acceptedFiles[0];
    setImageFile(file);
    fileReadering(file);
    rejectedFiles.forEach((file) => {
      file.errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          toast.error(`فقط مجاز به استفاده از فرمت های : ${accept}`);
        }
        if (error.code === "too-many-files") {
          toast.error(`فقط مجاز به ارسال ${maxFiles} فایل هستید`);
        }
      });
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    maxFiles: maxFiles,
    onDrop,
  });
  useEffect(() => {
    if (isObject(imageFile) && !selectedImage) {
      fileReadering(imageFile);
    }
  }, [imageFile, selectedImage]);
  return (
    <>
      {imageFile ? (
        <div className={`${styles.uploadedPicWrapper}`}>
          <div className={styles.right}>
            <img
              src={
                isObject(imageFile)
                  ? `${selectedImage}`
                  : `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${imageFile}`
              }
              alt={placeholder}
            />
            <span>{placeholder}</span>
          </div>
          <div className={styles.left}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <button className="btn">
                <img
                  src={`${process.env.PUBLIC_URL}/images/new-icons/edit.svg`}
                  alt="edit"
                />
              </button>
            </div>
            <button onClick={() => setImageFile("")} className="btn">
              <img
                src={`${process.env.PUBLIC_URL}/images/new-icons/close.svg`}
                alt="close"
              />
            </button>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps({
            className: `${styles.dropzone}`,
          })}>
          <input {...getInputProps()} />
          <div className={styles.section}>
            <div className={styles.fileUploaderIcon}>
              <img
                src={`${process.env.PUBLIC_URL}/images/new-icons/add-image.svg`}
                alt="add +"
              />
            </div>
            <span>
              {dropzonePlaceholder ? dropzonePlaceholder : " افزودن تصویر"}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

SingleUploader.defaultProps = {
  accept: ".jpg,.jpeg,.png",
  maxFiles: 1,
};
export default SingleUploader;
