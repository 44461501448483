import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Empty from "../empty/Empty";
import styles from "../OverView.module.scss";

const isPublished = true;

const handleIcons = (type) => {
  if (type === "movies") return "video-over-view";
  if (type === "series") return "series-over-view";
  if (type === "collections") return "collection-over-view";
  if (type === "musics") return "music-playlist-over-view";
  // if (iconResponse("")) return "video-clip-over-view";
};
const handleType = (type, id) => {
  if (type === "movies") return `/movies/addMovies/${id}`; // movie type
  if (type === "series") return `/series/addSeries/${id}`; // series type
  if (type === "collections") return `/collections/${id}/collectionMovies`; // collection type
  if (type === "musics") return `/musics/music/${id}`; // music type
};
const seeMoreLink = (key) => {
  switch (key) {
    case "movies":
      return "/movies";
    case "series":
      return "/series";
    case "collections":
      return "/collections";
    case "musics":
      return "/musics";
    default:
      return "/movies";
  }
};

const fileTypes = [
  {
    name: "movies",
    title: "فیلم",
  },
  {
    name: "series",
    title: "سریال",
  },
  {
    name: "collections",
    title: "کالکشن",
  },
  {
    name: "musics",
    title: "موسیقی",
  },
];

export default function FilesList({ data }) {
  const [isToggled, setIsToggled] = useState(false);
  const [type, setType] = useState({
    name: "movies",
    title: "فیلم",
  }); // movies | series | collections | musics

  const filteredData = (key) => {
    const filtering = (kind) =>
      data.filter((f) => Object.keys(f).includes(kind));

    switch (key) {
      case "movies":
        return filtering("release");
      case "series":
        return filtering("seasons");
      case "collections":
        return filtering("movies");
      case "musics":
        return filtering("singers");
      default:
        return filtering("release");
    }
  };

  return (
    <div className={styles.container}>
      <Dropdown
        className={styles.dropdown}
        show={isToggled}
        onToggle={(e) => setIsToggled(e)}>
        <Dropdown.Toggle className={`${styles.dropdownButton}`}>
          <span>لیست {type?.title} ها</span>
          <img
            className={isToggled ? styles.rotated : ""}
            src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/arrow.svg`}
            alt=""
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.dropdownMenu}>
          {fileTypes.map((f) => (
            <Dropdown.Item
              onClick={() => setType(f)}
              key={f.name}
              className={styles.dropdownItem}
              as="div">
              لیست {f.title} ها
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {filteredData(type?.name)?.length > 0 ? (
        <>
          <div className={`${styles.list} ${styles.filesList}`}>
            {filteredData(type?.name).map((item) => (
              <div key={item.id} className={styles.filesListItem}>
                <div className={styles.info}>
                  <div className={styles.imgWrapper}>
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/images/overView/${handleIcons(type.name)}.svg`}
                      alt="movies"
                    />
                  </div>
                  <div>
                    <span>
                      {item.title?.length > 20 || item.name?.length > 20
                        ? `${item.title.slice(0, 15) + "..."}` ||
                          `${item.name.slice(0, 15) + "..."}`
                        : item.title || item.name}
                    </span>
                    <span>
                      {item.title_in_persian?.length > 20 ||
                      item.name_in_persian?.length > 20
                        ? `${item.title_in_persian.slice(0, 15) + "..."}` ||
                          `${item.name_in_persian.slice(0, 15) + "..."}`
                        : item.title_in_persian || item.name_in_persian}
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles.status} ${
                    isPublished ? styles.publish : styles.unPublished
                  }`}>
                  <img
                    src={
                      isPublished
                        ? `${process.env.PUBLIC_URL}/images/articles/tick-square.svg`
                        : `${process.env.PUBLIC_URL}/images/articles/close-square.svg`
                    }
                    alt=""
                  />
                  <span> {isPublished ? "منتشر شده" : "منتشر نشده"}</span>
                </div>
                <Link
                  to={`${handleType(type.name, item.id)}`}
                  className={styles.dots}>
                  <img src="/images/icons/dots.svg" alt="..." />
                </Link>
              </div>
            ))}
          </div>
          <Link
            to={seeMoreLink(type?.name)}
            className={`${styles.seeMoreBtn} btn`}>
            مشاهده بیشتر
          </Link>
          <div className={styles.shadow}></div>
        </>
      ) : (
        <Empty
          icon="/images/overView/document-text.svg"
          title="شما تا کنون فایلی در سایت خود بارگزاری نکرده اید."
        />
      )}
    </div>
  );
}
