import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSlidersQuery } from "hooks/query/useSliderQuery";
import { updateInformationSlide } from "../../../../../redux/actions/slider";
import { Input } from "../../../../common/input/Input";
import DropDown from "../../../../common/dropDown/DropDown";
import TextArea from "../../../../common/textArea/TextArea";
import SwitchTwoLabel from "../../../../common/switchTwoLabel/SwitchTwoLabel";
import SingleImageUploader from "../../../../common/singleImageUploader/SingleImageUploader";
import styles from "./Information.module.scss";

export default function Information() {
  const { data } = useSlidersQuery();
  // redux
  const dispatch = useDispatch();
  const { id } = useParams();
  const { information, setting } = useSelector((state) => state.slideReducer);

  // Slider information states
  const [priorityOptions, setPriorityOptions] = useState([]);

  // creating priorityOptions
  const handleCreatePriorityOptions = useCallback(() => {
    const filteredArray = data?.filter(
      (item) => item.position === setting.position
    );

    if (id) {
      return filteredArray?.map((_, index) => ({
        title: `${index + 1}`,
        value: index,
      }));
    } else {
      return [...filteredArray, filteredArray.length + 1].map((_, index) => {
        return {
          title: `${index + 1}`,
          value: index,
        };
      });
    }
  }, [id, setting.position, data]);

  // maybe needed to add get slideList?
  useEffect(() => {
    if (data) {
      const options = handleCreatePriorityOptions(id);
      setPriorityOptions(options);
    }
  }, [handleCreatePriorityOptions, id, data]);

  const handleChangeTextArea = (e) => {
    dispatch(
      updateInformationSlide({
        description: e.target.value,
      })
    );
  };

  const handleChangePriority = (option, action) => {
    dispatch(
      updateInformationSlide({
        [action.name]: option.value,
      })
    );
  };

  const handleChangeInput = (e) => {
    dispatch(
      updateInformationSlide({
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleChangeSwitch = (bool) => {
    if (bool) {
      dispatch(
        updateInformationSlide({
          show_button: true,
        })
      );
    } else {
      dispatch(
        updateInformationSlide({
          show_button: false,
          button_title: "",
          button_url: "",
        })
      );
    }
  };

  const handleChangeImage = (file, property) => {
    dispatch(
      updateInformationSlide({
        [property]: file,
      })
    );
  };

  return (
    <section>
      <div className={styles.imageWrapper}>
        <label>
          تصویر کاور <span>*</span>
        </label>
        <SingleImageUploader
          placeholder="کاور"
          imageState={[
            information.cover,
            (file) => handleChangeImage(file, "cover"),
          ]}
        />
      </div>

      {setting.position === "bottom" && (
        <div className={styles.imageWrapper}>
          <label>تصویر پوستر</label>
          <SingleImageUploader
            placeholder="پوستر"
            imageState={[
              information.poster,
              (file) => handleChangeImage(file, "poster"),
            ]}
          />
        </div>
      )}

      <div className={styles.groupWrapper}>
        <Input
          label="عنوان اسلاید"
          placeholder="به طور مثال: فصل اول سریال برکینگ بد"
          name="title"
          value={information.title}
          onChange={handleChangeInput}
        />
      </div>

      <div className={styles.groupWrapper}>
        <TextArea
          label="متن اسلاید"
          placeholder="متن کوتاهی را که در زیر عنوان اسلاید قرار میگیرد وارد نمایید"
          value={information.description}
          onChange={handleChangeTextArea}
        />
      </div>

      <div className={styles.groupWrapper}>
        <DropDown
          label="اولویت نمایش"
          placeholder="اولویت نمایش خود را انتخاب نمایید"
          name="priority"
          options={priorityOptions}
          value={priorityOptions?.find(
            (item) => item.value === information.priority
          )}
          getOptionLabel={(option) => option["title"]}
          getOptionValue={(option) => option["value"]}
          onChange={handleChangePriority}
          isRequired
        />
      </div>

      <div className={styles.groupWrapper}>
        <SwitchTwoLabel
          switchState={[information.show_button, handleChangeSwitch]}
          rightLabel={"فعال شدن دکمه"}
          leftLabel={"عدم نیاز به دکمه"}
        />
      </div>

      {information.show_button && (
        <div className={styles.wrapper}>
          <Input
            placeholder="به طور مثال: تماشا کن"
            label="عنوان دکمه"
            name="button_title"
            value={information.button_title}
            onChange={handleChangeInput}
            require
          />
          <Input
            placeholder="به طور مثال: https://www.upload.com/watchvDHtsdLzHp8U.mkv"
            label="لینک دکمه"
            name="button_url"
            value={information.button_url}
            onChange={handleChangeInput}
            require
          />
        </div>
      )}
    </section>
  );
}
