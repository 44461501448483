import { useQuery } from "react-query";
import { getSupportsList } from "services/support/support";

export const useSupportsQuery = (page) => {
  return useQuery(
    ["supports", page],
    ({ signal }) => getSupportsList(page, 10, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
