export const qualityOptions = [
  { id: "128 Kbps", name: "128 Kbps" },
  { id: "320 Kbps", name: "320 Kbps" },

  { id: "480p", name: "480p" },
  { id: "480p x265", name: "480p x265" },
  { id: "480p x265 10bit", name: "480p x265 10bit" },

  { id: "528p", name: "528p" },
  { id: "576p", name: "576p" },

  { id: "720p", name: "720p" },
  { id: "720p x265", name: "720p x265" },
  { id: "720p x265 10bit", name: "720p x265 10bit" },
  { id: "720p HD", name: "720p HD" },

  { id: "1080p", name: "1080p" },
  { id: "1080p x265", name: "1080p x265" },
  { id: "1080p x265 10bit", name: "1080p x265 10bit" },
  { id: "1080p HD", name: "1080p HD" },
  { id: "1080p 3D", name: "1080p 3D" },

  { id: "2160p", name: "2160p" },
  { id: "2160p x265", name: "2160p x265" },
  { id: "2160p x265 10bit", name: "2160p x265 10bit" },
  { id: "2160p HD", name: "2160p HD" },

  { id: "HDCAM", name: "HDCAM" },
  { id: "HDTS", name: "HDTS" },
  { id: "WEBRip HC", name: "WEBRip HC" },

  { id: "Flac", name: "Flac" },
  { id: "Flac 24Bit", name: "Flac 24Bit" },
];
export const encoderOptions = [
  { id: "Unknown", name: "Unknown" },
  { id: "YIFY", name: "YIFY" },
  { id: "MkvCage", name: "MkvCage" },
  { id: "PSA", name: "PSA" },
  { id: "RMTeam", name: "RMTeam" },
  { id: "Pahe", name: "Pahe" },
  { id: "Silence", name: "Silence" },
  { id: "GalaxyTV", name: "GalaxyTV" },
  { id: "Ganool", name: "Ganool" },
  { id: "Sticky83", name: "Sticky83" },
  { id: "Sujaidr", name: "Sujaidr" },
  { id: "ShAaNig", name: "ShAaNig" },
  { id: "MZABI", name: "MZABI" },
  { id: "GalaxyRG", name: "GalaxyRG" },
  { id: "SUNSCREEN", name: "SUNSCREEN" },
  { id: "EVO", name: "EVO" },
  { id: "VXT", name: "VXT" },
  { id: "RARBG", name: "RARBG" },
  { id: "CMRG", name: "CMRG" },
  { id: "SaNiG", name: "SaNiG" },
  { id: "Joy", name: "Joy" },
  { id: "IC", name: "IC" },
  { id: "DeeJayAhmed", name: "DeeJayAhmed" },
  { id: "MRN", name: "MRN" },
  { id: "Tigole", name: "Tigole" },
  { id: "RZeroX", name: "RZeroX" },
  { id: "msD", name: "msD" },
  { id: "ZMNT", name: "ZMNT" },
  { id: "DDR", name: "DDR" },
  { id: "jlw", name: "jlw" },
  { id: "Qman", name: "Qman" },
  { id: "BWBP", name: "BWBP" },
  { id: "Psyz", name: "Psyz" },
  { id: "BugsFunny", name: "BugsFunny" },
  { id: "IKA", name: "IKA" },
  { id: "HazMatt", name: "HazMatt" },
  { id: "FGT", name: "FGT" },
  { id: "LorD", name: "LorD" },
  { id: "Cinefeel", name: "Cinefeel" },
  { id: "HETeam", name: "HETeam" },
  { id: "MkvHub", name: "MkvHub" },
  { id: "TombDoc", name: "TombDoc" },
  { id: "dedsec", name: "dedsec" },
  { id: "DameDesuYo", name: "DameDesuYo" },
  { id: "NTb", name: "NTb" },
  { id: "Vyndros", name: "Vyndros" },
  { id: "ARiES", name: "ARiES" },
  { id: "LOKi", name: "LOKi" },
  { id: "PCVR", name: "PCVR" },
  { id: "Rapta", name: "Rapta" },
  { id: "MONOLITH", name: "MONOLITH" },
  { id: "afm72", name: "afm72" },
  { id: "BTN", name: "BTN" },
  { id: "anoXmous", name: "anoXmous" },
  { id: "BONE", name: "BONE" },
  { id: "VectoR", name: "VectoR" },
  { id: "LION", name: "LION" },
  { id: "d3g", name: "d3g" },
  { id: "saints", name: "saints" },
  { id: "sharpysword", name: "sharpysword" },
  { id: "ImE", name: "ImE" },
  { id: "EmKayy", name: "EmKayy" },
  { id: "Telly", name: "Telly" },
  { id: "SCENI", name: "SCENI" },
  { id: "FrogPerson", name: "FrogPerson" },
  { id: "REWARD", name: "REWARD" },
  { id: "LiBRARiANS", name: "LiBRARiANS" },
  { id: "SFM", name: "SFM" },
  { id: "FreetheFish", name: "FreetheFish" },
  { id: "Xvid", name: "Xvid" },
  { id: "Judas", name: "Judas" },
  { id: "Lucifer22", name: "Lucifer22" },
  { id: "Vimeo", name: "Vimeo" },
  { id: "LUZ", name: "LUZ" },
  { id: "iKA", name: "iKA" },
  { id: "taxes", name: "taxes" },
  { id: "MeGusta", name: "MeGusta" },
  { id: "PHOENiX", name: "PHOENiX" },
  { id: "MRCS", name: "MRCS" },
  { id: "CAKES", name: "CAKES" },
  { id: "AnimeRG", name: "AnimeRG" },
  { id: "KatmovieHD", name: "KatmovieHD" },
  { id: "TEPES", name: "TEPES" },
  { id: "AGLET", name: "AGLET" },
  { id: "mRs", name: "mRs" },
  { id: "SerMovie", name: "SerMovie" },
  { id: "UNK", name: "UNK" },
  { id: "RONIN", name: "RONIN" },
  { id: "Classics", name: "Classics" },
  { id: "Natty", name: "Natty" },
  { id: "PUNCH", name: "PUNCH" },
  { id: "Anime Time", name: "Anime Time" },
  { id: "PT", name: "PT" },
  { id: "Reaktor", name: "Reaktor" },
  { id: "AceAres", name: "AceAres" },
  { id: "NOGRP", name: "NOGRP" },
  { id: "PlayBD", name: "PlayBD" },
  { id: "WELP", name: "WELP" },
  { id: "ION10", name: "ION10" },
  { id: "JFF", name: "JFF" },
  { id: "RBB", name: "RBB" },
  { id: "ETRG", name: "ETRG" },
  { id: "Atmos", name: "Atmos" },
  { id: "MiNX", name: "MiNX" },
  { id: "AMZN", name: "AMZN" },
  { id: "iDiOTS", name: "iDiOTS" },
  { id: "Castbox", name: "Castbox" },
  { id: "PECULATE", name: "PECULATE" },
  { id: "YouTube", name: "YouTube" },
  { id: "FLUX", name: "FLUX" },
  { id: "PHDTeam", name: "PHDTeam" },
  { id: "Trix", name: "Trix" },
  { id: "ASW", name: "ASW" },
  { id: "EMBER", name: "EMBER" },
  { id: "Judas", name: "Judas" },
  { id: "Spotify", name: "Spotify" },
];
