import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
function TextArea({ label, ...props }) {
  const [isFocussed, setIsFocussed] = useState(false);
  const [isBlured, setIsBlured] = useState(false);
  useEffect(() => {
    if (!props.value && isBlured && !isFocussed) {
      setIsBlured(false);
      setIsFocussed(false);
    }
  }, [isBlured, isFocussed, props.value]);
  useEffect(() => {
    if (props.value) {
      setIsBlured(true);
    }
  }, [props.value]);

  return (
    <div className={`${styles.group} ${isFocussed || isBlured ? styles.group__focussed : ""}`}>
      {label && <label className={styles.label}>{label}</label>}
      <textarea
        onFocus={() => {
          setIsFocussed(true);
        }}
        onBlur={(e) => {
          if (e.target.value) {
            setIsBlured(true);
          }
          setIsFocussed(false);
        }}
        className={styles.textarea}
        {...props}
      />
    </div>
  );
}
export default TextArea;
