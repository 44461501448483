export const addSelectedCollections = (data) => ({
  type: "ADD_SELECTED_COLLECTION_LIST",
  payload: data,
});
export const removeSelectedCollections = (id) => ({
  type: "REMOVE_SELECTED_COLLECTION_LIST",
  payload: id,
});
export const updateSelectAllCollections = (data) => ({
  type: "UPDATE_SELECT_ALL_COLLECTION",
  payload: data,
});
// Collection Information tab
export const updateCollectionInformation = (data) => ({
  type: "UPDATE_COLLECTION_INFO",
  payload: data,
});
export const resetCollectionInformation = () => ({
  type: "RESET_COLLECTION_INFO",
});
