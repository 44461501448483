import {
  patchAppendCountriesToSeries,
  patchAppendDirectorsToSeries,
  patchAppendGenresToSeries,
  patchAppendLanguageToSeries,
  patchAppendSeriesKeywords,
  patchAppendStarsToSeries,
  patchAppendWritersToSeries,
} from "../../../services/series/series";

export const createAppend = async (endpoint, movieId, list) => {
  const mappedAPIs = {
    languages: patchAppendLanguageToSeries,
    countries: patchAppendCountriesToSeries,
    genres: patchAppendGenresToSeries,
    stars: patchAppendStarsToSeries,
    directors: patchAppendDirectorsToSeries,
    writers: patchAppendWritersToSeries,
    keywords: patchAppendSeriesKeywords,
  };

  if (!list.length) return;

  try {
    const payload = {
      id: parseInt(movieId),
      [endpoint]: list.map((i) => i.id),
    };

    await mappedAPIs[endpoint](payload);
  } catch (error) {
    console.error(error);
  }
};