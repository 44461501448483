// Add Artist
export const updateArtistInfo = (data) => ({
  type: "UPDATE_ARTIST_INFO",
  payload: data,
});
export const addArtistOccupation = (occupation) => ({
  type: "ADD_ARTIST_OCCUPATION",
  payload: occupation,
});
export const removeArtistOccupation = (occupation) => ({
  type: "REMOVE_ARTIST_OCCUPATION",
  payload: occupation,
});
export const restArtist = () => ({ type: "RESET_ARTIST" });

// Artists List
export const addSelectedArtists = (data) => ({
  type: "ADD_SELECTED_ARTISTS_LIST",
  payload: data,
});
export const removeSelectedArtists = (artistId) => ({
  type: "REMOVE_SELECTED_ARTISTS_LIST",
  payload: artistId,
});
export const updateSelectAllArtists = (data) => ({
  type: "UPDATE_SELECT_ALL_ARTISTS",
  payload: data,
});