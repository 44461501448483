import videojs from "video.js";

import SettingsMenuItem from "./settings-menu-item";
const MenuButton = videojs.getComponent("MenuButton");
const Component = videojs.getComponent("Component");

class SettingsMenuButton extends MenuButton {
  constructor(player, options) {
    super(player, options);

    this.el_.setAttribute("aria-label", "Settings Menu");
    this.el_
      .querySelector(".vjs-icon-placeholder")
      .classList.add("vjs-icon-cog");
  }

  buildCSSClass() {
    return `vjs-settings-menu ${super.buildCSSClass()}`;
  }

  createItems(items = [], MenuItem = SettingsMenuItem) {
    this.options_.sources.forEach((source) => {
      items.push(
        new SettingsMenuItem(this.player_, {
          source,
          playlistItem: this.options_.playlistItem,
        })
      );
    });

    return items;
  }
}

Component.registerComponent("SettingsMenuButton", SettingsMenuButton);
export default SettingsMenuButton;
