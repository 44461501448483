import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateEpisode } from "../../../../../../redux/actions/series";
import SingleVideoUploader from "../../../../../common/singleVideoUploader/SingleVideoUploader";

export default function VideoPart({ data }) {
  // local state
  const [file, setFile] = useState("");
  useEffect(() => {
    if (data) {
      setFile(data?.data.file);
    } else {
      setFile("");
    }
  }, [data]);

  // redux state
  const dispatch = useDispatch();
  const handleChangeFile = () => {
    if (file) {
      dispatch(
        updateEpisode({
          linkURL: "",
          file: file,
          seasonId: data?.seasonId,
          episodeId: data?.data.imdb_id,
        })
      );
    }
  };
  useEffect(() => {
    handleChangeFile();
  }, [file]);

  return (
    <>
      <SingleVideoUploader
        dropzonePlaceholder="فایل این قسمت را بکشید و رها کنید."
        videoState={[file, (files) => setFile(files)]}
        data={data?.data}
        modalProps={{
          seasonId: data?.seasonId,
          episodeId: data?.data.imdb_id,
        }}
        acc
      />
    </>
  );
}
