import Status from "pages/tickets/status";
import styles from "./styles.module.scss";
export default function Details() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <span>شماره تیکت</span>
        <span>2184911212</span>
      </div>
      <div className={styles.content}>
        <span>بخش</span>
        <span>پشتیبانی فروش و کاربران</span>
      </div>
      <div className={styles.content}>
        <span>موضوع</span>
        <span>یاداوری تمدید سرور</span>
      </div>
      <div className={styles.content}>
        <span>وضعیت</span>
        <span>
          <Status status={"answered"} />
        </span>
      </div>
      <div className={styles.content}>
        <span>اولویت</span>
        <span>متوسط</span>
      </div>
      <div className={styles.content}>
        <span>آخرین بروز رسانی</span>
        <span>1399/12/06</span>
      </div>
    </div>
  );
}
