const flags = [
  {
    flag: "./images/flags/iran.png",
    name: "ایران",
    code: "+98",
  },
  {
    flag: "./images/flags/germany.png",
    name: "آلمان",
    code: "+49",
  },
  {
    flag: "./images/flags/france.png",
    name: "فرانسه",
    code: "+33",
  },
  {
    flag: "./images/flags/united-arab-emirates.png",
    name: "امارات",
    code: "+971",
  },
  {
    flag: "./images/flags/england.png",
    name: "انگلیس",
    code: "+44",
  },
  {
    flag: "./images/flags/iraq.png",
    name: "عراق",
    code: "+964",
  },
  {
    flag: "./images/flags/netherlands.png",
    name: "هلند",
    code: "+31",
  },
  {
    flag: "./images/flags/saudi-arabia.png",
    name: "عربستان سعودی",
    code: "+966",
  },
  {
    flag: "./images/flags/spain.png",
    name: "اسپانیا",
    code: "+34",
  },
  {
    flag: "./images/flags/turkey.png",
    name: "ترکیه",
    code: "+90",
  },
  {
    flag: "./images/flags/united-states.png",
    name: "آمریکا",
    code: "+1",
  },
];

export { flags };
