import { useEffect } from "react";
import { useArtistsQuery } from "hooks/query/useArtistQuery";
import {
  useCountriesQuery,
  useDistributorsQuery,
  useGenresQuery,
  useLanguagesQuery,
} from "hooks/query/useCastsQuery";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { deleteSeriesKeywords } from "services/series/series";
import { numberFormater } from "util/number";
import {
  removeSeriesKeywords,
  updateSeriesInformation,
} from "../../../../../redux/actions/series";
import {
  convertISOStringToObject,
  convertJalaliObjectToMiladiDate,
} from "../../../../../util/toJalali";
import { useSerieQuery } from "hooks/query/useSerieQuery";
import { Input } from "../../../../common/input/Input";
import { seriesContentRating } from "constants/contentRating";
import CategoryDropdown from "../../../../common/categoryDropdown/CategoryDropdown";
import DropDown from "../../../../common/dropDown/DropDown";
import TagInput from "components/common/tagInput/TagInput";
import toasts from "components/common/toasts/toasts";
import MultiTag from "../../../../common/multiTag/MultiTag";
import SingleDayDatePicker from "../../../../common/singleDayDatePicker/SingleDayDatePicker";
import SingleImageUploader from "../../../../common/singleImageUploader/SingleImageUploader";
import TextArea from "../../../../common/textArea/TextArea";
import styles from "./Information.module.scss";
import AsyncMultiTag from "components/asyncMultiTag/AsyncMultiTag";
import { getArtistsBySearch } from "services/search/searchService";

export default function Information() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = useSerieQuery(id);
  useEffect(() => {
    if (data && id) {
      const payload = {
        ...data,
        content_rating: {
          id: data.content_rating,
          title: data.content_rating,
        },
      };
      dispatch(updateSeriesInformation(payload));
    }
  }, [data, dispatch, id]);

  const createSeries = useSelector((state) => state.createSeriesReducer);
  // GET Casts data
  const { data: genres } = useGenresQuery();
  const { data: languages } = useLanguagesQuery();
  const { data: countries } = useCountriesQuery();
  const { data: distributors } = useDistributorsQuery();
  const { data: artists } = useArtistsQuery();

  // dropdown options
  const directors = artists?.results.filter((i) =>
    i.occupation.includes("director")
  );
  const writers = artists?.results.filter((i) =>
    i.occupation.includes("writer")
  );
  const stars = artists?.results.filter((i) => i.occupation.includes("actor"));

  // series information states
  const handleChangeInput = (e) => {
    dispatch(
      updateSeriesInformation({
        [e.target.name]: e.target.value,
      })
    );
  };
  const handleChangeMultiSelect = (options, action) => {
    dispatch(
      updateSeriesInformation({
        [action.name]: options,
      })
    );
  };
  const changeDescription = (e) => {
    dispatch(
      updateSeriesInformation({
        summary: e.target.value,
      })
    );
  };
  const handleCheckStatus = (name) => {
    dispatch(
      updateSeriesInformation({
        status: name,
      })
    );
  };
  const hanldeChangeStartDate = (e) => {
    dispatch(
      updateSeriesInformation({
        start_date: convertJalaliObjectToMiladiDate(e),
      })
    );
  };
  const hanldeChangeEndDate = (e) => {
    dispatch(
      updateSeriesInformation({
        end_date: convertJalaliObjectToMiladiDate(e),
      })
    );
  };
  const handleChangeImage = (file, property) => {
    dispatch(
      updateSeriesInformation({
        [property]: file,
      })
    );
  };
  const handleChangeCategory = (categoryId) => {
    dispatch(
      updateSeriesInformation({
        category_id: categoryId,
      })
    );
  };
  // Deleting
  const handleDeleteKeywords = async (keywordID) => {
    try {
      const { status } = await deleteSeriesKeywords({
        id: +id,
        keywords: [keywordID],
      });
      if (status === 200) {
        toasts.success("keyword مورد نظر حذف شد.");
        dispatch(removeSeriesKeywords(keywordID));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <section className={styles.wrapper}>
      <div className={styles.overview}>
        <h6 className={styles.title}>نمای کلی</h6>
        <div className={styles.imageGroup}>
          <SingleImageUploader
            placeholder={createSeries.cover?.name || "کاور"}
            dropzonePlaceholder="تصویر کاور سریال را بکشید و اینجا رها کنید؛ ابعاد ۱۱۰۰ در ۷۳۰ پیکسل"
            imageState={[
              createSeries.cover,
              (file) => handleChangeImage(file, "cover"),
            ]}
            bigSize
          />
          <SingleImageUploader
            placeholder={createSeries.banner?.name || "پوستر"}
            dropzonePlaceholder="تصویر پوستر سریال را بکشید و اینجا رها کنید؛ ابعاد ۱۱۰۰ در ۷۳۰ پیکسل"
            imageState={[
              createSeries.banner,
              (file) => handleChangeImage(file, "banner"),
            ]}
            bigSize
          />
        </div>
        <div className={styles.group}>
          <Input
            placeholder="نام و عنوان سریال به فارسی و بدون سال تولید"
            name="title_in_persian"
            value={createSeries.title_in_persian}
            onChange={handleChangeInput}
          />
          <Input
            placeholder="نام و عنوان سریال به انگلیسی و بدون سال تولید*"
            name="title"
            value={createSeries.title}
            onChange={handleChangeInput}
          />
        </div>
        <div className={styles.groupSecond}>
          <Input
            placeholder="امتیاز IMDB از ۱ تا ۱۰"
            name="rate_value"
            value={createSeries.rate_value}
            onChange={handleChangeInput}
            type="number"
          />
          <Input
            placeholder="تعداد رای دهندگان IMDB"
            name="rate_total_votes"
            value={
              createSeries.rate_total_votes &&
              numberFormater(createSeries.rate_total_votes)
            }
            onChange={handleChangeInput}
          />
        </div>
        <div className={styles.groupSecond}>
          <Input
            placeholder="امتیاز Meta از ۱ تا ۱۰۰"
            name="meta_rate_value"
            value={createSeries.meta_rate_value}
            onChange={handleChangeInput}
            type="number"
          />
          <Input
            placeholder="Additional_id"
            name="additional_id"
            value={createSeries.additional_id}
            readOnly
          />
        </div>
        <TextArea
          placeholder="خلاصه سریال (فارسی یا انگلیسی)*"
          name="summary"
          value={createSeries.summary}
          onChange={changeDescription}
        />
      </div>
      <div className={styles.casts}>
        <h6 className={styles.title}>عوامل سریال</h6>
        <AsyncMultiTag
          fetcher={getArtistsBySearch}
          label="هنرمندان، بازیگران و صداپیشگان سریال"
          options={stars}
          selectedOptions={createSeries.stars}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeMultiSelect}
          name="stars"
          hasMovingLabel
        />
        <AsyncMultiTag
          fetcher={getArtistsBySearch}
          label="کارگردان (های) سریال"
          options={directors}
          selectedOptions={createSeries.directors}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeMultiSelect}
          name="directors"
          hasMovingLabel
        />
        <AsyncMultiTag
          fetcher={getArtistsBySearch}
          label="نویسنده (گان) سریال"
          options={writers}
          selectedOptions={createSeries.writers}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeMultiSelect}
          name="writers"
          hasMovingLabel
        />
      </div>
      <div className={styles.details}>
        <h6 className={styles.title}>جزییات سریال</h6>

        <div className={styles.categoryContainer}>
          <CategoryDropdown
            value={createSeries}
            updateCb={handleChangeCategory}
          />
        </div>
        <TagInput
          theme="gray"
          label="Keywords"
          propState={[
            createSeries.keywords,
            (e) => dispatch(updateSeriesInformation({ keywords: e })),
          ]}
          handleDelete={handleDeleteKeywords}
        />
        <MultiTag
          label="ژانر (های) سریال"
          options={genres}
          selectedOptions={createSeries.genres}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeMultiSelect}
          name="genres"
          hasMovingLabel
        />
        <MultiTag
          label="کشور (های) سازنده سریال"
          options={countries}
          selectedOptions={createSeries.countries}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeMultiSelect}
          name="countries"
          hasMovingLabel
        />
        <MultiTag
          label="زبان (های) استفاده شده در سریال"
          options={languages}
          selectedOptions={createSeries.languages}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeMultiSelect}
          name="languages"
          hasMovingLabel
        />
        <div>
          <DropDown
            placeholder="شبکه اصلی پخش کننده سریال"
            options={distributors}
            value={createSeries.distributor}
            getOptionLabel={(option) => option["name"]}
            getOptionValue={(option) => option["id"]}
            onChange={handleChangeMultiSelect}
            name="distributor"
          />
          <DropDown
            placeholder="رده سنی سریال را وارد نمایید"
            options={seriesContentRating}
            value={createSeries.content_rating}
            getOptionLabel={(option) => option["title"]}
            getOptionValue={(option) => option["id"]}
            onChange={handleChangeMultiSelect}
            name="content_rating"
          />
        </div>
      </div>
      <div className={styles.status}>
        <h6 className={styles.title}>وضعیت پخش</h6>
        <div className={styles.checkBoxFilter}>
          <div>
            <Form.Check
              type="radio"
              className={styles.checkBox}
              checked={createSeries.status === "active"}
              onChange={() => handleCheckStatus("active")}
            />
            <span>درحال پخش</span>
          </div>
          <div>
            <Form.Check
              type="radio"
              className={styles.checkBox}
              checked={createSeries.status === "finished"}
              onChange={() => handleCheckStatus("finished")}
            />
            <span>پایان پخش</span>
          </div>
          <div>
            <Form.Check
              type="radio"
              className={styles.checkBox}
              checked={createSeries.status === "canceled"}
              onChange={() => handleCheckStatus("canceled")}
            />
            <span>توقف پخش</span>
          </div>
        </div>
        <div className={styles.date}>
          <SingleDayDatePicker
            placeholder="تاریخ شروع پخش"
            selectedDateState={[
              createSeries.start_date?.length
                ? convertISOStringToObject(createSeries.start_date)
                : "",
              hanldeChangeStartDate,
            ]}
          />
          <SingleDayDatePicker
            placeholder="تاریخ پایان پخش"
            selectedDateState={[
              createSeries.end_date?.length
                ? convertISOStringToObject(createSeries.end_date)
                : "",
              hanldeChangeEndDate,
            ]}
            disabled={createSeries.status === "active"}
          />
        </div>
      </div>
    </section>
  );
}
