import { getAlbum, getAlbums } from "../../services/musics/albums";

// musics table
export const fetchAlbums = (offset) => async (dispatch) => {
  try {
    const { data } = await getAlbums(offset);
    dispatch({
      type: "FETCH_MUSICS",
      payload: {
        list: data.data.cur,
        count: data.data.count,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const removeAlbum = (albumId) => ({
  type: "REMOVE_ALBUM",
  payload: albumId,
});

export const removeSingleTrack = (albumId) => ({
  type: "REMOVE_SINGLE_TRACK",
  payload: albumId,
});

// music type changer
export const fetchAlbum = (albumId) => async (dispatch) => {
  try {
    const { data } = await getAlbum(albumId);
    dispatch({ type: "FETCH_ALBUM", payload: data.data });
  } catch (err) {
    console.error(err);
  }
};

export const fetchAlbumInformations = (data) => ({
  type: "FETCH_ALBUM_INFORMATIONS",
  payload: data,
});

export const setMusicType = (type) => ({
  type: "SET_MUSIC_TYPE",
  payload: type,
});

// music form
export const updateMusicForm = (formInput) => ({
  type: "UPDATE_MUSIC_FORM",
  payload: formInput,
});

// appended ids
export const addPersonToAppendList = (appendListName, newItem) => ({
  type: "ADD_PEOPLE_TO_APPEND_LIST",
  payload: { appendListName, newItem },
});

export const removePersonFromAppendList = (appendListName, personId) => ({
  type: "REMOVE_PEOPLE_FROM_APPEND_LIST",
  payload: { appendListName, personId },
});

export const setMusicAudioToSingleTrack = (audio) => ({
  type: "SET_MUSIC_AUDIO_TO_SINGLE_TRACK",
  payload: audio,
});

export const addMusicAudioToAlbum = (trackBody) => ({
  type: "ADD_MUSIC_AUDIO_TO_ALBUM",
  payload: trackBody,
});

export const updateMusicAudioForAlbum = (newAudio, uuid) => ({
  type: "UPDATE_MUSIC_AUDIO",
  payload: { newAudio, uuid },
});

export const removeMusicAudioWithUuid = (uuid) => ({
  type: "REMOVE_MUSIC_AUDIO_FROM_ALBUM_WITH_UUID",
  payload: uuid,
});

export const removeMusicAudioWithId = (id) => ({
  type: "REMOVE_MUSIC_AUDIO_FROM_ALBUM_WITH_ID",
  payload: id,
});

export const updateMusicCover = (cover) => ({
  type: "UPDATE_MUSIC_COVER",
  payload: cover,
});

export const resetMusicForm = () => ({
  type: "RESET_MUSIC_FORM",
});
