import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDeleteTelevisionQuery } from "hooks/query/useTelevisionQuery";
import AddModal from "../addModal/AddModal";
import styles from "./Row.module.scss";

function Row({ data }) {
  const { mutate: deleteTvLive } = useDeleteTelevisionQuery(data.name);
  const handleDeleteTvLive = () => {
    deleteTvLive(data.id);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onOpenModal = () => {
    setIsModalOpen(true);
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <tr className={styles.row}>
        <td>
          <div className={styles.titleWrapper}>
            <div className={styles.logoWrapper}>
              {data.logo?.length && (
                <img
                  loading="lazy"
                  src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.logo}`}
                  alt=""
                />
              )}
            </div>
            <span>شبکه {data.name}</span>
          </div>
        </td>
        <td>{data.url}</td>
        <td>
          <div className={styles.buttonWrapper}>
            <button onClick={onOpenModal} className={styles.button}>
              <img src={"/images/categories/edit.svg"} alt="" />
            </button>
            <button onClick={handleDeleteTvLive} className={styles.button}>
              <img src={"/images/categories/trash.svg"} alt="" />
            </button>
          </div>
        </td>
      </tr>
      <Modal
        show={isModalOpen}
        onHide={onCloseModal}
        dialogClassName={styles.modal}
        centered>
        <AddModal type="update" data={data} onHide={onCloseModal} />
      </Modal>
    </>
  );
}

export default Row;
