export const createFileQueueBody = ({
  id,
  title,
  size,
  progress = 0,
  type,
  event
}) => {
  return { id, title, size, progress, type, event };
};

// check data flow
const initialTranscodeBody = {
  current_bitrate: "N/A",
  current_time: "00:00:00.00",
  farames_processed: "0",
  progress: 0,
  speed: "N/A",
  resolution: "",
};
const initDownloadInfo = {
  downloadInfo: {
    bytes_complete: 0,
    progress: 0,
    size: 0,
  },
};

export const createTranscodeQueueBody = ({ id, title, type, resolutions }) => {
  return {
    event: "download",
    id,
    title,
    type,
    resolutions: resolutions.map((item) => ({
      ...initialTranscodeBody,
      resolution: item,
    })),
    downloadInfo: initDownloadInfo,
  };
};
