import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { uploadFile } from "util/uploadFile";
import { Input } from "components/common/input/Input";
import {
  usePostExpertQuery,
  usePutExpertQuery,
} from "hooks/query/useExpertQuery";
import { CloseButton } from "components/packageAndFeatures/components/Components";
import LogoDropzone from "components/categories/logoDropzone/LogoDropzone";
import TextArea from "components/common/textArea/TextArea";
import styles from "./AddExpertModal.module.scss";

function AddExpertModal({ data, onHide, type }) {
  const [form, setForm] = useState({
    avatar: null,
    about: "",
    education: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    name: "",
    position: "",
    telegram: "",
    twitter: "",
    youtube: "",
  });

  const { mutate: postExpert } = usePostExpertQuery(form.name);
  const { mutate: updateExpert } = usePutExpertQuery(form.name);

  useEffect(() => {
    if (data || type === "update") {
      setForm({
        avatar: data.avatar,
        about: data.about,
        education: data.education,
        facebook: data.facebook,
        instagram: data.instagram,
        linkedin: data.linkedin,
        name: data.name,
        position: data.position,
        telegram: data.telegram,
        twitter: data.twitter,
        youtube: data.youtube,
      });
    }
  }, [data, type]);

  const onChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const onChangeAvatar = useCallback((image) => {
    setForm((prev) => ({ ...prev, avatar: image }));
  }, []);

  const onSubmit = async () => {
    const { avatar, ...rest } = form;
    const payload = { ...form };

    if (form.avatar?.hasOwnProperty("path")) {
      const fileId = await uploadFile({ file: form.avatar });
      payload["avatar"] = fileId;
    }

    if (type === "create") {
      handleCreate(payload);
    }

    if (type === "update") {
      handleUpdate(payload);
    }

    onHide();
  };
  const handleCreate = (payload) => {
    postExpert(payload);
  };
  const handleUpdate = (payload) => {
    updateExpert({ ...payload, id: data.id });
  };

  return (
    <div className={styles[`wrapper`]}>
      <Modal.Header className={styles[`header`]}>
        <CloseButton position="top" onClick={onHide} />
        <Modal.Title className={styles[`header__title`]}>
          افزودن کارشناس
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles[`body`]}>
        <div className={styles[`row`]}>
          {/* temp */}
          <div className={styles[`dropzone-wrapper`]}>
            <LogoDropzone image={form.avatar} onImageChange={onChangeAvatar} />
            <span>افزودن تصویر</span>
          </div>

          <Input
            placeholder="نام کارشناس"
            name="name"
            value={form.name}
            onChange={onChangeInput}
          />
        </div>
        <div className={styles[`row`]}>
          <Input
            placeholder="سمت"
            name="position"
            value={form.position}
            onChange={onChangeInput}
          />
          <Input
            placeholder="تحصیلات"
            name="education"
            value={form.education}
            onChange={onChangeInput}
          />
        </div>

        <TextArea
          placeholder="درباره کارشناس"
          name="about"
          value={form.about}
          onChange={onChangeInput}
        />

        <h4>شبکه های اجتماعی</h4>
        <div className={styles[`row`]}>
          <Input
            placeholder="اینستاگرام"
            name="instagram"
            value={form.instagram}
            onChange={onChangeInput}
          />
          <Input
            placeholder="فیس بوک"
            name="facebook"
            value={form.facebook}
            onChange={onChangeInput}
          />
        </div>
        <div className={styles[`row`]}>
          <Input
            placeholder="تلگرام"
            name="telegram"
            value={form.telegram}
            onChange={onChangeInput}
          />
          <Input
            placeholder="لینکدین"
            name="linkedin"
            value={form.linkedin}
            onChange={onChangeInput}
          />
        </div>
        <div className={styles[`row`]}>
          <Input
            placeholder="توییتر"
            name="twitter"
            value={form.twitter}
            onChange={onChangeInput}
          />
          <Input
            placeholder="یوتیوب"
            name="youtube"
            value={form.youtube}
            onChange={onChangeInput}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className={styles[`footer`]}>
        <button className={styles[`button`]} onClick={onSubmit}>
          ثبت کارشناس
        </button>
      </Modal.Footer>
    </div>
  );
}

export default AddExpertModal;
