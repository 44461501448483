import http from "../httpService";
// get
export const getCollections = (page, limit, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/collections/get-all?page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    { signal }
  );
export const getCollection = (id, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/collections/get?id=${id}`,
    {
      signal,
    }
  );
// post
export const postCollection = (data) =>
  http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/collections/new`,
    data
  );
// put
export const putUpdateCollection = (data) =>
  http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/collections/update`,
    data
  );
// delete
export const deleteCollection = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/collections/delete?id=${id}`
  );
// patch append data
export const patchAppendMovieToCollection = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/collections/movies/append`,
    data
  );
// patch delete for appended data
export const patchDeleteMovieFromCollection = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/collections/movies/delete`,
    data
  );
