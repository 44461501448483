import { useState, useEffect, useCallback } from "react";
import { uploadFile } from "util/uploadFile";
import {
  usePostTelevisionQuery,
  usePutTelevisionQuery,
} from "hooks/query/useTelevisionQuery";
import { Modal } from "react-bootstrap";
import { Input } from "components/common/input/Input";
import { CloseButton } from "components/packageAndFeatures/components/Components";
import LogoDropzone from "components/categories/logoDropzone/LogoDropzone";
import styles from "./AddModal.module.scss";

function AddModal({ data, onHide, type }) {
  const { mutate: postTvLive } = usePostTelevisionQuery();
  const { mutate: putTvLive } = usePutTelevisionQuery();
  const [form, setForm] = useState({ logo: null, name: "", url: "" });

  useEffect(() => {
    if (data || type === "update") {
      setForm({
        logo: data.logo,
        name: data.name,
        url: data.url,
      });
    }
  }, [data, type]);

  const onChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const onChangeLogo = useCallback((image) => {
    setForm((prev) => ({ ...prev, logo: image }));
  }, []);

  const handleCreate = (payload) => {
    postTvLive(payload);
  };
  const handleUpdate = (payload) => {
    putTvLive({ ...payload, id: data.id });
  };
  const onSubmit = async () => {
    if (form.logo?.hasOwnProperty("path")) {
      const fileId = await uploadFile({ file: form.logo });
      form["logo"] = fileId;
    }

    if (type === "create") {
      handleCreate(form);
    }

    if (type === "update") {
      handleUpdate(form);
    }

    onHide();
  };

  return (
    <div className={styles.wrapper}>
      <Modal.Header className={styles.header}>
        <CloseButton position="top" onClick={onHide} />
        <Modal.Title className={styles.header__title}>
          {type === "update" ? "ویرایش" : "افزودن"} شبکه
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <div className={styles.row}>
          <LogoDropzone image={form.logo} onImageChange={onChangeLogo} />
          <Input
            name="name"
            placeholder="نام شبکه"
            onChange={onChangeInput}
            value={form.name}
          />
        </div>
        <Input
          name="url"
          placeholder="لینک شبکه"
          onChange={onChangeInput}
          value={form.url}
        />
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <button onClick={onSubmit} className={styles.button}>
          {type === "update" ? "ویرایش" : "ثبت"} شبکه
        </button>
      </Modal.Footer>
    </div>
  );
}

export default AddModal;
