import http from "../httpService";
export const getSliders = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/sliders/get-all`, {
    signal,
  });
export const getSlider = (id, signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/sliders/get?id=${id}`, {
    signal,
  });
export const deleteSlider = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/sliders/delete?id=${id}`
  );
export const postSlider = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/sliders/new`, data);
export const putSlider = (data) =>
  http.put(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/sliders/update`, data);
