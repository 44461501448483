import http from "../httpService";
// get
export const getPlayLists = (page, limit, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/playlists/get-all?page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    { signal }
  );
export const getPlayList = (id, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/playlists/get?id=${id}`,
    { signal }
  );
// delete
export const deletePlayList = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/playlists/by-user/delete?id=${id}`
  );
