import { combineReducers } from "redux";

import {
  createMovieReducer,
  getMovieByImdbReducer,
  movieFileUploadReducer,
  moviesListReducer,
  movieDownloadLinkReducer,
} from "./movies";

import {
  addNewSeasonEpisodeReducer,
  createSeriesReducer,
  seriesListReducer,
} from "./series";

import { getAllLiveStreamingReducer, setLiveStreamingReducer } from "./stream";

import { slideReducer } from "./slider";

import { artistListReducer, artistReducer } from "./artists";

import { collectionListReducer, createCollectionReducer } from "./collections";

import { uploadVideoManagerReducer } from "./videoUploadManager";

import { profileReducer } from "./profile";

import { articleFormReducer } from "./articles";
import { boxOfficeReducer } from "./boxOffice";
import { expertsReducer } from "./experts";
import { musicFormReducer, musicsReducer } from "./musics";

import { transcodeManagerReducer } from "./transcodeManager";

export const allReducers = combineReducers({
  moviesListReducer,
  getMovieByImdbReducer,
  createMovieReducer,
  movieFileUploadReducer,
  movieDownloadLinkReducer,

  seriesListReducer,
  createSeriesReducer,
  addNewSeasonEpisodeReducer,

  setLiveStreamingReducer,
  getAllLiveStreamingReducer,

  slideReducer,

  uploadVideoManagerReducer,
  transcodeManagerReducer,

  artistReducer,
  artistListReducer,

  collectionListReducer,
  createCollectionReducer,

  profileReducer,

  boxOfficeReducer,

  articleFormReducer,

  musicFormReducer,
  musicsReducer,

  expertsReducer,
});
