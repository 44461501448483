import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { isObject } from "lodash-es";
import styles from "./styles.module.scss";

//TODO: check accept files type for subtitle and pictures
//TODO: Fix component for other type of file (now its work for image preview type)

function FileUploader({
  text,
  maxFiles,
  images,
  btnPlace = "null",
  height,
  type,
  imageState,
  onRemove,
  isBanner,
}) {
  // const imageAcceptType = ".jpg,.jpeg,.png";

  const acceptedFile = (kind) => {
    switch (kind) {
      case "image":
        return ".jpg,.jpeg,.png";
      case "subtitle":
        // return ".srt,.vtt,.ass";
        return ".srt,.vtt";
      default:
        return ".jpg,.jpeg,.png,.pdf,.doc, .docx,.xlsx";
    }
  };

  const [imageFile, setImageFile] = imageState;
  const [selectedImage, setSelectedImage] = useState("");

  const onDropAccepted = useCallback((acceptedFiles, rejectedFiles) => {
    // Do something with the files
    // images(acceptedFiles);
    const file = acceptedFiles[0];

    setImageFile(file);

    const fileReader = new FileReader();
    fileReader.addEventListener(
      "load",
      () => {
        setSelectedImage(fileReader.result);
      },
      false
    );

    fileReader.readAsDataURL(file);

    //TODO: fix it
    // rejectedFiles.forEach((file) => {
    //   file.errors.forEach((error) => {
    //     if (error.code === "file-invalid-type") {
    //       toasts.error(`فقط مجاز به استفاده از فرمت های : ${imageAcceptType}`);
    //     }

    //     if (error.code === "too-many-files") {
    //       toasts.error(`فقط مجاز به ارسال ${maxFiles} فایل هستید`);
    //     }
    //   });
    // });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFile(type),
    maxFiles: maxFiles,
    onDropAccepted,
  });

  return (
    <>
      {imageFile && type !== "subtitle" ? (
        <div className={styles.uploadPicList}>
          <div className={styles.uploadPicListItem}>
            <img
              className={`${styles.posterPic} ${isBanner ? styles.banner : ""}`}
              src={
                isObject(imageFile)
                  ? `${selectedImage}`
                  : `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${imageFile}`
              }
              alt=""
            />
            <button className="btn">
              {imageFile.name}
              <img
                onClick={onRemove}
                className={styles.trashIcon}
                src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
                alt="x"
              />
            </button>
          </div>
        </div>
      ) : (
        <section className={styles.wrapper}>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div style={{ height: `${height}px` }} className={styles.zone}>
              {btnPlace === "top" && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/upload-plus.svg`}
                  className={styles.plus}
                  alt=""
                />
              )}
              <p>
                {btnPlace === "right" && (
                  <img
                    className={styles.plusBlue}
                    src={`${process.env.PUBLIC_URL}/images/common/plus-black.svg`}
                    alt=""
                  />
                )}
                {text}
              </p>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default FileUploader;
