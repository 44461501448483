import { useSelector } from "react-redux";
import Information from "./information";
import InputType from "./input-type";
import Watermark from "./watermark";
export default function GeneralInformation() {
  const liveStreaming = useSelector((state) => state.setLiveStreamingReducer);
  return (
    <>
      <Information liveStreaming={liveStreaming} />
      <InputType />
      <Watermark liveStreaming={liveStreaming} />
    </>
  );
}