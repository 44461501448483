import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import SliderRow from "./sliderRow/SliderRow";
import styles from "./SliderList.module.scss";

export default function SliderList({ data }) {
  return (
    <>
      <section className={styles.tableWrapper}>
        <Table className={styles.table} responsive>
          <thead>
            <tr className={styles.THead}>
              <th>اسلاید</th>
              <th>ردیف اسلاید ({data.length} مورد)</th>
              <th>محل قرارگیری</th>
              <th>اولویت نمایش</th>
              <th>وضعیت نمایش</th>
            </tr>
          </thead>
          <tbody>
            {data.map((list, index) => (
              <SliderRow key={list.id} data={list} index={index} />
            ))}
          </tbody>
        </Table>
        <Link to="/sliders/addSlider" className={`${styles.showMoreBtn} btn`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/plus.svg`}
            alt="+"
          />
          افزودن اسلاید
        </Link>
      </section>
    </>
  );
}
