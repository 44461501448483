import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { updateCollectionInformation } from "../../../../../../redux/actions/collections";
import { deleteFile } from "../../../../../../services/files/files";
import FileUploader from "../../../../../common/fileUploader";
import toasts from "../../../../../common/toasts/toasts";
// import ProgressBar from "../../../../../common/progressBar/ProgressBar";
import styles from "./UploadPictures.module.scss";

//TODO: for uploadPicProgressBar className should use condition
//TODO: can create separate component for uploaded pictures

export default function UploadPictures() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const createCollection = useSelector(
    (state) => state.createCollectionReducer
  );
  const handleChangeImage = (file) => {
    dispatch(
      updateCollectionInformation({
        cover: file,
      })
    );
  };

  const handleRemoveImage = async () => {
    if (id) {
      const { status } = await deleteFile(createCollection?.cover);
      if (status === 200) {
        toasts.success("فایل باموفقیت حذف شد.");
      }
    }
    dispatch(
      updateCollectionInformation({
        cover: "",
      })
    );
  };

  return (
    <>
      {createCollection.cover?.path ? "" : <h4>بارگذاری تصاویر</h4>}
      <div className={styles.uploadPicPill}>
        <div className={styles.fileUploaderCustom}>
          <FileUploader
            text="انتخاب Thumbnail در ابعاد 300x450 پیکسل"
            maxFiles={1}
            btnPlace={"null"}
            height={56}
            type="image"
            imageState={[
              createCollection.cover,
              (file) => handleChangeImage(file),
            ]}
            onRemove={handleRemoveImage}
          />
        </div>
        {/* <div className={styles.addBtn}>افزودن</div> */}
      </div>

      {/* <div className={`${styles.uploadPicPill} ${styles.uploadPicProgressBar}`}>
        <div className={styles.fileUploaderCustom}>
          <FileUploader
            text="انتخاب Poster در ابعاد 1200x675 پیکسل"
            maxFiles={1}
            btnPlace={"null"}
            height={56}
          />
        </div>
        <ProgressBar fileName={"poster.jpg"} progressed={64} type={"second"} />
        <div className={styles.addBtn}>افزودن</div>
      </div> */}

      {/* <div className={styles.uploadPicList}>
        <div className={styles.uploadPicListItem}>
          <img
            className={styles.thumbnailPic}
            src={`${process.env.PUBLIC_URL}/images/movies/test-thumbnail.jpg`}
            alt=""
          />
          <button className="btn">
            thumbnail.jpg
            <img
              className={styles.trashIcon}
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt=""
            />
          </button>
        </div>
        <div className={styles.uploadPicListItem}>
          <img
            className={styles.posterPic}
            src={`${process.env.PUBLIC_URL}/images/movies/test-poster.jpg`}
            alt=""
          />
          <button className="btn">
            poster.jpg
            <img
              className={styles.trashIcon}
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt=""
            />
          </button>
        </div>
      </div> */}
    </>
  );
}
