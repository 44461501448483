import { useDispatch } from "react-redux";
import { updateLiveStreamField } from "redux/actions/stream";
import { watermarkPositionConstants } from "constants/stream";
import DropDown from "new-components/drop-down";
import HeadTitle from "new-components/head-title";
import SingleUploader from "new-components/file-uploader/single-uploader";
import styles from "./styles.module.scss";
export default function Watermark({ liveStreaming }) {
  const dispatch = useDispatch();
  const handleChangeFields = (options, name) => {
    dispatch(
      updateLiveStreamField({
        [name]: options,
      })
    );
  };
  const handleChangeImage = (file, name) => {
    dispatch(
      updateLiveStreamField({
        [name]: file,
      })
    );
  };
  return (
    <>
      <HeadTitle title="نمایش واترمارک" />
      <div className={styles.wapper}>
        <SingleUploader
          placeholder={liveStreaming.watermark_id?.name}
          dropzonePlaceholder="افزودن واترمارک"
          imageState={[
            liveStreaming.watermark_id,
            (file) => handleChangeImage(file, "watermark_id"),
          ]}
        />
        <DropDown
          placeholder="انتخاب جایگاه واترمارک"
          options={watermarkPositionConstants}
          value={liveStreaming.watermark_position}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={(options) =>
            handleChangeFields(options, "watermark_position")
          }
          name="watermark_position"
        />
      </div>
    </>
  );
}
