import { useState } from "react";
import { uniqueId } from "lodash-es";
import { useCategoriesQuery } from "hooks/query/useCategoryQuery";
import { Modal, Table } from "react-bootstrap";
import AddCategoryModal from "./addCategoryModal/AddCategoryModal";
import Row from "./row/Row";
import styles from "./Categories.module.scss";

function Categories() {
  const { data } = useCategoriesQuery();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onOpenModal = () => {
    setIsModalOpen(true);
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles[`wrapper`]}>
        <Table className={styles[`table`]}>
          <thead>
            <tr className={styles[`head-row`]}>
              <th>نام دسته بندی</th>
              <th colSpan={2}>
                توضیحات
                <button
                  className={`${styles[`button`]} ${styles[`button--primary`]}`}
                  onClick={onOpenModal}>
                  <img src={"/images/categories/add-square.svg"} alt="" />
                  <span>افزودن دسته بندی</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <Row key={uniqueId("category-item-")} data={item} />
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        show={isModalOpen}
        onHide={onCloseModal}
        dialogClassName={styles[`modal`]}
        centered>
        <AddCategoryModal onHide={onCloseModal} type="create" />
      </Modal>
    </>
  );
}

export default Categories;
