import http from "../httpService";
export const getRequestTitles = (sortType, page, limit, signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/requests/get-all-by-type?type=${sortType}&page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    { signal }
  );
};
export const deleteRequestTitle = (id) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/requests/delete?id=${id}`
  );
};
