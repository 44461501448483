import styles from "./PackagesSummary.module.scss";

function PackagesSummary({ listOfPackages }) {
  return (
    <div className={styles.packageDetail}>
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/total-packages.svg`}
          alt=""
        />
        <span>{listOfPackages.packages?.length}</span>
        کل بسته ها
      </div>
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/active-packages.svg`}
          alt=""
        />
        <span>{listOfPackages.activePackages?.length}</span>
        بسته های فعال
      </div>
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/inactive-packages.svg`}
          alt=""
        />
        <span>{listOfPackages.inActivePackages?.length}</span>
        بسته های غیر فعال
      </div>
    </div>
  );
}

export default PackagesSummary;