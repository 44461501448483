import Row from "../row/Row";

export default function Tracks({ data }) {
  return (
    <>
      {data?.map((item) => (
        <Row
          key={item.id}
          data={item}
          tab="single"
        />
      ))}
    </>
  );
}
