import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  deleteUserCountrie,
  deleteUserLanguage,
  getAllMyCountrie,
  getAllMyLanguage,
  postUserCountrie,
  postUserLanguage,
} from "services/settings/settingsService";
import toasts from "components/common/toasts/toasts";

//Language
export const useMyLanguagesQuery = () => {
  return useQuery(["my-languages"], ({ signal }) => getAllMyLanguage(signal), {
    select: (data) => {
      return data.data.data.results;
    },
  });
};
export const usePostUserLanguageQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postUserLanguage, {
    onSuccess: () => {
      queryClient.invalidateQueries("my-languages");
      toasts.success(`زبان(های) موردنظر با موفقیت اضافه شد`);
    },
    onError: (error) => {
      if (error.response.status === 409) {
        toasts.error("زبان(های) موردنظر قبلا اضافه شده اند!");
      } else {
        toasts.error("مشکلی برای اضافه شدن زبان(های) موردنظر پیش آمده");
      }
    },
  });
};
export const useDeleteUserLanguageQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteUserLanguage, {
    onSuccess: () => {
      queryClient.invalidateQueries("my-languages");
      toasts.success(`زبان موردنظر باموفقیت حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
//Countries
export const useMyCountriesQuery = () => {
  return useQuery(["my-countries"], ({ signal }) => getAllMyCountrie(signal), {
    select: (data) => {
      return data.data.data.results;
    },
  });
};
export const usePostUserCountriesQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postUserCountrie, {
    onSuccess: () => {
      queryClient.invalidateQueries("my-countries");
      toasts.success(`کشور(های) موردنظر با موفقیت اضافه شد.`);
    },
    onError: (error) => {
      if (error.response.status === 409) {
        toasts.error("کشور(های) موردنظر قبلا اضافه شده اند!");
      } else {
        toasts.error("مشکلی برای اضافه شدن کشور(های) موردنظر پیش آمده");
      }
    },
  });
};
export const useDeleteUserCountriesQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteUserCountrie, {
    onSuccess: () => {
      queryClient.invalidateQueries("my-countries");
      toasts.success(`کشور موردنظر باموفقیت حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
