import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Table, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import { updateSelectAllSeries } from "../../../redux/actions/series";
import { useDeleteSeriesQuery } from "hooks/query/useSerieQuery";
import { Input } from "components/common/input/Input";
import SeriesRow from "./seriesRow/SeriesRow";
// import Filter from "../Filter/Filter";
import toasts from "components/common/toasts/toasts";
import DeleteMultiModal from "../../common/modals/deleteMultiModal/DeleteMultiModal";
import EmptyState from "../../common/emptyState/EmptyState";
import styles from "./SeriesList.module.scss";

export default function SeriesList({
  data,
  isPreviousData,
  refetch,
  seriesName,
  setSeriesName,
  currentPage
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { mutateAsync: deleteSeries } = useDeleteSeriesQuery("multiType");
  const { selectedSeries, isAllSelected } = useSelector(
    (state) => state.seriesListReducer
  );
  const [allChecked, setAllChecked] = useState(false);
  // Modals State
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (data?.length) {
      if (selectedSeries.length === data.length) {
        dispatch(
          updateSelectAllSeries({
            allChecked: true,
            seriesList: data,
          })
        );
        setAllChecked(true);
      } else if (isAllSelected && selectedSeries.length < data.length) {
        dispatch(
          updateSelectAllSeries({
            allChecked: false,
            seriesList: [],
          })
        );
        setAllChecked(false);
      } else {
        setAllChecked(false);
      }
    }
  }, [data, dispatch, isAllSelected, selectedSeries]);

  const handleSelectAll = (allChecked) => {
    dispatch(
      updateSelectAllSeries({
        allChecked: allChecked,
        seriesList: data,
      })
    );
    setAllChecked(allChecked);
  };

  const handleDeleteSeries = async () => {
    for (const item of selectedSeries) {
      const { status } = await deleteSeries(item.id);
      if (status === 200) {
        toasts.success(`سریال ${item.title} باموفقیت حذف شد.`);
      }
      setShowDeleteModal(false);
      dispatch(
        updateSelectAllSeries({
          allChecked: false,
          seriesList: [],
        })
      );
    }
  };

  // Search
  const [showInput, setShowInput] = useState(false);
  const ref = useRef();
  const handleCloseInput = (e) => {
    if (!ref.current.contains(e.target)) {
      setShowInput(false);
    }
  };

  return (
    <section onClick={handleCloseInput}>
      <div className={styles.headerWrapper}>
        <div
          onClick={() => handleSelectAll(!allChecked)}
          className={`btn ${styles.btn} ${styles.primary} ${
            allChecked ? styles.activeBtn : ""
          }`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/movies/chose-all.svg`}
            alt=""
          />
          انتخاب همه
        </div>
        {/* {!selectedSeries.length && <Filter />} */}
        {selectedSeries.length > 0 && (
          <div
            onClick={() => setShowDeleteModal(true)}
            className={`btn ${styles.btn} ${styles.danger}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt=""
            />
            حذف موارد انتخاب شد
          </div>
        )}
        {!selectedSeries.length && (
          <div
            onClick={() => history.push("/series/addSeries")}
            className={`btn ${styles.btn} ${styles.primary}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/movies.svg`}
              alt=""
              className={styles.logoActive}
            />
            افزودن سریال
          </div>
        )}
      </div>
      <section className={styles.tableWrapper}>
        <Table className={styles.table} responsive>
          <thead>
            <tr className={styles.THead}>
              <th
                ref={ref}
                onClick={() => setShowInput(true)}
                className={styles.customHeader}>
                {showInput ? (
                  <Input
                    placeholder="جستجو سریال"
                    value={seriesName}
                    onBlur={refetch}
                    onChange={(e) => setSeriesName(e.target.value)}
                    image={{
                      enable: true,
                      icon: "/images/icons/search.svg",
                      clickHandler: refetch,
                    }}
                  />
                ) : (
                  <>
                    پوستر و عنوان سریال
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/search.svg`}
                      alt=""
                    />
                  </>
                )}
              </th>
              <th>ردیف ({data?.length} مورد)</th>
              <th>سال انتشار</th>
              <th>تعداد فصل ها و قسمت ها</th>
              <th>وضعیت پخش</th>
              {/* <th>زمان افزودن سریال به پروژه</th> */}
              <th>وضعیت انتشار</th>
              <th>عملیات</th>
            </tr>
          </thead>
          {data?.length > 0 && (
            <tbody>
              {isPreviousData ? (
                <div className="spinnerCenter">
                  <Spinner animation="border" as="i" />
                </div>
              ) : (
                data.map((series, index) => (
                  <SeriesRow key={series.id} data={series} index={index} currentPage={currentPage}/>
                ))
              )}
            </tbody>
          )}
        </Table>
        {!data?.length > 0 && (
          <div className={styles.emptyState}>
            <EmptyState
              subHeader
              buttonOptions={{
                link: "/series/addSeries",
                text: "افزودن سریال",
                icon: "/images/menu/series.svg",
                class: true,
              }}>
              <p className={styles.emptyStateText}>نتیجه ای یافت نشد!</p>
            </EmptyState>
          </div>
        )}
      </section>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={styles.deleteMovieModal}>
        <DeleteMultiModal
          titleData={{
            deleteObject:
              selectedSeries.length === 1 ? selectedSeries[0].name : "",
            isMoreThanOne: selectedSeries.length > 1,
            placeName: "سریال",
          }}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={handleDeleteSeries}
        />
      </Modal>
    </section>
  );
}
