import { useState } from "react";
import styles from "./Switch.module.scss";

//TODO: dynamicing

function Switch({ label, switchState, isDisabled }) {
  //   const [toggle, setToggle] = switchState;
  const [toggle, setToggle] = useState(true);

  return (
    <div
      className={`${styles.group} ${isDisabled ? styles.disabled : ""}`}
      onClick={() => setToggle(!isDisabled ? !toggle : false)}>
      <div
        className={`${styles.switchBtn} ${
          toggle ? styles.toggleOn : styles.toggleOff
        }`}>
        <div className={styles.toggleBtn}></div>
      </div>

      <span className={styles.label}>
        {label} {` `}
        <span className={toggle ? styles.status : ""}>
          ({toggle ? "فعال" : "غیر فعال"})
        </span>
      </span>
    </div>
  );
}

export default Switch;
