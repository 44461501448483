import Row from "../row/Row";
import styles from "../Playlists.module.scss";

export default function List({ data }) {
  return (
    <>
      {data?.length > 0 ? (
        data.map((item) => <Row key={item.id} data={item} />)
      ) : (
        <div className={styles.empty}>پلی لیست خالی می باشد.</div>
      )}
    </>
  );
}
