import { useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import styles from "./SearchDropdown.module.scss";

// sub components for dropdown
const SearchInput = ({ value, onChange, searchPlaceholder, ...props }) => {
  return (
    <div className={styles.inputWrapper}>
      <input
        type="text"
        placeholder={searchPlaceholder}
        onChange={onChange}
        // value={value}
        autoFocus
        {...props}
      />
      <img src={`${process.env.PUBLIC_URL}/images/icons/search.svg`} alt="" />
    </div>
  );
};
// Option
const Option = ({ item, onSelectOption, onRemoveOption }) => {
  const [checked, setChecked] = useState(false);
  const handleSelect = (checked) => {
    if (checked) {
      onSelectOption(item);
    } else {
      onRemoveOption(item.id);
    }
    setChecked(checked);
  };
  return (
    <div className={styles.optionWrapper}>
      <input onClick={() => handleSelect(!checked)} type="checkbox" />
      {item.name}
    </div>
  );
};
// main component
function SearchDropdown({
  data,
  stateProps,
  placeholder,
  searchPlaceholder,
  handlePost,
  type,
}) {
  const [value, setValue] = stateProps;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSelectOption = (option) => {
    setValue((prev) => [...prev, option]);
  };
  const handleRemoveOption = (option) => {
    const removeFromArrayWithID = value.filter((item) => item.id !== option);
    setValue(removeFromArrayWithID);
  };

  const onOpenMenu = () => {
    setIsMenuOpen(true);
  };
  const onCloseMenu = () => {
    setIsMenuOpen(false);
    if (value.length) {
      handlePost({
        [type]: value.map((i) => i.id),
      });
    }
  };

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const filteredList = useMemo(() => {
    return data?.filter(
      (item) =>
        item.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      // search for persian name
      // 	||
      // item.name_in_persian
      // 	.toLocaleLowerCase()
      // 	.includes(searchValue.toLocaleLowerCase())
    );
  }, [searchValue, data]);

  return (
    <div className={styles.wrapper}>
      <Dropdown bsPrefix={styles.dropdown} show={isMenuOpen}>
        <Dropdown.Toggle
          bsPrefix={styles.toggle}
          childBsPrefix={styles.button}
          onClick={isMenuOpen ? onCloseMenu : onOpenMenu}>
          <div className={`${styles.valueContainer} ${styles.withOutValue}`}>
            {/* {value ? value.name : placeholder} */}
            {placeholder}
          </div>
          <div className={styles.indicatorContainer}>
            <FaChevronDown color="#909090" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix={styles.menu}>
          {/* SEARCH INPUT */}
          <div className={styles.searchContainer}>
            <SearchInput
              searchPlaceholder={searchPlaceholder}
              // value={searchValue}
              onChange={onChangeSearchValue}
            />
          </div>

          {/* MENU LIST */}
          <div className={styles.optionContainer}>
            {data?.length && filteredList.length ? (
              filteredList.map((item) => (
                <Option
                  key={item.id}
                  item={item}
                  onSelectOption={handleSelectOption}
                  onRemoveOption={handleRemoveOption}
                />
              ))
            ) : (
              <div className={styles.notFoundedSection}>
                هیچ نتیجه ای یافت نشد لطفا دوباره سعی کنید
              </div>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default SearchDropdown;
