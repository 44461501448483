import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import styles from "./Input.module.scss";

export const Input = (props) => {
  return (
    <Form.Group className={`${styles.group} ${styles.errorsInput}`}>
      {props.label && (
        <label className={styles.label}>
          {props.label}
          {props.tooltip && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip className={`${styles.tooltip} input`}>
                  {" "}
                  {props.tooltip}
                </Tooltip>
              }>
              {({ ref, ...triggerHandler }) => (
                <img
                  src={`${process.env.PUBLIC_URL}/images/trades/question.svg`}
                  alt=""
                  className={styles.tip}
                  {...triggerHandler}
                  ref={ref}
                />
              )}
            </OverlayTrigger>
          )}
          {props.require && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      <input
        type="text"
        name={props.name}
        className={`form-control ${styles.formControl} ${
          props.errors && styles.errors
        } ${props.sign && styles.inputEnable}`}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyPress={props.onKeyPress}
        value={props.value}
        readOnly={props.isReadonly}
        autoComplete={`${props.autoComplete || "off"}`}
        {...props}
      />
      {props.errors && (
        <img
          className={styles.errorsImg}
          src={`${process.env.PUBLIC_URL}/images/common/error.svg`}
          alt="Error!"
        />
      )}
      {props.clear && (
        <img
          className={styles.clearInputIcon}
          src={`${process.env.PUBLIC_URL}/images/common/red-close.svg`}
          alt="clear"
          onClick={props.clearOnClick}
        />
      )}
      {props.errors && (
        <span className={styles.errorsText}>{props.errorsText}</span>
      )}
      {props.sign && <span className={styles.sign}>{props.signIcon}</span>}
      {props.text && (
        <span onClick={props.handleTextClick} className={styles.text}>
          {props.text}
        </span>
      )}
      {props.image?.enable && (
        <img
          className={styles.inputImageIcon}
          src={`${process.env.PUBLIC_URL}${props.image.icon}`}
          alt=""
          onClick={props.image.clickHandler}
        />
      )}
    </Form.Group>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
  require: PropTypes.bool,
  sign: PropTypes.bool,
  // value: PropTypes.string,
  // onChange: PropTypes.func,
  // onBlur: PropTypes.func,
};
