import { useMemo, useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAlbumsQuery } from "hooks/query/useMusicQuery";
import { Table, Spinner } from "react-bootstrap";
import Paginate from "components/common/paginate/Paginate";
import Albums from "./albums/Albums";
import Tracks from "./tracks/Tracks";
import styles from "./Music.module.scss";

function Music() {
  const history = useHistory();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const { data: albums, isPreviousData } = useAlbumsQuery(currentPage, 10);

  const albumsList = albums?.results.filter((item) => item.type === "album");
  const singleTracksList = albums?.results.filter((item) => item.type === "single");

  useEffect(() => {
    if (location.hash === "") {
      history.replace("/musics?page=1#type=single");
    }
  }, [history, location.hash]);

  const listToShow = useMemo(
    () => (location.hash === "#type=album" ? albumsList : singleTracksList),
    [albumsList, location.hash, singleTracksList]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.cTab}>
        <Link to="/musics?page=1#type=single">
          <button
            className={location.hash === "#type=single" ? styles.active : ""}>
            تک آهنگ
          </button>
        </Link>
        <Link to="/musics?page=1#type=album">
          <button
            className={location.hash === "#type=album" ? styles.active : ""}>
            آلبوم
          </button>
        </Link>
      </div>

      <div className={styles.bgWrapper}>
        <Table className={styles.table} responsive>
          <thead>
            <tr>
              <th>نام موسیقی</th>
              <th>خواننده</th>
              <th colSpan={2}>نوع موسیقی</th>
              <th className={styles.newMusic}>
                <Link to="/musics/addMusic">
                  <img src="/images/common/plus.svg" alt="+" />
                  <span> افزودن موسیقی جدید</span>
                </Link>
              </th>
            </tr>
          </thead>
          <tbody>
            {isPreviousData ? (
              <div className="spinnerCenter">
                <Spinner animation="border" as="i" />
              </div>
            ) : location.hash === "#type=album" ? (
              <Albums data={listToShow} />
            ) : (
              <Tracks data={listToShow} />
            )}
          </tbody>
        </Table>
      </div>

      <Paginate
        type="musics"
        paginateState={[currentPage, setCurrentPage]}
        total={
          location.hash === "#type=album"
            ? albumsList?.count
            : singleTracksList?.count
        }
      />
    </div>
  );
}

export default Music;
