import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  deleteArticle,
  getArticle,
  getArticles,
  postArticle,
  putArticle,
} from "services/articles/articlesService";
import toasts from "components/common/toasts/toasts";

export const useArticlesQuery = (page) => {
  return useQuery(["articles", page], ({ signal }) => getArticles(page,signal), {
    // cacheTime: 5000,
    // refetchOnWindowFocus: false,
    // refetchInterval: 2000,
    // refetchIntervalInBackground: true,
    keepPreviousData: true,
    select: (data) => {
      return data.data.data;
    },
  });
};
export const useArticleQuery = (articleId) => {
  return useQuery(["article", articleId], ({ signal }) => getArticle(articleId,signal), {
    enabled: !!articleId,
    select: (data) => {
      return data.data.data;
    },
  });
};

export const usePostArticleQuery = (title) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(postArticle, {
    onSuccess: () => {
      queryClient.invalidateQueries("articles");
      history.push("/articles");
      toasts.success(`مقاله ${title} با موفقیت ثبت شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت مقاله پیش آمده");
    },
  });
};
export const usePutArticleQuery = (title) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(putArticle, {
    onSuccess: () => {
      queryClient.invalidateQueries("articles");
      history.push("/articles");
      toasts.success(`مقاله ${title} با موفقیت بروزرسانی شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی مقاله پیش آمده");
    },
  });
};
export const useDeleteArticleQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteArticle, {
    onSuccess: () => {
      queryClient.invalidateQueries("articles");
      toasts.success(`مقاله ${title} باموفقیت حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
