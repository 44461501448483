import { useDeletePlaylistQuery } from "hooks/query/usePlaylistQuery";
import { Link } from "react-router-dom";
import { handleGenerateStringWithComma } from "util/generator";
import styles from "./Row.module.scss";

export default function Row({ data }) {
  const { mutate: deletePlayList } = useDeletePlaylistQuery(
    data.title_in_persian || data.title
  );
  const allMovies = [...data.movies, ...data.series];

  const handleDelete = () => {
    deletePlayList(data.id);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <div className={styles.postersWrapper}>
          <div className={styles.posters}>
            {data.cover?.length ? (
              <img
                loading="lazy"
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
                alt=""
              />
            ) : (
              <img
                loading="lazy"
                src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
                alt=""
              />
            )}
            {allMovies?.slice(0, 2).map(({ cover }, i) => (
              <img
                loading="lazy"
                alt=""
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${cover}`}
                key={i}
              />
            ))}
          </div>
        </div>
        <div className={styles.title}>
          <span>{data.title_in_persian || data.title}</span>
          {/* <span>1400/08/15</span> */}
        </div>
      </div>
      <div className={styles.content}>
        عنوان ها:{" "}
        {allMovies.length > 0
          ? allMovies?.length > 3
            ? handleGenerateStringWithComma(allMovies.slice(0, 3), "title") +
              "..."
            : handleGenerateStringWithComma(allMovies, "title")
          : "-"}
      </div>
      <div className={styles.details}>
        <div className={styles.box}>
          <div className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/eye.svg`}
              alt=""
            />
          </div>
          <span>0</span>
        </div>
        <div className={styles.box}>
          <div className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/heart.svg`}
              alt=""
            />
          </div>
          <span>{data?.likes}</span>
        </div>
        <div className={styles.box}>
          <div className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/save2.svg`}
              alt=""
            />
          </div>
          <span>0</span>
        </div>
      </div>
      <div className={styles.actions}>
        <Link to={`/playlists/${data.id}`} className={styles.seeBtn}>
          مشاهده پلی لیست
        </Link>
        <button onClick={handleDelete} className="btn">
          <img
            src={`${process.env.PUBLIC_URL}/images/articles/trash.svg`}
            alt=""
          />
        </button>
      </div>
    </div>
  );
}
