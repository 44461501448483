import { Fragment, useState } from "react";
import { usePostCategoryQuery } from "hooks/query/useCategoryQuery";
import { Input } from "components/common/input/Input";
import { CloseButton } from "components/packageAndFeatures/components/Components";
import { Modal } from "react-bootstrap";
import toasts from "components/common/toasts/toasts";
import styles from "./AddModal.module.scss";

function AddModal({ onHide, userId }) {
  const [input, setInput] = useState("");
  const { mutate: postCategory } = usePostCategoryQuery();
  const handleAddCategoryToOption = () => {
    if (input.length > 0) {
      postCategory({
        name: input,
        user_id: userId,
      });
      onHide();
    } else {
      toasts.error("لطفا نام دسته بندی را وارد کنید!");
    }
  };

  return (
    <Fragment>
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
          افزودن دسته بندی جدید
        </Modal.Title>

        <CloseButton position="top" onClick={onHide} />
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <Input
          placeholder={"نام دسته بندی ها"}
          onChange={(e) => setInput(e.target.value)}
          value={input}
        />
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <button onClick={onHide} className={`${styles.btn} ${styles.text}`}>انصراف</button>
        <button
          className={`${styles.btn} ${styles.primary}`}
          onClick={handleAddCategoryToOption}>
          افزودن دسته بندی
        </button>
      </Modal.Footer>
    </Fragment>
  );
}

export default AddModal;
