import http from "../httpService";

//Get
export const getAllLanguage = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/languages/get-all`, {
    signal,
  });

export const getAllGenre = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/genres/get-all`, {
    signal,
  });

export const getAllCountrie = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/countries/get-all`, {
    signal,
  });

export const getAllDistributors = (signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/distributors/get-all`,
    {
      signal,
    }
  );
