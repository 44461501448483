import http from "../httpService";
export const postNewArtist = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/artists/new`, data);
export const putArtist = (data) =>
  http.put(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/artists/update`, data);
export const deleteArtist = (artistId) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/artists/delete?id=${artistId}`
  );
export const getArtist = (artistId, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/artists/get?id=${artistId}`,
    {
      signal,
    }
  );
export const getArtists = (page, limit, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/artists/get-all?page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    { signal }
  );
