import { musicTypes } from "../../constants/musics";

const addFlagOfAlreadyAppended = (arr) =>
  arr.map((item) => ({ ...item, alreadyAppended: true }));

// music form
export const initialState = {
  // maybe remove the nesting or separate the logics
  form: {
    name: "",
    name_in_persian: "",
    release: "",
    lyrics: "",
    publisher: "",
    owner_name: "",
    producer: null,
    kind: null,
    genre: null,
  },
  appendedPeople: {
    artists: [],
    singers: [],
    arrangements: [],
    songwriters: [],
  },
  audio: [], // can be an array with one item or more
  cover: null,
  type: "single", // enum of: single-track | album
};

export const musicFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALBUM": {
      const { payload } = action;

      return {
        ...state,
        form: {
          ...state.form,
          name: payload.name,
          name_in_persian: payload.name_in_persian,
          release: payload.release,
          lyrics: payload.lyrics,
          publisher: payload.publisher,
          owner_name: payload.owner_name,
          producer: payload.producer,
          kind: payload.kind,
          genre: payload.genre,
        },
        appendedPeople: {
          ...state.appendedPeople,
          artists: addFlagOfAlreadyAppended(payload.artists),
          singers: addFlagOfAlreadyAppended(payload.singers),
          arrangements: addFlagOfAlreadyAppended(payload.arrangements),
          songwriters: addFlagOfAlreadyAppended(payload.songwriters),
        },
        audio: addFlagOfAlreadyAppended(payload.tracks),
        cover: payload.cover,
        type: payload.type,
      };
    }
    case "FETCH_ALBUM_INFORMATIONS": {
      const { payload } = action;

      return {
        ...state,
        form: {
          ...state.form,
          name: payload.name,
          name_in_persian: payload.name_in_persian,
          release: payload.release,
          lyrics: payload.lyrics,
          publisher: payload.publisher,
          owner_name: payload.owner_name,
          producer: payload.producer,
          kind: payload.kind,
          genre: payload.genre,
        },
        appendedPeople: {
          ...state.appendedPeople,
          artists: addFlagOfAlreadyAppended(payload.artists),
          singers: addFlagOfAlreadyAppended(payload.singers),
          arrangements: addFlagOfAlreadyAppended(payload.arrangements),
          songwriters: addFlagOfAlreadyAppended(payload.songwriters),
        },
        audio: addFlagOfAlreadyAppended(payload.tracks),
        cover: payload.cover,
        type: payload.type,
      };
    }

    //* NOTE: on change type of music reset other fields
    case "SET_MUSIC_TYPE":
      return {
        ...initialState,
        type: action.payload,
      };

    // setting audio files
    case "SET_MUSIC_AUDIO_TO_SINGLE_TRACK":
      return { ...state, audio: [action.payload] };

    case "ADD_MUSIC_AUDIO_TO_ALBUM":
      return { ...state, audio: [...state.audio, action.payload] };

    // remove from local store with uuid (not stored in database)
    case "REMOVE_MUSIC_AUDIO_FROM_ALBUM_WITH_UUID":
      return {
        ...state,
        audio: state.audio.filter((item) => item.uuid !== action.payload),
      };
    // remove from database then remove from reducer
    case "REMOVE_MUSIC_AUDIO_FROM_ALBUM_WITH_ID":
      return {
        ...state,
        audio: state.audio.filter((item) => item.id !== action.payload),
      };

    case "UPDATE_MUSIC_COVER":
      return { ...state, cover: action.payload };

    case "UPDATE_MUSIC_FORM":
      return { ...state, form: { ...state.form, ...action.payload } };
    // appended section
    case "ADD_PEOPLE_TO_APPEND_LIST": {
      const { appendListName, newItem } = action.payload;

      return {
        ...state,
        appendedPeople: {
          ...state.appendedPeople,
          [appendListName]: [...state.appendedPeople[appendListName], newItem],
        },
      };
    }

    case "REMOVE_PEOPLE_FROM_APPEND_LIST": {
      const { appendListName, personId } = action.payload;

      return {
        ...state,
        appendedPeople: {
          ...state.appendedPeople,
          [appendListName]: state.appendedPeople[appendListName].filter(
            (item) => item.id !== personId
          ),
        },
      };
    }

    case "UPDATE_MUSIC_AUDIO":
      return {
        ...state,
        audio: state.audio.map((item) => {
          if (item.uuid === action.payload.uuid) {
            return action.payload.newAudio;
          }

          return item;
        }),
      };

    case "RESET_MUSIC_FORM":
      return initialState;

    default:
      return state;
  }
};

// musics table
const musicsInitialState = {
  albums: [],
  tracks: [],
  musicsCount: "",
};

export const musicsReducer = (state = musicsInitialState, action) => {
  switch (action.type) {
    case "FETCH_MUSICS":
      return {
        ...state,
        albums: action.payload.list.filter(
          (item) => item.type === musicTypes.album
        ),
        tracks: action.payload.list.filter(
          (item) => item.type === musicTypes.single
        ),
        musicsCount: action.payload.count,
      };

    case "REMOVE_ALBUM":
      return {
        ...state,
        albums: state.albums.filter((item) => item.id !== action.payload),
      };

    case "REMOVE_SINGLE_TRACK":
      return {
        ...state,
        tracks: state.tracks.filter((item) => item.id !== action.payload),
      };

    default:
      return state;
  }
};
