import { useState } from "react";
import {
  useDeletePaymentGatewaysQuery,
  usePaymentGatewaysQuery,
} from "hooks/query/usePackageQuery";
import { Modal } from "react-bootstrap";
import DeleteModal from "../deleteModal/DeleteModal";
import PaymentGatewayModal from "../paymentGatewayModal/PaymentGatewayModal";
import styles from "./PaymentGateway.module.scss";

function PaymentGateway() {
  const { data: paymentGateways } = usePaymentGatewaysQuery();
  const { mutate: deletePaymentGateway } = useDeletePaymentGatewaysQuery();

  const [modalVisibility, setModalVisibility] = useState({
    edit: false,
    delete: false,
  });

  const handleDeletePaymentGateway = () => {
    deletePaymentGateway(paymentGateways.id);
    setModalVisibility({ ...modalVisibility, delete: false });
  };

  return (
    <>
      {paymentGateways ? (
        <div className={`${styles.paymentBody} ${styles.connectPayment}`}>
          <div className={styles.logoWrapper}>
            <img
              src={`${process.env.PUBLIC_URL}/images/zarin-pay.svg`}
              alt=""
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/tick-circle.svg`}
              alt=""
            />
          </div>
          <span>درگاه پرداخت ثبت شده</span>
          <div className={styles.buttonWrapper}>
            <button
              onClick={() =>
                setModalVisibility({ ...modalVisibility, edit: true })
              }>
              <img
                src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/edit.svg`}
                alt=""
              />
            </button>
            <button
              onClick={() =>
                setModalVisibility({ ...modalVisibility, delete: true })
              }>
              <img
                src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/grey-trash.svg`}
                alt=""
              />
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`${styles.paymentBody} ${styles.payment}`}
          onClick={() =>
            setModalVisibility({ ...modalVisibility, edit: true })
          }>
          <img
            src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/add-square.svg`}
            alt=""
          />
          افزودن درگاه پرداخت
        </div>
      )}

      <Modal
        show={modalVisibility.edit}
        onHide={() => setModalVisibility({ ...modalVisibility, edit: false })}
        dialogClassName={styles.modal}
        centered>
        <PaymentGatewayModal
          data={paymentGateways}
          onHide={() => setModalVisibility({ ...modalVisibility, edit: false })}
        />
      </Modal>

      <Modal
        show={modalVisibility.delete}
        onHide={() => setModalVisibility({ ...modalVisibility, delete: false })}
        dialogClassName={styles.deleteModal}
        centered>
        <DeleteModal
          onHide={() =>
            setModalVisibility({ ...modalVisibility, delete: false })
          }
          onDelete={handleDeletePaymentGateway}
          context={{
            title: `(درگاه)`,
            button: "درگاه",
          }}
        />
      </Modal>
    </>
  );
}

export default PaymentGateway;
