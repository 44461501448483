import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDeleteArtistQuery } from "hooks/query/useArtistQuery";
import { occupation } from "constants/actors";
import DeleteMultiModal from "../../../common/modals/deleteMultiModal/DeleteMultiModal";
import styles from "../ActorsList.module.scss";

export default function ActorsRow({ data, index, currentPage }) {
  const history = useHistory();
  const { mutate: deleteArtist } = useDeleteArtistQuery(
    "single",
    currentPage,
    data.name
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteArtist = () => {
    deleteArtist(data.id);
  };

  const createOccupations = () =>
    data.occupation.reduce((acc, curr) => {
      const findItem = occupation.find((item) => item.en === curr);

      if (!!findItem) {
        const s = occupation.find((item) => item.en === curr);
        acc.push(s.fa);
      }

      return acc;
    }, []);

  return (
    <>
      <tr>
        <td width="17.9%">
          <div className={styles.typeCell}>
            <figure className={styles.imageWrapper}>
              <img
                loading="lazy"
                src={
                  data.avatar
                    ? `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.avatar}`
                    : `${process.env.PUBLIC_URL}/images/common/person.svg`
                }
                alt=""
                className={styles.company}
              />
            </figure>
            {data.name}
          </div>
        </td>
        <td width="17.9%">{++index}</td>
        <td width="36%">{createOccupations().join(", ")}</td>
        <td width="8.5%">--</td>
        <td width="8.5%">--</td>
        <td onClick={() => history.push(`/actors/addActors/${data.id}`)}>
          <img
            className={styles.edit}
            src={`${process.env.PUBLIC_URL}/images/icons/edit.svg`}
            alt="edit"
          />
        </td>
        <td onClick={() => setShowDeleteModal(true)}>
          <img
            className={styles.remove}
            src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
            alt="remove"
          />
        </td>
      </tr>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={styles.deleteMovieModal}>
        <DeleteMultiModal
          titleData={{
            deleteObject: data.name,
            isMoreThanOne: false,
            placeName: "هنرمند",
          }}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={handleDeleteArtist}
        />
      </Modal>
    </>
  );
}
