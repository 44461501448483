import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getMyProfile,
  resetProfile,
  updateContactInfo,
} from "../../../../redux/actions/profile";
import {
  generateApiKey,
  updateProfile,
} from "../../../../services/profile/profileServices";
import { postFile } from "../../../../services/files/files";
import { Input } from "../../../common/input/Input";
import { copyToClipboard } from "../../../../util/copyToClipboard";
import cookie from "react-cookies";
import toasts from "../../../common/toasts/toasts";
import SingleFileUploader from "../../../common/SingleFileUploader/SingleFileUploader";
import styles from "./PersonalInfo.module.scss";

//TODO: fix defaultImg SingleFileUploader

function PersonalInfo() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { contactInfo } = useSelector((state) => state.profileReducer);

  const setFullName = (e) =>
    dispatch(updateContactInfo({ fullname: e.target.value }));

  const handleChangeAvatar = (file) => {
    dispatch(updateContactInfo({ avatar: file[0] }));
  };

  const handleUpdateProfile = async () => {
    try {
      const { avatar, ...rest } = contactInfo;
      let avatarFileId = "";

      if (avatar?.path) {
        toasts.warn("در حال آپلود عکس پروفایل");
        const { data, status } = await postFile(avatar);
        if (status === 200) {
          avatarFileId = data.data;
        }
      }

      const userObj = {
        ...rest,
        avatar: avatarFileId,
      };

      const { status } = await updateProfile(userObj);

      if (status === 200) {
        toasts.success(`حساب کاربری شما بروز شد`);
        dispatch(getMyProfile());
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogout = () => {
    cookie.remove("token");
    dispatch(resetProfile());
    history.replace("/");
  };

  const handleGenerateApiKey = async () => {
    try {
      const { status, data } = await generateApiKey();
      if (status === 200) {
        dispatch(updateContactInfo({ api_key: data.data }));
      }
    } catch (error) {
      console.log(error);
      toasts.error("مشکلی پیش آمده است");
    }
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.top}>
        <SingleFileUploader
          title="بارگذاری تصویر پروفایل"
          defaultImg="/images/common/default-profile.svg"
          imageState={[contactInfo?.avatar, handleChangeAvatar]}
        />
        <div className={styles.inputGroup}>
          <Input
            label="نام و نام خانوادگی"
            placeholder="نام و نام خانوادگی خود را وارد نمایید"
            value={contactInfo?.fullname}
            onChange={(e) => setFullName(e)}
          />
          <Input
            label="ایمیل"
            // placeholder="ایمیل خود را وارد نمایید"
            value={contactInfo?.email}
            readOnly
          />
        </div>
        <Input
          label="شماره موبایل"
          // placeholder="شماره موبایل خود را وارد نمایید"
          value={contactInfo?.mobile_number}
          readOnly
        />
        <div className={styles.generate}>
          <div>
            <Input
              label="API-KEY"
              handleTextClick={() => {
                copyToClipboard(
                  contactInfo?.api_key,
                  "کد شما با موفقیت کپی شد"
                );
              }}
              value={contactInfo?.api_key}
              text="کپی"
              readOnly
            />
            <button onClick={handleGenerateApiKey} className="btn">
              ایجاد API-KEY
            </button>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.save} onClick={handleUpdateProfile}>
          ذخیره تغییرات
        </div>
        <div className={styles.logout} onClick={handleLogout}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/exit.svg`}
            alt="خروج"
          />
          خروج از پروفایل
        </div>
      </div>
    </section>
  );
}

export default PersonalInfo;
