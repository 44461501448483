import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deletePackage,
  deletePaymentGateway,
  getCurrencies,
  getPackages,
  getPaymentGateways,
  patchAppendFeatureItemToPackage,
  patchRemoveFeatureItemFromPackage,
  postPackage,
  postPaymentGateway,
  putPackage,
  putPaymentGateway,
} from "services/packages/packages";
import toasts from "components/common/toasts/toasts";

// Packages
export const usePackagesQuery = () => {
  return useQuery(["packages"], ({ signal }) => getPackages(signal), {
    select: (data) => {
      return data.data.data.results;
    },
  });
};
export const usePostPackageQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postPackage, {
    onSuccess: () => {
      queryClient.invalidateQueries("packages");
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت دسته بندی پیش آمده");
    },
  });
};
export const usePatchAppendFeatureItemToPackageQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(patchAppendFeatureItemToPackage, {
    onSuccess: () => {
      queryClient.invalidateQueries("packages");
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت ویژگی های بسته پیش آمده");
    },
  });
};
export const usePatchRemoveFeatureItemFromPackageQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(patchRemoveFeatureItemFromPackage, {
    onSuccess: () => {
      queryClient.invalidateQueries("packages");
    },
    onError: () => {
      toasts.error("مشکلی برای حذف ویژگی های بسته پیش آمده");
    },
  });
};

export const usePutPackageQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(putPackage, {
    onSuccess: () => {
      queryClient.invalidateQueries("packages");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی دسته بندی پیش آمده");
    },
  });
};
export const useDeletePackageQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePackage, {
    onSuccess: () => {
      queryClient.invalidateQueries("packages");
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};

// Gateway
export const usePaymentGatewaysQuery = () => {
  return useQuery(["gateways"], ({ signal }) => getPaymentGateways(signal), {
    select: (data) => {
      return data.data.data.results[0];
    },
  });
};
export const usePostPaymentGatewaysQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postPaymentGateway, {
    onSuccess: () => {
      queryClient.invalidateQueries("gateways");
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت درگاه پرداخت پیش آمده");
    },
  });
};
export const usePutPaymentGatewaysQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(putPaymentGateway, {
    onSuccess: () => {
      queryClient.invalidateQueries("gateways");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی درگاه پرداخت پیش آمده");
    },
  });
};
export const useDeletePaymentGatewaysQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePaymentGateway, {
    onSuccess: () => {
      queryClient.invalidateQueries("gateways");
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};

// Currencies
export const useCurrenciesQuery = () => {
  return useQuery(["currencies"], ({ signal }) => getCurrencies(signal), {
    select: (data) => {
      return data.data.data;
    },
  });
};
