import { useEffect, useState } from "react";
import { liveStreamSteps } from "constants/stream";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { postCreateKeyStream } from "services/stream/stream";
import { postFile } from "services/files/files";
import { Spinner } from "react-bootstrap";
import {
  usePostStreamQuery,
  usePutStreamQuery,
  useStreamQuery,
} from "hooks/query/useStreamQuery";
import { resetLiveStream, updateLiveStreamField } from "redux/actions/stream";
import Stepper from "new-components/stepper";
import GeneralInformation from "./steps/general-information";
import ImageOutput from "./steps/image-output";
import FinalReview from "./steps/final-review";
import toasts from "components/common/toasts/toasts";
import styles from "./styles.module.scss";

export default function LiveStreamDetails() {
  const { id } = useParams();
  const { data } = useStreamQuery(id);
  const liveStreaming = useSelector((state) => state.setLiveStreamingReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(
        updateLiveStreamField({
          title: data.title,
          cover_id: data.cover_id,
          stream_key: data.stream_key,
          hls_url: data.hls_url,
          server_url: data.server_url,
          active: data.active,
        })
      );
    }
    return () => dispatch(resetLiveStream());
  }, [data, dispatch]);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const createAttachment = async () => {
    if (liveStreaming.watermark_id?.path) {
      try {
        toasts.warn("درحال آپلود واترمارک پخش زنده");
        const fileResponse = await postFile(
          liveStreaming.watermark_id,
          undefined,
          `${window.__RUNTIME_CONFIG__.REACT_APP_LIVE_API}/files/upload`
        );
        return fileResponse;
      } catch (error) {
        console.log(error);
        toasts.error("ثبت واترمارک پخش زنده با مشکل مواجه شد");
      }
    }
  };
  const { mutate: postLiveStream } = usePostStreamQuery();
  const { mutate: updateLiveStream } = usePutStreamQuery("update");
  const handleCreateLiveStream = async () => {
    // let liveCover = "";
    // if (liveStreaming.cover_id.path?.length) {
    //   const response = await createAttachment();
    //   if (response.status === 200) {
    //     liveCover = response.data.data;
    //   }
    // }
    postLiveStream({
      ...liveStreaming,
      // ...(liveCover && { cover_id: liveCover }),
    });
  };
  const handleUpdateLiveStream = async () => {
    // let liveCover = "";

    // if (liveStreaming.cover_id?.path && !liveStreaming.cover_id?.id) {
    //   const response = await createAttachment();
    //   if (response.status === 200) {
    //     liveCover = response.data.data;
    //   }
    // } else if (liveStreaming.cover_id === "") {
    //   liveCover = "";
    // }
    updateLiveStream({
      id: +id,
      ...liveStreaming,
      // ...(liveCover && { cover_id: liveCover }),
    });
  };
  const nextStep = async () => {
    if (step === 1) {
      if (liveStreaming.title) {
        setStep(2);
      } else {
        toasts.error("عنوان پخش را وارد نمایید");
      }
    }
    if (step === 2) {
      if (id) {
        setStep(3);
      } else {
        if (
          liveStreaming.resolutions.length &&
          !!liveStreaming.resolutions[0]?.resolution?.name
        ) {
          for (const item of liveStreaming.resolutions) {
            if (item.resolution.name) {
              if (
                liveStreaming.resolutions.filter(
                  (f) => f.resolution.name === item.resolution.name
                )?.length > 1
              ) {
                toasts.error("رزولوشن های تکراری قابل قبول نمی باشد!");
                return false;
              } else {
                if (
                  +item.audio_bitrate >= item.audio.min &&
                  +item.audio_bitrate <= item.audio.max
                ) {
                  if (
                    +item.video_bitrate >= item.video.min &&
                    +item.video_bitrate <= item.video.max
                  ) {
                  } else {
                    toasts.error(
                      `بیت‌ریت تصویر رزولوشن ${item.resolution.name} باید در محدوده ${item.video.min} و ${item.video.max} باشد!`
                    );
                    return false;
                  }
                } else {
                  toasts.error(
                    `بیت‌ریت صدا رزولوشن ${item.resolution.name} باید در محدوده ${item.audio.min} و ${item.audio.max} باشد!`
                  );
                  return false;
                }
              }
            } else {
              toasts.error("رزولوشن فاقد مقدار است!");
              return false;
            }
          }
          try {
            setIsLoading(true);
            let watermarkId = "";
            if (liveStreaming.watermark_id.path?.length) {
              const response = await createAttachment();
              if (response.status === 200) {
                watermarkId = response.data.data;
              }
            }
            const { data, status } = await postCreateKeyStream({
              resolution_configs: liveStreaming.resolutions.map((f) => {
                return {
                  audio_bitrate: +f.audio_bitrate,
                  resolution: f.resolution.name,
                  video_bitrate: +f.video_bitrate,
                };
              }),
              ...(watermarkId.length && { watermark_id: watermarkId }),
              watermark_position: liveStreaming.watermark_position.id,
              fps: +liveStreaming.fps,
            });
            if (status === 200) {
              const { hls_url, server_url, stream_key } = data.data;
              dispatch({
                type: "SET_STREAM_KEYS",
                payload: { hls_url, server_url, stream_key },
              });
              setStep(3);
              setIsLoading(false);
            }
          } catch (err) {
            console.error(err);
            setIsLoading(false);
          }
        } else {
          toasts.error("لطفا رزولوشن را تعیین کنید!");
        }
      }
    }
    if (step === 3) {
      if (id) {
        await handleUpdateLiveStream();
      } else {
        await handleCreateLiveStream();
      }
    }
  };
  const prevStep = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  };
  return (
    <section className={styles.wrapper}>
      <div className={styles.stepper}>
        <Stepper step={step} stepsList={liveStreamSteps} />
      </div>
      <div className={styles.container}>
        <div className={styles.box}>
          {step === 1 && <GeneralInformation />}
          {step === 2 && <ImageOutput />}
          {step === 3 && <FinalReview />}
        </div>
        <div className={styles.actions}>
          <button
            onClick={prevStep}
            className={`${styles.prev} ${
              step === 1 ? styles.disabledButton : ""
            } btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/arrow.svg`}
              alt="prev"
            />
            مرحله قبل
          </button>
          <button onClick={nextStep} className={`${styles.next} btn`}>
            {step === 3 ? (
              "ذخیره"
            ) : (
              <>
                مرحله بعد
                {step === 2 && isLoading ? (
                  <Spinner
                    style={{
                      width: "1rem",
                      height: "1rem",
                    }}
                    animation="border"
                    as="i"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/new-icons/arrow.svg`}
                    alt="next"
                  />
                )}
              </>
            )}
          </button>
        </div>
      </div>
    </section>
  );
}
