import http from "../httpService";
export const getMyEmailConfig = () => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/email-configs/my`
  );
};
export const postEmailConfig = (data) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/email-configs/new`,
    data
  );
};
export const putEmailConfig = (data) => {
  return http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/email-configs/update`,
    data
  );
};
