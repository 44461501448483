import { useMemo, useState } from "react";
import { useMoviesQuery } from "hooks/query/useMovieQuery";
import { Dropdown } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import styles from "./SearchDropdown.module.scss";

// sub components for dropdown
const SearchInput = ({ value, onChange, ...props }) => {
  return (
    <div className={styles.inputWrapper}>
      <input
        type="text"
        placeholder="جستجوی فیلم"
        onChange={onChange}
        value={value}
        autoFocus
        {...props}
      />
      <img src={`${process.env.PUBLIC_URL}/images/icons/search.svg`} alt="" />
    </div>
  );
};

const Option = ({ item, onSelectOption }) => {
  return (
    <div onClick={() => onSelectOption(item)} className={styles.optionWrapper}>
      <div className={styles.col}>
        {item.cover?.length ? (
          <img
            src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${item.cover}`}
            alt=""
          />
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
            alt=""
          />
        )}
      </div>
      <div className={styles.col}>
        <div className={styles.flexRow}>
          <h2>
            {item.title.length > 30
              ? item.title.slice(0, 30) + "..."
              : item.title}
          </h2>
        </div>
        <div className={styles.InfoWrapper}>
          <div>
            مدت زمان: <span>{item.duration} دقیقه</span>
          </div>
          <div>
            زبان: <span>فارسی / انگلیسی</span>
          </div>
          <div>
            کیفیت: <span>دارای سه کیفیت</span>
          </div>
          <div>
            سال انتشار:{" "}
            <span>{item.release ? item.release.slice(0, 4) : "-"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// main component
function SearchDropdown({ value, onChange, placeholder }) {
  const { data: moviesList } = useMoviesQuery();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSelectOption = (option) => {
    onChange(option);
    setSearchValue("");
    onCloseMenu();
  };
  const onOpenMenu = () => {
    setIsMenuOpen(true);
  };
  const onCloseMenu = () => {
    setIsMenuOpen(false);
  };
  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredList = useMemo(() => {
    return moviesList?.cur.filter(
      (item) =>
        item.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      // search for persian name
      // 	||
      // item.title_in_persian
      // 	.toLocaleLowerCase()
      // 	.includes(searchValue.toLocaleLowerCase())
    );
  }, [searchValue, moviesList]);

  return (
    <div className={styles.wrapper}>
      <Dropdown bsPrefix={styles.dropdown} show={isMenuOpen}>
        <Dropdown.Toggle
          bsPrefix={styles.toggle}
          childBsPrefix={styles.button}
          onClick={isMenuOpen ? onCloseMenu : onOpenMenu}>
          <div
            className={`${styles.valueContainer} ${
              value ? styles.withValue : styles.withOutValue
            }`}>
            {value
              ? value.title.length > 30
                ? value.title.slice(0, 30) + "..."
                : value.title
              : placeholder}
          </div>
          <div className={styles.indicatorContainer}>
            <FaChevronDown color="#909090" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix={styles.menu}>
          {/* SEARCH INPUT */}
          <div className={styles.searchContainer}>
            <SearchInput value={searchValue} onChange={onChangeSearchValue} />
          </div>

          {/* MENU LIST */}
          <div className={styles.optionContainer}>
            {moviesList?.cur.length && filteredList.length ? (
              filteredList.map((item) => (
                <Option
                  key={item.id}
                  item={item}
                  onSelectOption={handleSelectOption}
                />
              ))
            ) : (
              <div className={styles.notFoundedSection}>
                {/* <img
                  src={`${process.env.PUBLIC_URL}/images/musics/music-square-remove.svg`}
                  alt=""
                /> */}
                <span>
                  {moviesList?.cur.length
                    ? " هیچ نتیجه ای یافت نشد لطفا دوباره سعی کنید"
                    : "فیلم ثبت شده‌ای یافت نشد"}
                </span>
              </div>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default SearchDropdown;
