import HeadTitle from "new-components/head-title";
import NotAvailable from "new-components/not-available";
import styles from "./styles.module.scss";
export default function InputType() {
  return (
    <>
      <HeadTitle title="انتخاب نوع ورودی" />
      <div className={styles.wrapper}>
        <div className={`${styles.card} ${styles.card__active}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/new-icons/input-type.svg`}
            alt="Push"
          />
          <p>ورودی از برودکست (Push)</p>
          <span>
            اگر از نرم‌افزار برودکست استفاده میکنید و می‌خواهید استریمر محتوا را
            توزیع کند کلیک کنید.
          </span>
        </div>
        <div className={`${styles.card} ${styles.card__disabled}`}>
          <div className={styles.center}>
            <NotAvailable />
          </div>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/input-type.svg`}
              alt="Pull"
            />
            <p>ورودی از سرور Pull</p>
            <span>
              اگر محتوا را از سرور خودتان ارسال میکنید و می‌خواهید توزیع امن
              محتوا را به استریمر بسپارید کلیک کنید.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
