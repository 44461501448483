import Sender from "./Sender";
import Receiver from "./Receiver";
import styles from "./styles.module.scss";
export default function List() {
  return (
    <div className={styles.wrapper}>
      <Sender />
      <Receiver />
      <Sender />
      <Receiver />
    </div>
  );
}