import videojs from "video.js";
import "./airplay.css";

const Button = videojs.getComponent("Button");
const registerPlugin = videojs.registerPlugin || videojs.plugin;

class AirPlayToggle extends Button {
  // Include the class to video elem
  buildCSSClass() {
    return `vjs-airplay-control ${super.buildCSSClass()}`;
  }

  // Handle the click and start airplay
  handleClick() {
    this.player()
      .el()
      .getElementsByTagName("video")[0]
      .webkitShowPlaybackTargetPicker();
    this.player().trigger("airplayStart");
  }
}

AirPlayToggle.prototype.controlText_ = "AirPlay";
videojs.registerComponent("AirplayToggle", AirPlayToggle);

const AirplayButton = function (options) {
  let player = this;

  // Check if device has support for airdrop and add button
  if (window.WebKitPlaybackTargetAvailabilityEvent) {
    let videoEl = player.el().getElementsByTagName("video")[0];
    videoEl.addEventListener(
      "webkitplaybacktargetavailabilitychanged",
      function (event) {
        switch (event.availability) {
          case "available": // if device has support then add the airplay button before fullscreen button
            player.on("loadeddata", function () {
              if (
                !player.controlBar.childNameIndex_.hasOwnProperty(
                  "AirplayToggle"
                )
              ) {
                let AirplayToggle = this.controlBar.addChild(
                  "AirplayToggle",
                  options
                );
                player.controlBar
                  .el()
                  .insertBefore(
                    AirplayToggle.el(),
                    player.controlBar.fullscreenToggle.el()
                  );
              }
            });
            break;
          case "not-available":
            break;
          default:
            break;
        }
      }
    );
  }
};

registerPlugin("airplayButton", AirplayButton);
