import { Link } from "react-router-dom";
import { ToJalaliFormat } from "../../../util/toJalali";
import Empty from "../empty/Empty";
import styles from "../OverView.module.scss";

//TODO: for dots can add modal

export default function RequestList({ data }) {
  return (
    <div className={styles.container}>
      <h6>لیست درخواست ها</h6>

      {data?.length > 0 ? (
        <div className={`${styles.list} ${styles.filesList}`}>
          {data.map((item) => (
            <div key={item.id} className={styles.requestListItem}>
              <div className={styles.info}>
                {item.avatar ? (
                  <img src={`${process.env.PUBLIC_URL}${item.avatar}`} alt="" />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/users/user-default.svg`}
                    alt=""
                  />
                )}
                <span>{item.member.fullname}</span>
              </div>
              <div>{item.name}</div>
              <div>{ToJalaliFormat(item.created_at)}</div>
              <div className={styles.dots}>
                <img src="/images/icons/dots.svg" alt="..." />
              </div>
            </div>
          ))}
          <Link to="/contentRequest" className={`${styles.seeMoreBtn} btn`}>
            مشاهده بیشتر
          </Link>
          <div className={styles.shadow}></div>
        </div>
      ) : (
        <Empty
          icon="/images/overView/clipboard-text.svg"
          title="درخواستی تا کنون در سایت شما ثبت نشده."
        />
      )}
    </div>
  );
}
