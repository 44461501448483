import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToJalaliFormat } from "../../../util/toJalali";
import SupportModal from "../supportModal/SupportModal";
import styles from "../Support.module.scss";

function Row({ data }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <tr className={styles.row}>
        <td>{data.name}</td>
        <td>{data.mobile_number}</td>
        <td>{data.email}</td>
        <td>-</td>
        <td>{ToJalaliFormat(data.created_at)}</td>
        <td>
          {data.title.length > 30
            ? data.title.slice(0, 30) + "..."
            : data.title}
        </td>
        <td>
          <button
            className={`${styles.button} btn`}
            onClick={() => setShowModal(true)}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/eye1.svg`}
              alt=""
            />
          </button>
        </td>
      </tr>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={styles.modal}
        centered>
        <SupportModal data={data} onHide={() => setShowModal(false)} />
      </Modal>
    </>
  );
}

export default Row;
