import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { postFile } from "../../../../services/files/files";
import {
  usePostCollectionQuery,
  usePutCollectionQuery,
} from "hooks/query/useCollectionQuery";
import { Link } from "react-router-dom";
import toasts from "../../../common/toasts/toasts";
import styles from "./TabHeader.module.scss";

export default function TabHeader() {
  const { id } = useParams();
  const createCollection = useSelector(
    (state) => state.createCollectionReducer
  );
  const { mutate: postCollection } = usePostCollectionQuery(
    createCollection.title
  );
  const { mutate: updateCollection } = usePutCollectionQuery();

  const createAttachment = async () => {
    if (createCollection.cover?.path) {
      try {
        toasts.warn("درحال آپلود فایل ها");
        const fileResponse = await postFile(createCollection.cover);
        return fileResponse;
      } catch (error) {
        console.log(error);
        toasts.error("ثبت فایل با مشکل مواجه شد");
      }
    }
  };

  // update Collection
  const handleUpdateCollection = async (payload) => {
    if (createCollection.cover?.path && !createCollection.cover?.id) {
      const response = await createAttachment();
      if (response.status === 200) {
        updateCollection({
          ...payload,
          id: parseInt(id),
          cover: response.data.data,
        });
      }
    } else if (createCollection.cover === "") {
      updateCollection({
        id: parseInt(id),
        cover: "",
        ...payload,
      });
    } else {
      updateCollection({
        id: parseInt(id),
        ...payload,
      });
    }
  };

  // create Collection
  const handleCreateCollection = async (payload) => {
    const newPayload = { ...payload };
    if (createCollection.cover.path?.length) {
      const response = await createAttachment();
      if (response.status === 200) {
        postCollection({
          ...newPayload,
          cover: response.data.data,
        });
      }
    } else {
      postCollection(newPayload);
    }
  };

  const handlePostCollection = () => {
    const submitCondition =
      createCollection.title.length && createCollection.summary.length;

    if (submitCondition) {
      const payload = {
        // cover: createCollection.cover,
        title: createCollection.title,
        title_in_persian: createCollection.title_in_persian,
        summary: createCollection.summary,
        category_id: createCollection.category_id,
      };
      if (id) {
        handleUpdateCollection(payload);
      } else {
        handleCreateCollection(payload);
      }
    } else {
      toasts.error("فیلد های الزامی را پر کنید.");
    }
  };

  return (
    <div className={styles.container}>
      <Link
        to={id ? `/collections/${id}/collectionMovies` : "/collections"}
        className={`btn ${styles.btn} ${styles.removeDeal}`}>
        انصراف
      </Link>
      <div
        onClick={handlePostCollection}
        className={`btn ${styles.btn} ${styles.addDeal}`}>
        <img
          src={`${process.env.PUBLIC_URL}/images/common/save.svg`}
          alt="save"
        />
        ثبت تغییرات
      </div>
    </div>
  );
}
