import styles from "./styles.module.scss";
export default function NotAvailable() {
  return (
    <div className={styles.wrapper}>
      <img
        src={`${process.env.PUBLIC_URL}/images/new-icons/not-available.svg`}
        alt="not-available"
      />
      <span>در حال حاضر قابل استفاده نمی باشد</span>
    </div>
  );
}