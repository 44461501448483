import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { allReducers } from "../reducers";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

export const store = createStore(allReducers, enhancer);