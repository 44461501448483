import http from "../httpService";
export const postLiveStream = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/live/new`, data);
export const updateLiveStream = (data) =>
  http.put(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/live/update`, data);
export const deleteLiveStream = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/live/delete?id=${id}`
  );
export const getAllLiveStreams = (page, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/live/get-all?page=${
      page ? page : ""
    }&limit=10`,
    {
      signal,
    }
  );
export const getLiveStream = (id, signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/live/get?id=${id}`, {
    signal,
  });
export const postCreateKeyStream = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_LIVE_API}/live/new`, data);
export const getLiveConfigs = (signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/backoffice/live-config/get`,
    {
      signal,
    }
  );
