import styles from "./SwitchSmall.module.scss";

function SwitchSmall({ label, switchState, isDisabled }) {
  const [toggle, setToggle] = switchState;

  return (
    <div
      className={`${styles.group} ${isDisabled ? styles.disabled : ""}`}
      onClick={() => setToggle(!isDisabled ? !toggle : false)}>
      <div
        className={`${styles.switchBtn} ${
          toggle ? styles.toggleOn : styles.toggleOff
        }`}>
        <div className={styles.toggleBtn}></div>
      </div>
      <span className={styles.label}>{label}</span>
    </div>
  );
}

export default SwitchSmall;
