import { useState } from "react";
import { Modal } from "react-bootstrap";
import { CloseButton } from "../../packageAndFeatures/components/Components";
import { Input } from "../../common/input/Input";
import SearchDropdown from "./searchDropdown/SearchDropdown";
import DropDown from "components/common/dropDown/DropDown";
import styles from "./AddModal.module.scss";

const tempCost = [
  {
    id: "مشاهده فقط با اکانت ویژه",
    title: "مشاهده فقط با اکانت ویژه",
  },
  {
    id: "5000 تومان",
    title: "5000 تومان",
  },
  {
    id: "10000 تومان",
    title: "10000 تومان",
  },
  {
    id: "20000 تومان",
    title: "20000 تومان",
  },
  {
    id: "50000 تومان",
    title: "50000 تومان",
  },
];

//TODO: think about calendar and time

function AddModal({ onHide }) {
  const [selectMovie, setSelectMovie] = useState(null);
  const handleSelectMovie = (selectedMovie) => {
    setSelectMovie(selectedMovie);
  };
  const [cost, setCost] = useState(null);

  return (
    <div className={styles.modalWrapper}>
      <CloseButton position="top" onClick={onHide} />
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>افزودن پخش جدید</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <SearchDropdown
          placeholder="انتخاب فیلم"
          value={selectMovie}
          onChange={handleSelectMovie}
        />
        <div className={styles.inputWrapper}>
          <div className={styles.dropDown}>
            <DropDown
              placeholder="هزینه پخش"
              name="cost"
              options={tempCost}
              value={cost}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={setCost}
            />
          </div>
          <Input placeholder="زمان پخش" />
        </div>
        <div className={styles.addBtn}>
          <button className="btn">افزودن فیلم</button>
        </div>
      </Modal.Body>
    </div>
  );
}

export default AddModal;
