import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAudioDropzone } from "../../../../hooks/dropzone";
import AlbumModal from "../../albumModal/AlbumModal";

import styles from "./AudioDropzone.module.scss";

function AudioDropzone({
	audioChangeCb,
	currentAudioLength,
	isAlbum,
	isReadonly,
}) {
	const [show, setShow] = useState(false);
	const { getRootProps, getInputProps, audioFiles } = useAudioDropzone();

	useEffect(() => {
		if (audioChangeCb) {
			audioChangeCb(audioFiles); // should have add audio to redux
		}
	}, [audioChangeCb, audioFiles]);

	const rootPropsConfig = {
		className: styles.uploaderWrapper,
	};

	const onShow = () => {
		setShow(true);
	};

	const onHide = () => {
		setShow(false);
	};

	if (isAlbum) {
		return (
			<>
				<button className={rootPropsConfig.className} onClick={onShow}>
					<div className={styles.section}>
						<img
							src={`${process.env.PUBLIC_URL}/images/musics/music-square.svg`}
							alt=""
						/>

						<p>
							<span> افزودن فایل صوتی </span>
							<span>{currentAudioLength + 1}</span>
						</p>
					</div>
				</button>

				<Modal
					show={show}
					onHide={onHide}
					dialogClassName={styles.modal}
					centered
				>
					<AlbumModal onHide={onHide} />
				</Modal>
			</>
		);
	} else {
		return (
			<button {...getRootProps(rootPropsConfig)}>
				<input {...getInputProps({ disabled: isReadonly })} />

				<div className={styles.section}>
					<img
						src={`${process.env.PUBLIC_URL}/images/musics/music-square.svg`}
						alt=""
					/>

					<p>
						<span> افزودن فایل صوتی </span>
					</p>
				</div>
			</button>
		);
	}
}

export default AudioDropzone;
