import { useEffect, useRef, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import { loadPaginateOptions } from "util/loadPaginateOptions";
import {
  calculateNumberOfRows,
  createMultiSelectStyle,
} from "../../../util/reactSelect";
import styles from "./AsyncMultiSelect.module.scss";

function AsyncMultiSelect({
  label,
  options,
  placeholder,
  selectedOptions,
  handleDelete,
  fetcher,
  optionFlags: { title, value },
  ...props
}) {
  const [name, setName] = useState([]);
  const loadActorOptions = loadPaginateOptions(fetcher, name);

  const selectRef = useRef(null);

  const [rows, setRows] = useState(0);

  useEffect(() => {
    const { current } = selectRef;

    const hasSelectedOptions = selectedOptions ? true : false;
    const isSelectedOptionEmpty =
      selectedOptions === [] || selectedOptions === null;

    if (current) {
      const totalWidthOfContainer = current.querySelector(
        ".select__value-container"
      ).offsetWidth;

      const calculationCondition = hasSelectedOptions && !isSelectedOptionEmpty;

      const calculatedRows =
        calculationCondition &&
        calculateNumberOfRows(
          selectedOptions.length,
          totalWidthOfContainer,
          210
        );

      setRows(calculatedRows);
    }
  }, [selectRef, selectedOptions]);

  // react select Components
  const Option = ({ data, ...props }) => (
    <components.Option {...props} className={styles.option}>
      {data.avatar && (
        <span className={styles.pic}>
          {data.avatar.length ? (
            <img
              loading="lazy"
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.avatar}`}
              alt={data.name}
            />
          ) : (
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/images/person.svg`}
              alt={data.name}
            />
          )}
        </span>
      )}

      <span className={styles.optionTitle}>{data[title]} </span>

      {data.year && (
        <button className={`${styles.selectAddBtn} btn`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/addPlus.svg`}
            alt=""
          />
        </button>
      )}

      {data.color && (
        <span
          className={styles.circle}
          style={{ backgroundColor: data.color }}></span>
      )}
    </components.Option>
  );
  const MultiValueRemove = ({ ...props }) => (
    <components.MultiValueRemove {...props}>
      <img
        onClick={() => (handleDelete ? handleDelete(value) : null)} // just for preventing error if we didn't pass any delete func
        src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
        alt=""
        className={styles.trash}
      />
    </components.MultiValueRemove>
  );
  const MultiValueLabel = ({ data, ...props }) => (
    <components.MultiValueLabel {...props}>
      {data.color && (
        <span
          className={styles.circle}
          style={{ backgroundColor: data.color }}></span>
      )}

      {data.avatar && (
        <span className={styles.pic}>
          {data.avatar.length ? (
            <img
              loading="lazy"
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.avatar}`}
              alt={data.name}
            />
          ) : (
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/images/person.jpg`}
              alt={data.name}
            />
          )}
        </span>
      )}

      <span className={`${styles.title}`}>{data[title]}</span>
    </components.MultiValueLabel>
  );

  return (
    <>
      <div className={styles.wrapper} ref={selectRef}>
        <label className={styles.label}>{label}</label>
        <AsyncPaginate
          loadOptions={loadActorOptions}
          onInputChange={setName}
          placeholder={placeholder}
          styles={createMultiSelectStyle(rows, 210)}
          options={options}
          value={selectedOptions}
          components={{
            Option,
            MultiValueRemove,
            MultiValueLabel,
          }}
          className="basic-multi-select"
          classNamePrefix="select"
          isMulti
          isRtl
          additional={{ page: 1 }}
          debounceTimeout={450}
          defaultOptions
          {...props}
        />
      </div>
    </>
  );
}

export default AsyncMultiSelect;
