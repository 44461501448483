import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  addSelectedCollections,
  removeSelectedCollections,
} from "redux/actions/collections";
import { useDeleteCollectionQuery } from "hooks/query/useCollectionQuery";
import DeleteMultiModal from "../../../common/modals/deleteMultiModal/DeleteMultiModal";
import TableCheckBox from "../../../common/TableCheckBox/TableCheckBox";
import styles from "../CollectionList.module.scss";

export default function CollectionRow({ data, index }) {
  const { mutate: deleteCollection } = useDeleteCollectionQuery(
    "single",
    data.title
  );
  const dispatch = useDispatch();

  const { selectedCollection, isAllSelected } = useSelector(
    (state) => state.collectionListReducer
  );
  const [checked, setChecked] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (!isAllSelected && selectedCollection.length) {
      setChecked(checked);
    } else {
      setChecked(isAllSelected);
    }
  }, [isAllSelected]);

  const handleSelect = (checked) => {
    if (checked) {
      dispatch(addSelectedCollections(data));
    } else {
      dispatch(removeSelectedCollections(data.id));
    }
    setChecked(checked);
  };

  const handleDeleteCollection = () => {
    deleteCollection(data.id);
  };

  return (
    <>
      <tr className={checked ? styles.active : styles.rowCell}>
        <td
          onClick={() => handleSelect(!checked)}
          className={checked ? styles.typeCell : ""}>
          {selectedCollection.length > 0 && (
            <TableCheckBox
              checkBoxState={[checked, setChecked]}
              place="tableRow"
            />
          )}
          {data.cover?.length ? (
            <img
              loading="lazy"
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
              alt={data.title}
              className={`${styles.moviePoster}`}
            />
          ) : (
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
              alt={data.title}
              className={`${styles.moviePoster} ${
                checked && styles.moviePosterActive
              }`}
            />
          )}
          {data.title.length > 30
            ? "..." + data.title.slice(0, 20)
            : data.title}
        </td>
        <td onClick={() => handleSelect(!checked)}>{index + 1}</td>
        <td onClick={() => handleSelect(!checked)}>
          {data.endYear} - {data.startYear}
        </td>
        <td onClick={() => handleSelect(!checked)}>{data.movies.length}</td>
        <td onClick={() => handleSelect(!checked)}>-</td>
        <td onClick={() => handleSelect(!checked)}>-</td>
        {!data.release ? (
          <td onClick={() => handleSelect(!checked)} className={styles.green}>
            منتشر شده
          </td>
        ) : (
          <td onClick={() => handleSelect(!checked)} className={styles.orange}>
            منتشر نشده
          </td>
        )}
        <td>
          <Link
            to={`/collections/${data.id}/collectionMovies`}
            className={`${styles.seeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/movies/see.svg`}
              alt="see"
            />
            مشاهده و ویرایش
          </Link>
          <button
            onClick={() => setShowDeleteModal(true)}
            className={`${styles.removeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt="see"
            />
            حذف کالکشن
          </button>
        </td>
      </tr>
      {showDeleteModal && (
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          dialogClassName={styles.deleteMovieModal}>
          <DeleteMultiModal
            titleData={{
              deleteObject: data.title,
              isMoreThanOne: false,
              placeName: "کالکشن",
            }}
            onHide={() => setShowDeleteModal(false)}
            handleDelete={handleDeleteCollection}
          />
        </Modal>
      )}
    </>
  );
}
