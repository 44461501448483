import { Table } from "react-bootstrap";
import styles from "./MovieTable.module.scss";

export default function MovieTable({
  data,
  type,
  handleRemoveSubtitle,
  handleDeleteSubtitle,
}) {
  return (
    <>
      <Table className={styles.table} responsive>
        {type === "movie" && (
          <>
            <thead>
              <tr className={styles.THead}>
                <th>کیفیت ها</th>
                <th>حجم فایل</th>
                <th>فرمت</th>
                <th>زیرنویس</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.englishFormat}>480</td>
                <td className={styles.englishFormat}>900 KB</td>
                <td className={styles.englishFormat}>.mkv</td>
                <td className={styles.notAdd}>اضافه نشده</td>
                <td className={styles.playCell}>
                  <img
                    className={styles.playIcon}
                    src={`${process.env.PUBLIC_URL}/images/movies/play.svg`}
                    alt="Play"
                  />
                  <span>پخش</span>
                </td>
                <td className={styles.trashCell}>
                  <img
                    className={styles.trashIcon}
                    src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
                    alt="x"
                  />
                  <span>حذف کیفیت</span>
                </td>
              </tr>
            </tbody>
          </>
        )}

        {type === "subtitle" && (
          <>
            <thead>
              <tr className={styles.THead}>
                <th>زیرنویس ها</th>
                <th>زبان</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={item.file.id || item.file?.name}
                  className={styles.subtitleRow}>
                  <td className={styles.englishFormat}>
                    {item.file?.name || item.file?.title}
                  </td>
                  <td>{item.language?.name}</td>
                  <td
                    className={styles.trashCell}
                    onClick={() => {
                      if (item.file.id) {
                        handleDeleteSubtitle(item.file.id);
                      } else {
                        handleRemoveSubtitle(index);
                      }
                    }}>
                    <img
                      className={styles.trashIcon}
                      src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
                      alt="x"
                    />
                    <span>حذف زیرنویس</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        )}

        {type === "trailer" && (
          <>
            <thead>
              <tr className={styles.THead}>
                <th>تریلر ها</th>
                <th>نام تریلر</th>
                <th>تاریخ انتشار تریلر</th>
                <th>زیرنویس</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.trailerRow}>
                <td className={styles.englishFormat}>Jolt.2021.trailer.mp4</td>
                <td>اولین تریلر رسمی</td>
                <td className={styles.englishFormat}>2021 - 08 - 06</td>
                <td>فارسی</td>
                <td className={styles.playCell}>
                  <img
                    className={styles.playIcon}
                    src={`${process.env.PUBLIC_URL}/images/movies/play.svg`}
                    alt="Play"
                  />
                  <span>پخش</span>
                </td>
                <td className={styles.trashCell}>
                  <img
                    className={styles.trashIcon}
                    src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
                    alt="x"
                  />
                  <span>حذف تریلر</span>
                </td>
              </tr>
              <tr className={styles.trailerRow}>
                <td className={styles.englishFormat}>
                  Jolt.2021.second.trailer.mkv
                </td>
                <td>دومین تریلر رسمی</td>
                <td className={styles.englishFormat}>2021 - 09 - 07</td>
                <td className={styles.notAdd}>اضافه نشده</td>
                <td className={styles.playCell}>
                  <img
                    className={styles.playIcon}
                    src={`${process.env.PUBLIC_URL}/images/movies/play.svg`}
                    alt="Play"
                  />
                  <span>پخش</span>
                </td>
                <td className={styles.trashCell}>
                  <img
                    className={styles.trashIcon}
                    src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
                    alt="x"
                  />
                  <span>حذف تریلر</span>
                </td>
              </tr>
            </tbody>
          </>
        )}
      </Table>
    </>
  );
}
