import { useEffect } from "react";
import { useSingleImageDropZone } from "../../../hooks/dropzone";
import styles from "./MovieCoverDropzone.module.scss";

const rootConfig = {
  className: styles.dropzone,
};

function MovieCoverDropzone({ initialImage, setCover }) {
  const { getInputProps, getRootProps, imageFile, imagePreview } =
    useSingleImageDropZone(initialImage);

  useEffect(() => {
    if (imageFile) {
      setCover(imageFile);
    }
  }, [imageFile]);

  return (
    <div
      className={styles.imageUploaderCard}
      style={{
        background: `url(${imagePreview})`,
      }}>
      <div className={styles.darkLayer} />
      <div {...getRootProps(rootConfig)}>
        <input {...getInputProps()} />

        <div className={styles.section}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/gallery-add.svg`}
            alt=""
          />
          <p>تغیبر تصویر</p>
        </div>
      </div>
    </div>
  );
}

export default MovieCoverDropzone;
