export const moviesContentRating = [
  {
    id: "G",
    title: "G",
  },
  {
    id: "PG",
    title: "PG",
  },
  {
    id: "PG-13",
    title: "PG-13",
  },
  {
    id: "R",
    title: "R",
  },
  {
    id: "NC-17",
    title: "NC-17",
  },
];
export const seriesContentRating = [
  {
    id: "TV-Y",
    title: "TV-Y",
  },
  {
    id: "TV-Y7",
    title: "TV-Y7",
  },
  {
    id: "TV-G",
    title: "TV-G",
  },
  {
    id: "TV-PG",
    title: "TV-PG",
  },
  {
    id: "TV-14",
    title: "TV-14",
  },
  {
    id: "TV-MA",
    title: "TV-MA",
  },
];
