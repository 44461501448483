import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { convertToMegaBytes } from "../../../util/changeByteToKByte";
import {
  getTranscodedFileInfo,
  getTranscoderFileInfo,
} from "../../../services/files/files";
import { Modal } from "react-bootstrap";
import { putUpdateEpisodeOfSeason } from "services/series/series";
import { useDeleteTranscodedFileQuery } from "hooks/query/useFileQuery";
import ReactPlayer from "react-player";
import toasts from "../toasts/toasts";
import AddVideoModal from "./addVideoModal/AddVideoModal";
import styles from "./SingleVideoUploader.module.scss";

function SingleVideoUploader({
  videoState,
  maxFiles,
  accept,
  dropzonePlaceholder,
  data,
  modalProps,
}) {
  const [showModal, setShowModal] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [showInformation, setShowInformation] = useState(true);
  const [information, setInformation] = videoState;
  const [videoSrc, setVideoSrc] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [dataContentUrl, setDataContentUrl] = useState("");

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const file = acceptedFiles[0];
    var url = URL.createObjectURL(file);
    setInformation(file);
    setVideoSrc(url);

    rejectedFiles.forEach((file) => {
      file.errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          toasts.warn(`فقط مجاز به استفاده از فرمت های : ${accept}`);
        }

        if (error.code === "too-many-files") {
          toasts.warn(`فقط مجاز به ارسال ${maxFiles} فایل هستید`);
        }
      });
    });
  }, []);

  // if information changed >> reload the video
  useEffect(() => {
    if (information) {
      var url = URL.createObjectURL(information);
      setVideoSrc(url);
    }
    // setPlaying(false);
  }, [information]);
  // useEffect(() => {
  //   if (playing) {
  //     setPlaying(false);
  //     setShowInformation(true);
  //   }
  // }, [data?.content_url]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    maxFiles: maxFiles,
    onDrop,
  });

  // GET movie transcode ID
  const [transcodeID, setTranscodeID] = useState("");
  const [transcodeServer, setTranscodeServer] = useState("");
  useEffect(() => {
    if (data) {
      setTranscodeServer(data?.content_url?.split("/")[2]);
      setTranscodeID(data?.content_url?.split("/")[4]);
      setDataContentUrl(data?.content_url ? data.content_url : "");
      setLinkUrl(data?.linkURL);
    }
  }, [data]);
  const [transcodeInfo, setTranscodeInfo] = useState("");
  const handleGetTranscodedFileInfo = async () => {
    try {
      const { data, status } = await getTranscodedFileInfo(transcodeID);
      if (status === 200) {
        setTranscodeInfo(data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status == 404) {
          const { data, status } = await getTranscoderFileInfo(
            transcodeServer,
            transcodeID
          );
          if (status === 200) {
            setTranscodeInfo(data.data);
          } else {
            setTranscodeInfo("");
          }
        }
      } else {
        console.log(error);
        setTranscodeInfo("");
      }
    }
  };
  useEffect(() => {
    if (transcodeID) {
      handleGetTranscodedFileInfo();
    }
  }, [transcodeID]);

  // DELETE file
  const { mutateAsync: deleteTranscodedFile } =
    useDeleteTranscodedFileQuery("serie");
  const handleDeleteFile = async () => {
    const { status } = await deleteTranscodedFile({
      server: transcodeServer,
      transcodeId: transcodeID,
    });
    if (status === 200) {
      setVideoSrc("");
      setInformation("");
      setDataContentUrl("");
      await putUpdateEpisodeOfSeason({
        id: data.id,
        title: data.title,
        imdb_id: data.imdb_id,
        content_url: "",
      });
    }
  };

  return (
    <>
      {(information && videoSrc) || dataContentUrl ? (
        <>
          <div className={`${styles.theme} ${styles.uploadedWrapper}`}>
            <div className={styles.top}>
              <ReactPlayer
                playsinline
                url={dataContentUrl || videoSrc}
                width={297}
                height={171}
                playing={playing}
                controls={playing}
                onPlay={() => {
                  setShowInformation(false);
                }}
                onPause={() => {
                  setShowInformation(true);
                }}
              />
              {showInformation && (
                <>
                  <div className={styles.videoSize}>
                    {transcodeInfo && transcodeInfo.size && (
                      <>
                        {convertToMegaBytes(transcodeInfo?.size)} Mb
                        <span> : حجم </span>
                      </>
                    )}
                  </div>
                  <img
                    className={styles.videoPlay}
                    src={`${process.env.PUBLIC_URL}/images/icons/video-play.svg`}
                    alt="video"
                    onClick={() => setPlaying(true)}
                  />
                  <div className={styles.videoTitle}>
                    {information
                      ? information?.name?.slice(0, 15) + "..."
                      : transcodeInfo?.name
                      ? transcodeInfo?.name?.slice(0, 15) + "..."
                      : data.title.slice(0, 15) + "..."}
                    <span> : نام فایل </span>
                  </div>
                </>
              )}
            </div>
            {dataContentUrl ? (
              <div onClick={handleDeleteFile}>
                <button className="btn">حذف این فایل و انتخاب مجدد</button>
              </div>
            ) : (
              <div onClick={() => setShowModal(true)}>
                <button className="btn">حذف این فایل و انتخاب مجدد</button>
              </div>
            )}
          </div>
        </>
      ) : linkUrl ? (
        <div className={`${styles.theme} ${styles.uploadedWrapper}`}>
          <div className={styles.top}>
            آدرس URL وارد شده:{" "}
            {linkUrl.length > 100 ? linkUrl.slice(0, 100) + "..." : linkUrl}
          </div>
          {dataContentUrl ? (
            <div onClick={handleDeleteFile}>
              <button className="btn">حذف این فایل و انتخاب مجدد</button>
            </div>
          ) : (
            <div onClick={() => setShowModal(true)}>
              <button className="btn">حذف این آدرس و انتخاب مجدد</button>
            </div>
          )}
        </div>
      ) : (
        <div
          {...getRootProps({
            className: `${styles.theme} ${styles.dropzone}`,
          })}>
          <section
            onClick={() => setShowModal(true)}
            className={styles.section}>
            <div className={styles.fileUploaderImage}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/video-upload.svg`}
                alt="+"
              />
            </div>
            <p>
              {dropzonePlaceholder
                ? dropzonePlaceholder
                : "فایل را بکشید و رها کنید."}
              <br />
              <span>و یا آن را انتخاب نمایید</span>
            </p>
            {/* <input {...getInputProps()} /> */}
          </section>
        </div>
      )}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={styles.modal}>
        <AddVideoModal
          linkState={[linkUrl, setLinkUrl]}
          maxFiles={1}
          inputProps={{ ...getInputProps() }}
          rootProps={{ ...getRootProps() }}
          onHide={() => setShowModal(false)}
          setVideoSrc={setVideoSrc}
          setInformation={setInformation}
          modalProps={modalProps}
        />
      </Modal>
    </>
  );
}

SingleVideoUploader.defaultProps = {
  accept: ".mp4,.mkv,.avi",
  maxFiles: 1,
};

export default SingleVideoUploader;
