import DatePicker from "react-modern-calendar-datepicker";
import styles from "./SingleDayDatePicker.module.scss";

// NOTE: Create another datePicker but works in different way component name is : InputCalender in new music
function SingleDayDatePicker({
	label,
	selectedDateState,
	placeholder,
	disabled,
	...props
}) {
	const [selectedDate, setSelectedDate] = selectedDateState;

	const renderCustomInput = ({ ref }) => {
		return (
			<div className={styles.inputWrapper}>
				<input
					ref={ref}
					value={
						selectedDate
							? `${selectedDate.year} - ${selectedDate.month} - ${selectedDate.day}`
							: ""
					}
					placeholder={placeholder}
					readOnly
					disabled={disabled}
				/>
				<img
					src={`${process.env.PUBLIC_URL}/images/common/calendar.svg`}
					alt=""
				/>
			</div>
		);
	};

	return (
		<div className={styles.wrapper}>
			<label className={styles.label}>{label}</label>
			<DatePicker
				wrapperClassName="customCalendar"
				// calendarClassName="responsive-calendar"
				value={selectedDate}
				onChange={setSelectedDate}
				renderInput={renderCustomInput}
				colorPrimary="#112DF1"
				locale={props.locale ? props.locale : "fa"}
				shouldHighlightWeekends
				{...props}
			/>
		</div>
	);
}

export default SingleDayDatePicker;
