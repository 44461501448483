import React, { Fragment, useState } from "react";
import { registerUser } from "../../../services/entry/entryService";
import { IsValidEmail } from "../../../util/validEmail";
import { strengthIndicator } from "../../common/passwordInput/strenghPassword";
import { IsValidMobile } from "./../../../util/validMobile";
import toasts from "../../common/toasts/toasts";
import PasswordInput from "../../common/passwordInput/PasswordInput";
import CustomInput from "../../common/customInput/CustomInput";
import AreaCodeDropDown from "../../common/areaCodeDropDown/AreaCodeDropDown";
import styles from "../RegisterAndLogin.module.scss";

function Register({ changeStep, changeRegisterOBj }) {
  const [theme, setTheme] = useState("movie");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [strength, setStrength] = useState("");
  const [password, setPassword] = useState("");
  const [repass, setRepass] = useState("");
  const [classValidationEmail, setClassValidationEmail] = useState("");
  const [classValidationPhone, setClassValidationPhone] = useState("");
  const [classValidationPassword, setClassValidationPassword] = useState("");
  const [classValidationRePassword, setClassValidationRePassword] =
    useState("");
  // select Country dropDown
  const [selectedCountry, setSelectedCountry] = useState("+98");
  // const finalPhoneNumber = selectedCountry + phoneNumber;

  const submit = async () => {
    let validateEmail = IsValidEmail(email);
    let validatePhone = IsValidMobile(phoneNumber);
    let finalPhoneNumber = phoneNumber.startsWith(0)
      ? phoneNumber
      : 0 + phoneNumber;
    if (
      validatePhone &&
      validateEmail &&
      email !== "" &&
      password !== "" &&
      repass !== "" &&
      password === repass &&
      strength >= 5
    ) {
      setClassValidationEmail("verify");
      setClassValidationPhone("");
      setClassValidationPassword("");
      setClassValidationRePassword("verify");
      const register = {
        theme: theme,
        email: email,
        password: password,
        mobile: finalPhoneNumber,
        country_code: selectedCountry,
        verification: "sms",
        sms_provider: "magfa",
      };
      try {
        const { status } = await registerUser(register);
        if (status === 200) {
          toasts.success("کد تایید به شمراه همراه شما ارسال شد");
          changeStep();
          changeRegisterOBj(register);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status == 409) {
            toasts.error("ایمیل و یا شماره همراه موردنظر قبلا ثبت شده است");
          }
          if (error.response.status == 406) {
            toasts.error("فرمت ایمیل وارد شده صحیح نمی باشد");
          }
          if (error.response.status != 409 && error.response.status != 406) {
            toasts.error("خطایی پیش آمده است");
          }
        }
      }
    }
    if (
      email === "" &&
      password === "" &&
      repass === "" &&
      phoneNumber === ""
    ) {
      setClassValidationEmail("danger");
      setClassValidationPassword("danger");
      setClassValidationRePassword("danger");
      setClassValidationPhone("danger");

      toasts.error(
        "شماره همراه و ایمیل و رمزعبور و تکرار رمز عبور وارد نشده است "
      );
      return;
    }
    if (email === "" && password !== "" && repass !== "") {
      setClassValidationEmail("danger");
      setClassValidationPassword("");
      setClassValidationRePassword("verify");
      toasts.error("ایمیل وارد نشده است");
      return;
    }
    if (email === "" && password === "" && repass !== "") {
      setClassValidationEmail("danger");
      setClassValidationPassword("");
      setClassValidationRePassword("verify");
      toasts.error("ایمیل وارد نشده است");
      return;
    }
    if (email === "" && password !== "" && repass === "") {
      setClassValidationEmail("danger");
      setClassValidationPassword("");
      setClassValidationRePassword("");
      toasts.error("ایمیل وارد نشده است");
      return;
    }
    if (email !== "" && password === "" && repass !== "") {
      setClassValidationEmail("verify");
      setClassValidationPassword("danger");
      setClassValidationRePassword("");
      toasts.error("رمزعبور وارد نشده است");
      return;
    }
    if (email !== "" && password === "" && repass === "") {
      setClassValidationEmail("verify");
      setClassValidationPassword("danger");
      setClassValidationRePassword("danger");
      toasts.error("رمزعبور وارد نشده است");
      return;
    }
    if (email !== "" && !validateEmail) {
      setClassValidationEmail("danger");
      toasts.error("ایمیل وارد شده معتبر نمی باشد");
      return;
    }
    if (phoneNumber === "") {
      setClassValidationPhone("danger");
      toasts.error("شماره همراه را وارد نمایید");
      return;
    }
    if (phoneNumber !== "" && !validatePhone) {
      setClassValidationPhone("danger");
      toasts.error("شماره همراه وارد شده معتبر نمی باشد");
      return;
    }
    if (strength < 5) {
      setClassValidationPassword("danger");
      toasts.error("رمز عبور قوی تری انتخاب نمایید");
      return;
    }
    if (email !== "" && password !== repass && phoneNumber !== "") {
      setClassValidationPassword("danger");
      setClassValidationRePassword("danger");
      setClassValidationEmail("verify");
      setClassValidationPhone("");
      toasts.error("رمزعبور و تکرار آن با هم تطابق ندارند");
      return;
    }
  };

  const handlePasswordChanges = (event) => {
    setPassword(event.target.value);
    setStrength(strengthIndicator(event.target.value));
  };

  return (
    <Fragment>
      <span className={styles.themeTitle}>
        زمینه استفاده خود را از استریمر مشخص کنید.
      </span>
      <div className={styles.tabs}>
        <button
          onClick={() => setTheme("movie")}
          className={theme === "movie" ? styles.active : ""}>
          سینمایی
        </button>
        <button
          onClick={() => setTheme("education")}
          className={theme === "education" ? styles.active : ""}>
          آموزشی
        </button>
        <button
          onClick={() => setTheme("music")}
          className={theme === "music" ? styles.active : ""}>
          موسیقی
        </button>
      </div>

      <CustomInput
        type="email"
        label="ایمیل"
        classValidation={classValidationEmail}
        handleChange={setEmail}
        value={email}
        isRequired
      />
      <div className={styles.registerTelInput}>
        <CustomInput
          type="tel"
          label="شماره همراه"
          classValidation={classValidationPhone}
          handleChange={setPhoneNumber}
          value={phoneNumber}
          isRequired
        />
        <AreaCodeDropDown
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
      </div>
      <PasswordInput
        label="رمز عبور"
        value={password}
        classValidation={classValidationPassword}
        onChange={handlePasswordChanges}
      />
      <CustomInput
        type="password"
        label="تکرار رمز عبور"
        classValidation={classValidationRePassword}
        handleChange={setRepass}
        value={repass}
      />
      <button className={`btn ${styles.btn}`} onClick={submit}>
        دریافت کد تأیید
      </button>
    </Fragment>
  );
}

export default Register;
