import moment from "jalali-moment";
// import {
//   convertWeekdaysIntoPersianDay,
//   covertMonthsToPersianMonth,
// } from "./weekConvertor";

export const ToJalaliFormat = (date) => {
  return moment(date, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD");
};

export const convertToJalaliDate = (date) =>
  moment(date, "YYYY - MM - DD").locale("fa").format("YYYY - MM - DD");

export const convertToJalaliDateWithOutSpace = (date) =>
  moment(date, "YYYY - MM - DD").locale("fa").format("YYYY/MM/DD");

export const convertToJalaliDateLikeNumber = (date) =>
  moment(date, "YYYY - MM - DD").locale("fa").format("YYYYMMDD");

export const convertToJalaliTime = (date) =>
  moment(date).local("fa").format("HH:mm");

export const getStartDayOfWeek = (dateObject, locale) => {
  if (locale === "fa") {
    const date = moment(
      `${dateObject.year}-${dateObject.month}-${dateObject.day}`,
      "jYYYY-jMM-jDD"
    );

    const startDayOfWeek = date
      .startOf("week")
      .format("jYYYY-jMM-jDD")
      .split("-");

    return startDayOfWeek[2] - 1;
  } else {
    // Need to test
    const date = moment(
      `${dateObject.year}-${dateObject.month}-${dateObject.day}`,
      "YYYY-MM-DD"
    );

    const startDayOfWeek = date.startOf("week").format("YYYY-MM-DD").split("-");

    return startDayOfWeek;
  }
};

export const getEndDayOfWeek = (dateObject, locale) => {
  if (locale === "fa") {
    const date = moment(
      `${dateObject.year}-${dateObject.month}-${dateObject.day}`,
      "jYYYY-jMM-jDD"
    );

    const isFirstHalfOfTheYear = dateObject.month <= 6;

    const endDayOfWeek = date.endOf("week").format("jYYYY-jMM-jDD").split("-");

    if (endDayOfWeek[2] - 1 === 0) {
      return isFirstHalfOfTheYear ? 31 : 30;
    } else {
      return endDayOfWeek[2] - 1;
    }
  } else {
    // Need to test
    const date = moment(
      `${dateObject.year}-${dateObject.month}-${dateObject.day}`,
      "YYYY-MM-DD"
    );

    const endDayOfWeek = date.endOf("week").format("YYYY-MM-DD").split("-");

    return endDayOfWeek;
  }
};

export const getDateBetweenTwoDate = (dateObject, locale = "fa") => {
  let dateArray = [];
  let currentDate = moment(dateObject.start_date);
  let stopDate = moment(dateObject.end_date);

  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }

  if (locale === "fa") {
    const result = dateArray.map((date) =>
      moment.from(date).locale(locale).format("YYYY-MM-DD")
    );

    return result;
  } else {
    return dateArray;
  }
};

// export const getFullPersianDate = (date) => {
//   const dateArray = date.split("-");

//   const year = dateArray[0];
//   const month = covertMonthsToPersianMonth(parseInt(dateArray[1]));
//   const day = dateArray[2];
//   const weekDay = convertWeekdaysIntoPersianDay(moment(date).weekday());

//   return `${weekDay} ${day} ${month} ${year}`;
// };

// get jalali date instead of dateObject
export const convertJalaliToMiladiISO = (dateObject) => {
  const date = moment
    .from(
      `${dateObject.year}-${dateObject.month}-${dateObject.day}`,
      "fa",
      "YYYY-MM-DD"
    )
    .format("YYYY-MM-DD");

  const ISOString = new Date(date).toISOString();

  return ISOString;
};

export const convertJalaliObjectToMiladiDate = (dateObject) => {
  const date = moment
    .from(
      `${dateObject.year}-${dateObject.month}-${dateObject.day}`,
      "fa",
      "YYYY-MM-DD"
    )
    .format("YYYY-MM-DD");

  return date;
};

export const convertISOMiladiToJalali = (ISOString) => {
  const date = new Date(ISOString).toLocaleDateString();

  const jalaliDate = moment.from(date).locale("fa").format("YYYY - MM - DD");

  return jalaliDate;
};

export const convertISOStringToObject = (ISOString) => {
  const jalali = convertISOMiladiToJalali(ISOString).split("-");
  const object = {
    year: parseInt(jalali[0]),
    month: parseInt(jalali[1]),
    day: parseInt(jalali[2]),
  };
  return object;
};

export const convertISOStringToObjectUnchanged = (ISOString) => {
  if (ISOString) {
    const jalali = ISOString.split("-");
    const object = {
      year: parseInt(jalali[0]),
      month: parseInt(jalali[1]),
      day: parseInt(jalali[2]),
    };
    return object;
  }
};

// export const getDayFromNow = (date) => {
//   let theDay = moment(date).locale("fa");
//   let today = moment().local("fa");
//   let calcDay = today.diff(theDay, "day");
//   if (calcDay === 0) {
//     return "امروز";
//   }
//   if (calcDay === 1) {
//     return "1 روز پیش";
//   }
//   if (calcDay === 2) {
//     return "2 روز پیش";
//   }
//   if (calcDay > 2) {
//     const getDateDetails = moment(date)
//       .locale("fa")
//       .format("YYYY/MM/DD")
//       .split("/");
//     const year = getDateDetails[0];
//     const month = covertMonthsToPersianMonth(parseInt(getDateDetails[1]));
//     const day = getDateDetails[2];
//     return `${day} ${month} ${year}`;
//   }
// };

export const convertJalaliObjectToMildai = (date) => {
  return moment.from(`${date}`, "fa", "YYYY/MM/DD").format("YYYY-MM-DD");
};

export const getDatesOfTheWeekToday = () => {
  const now = moment([2046 - 12 - 12])
    .locale("fa")
    .format("YYYY-MM-DD");
  const convertNow = convertISOStringToObjectUnchanged(now);
  const startDayOfTheWeekInMiladi = convertJalaliObjectToMildai(
    `${convertNow.year}-${convertNow.month}-${getStartDayOfWeek(
      convertNow,
      "fa"
    )}`
  );
  const endDayOfTheWeekInMiladi = convertJalaliObjectToMildai(
    `${convertNow.year}-${convertNow.month}-${getEndDayOfWeek(
      convertNow,
      "fa"
    )}`
  );
  const getBetweenDatesInJalali = getDateBetweenTwoDate({
    start_date: startDayOfTheWeekInMiladi,
    end_date: endDayOfTheWeekInMiladi,
  });

  const changedDate = getBetweenDatesInJalali.map((d) =>
    convertISOStringToObjectUnchanged(d)
  );
  return changedDate;
};

export const countDownDays = (endDate) => {
  let today = moment();
  let end = moment(endDate);
  return end.diff(today, "days");
};
