import http from "../httpService";

// packages
export const getPackages = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/packages/get-all`, {
    signal,
  });

export const deletePackage = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/packages/delete?id=${id}`
  );

export const postPackage = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/packages/new`, data);

export const putPackage = (data) =>
  http.put(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/packages/update`, data);

export const patchAppendFeatureItemToPackage = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/packages/feature/append`,
    data
  );

export const patchRemoveFeatureItemFromPackage = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/packages/feature/delete`,
    data
  );

// gateway
export const getPaymentGateways = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/payments/get-all`, {
    signal,
  });

export const postPaymentGateway = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/payments/new`, data);

export const putPaymentGateway = (data) =>
  http.put(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/payments/update`, data);

export const deletePaymentGateway = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/payments/delete?payment_id=${id}`
  );

// currencies
export const getCurrencies = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/currencies/get-all`, {
    signal,
  });
