import { useState } from "react";
import { Table, Spinner, Modal } from "react-bootstrap";
import { useMembersQuery } from "hooks/query/useMemberQuery";
import MemberRow from "./memberRow/MemberRow";
import Paginate from "components/common/paginate/Paginate";
import ConfigModal from "./configModal/ConfigModal";
import styles from "./Members.module.scss";

//TODO: filter icon  ???

export default function Members() {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = useMembersQuery(currentPage);

  return (
    <>
      <section className={styles.wrapper}>
        <div className={`${styles.pill}`}>
          <Table className={styles.table} responsive>
            <thead>
              <tr>
                <th>نام کاربری</th>
                <th>
                  {/* <img src="/images/icons/search.svg" alt="search" /> */}
                  ایمیل
                </th>
                <th>
                  {/* <img src="/images/icons/search.svg" alt="search" /> */}
                  {/* سطح کاربری */}
                  شماره همراه
                </th>
                <th>
                  {/* <img src="/images/icons/filter.svg" alt="filter" /> */}
                  نوع بسته
                </th>
                <th>مدت بسته</th>
                {/* <th>وضعیت</th> */}
                <th>تاریخ عضویت</th>
                <th
                  onClick={() => setShowModal(true)}
                  className={styles.configBtn}>
                  <div>
                    <span>تنظیمات کد تایید</span>
                  </div>
                </th>
              </tr>
            </thead>
            {data?.results?.length ? (
              <tbody>
                {isPreviousData ? (
                  <div className="spinnerCenter">
                    <Spinner animation="border" as="i" />
                  </div>
                ) : (
                  data?.results.map((item) => (
                    <MemberRow key={item.id} data={item} />
                  ))
                )}
              </tbody>
            ) : (
              <>
                <p className={styles.emptyState}>کاربری جدیدی وجود ندارد.</p>
              </>
            )}
          </Table>
        </div>

        {data?.results?.length > 0 && (
          <Paginate
            type="members"
            paginateState={[currentPage, setCurrentPage]}
            total={data?.pagination.count}
          />
        )}
      </section>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={styles.modal}
        centered>
        <ConfigModal onHide={() => setShowModal(false)} />
      </Modal>
    </>
  );
}
