import UploadMovie from "./uploadMovie/UploadMovie";
import UploadPictures from "./uploadPictures/UploadPictures";
import UploadSubtitles from "./uploadSubtitles/UploadSubtitles";
import UploadDownloadLinks from "./uploadDownloadLinks/UploadDownloadLinks";
// import UploadTrailer from "./uploadTrailer/UploadTrailer";
import styles from "./UploadFiles.module.scss";

//! commented unnecessary parts

//  TEST state
// const yearOptions = [
//   { id: 1, title: "2021" },
//   { id: 2, title: "2020" },
//   { id: 3, title: "2019" },
//   { id: 4, title: "2018" },
// ];
// const monthOptions = [
//   { id: 1, title: "ژانویه" },
//   { id: 2, title: "دسامبر" },
//   { id: 3, title: "آپریل" },
//   { id: 4, title: "نوامبر" },
// ];
// const dayOptions = [
//   { id: 1, title: "20" },
//   { id: 2, title: "21" },
//   { id: 3, title: "22" },
//   { id: 4, title: "23" },
// ];
// // default langOptions Options data

export default function UploadFiles() {
  // // default yearOptions Options data
  // const [year, setYear] = useState(yearOptions);
  // const [month, setMonth] = useState(monthOptions);
  // const [day, setDay] = useState(dayOptions);

  return (
    <>
      <section className={styles.wrapper}>
        <UploadMovie />
        <UploadSubtitles />
        {/*
        <UploadTrailer
          setDay={setDay}
          dayOptions={dayOptions}
          setMonth={setMonth}
          monthOptions={monthOptions}
          setYear={setYear}
          yearOptions={yearOptions}
        /> */}
        <UploadPictures />
        <UploadDownloadLinks />
      </section>
    </>
  );
}
