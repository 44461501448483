import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteRequestTitle,
  getRequestTitles,
} from "services/requestTitle/requestServices";
import toasts from "components/common/toasts/toasts";

export const useRequestsQuery = (sort, page) => {
  return useQuery(
    ["requests", page, sort],
    ({ signal }) => getRequestTitles(sort, page, 10, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
export const useDeleteRequestsQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteRequestTitle, {
    onSuccess: () => {
      queryClient.invalidateQueries("requests");
      toasts.success(`درخواست ${title} با موفقیت حذف شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};