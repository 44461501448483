import { useState } from "react";
import { useDeletePackageQuery } from "hooks/query/usePackageQuery";
import { Modal } from "react-bootstrap";
import { numberFormater } from "../../../util/number";
import { CardDivider } from "../components/Components";
import DeleteModal from "../deleteModal/DeleteModal";
import PackageModal from "../packageModal/PackageModal";
import styles from "./PackageCard.module.scss";

function PackageCard({ data }) {
  const { mutate: deletePackage } = useDeletePackageQuery();

  const [modalVisibility, setModalVisibility] = useState({
    edit: false,
    delete: false,
  });

  const handleDeletePackage = () => {
    deletePackage(data.id);
    setModalVisibility({ ...modalVisibility, delete: false });
  };

  return (
    <>
      <div className={`${styles.card} ${data.premium && styles.active}`}>
        <div className={styles.title}>
          <h3>{data.name}</h3>

          <p>
            <span>{numberFormater(data.price)}</span>
            <span>{data.currency.name}</span>
          </p>
        </div>

        <CardDivider position="top" fill="#f5f5f5" />

        <ul className={styles.list}>
          {data.features.map((item) => (
            <li key={item.id}>
              <img
                src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/tick-square.svg`}
                alt=""
              />
              {item.text}
            </li>
          ))}
        </ul>

        <CardDivider position="bottom" fill="#f5f5f5" />

        <div className={styles.buttonWrapper}>
          <button
            className={`btn ${styles.edit}`}
            onClick={() =>
              setModalVisibility({ ...modalVisibility, edit: true })
            }>
            ویرایش بسته
          </button>
          <button
            className={`btn ${styles.delete}`}
            onClick={() =>
              setModalVisibility({ ...modalVisibility, delete: true })
            }>
            <img
              src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/trash.svg`}
              alt=""
            />
          </button>
        </div>
      </div>

      <Modal
        show={modalVisibility.edit}
        onHide={() => setModalVisibility({ ...modalVisibility, edit: false })}
        dialogClassName={styles.editModal}>
        <PackageModal
          onHide={() => setModalVisibility({ ...modalVisibility, edit: false })}
          data={data}
        />
      </Modal>
      <Modal
        show={modalVisibility.delete}
        onHide={() => setModalVisibility({ ...modalVisibility, delete: false })}
        dialogClassName={styles.deleteModal}
        centered>
        <DeleteModal
          onHide={() =>
            setModalVisibility({ ...modalVisibility, delete: false })
          }
          onDelete={handleDeletePackage}
          context={{
            title: `(بسته ${data.name})`,
            button: "بسته",
          }}
        />
      </Modal>
    </>
  );
}

export default PackageCard;
