import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteTvLive,
  getTvLives,
  postTvLive,
  putTvLive,
} from "services/tvLive/tvLiveService";
import toasts from "components/common/toasts/toasts";

export const useTelevisionsQuery = (page) => {
  return useQuery(
    ["televisions", page],
    ({ signal }) => getTvLives(page, 10, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};

export const usePostTelevisionQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postTvLive, {
    onSuccess: () => {
      queryClient.invalidateQueries("televisions");
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت شبکه پیش آمده");
    },
  });
};
export const usePutTelevisionQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(putTvLive, {
    onSuccess: () => {
      queryClient.invalidateQueries("televisions");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی شبکه پیش آمده");
    },
  });
};
export const useDeleteTelevisionQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTvLive, {
    onSuccess: () => {
      queryClient.invalidateQueries("televisions");
      toasts.success(`${title} شبکه حذف شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
