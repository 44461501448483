import { parseJWTPayload } from "./tokenParser";
import cookie from "react-cookies";
export const cookieOptions = (exp) => {
  return {
    path: "/",
    expires: new Date(exp || Date.now() + 30 * 24 * 60 * 60 * 1000),
    // httpOnly: true,
  };
};
export const handleCookie = (data) => {
  const { exp: expToken } = parseJWTPayload(data.data.data.token);
  const { exp: expRefreshToken } = parseJWTPayload(
    data.data.data.refresh_token
  );
  cookie.save("token", data.data.data.token, cookieOptions(expToken * 1000));
  cookie.save(
    "panel-refreshToken",
    data.data.data.refresh_token,
    cookieOptions(expRefreshToken * 1000)
  );
};