import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { isObject } from "lodash-es";
import { toast } from "react-toastify";
import { changeByteToKByte } from "../../../util/changeByteToKByte";
import styles from "./SingleImageUploader.module.scss";

function SingleImageUploader({
  imageState,
  placeholder,
  maxFiles,
  accept,
  dropzonePlaceholder,
  bigSize,
  hasSpanText = true,
  eventPosterStyles,
}) {
  const [imageFile, setImageFile] = imageState;
  const [selectedImage, setSelectedImage] = useState("");

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const file = acceptedFiles[0];

    setImageFile(file);

    const fileReader = new FileReader();
    fileReader.addEventListener(
      "load",
      () => {
        setSelectedImage(fileReader.result);
      },
      false
    );

    fileReader.readAsDataURL(file);

    rejectedFiles.forEach((file) => {
      file.errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          toast.error(`فقط مجاز به استفاده از فرمت های : ${accept}`);
        }

        if (error.code === "too-many-files") {
          toast.error(`فقط مجاز به ارسال ${maxFiles} فایل هستید`);
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    maxFiles: maxFiles,
    onDrop,
  });

  return (
    <>
      {imageFile ? (
        <div
          className={`${styles.theme} ${styles.uploadedPicWrapper} ${
            bigSize ? styles.bigWrapper : ""
          } ${eventPosterStyles ? styles.eventPosterRight : ""}`}>
          <div className={styles.right}>
            <img
              src={
                isObject(imageFile)
                  ? `${selectedImage}`
                  : `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${imageFile}`
              }
              alt=""
            />
          </div>
          <div className={styles.left}>
            <span>تصویر {placeholder}</span>

            {isObject(imageFile) && (
              <div>
                <span className={styles.gray}>حجم عکس : </span>
                <span>{changeByteToKByte(imageFile.size)} KB</span>
              </div>
            )}

            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <button className="btn">انتخاب یک عکس دیگر</button>
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps({
            className: `${styles.theme} ${styles.dropzone} ${
              bigSize ? styles.bigDropzone : ""
            } ${eventPosterStyles ? styles.eventPosterStyles : ""}`,
          })}>
          <input {...getInputProps()} />
          <section className={styles.section}>
            <div className={styles.fileUploaderImage}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/image-upload.svg`}
                alt=""
              />
            </div>
            <p>
              {dropzonePlaceholder
                ? dropzonePlaceholder
                : " انتخاب تصویر در ابعاد ۱۹۲۰x۴۵۰ پیکسل"}

              <br />
              {hasSpanText && <span>و یا آن را انتخاب نمایید</span>}
              {/* <button className="btn">انتخاب تصویر {placeholder}</button> */}
            </p>
          </section>
        </div>
      )}
    </>
  );
}

SingleImageUploader.defaultProps = {
  accept: ".jpg,.jpeg,.png",
  maxFiles: 1,
};

export default SingleImageUploader;
