import { useState, useEffect } from "react";
import { useParams } from "react-router";
import styles from "./TagInput.module.scss";

export default function TagInput({
  propState,
  label,
  theme,
  handleDelete,
  type,
  isSeries,
  ...props
}) {
  const { id } = useParams();
  const [tags, setTags] = propState;
  const [isFocussed, setIsFocussed] = useState(false);

  function handleKeyDown(e) {
    // If user did not press enter key, return
    if (e.key !== "Enter") return;
    // Get the value of the input
    const value = e.target.value;
    // If the value is empty, return
    if (!value.trim()) return;
    // Add the value to the tags array
    if (type === "extra_data") {
      setTags([...tags, value]);
    } else {
      setTags([
        ...tags,
        {
          label: value,
        },
      ]);
    }

    // Clear the input
    e.target.value = "";
  }

  function removeTag(index, itemId) {
    if (id && !isSeries) {
      handleDelete(itemId);
    }
    setTags(tags?.filter((_, i) => i !== index));
  }

  useEffect(() => {
    if (tags?.length > 0) {
      setIsFocussed(true);
    } else {
      setIsFocussed(false);
    }
  }, [tags?.length]);

  return (
    <div className={styles.tags_input_container}>
      {label?.length && (
        <label
          htmlFor={`${label}`}
          className={`${styles.label} ${isFocussed ? styles.focusLabel : ""} ${
            theme === "gray" && isFocussed ? styles.grayTheme : ""
          }`}>
          {label}
        </label>
      )}
      {tags?.map((tag, index) => (
        <div className={styles.tag_item} key={index}>
          <span className={styles.text}>
            {type === "extra_data"
              ? tag.id
                ? tag.extra_data
                : tag
              : tag.label}
          </span>
          <span
            className={styles.close}
            onClick={() => removeTag(index, tag.id)}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/close-blue.svg`}
              alt=""
              className={styles.remove}
            />
          </span>
        </div>
      ))}

      <input
        id={`${label}`}
        onKeyDown={handleKeyDown}
        onFocus={() => {
          setIsFocussed(true);
        }}
        onBlur={() => {
          if (!tags?.length) {
            setIsFocussed(false);
          }
        }}
        type="text"
        className={styles.tags_input}
        // placeholder="عنوان keyword را وارد نمایید..."
      />
    </div>
  );
}
