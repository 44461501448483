import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  deleteSlider,
  getSlider,
  getSliders,
  postSlider,
  putSlider,
} from "services/slider/slider";
import toasts from "components/common/toasts/toasts";

export const useSlidersQuery = () => {
  return useQuery(["sliders"], ({ signal }) => getSliders(signal), {
    select: (data) => {
      return data.data.data;
    },
  });
};
export const useSliderQuery = (id) => {
  return useQuery(["slider", id], ({ signal }) => getSlider(id, signal), {
    enabled: !!id,
    select: (data) => {
      return data.data.data;
    },
  });
};

export const usePostSliderQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(postSlider, {
    onSuccess: () => {
      queryClient.invalidateQueries("sliders");
      history.push("/sliders");
      toasts.success(`اسلاید با موفقیت ثبت شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت اسلاید پیش آمده");
    },
  });
};
export const usePutSliderQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(putSlider, {
    onSuccess: () => {
      queryClient.invalidateQueries("sliders");
      history.push("/sliders");
      toasts.success(`اسلاید با موفقیت بروزرسانی شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی اسلاید پیش آمده");
    },
  });
};
export const useDeleteSliderQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteSlider, {
    onSuccess: () => {
      queryClient.invalidateQueries("sliders");
      toasts.success(`اسلاید ${title} با موفقیت حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
