import http from "../httpService";
export const getArtistsBySearch = (page, limit, name, signal) =>
  http.get(
    `${
      window.__RUNTIME_CONFIG__.REACT_APP_API
    }/search/artists?name=${name}&page=${page ? page : ""}&limit=${
      limit ? limit : ""
    }`,
    {
      signal,
    }
  );
export const getAdvancedSearch = (page, limit, name, type, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/search/advanced?name=${name}&type=${type}&page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    {
      signal,
    }
  );
// export const getAdvancedSearch = (page, limit, name, type, signal) =>
//   http.get(
//     `${cookie.load("baseURL")}/search/advanced?name=${name}&type=${type}&page=${
//       page ? page : ""
//     }&limit=${limit ? limit : ""}`,
//     {
//       signal,
//     }
//   );
