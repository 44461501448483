import { useState, useEffect } from "react";
import {
  usePostPaymentGatewaysQuery,
  usePutPaymentGatewaysQuery,
} from "hooks/query/usePackageQuery";
import { Modal } from "react-bootstrap";
import { CloseButton } from "../components/Components";
import { Input } from "../../common/input/Input";
import toasts from "../../common/toasts/toasts";
import styles from "./PaymentGatewayModal.module.scss";

function PaymentGatewayModal({ data, onHide }) {
  const { mutate: postPaymentGateway } = usePostPaymentGatewaysQuery();
  const { mutate: putPaymentGateway } = usePutPaymentGatewaysQuery();

  const [merchantId, setMerchantId] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (data) {
      setMerchantId(data.merchant_id);
      setUrl(data.callback_url);
    }
  }, [data]);

  const handleCreatePaymentGateway = (payload) => {
    postPaymentGateway(payload);
    onHide();
  };
  const handleUpdatePaymentGateway = async (id, payload) => {
    putPaymentGateway({ ...payload, id });
    onHide();
  };
  const handleSubmit = () => {
    // patterns
    const urlPattern = RegExp(/(^(http|https):\/\/[^|"]+$)/g);
    const merchantIdPattern = RegExp(
      /(^([0-9a-f]{8})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{12})$)/g
    );

    // validation
    const isMerchantIdValid = merchantIdPattern.test(merchantId);
    const isUrlValid = urlPattern.test(url);

    // error handling
    if (!merchantId.length || !url.length) {
      return toasts.error("فیلدهای مورد نظر را وارد کنید");
    } else if (!isMerchantIdValid) {
      return toasts.error("فرمت درگاه وارد شده مناسب نیست.");
    } else if (!isUrlValid) {
      return toasts.error("فرمت URL وارد شده صحیح نیست");
    }

    // request
    const payload = {
      merchant_id: merchantId,
      callback_url: url,
      name: "zarin-pay",
    };

    if (data) {
      handleUpdatePaymentGateway(data.id, payload);
    } else {
      handleCreatePaymentGateway(payload);
    }
  };

  return (
    <div>
      <CloseButton position="top" onClick={onHide} />

      <Modal.Header className={styles.header}>
        <Modal.Title>افزودن درگاه پرداخت</Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <div className={styles.inputWrapper}>
          {/* becuz of the gradient color below logo this image not ganna fit */}
          <img src={`${process.env.PUBLIC_URL}/images/zarin-pay.svg`} alt="" />
          <Input
            placeholder="Metchant-id"
            onChange={(e) => setMerchantId(e.target.value)}
            value={merchantId}
          />
        </div>

        <div className={styles.urlWrapper}>
          <Input
            label="لینک بازگشت از درگاه پرداخت"
            placeholder="URL"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          />
        </div>

        <button
          className={`${styles.btn} ${styles.primary}`}
          onClick={handleSubmit}>
          ثبت درگاه
        </button>
      </Modal.Body>
    </div>
  );
}

export default PaymentGatewayModal;
