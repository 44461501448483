// Series List
export const addSelectedSeries = (data) => ({
  type: "ADD_SELECTED_SERIES_LIST",
  payload: data,
});
export const removeSelectedSeries = (movieID) => ({
  type: "REMOVE_SELECTED_SERIES_LIST",
  payload: movieID,
});
export const updateSelectAllSeries = (data) => ({
  type: "UPDATE_SELECT_ALL_SERIES",
  payload: data,
});

// Add Series State by IMDB ID
export const getSeriesByImdb = (data) => ({
  type: "GET_SERIES_INFO",
  payload: data,
});

// Series Information
export const updateSeriesInformation = (data) => ({
  type: "UPDATE_SERIES_INFO",
  payload: data,
});
export const resetSeriesInformation = () => ({
  type: "RESET_SERIES_INFO",
});

// Series AWARDS tab
export const addSeriesAwards = (data) => ({
  type: "ADD_SERIES_AWARD",
  payload: data,
});
export const removeSeriesAwards = (data) => ({
  type: "REMOVE_SERIES_AWARD",
  payload: data,
});

// add Episodes and Seasons

export const getSeriesSeasons = (data) => ({
  type: "GET_SEASONS",
  payload: data,
});

export const addNewSesons = (id) => ({ type: "ADD_NEW_SEASON", payload: id });
export const updateSesons = (data) => ({ type: "UPDATE_SEASON", payload: data });
export const removeSesons = (id) => ({ type: "REMOVE_SEASON", payload: id });

export const addNewEpisode = (data) => ({
  type: "ADD_NEW_EPISODE",
  payload: data,
});

export const updateEpisode = (data) => ({
  type: "UPDATE_EPISODE",
  payload: data,
});

export const updateEpisodeSubtitle = (data) => ({
  type: "UPDATE_EPISODE_SUBTITLE",
  payload: data,
});

export const resetsSeasonsAndEpisodes = () => ({
  type: "RESET_SEASONS_EPISODE",
});

export const addSeriesSubtitleToRemoved = (data) => ({
  type: "PEND_SERIES_SUBTITLE_TO_REMOVED",
  payload: data,
});
export const resetSeriesSubtitleToRemoved = () => ({
  type: "RESET_PEND_SERIES_SUBTITLE_TO_REMOVED",
});

// keywords
export const removeSeriesKeywords = (keywordID) => ({
  type: "REMOVE_SERIES_KEYWORDS",
  payload: keywordID,
});

//download file
export const addEpisodeDownloadLink = (data) => ({
  type: "ADD_EPISODE_DOWNLOADLINK",
  payload: data,
});
export const deleteEpisodeDownloadLinkByIndex = (data) => ({
  type: "DELETE_EPISODE_DOWNLOADLINK_BY_INDEX",
  payload: data,
});
