import TooltipMenu from "../toolTipMenu/ToolTipMenu";
import styles from "../styles.module.scss";

function MenuDropdown({ title, iconUrl, menuIsOpen, dropdownState, children }) {
  const [toggle, setToggle] = dropdownState;

  return (
    <ul
      className={`${styles.subMenuWrapper} ${
        toggle ? styles.open : styles.close
      }`}>
      <li
        style={{
          backgroundColor: `${
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              ? "#160442"
              : "#010204"
          }`,
        }}
        onClick={setToggle}
        className={`${styles.dropdown} ${
          menuIsOpen ? styles.expand : styles.shrink
        }`}>
        {menuIsOpen ? (
          <img
            src={`${process.env.PUBLIC_URL}${iconUrl}`}
            alt=""
            className={styles.logo}
          />
        ) : (
          <TooltipMenu text={title}>
            <img
              src={`${process.env.PUBLIC_URL}${iconUrl}`}
              alt=""
              className={styles.logo}
            />
          </TooltipMenu>
        )}
        {menuIsOpen && <span>{title}</span>}
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/chevron-left.svg`}
          alt="chevron"
          className={styles.chevron}
        />
      </li>

      {toggle && children}
    </ul>
  );
}

export default MenuDropdown;
