import axios from "axios";
import http from "../httpService";

export const uploadFile = (url, file, uploadProgressCb) => {
  const formData = new FormData();

  formData.append("file", file);

  return axios({
    method: "POST",
    url: url,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (event) => {
      if (!uploadProgressCb) return;

      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      uploadProgressCb(event, percentCompleted);
    },
  });
};

export const postFile = (
  file,
  uploadProgressCb,
  url = `${window.__RUNTIME_CONFIG__.REACT_APP_API}/files/upload`
) => uploadFile(url, file, uploadProgressCb);

export const getFileInfo = (fileId) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/files/get-file-info?file_id=${fileId}`
  );

export const getFile = (fileId) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/files/get?file_id=${fileId}`
  );

export const deleteFile = (fileId) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/files/delete?file_id=${fileId}`
  );

// upload transcode
export const uploadVideo = (file, progressCallback, serverUrl, serverId) => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("transcode_server", serverUrl);
  formData.append(
    "streamer_server",
    window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL
  );
  formData.append("transcode_server_id", serverId);

  return axios({
    method: "POST",
    url: `${window.__RUNTIME_CONFIG__.REACT_APP_UPLOAD_API}/files/upload`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (e) => {
      const percentCompleted = Math.round((e.loaded * 100) / e.total);
      progressCallback(percentCompleted);
    },
  });
};

export const getTranscodedFileInfo = (transcodeId) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_UPLOAD_API}/files/get-file-info-by-transcode?transcode_id=${transcodeId}`
  );
export const getTranscoderFileInfo = (server, transcodeId) =>
  http.get(
    `https://${server}/api/transcoder/information/get?id=${transcodeId}`
  );

export const deleteTranscodedFile = (data) =>
  http.delete(
    `https://${data.server}/api/transcoder/delete?id=${data.transcodeId}`
  );
