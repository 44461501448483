import styles from "./CompatibleSection.module.scss";

export default function CompatibleSection() {
  return (
    <section id="trait" className={styles.wrapper}>
      <div className={styles.wrapperContent}>
        <figure className="image-cover">
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/compatible.svg`}
            alt="Compatible"
          />
        </figure>
        <div className={styles.info}>
          <h1 className="text__black">سازگاری با همه دستگاه ها</h1>
          <p className="text__black">
            این پلتفرم، ریسپانسیو و سازگار با تمامی مرورگرها و دستگاه‌ها است
            مانند کامپیوترهای شخصی، موبایل، تبلت و تلویزیون‌های هوشمند در تمامی
            ابعاد و رزولوشن ها
          </p>
        </div>
      </div>
    </section>
  );
}
