import { useState } from "react";
import { useDeleteBoxOfficeMovieQuery } from "hooks/query/useBoxOfficeQuery";
import { Dropdown, Modal } from "react-bootstrap";
import CardEditModal from "../cardEditModal/CardEditModal";
import styles from "./DotDropdown.module.scss";

function DotDropdown({ data }) {
  const { mutate: deleteBoxOfficeMovie } = useDeleteBoxOfficeMovieQuery(
    data.title
  );
  const handleRemoveMovie = () => {
    deleteBoxOfficeMovie(data.id);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [shouldShowEditModal, setShouldShowEditModal] = useState(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  const onHide = () => {
    setShouldShowEditModal(false);
  };
  const onShow = () => {
    setShouldShowEditModal(true);
  };

  return (
    <>
      <Dropdown
        bsPrefix={styles.dropdown}
        drop="right"
        onToggle={onToggle}
        show={isOpen}>
        <Dropdown.Toggle
          bsPrefix="dot-dropdown"
          childBsPrefix={`${styles.dropdownButton} ${
            isOpen ? styles.open : styles.close
          }`}>
          <img
            src={
              isOpen
                ? `${process.env.PUBLIC_URL}/images/icons/rounded-close.svg`
                : `${process.env.PUBLIC_URL}/images/icons/dots.svg`
            }
            alt=""
          />
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix={styles.dropdownMenu}>
          <div className={styles.dropdownItem} onClick={onShow}>
            <img
              src={`${process.env.PUBLIC_URL}/images/articles/edit.svg`}
              alt=""
            />
            <span>ویرایش</span>
          </div>
          <div className={styles.dropdownItem} onClick={handleRemoveMovie}>
            <img
              src={`${process.env.PUBLIC_URL}/images/articles/trash.svg`}
              alt=""
            />
            <span>حذف فیلم</span>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        show={shouldShowEditModal}
        onHide={onHide}
        dialogClassName={styles.editModal}
        centered>
        <CardEditModal onHide={onHide} data={data} />
      </Modal>
    </>
  );
}

export default DotDropdown;
