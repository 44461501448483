import DatePicker from "react-modern-calendar-datepicker";

import styles from "./InputCalender.module.scss";

//* NOTE: this component logic only works with string date and returning string date with formate like: 1999-09-21
function InputCalender({ label, placeholder, onChange, value, ...props }) {
	const covertStringDateToObject = (str) => {
		if (!str) return undefined;

		const [year, month, day] = str.split("-");
		return { year: parseInt(year), month: parseInt(month), day: parseInt(day) };
	};

	const covertObjectDateToString = (obj) => {
		const formatDigit = (num) => (num < 10 ? `0${num}` : `${num}`);
		return `${obj.year}-${formatDigit(obj.month)}-${formatDigit(obj.day)}`;
	};

	const spacingSeparatorDate = (str) => {
		if (!str) return undefined;

		return str.replace(/-/g, " - ");
	};

	const renderCustomInput = ({ ref }) => {
		return (
			<div className={styles.inputWrapper}>
				<input
					ref={ref}
					defaultValue={spacingSeparatorDate(value)}
					placeholder={placeholder}
					disabled={props.disabled}
					readOnly
				/>
				<img
					src={`${process.env.PUBLIC_URL}/images/common/calendar.svg`}
					alt=""
				/>
			</div>
		);
	};

	return (
		<div className={styles.wrapper}>
			{label && <label className={styles.label}>{label}</label>}
			<DatePicker
				wrapperClassName="customCalendar"
				// calendarClassName="responsive-calendar"
				renderInput={renderCustomInput}
				onChange={(e) => onChange(covertObjectDateToString(e))}
				value={covertStringDateToObject(value)}
				colorPrimary="#112DF1"
				shouldHighlightWeekends
				{...props}
			/>
		</div>
	);
}

export default InputCalender;
