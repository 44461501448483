import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useBoxOfficeQuery,
  usePostGenerateBoxOfficeQuery,
  usePostSyncBoxOfficeQuery,
} from "hooks/query/useBoxOfficeQuery";
import Header from "./header/Header";
import Card from "./card/Card";
import toasts from "components/common/toasts/toasts";
import styles from "./BoxOffice.module.scss";

// TODO: Need replace, update and remove or any selection of boxoffice data with imdb_id to id of box office
function BoxOffice() {
  const { mutateAsync: postSyncBoxOffice } = usePostSyncBoxOfficeQuery();
  const { mutateAsync: postGenerateBoxOffice } =
    usePostGenerateBoxOfficeQuery();
  const { data: boxOffice, isLoading } = useBoxOfficeQuery();
  const { isFindingMovieFromImdb } = useSelector(
    (state) => state.boxOfficeReducer
  ); // when want to add a movie in box-office to movies-list

  const [isGenerateLoading, setIsGenerateLoading] = useState(false);

  // sync and generate
  const handleGenerateBoxOffice = async () => {
    try {
      setIsGenerateLoading(true);
      const { data: syncData } = await postSyncBoxOffice();
      await postGenerateBoxOffice({
        items: syncData.data,
      });
      // const { data: generateData } = await postGenerateBoxOffice({
      //   items: syncData.data,
      // });

      // dispatch(setLatestBoxOffice(generateData.data));
      setIsGenerateLoading(false);
    } catch (err) {
      console.error(err);
      setIsGenerateLoading(false);
      toasts.error("مشکلی پیش آمده است! لطفا مجددا تلاش کنید");
    }
  };

  // render conditions
  if ((!boxOffice?.length && isLoading) || isFindingMovieFromImdb) {
    return (
      <div className={styles.fullPageLoading}>
        <Spinner animation="border" as="i" />
        {isFindingMovieFromImdb && (
          <span>در حال گرفتن اطلاعات فیلم از imdb. لطفا صبر کنید</span>
        )}
      </div>
    );
  }

  if (!isLoading && !boxOffice?.length) {
    return (
      <div className={styles.emptyWrapper}>
        <img
          src={`${process.env.PUBLIC_URL}/images/common/video-add.svg`}
          alt=""
        />
        <p>
          بخش باکس آفیس ها غیر فعال می باشد لطفا با کلیک بر روی دکمه زیر این بخش
          را از IMDB فعال نمایید.
        </p>
        <button
          className={styles.button}
          onClick={handleGenerateBoxOffice}
          disabled={isGenerateLoading}>
          <span>افزودن باکس آفیس</span>
          {isGenerateLoading && <Spinner animation="border" as="i" />}
        </button>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className={styles.wrapper}>
        {boxOffice?.map((item) => (
          <Card key={item.id} data={item} />
        ))}
      </div>
    </div>
  );
}

export default BoxOffice;
