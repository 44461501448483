import UploadPictures from "./uploadPictures/UploadPictures";
import styles from "./UploadFiles.module.scss";

export default function UploadFiles() {
  return (
    <>
      <section className={styles.wrapper}>
        <UploadPictures />
      </section>
    </>
  );
}
