import CountUp from "react-countup";
import styles from "./ProgressBar.module.scss";

//TODO: find better name for types
// props P: means of the resolution 720p Or 480p

export default function ProgressBar({
  type,
  progressed,
  label = true,
  fileName,
  totalVolume,
  totalFilled,
  p,
}) {
  return (
    <>
      {type === "first" && (
        <div className={styles.progressBarWrapper}>
          {label && (
            <div className={styles.progressBarContent}>
              <div>
                <span>{fileName}</span>
                <span className={styles.blue}>({totalVolume} GB)</span>
              </div>
              <div>
                <span className={styles.gray}>{progressed}% </span>
                <span className={styles.gray}>
                  {" "}
                  ({totalFilled} / {totalVolume} GB){" "}
                </span>
              </div>
            </div>
          )}
          <div className={styles.progressBar}>
            <div
              style={{
                width: `${progressed}%`,
                backgroundColor: progressed === 100 && "#00C875",
              }}
              className={styles.ProgressBarInner}></div>
          </div>
        </div>
      )}
      {type === "second" && (
        <div className={styles.progressBarGreenWrapper}>
          {label && (
            <div className={styles.progressBarGreenContent}>
              {/* <span className={styles.gray}>{progressed}%</span> */}
              <CountUp start={0} end={100} duration={3} delay={0.5} />%
            </div>
          )}
          <div className={styles.progressBarGreen}>
            <span>{fileName}</span>
            <div
              // style={{ width: `${progressed}%` }}
              className={styles.ProgressBarGreenInner}></div>
          </div>
        </div>
      )}
      {type === "converting" && (
        <div className={styles.progressBarWrapper}>
          {label && (
            <div className={styles.progressBarContent}>
              <div>
                <span>Converting to</span>
                <span className={styles.blue}>{p}</span>
              </div>
              <div>
                <span className={styles.gray}>{progressed}% </span>
              </div>
            </div>
          )}
          <div className={styles.progressBar}>
            <div
              style={{
                width: `${progressed}%`,
                backgroundColor: progressed === 100 && "#00C875",
              }}
              className={styles.ProgressBarInner}></div>
          </div>
        </div>
      )}
    </>
  );
}
