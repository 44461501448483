import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { updateMovieInformation } from "../../../../../../redux/actions/movies";
import { deleteFile } from "../../../../../../services/files/files";
import FileUploader from "../../../../../common/fileUploader";
import ProgressBar from "../../../../../common/progressBar/ProgressBar";
import toasts from "../../../../../common/toasts/toasts";
import styles from "./UploadPictures.module.scss";

//TODO: for uploadPicProgressBar className should use condition
// TODO: can create separate component for uploaded pictures

export default function UploadPictures() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const createMovie = useSelector((state) => state.createMovieReducer);

  const handleChangeImage = (file, property) => {
    dispatch(
      updateMovieInformation({
        [property]: file,
      })
    );
  };
  const handleRemoveImage = async (property) => {
    if (property === "cover") {
      if (id) {
        const { status } = await deleteFile(createMovie?.cover);
        if (status === 200) {
          toasts.success("فایل باموفقیت حذف شد.");
        }
      }
      dispatch(
        updateMovieInformation({
          cover: "",
        })
      );
    }
    if (property === "banner") {
      if (id) {
        const { status } = await deleteFile(createMovie?.banner);
        if (status === 200) {
          toasts.success("فایل باموفقیت حذف شد.");
        }
      }
      dispatch(
        updateMovieInformation({
          banner: "",
        })
      );
    }
  };

  return (
    <>
      <h4>بارگذاری تصاویر</h4>
      <div className={styles.uploadPicPill}>
        <div className={styles.fileUploaderCustom}>
          <FileUploader
            text="انتخاب Thumbnail در ابعاد 300x450 پیکسل"
            maxFiles={1}
            btnPlace={"null"}
            height={56}
            type="image"
            imageState={[
              createMovie.cover,
              (file) => handleChangeImage(file, "cover"),
            ]}
            onRemove={() => handleRemoveImage("cover")}
          />
        </div>
        {/* <div className={styles.addBtn}>افزودن</div> */}
      </div>
      <div className={styles.uploadPicPill}>
        <div className={styles.fileUploaderCustom}>
          <FileUploader
            text="انتخاب Poster در ابعاد 1200x675 پیکسل"
            maxFiles={1}
            btnPlace={"null"}
            height={56}
            type="image"
            isBanner
            imageState={[
              createMovie.banner,
              (file) => handleChangeImage(file, "banner"),
            ]}
            onRemove={() => handleRemoveImage("banner")}
          />
        </div>
        {/* <div className={styles.addBtn}>افزودن</div> */}
      </div>
      {/* <div className={`${styles.uploadPicPill} ${styles.uploadPicProgressBar}`}>
        <div className={styles.fileUploaderCustom}>
          <FileUploader
            text="انتخاب Poster در ابعاد 1200x675 پیکسل"
            maxFiles={1}
            btnPlace={"null"}
            height={56}
          />
        </div>
        <ProgressBar fileName={"poster.jpg"} progressed={64} type={"second"} />
        <div className={styles.addBtn}>افزودن</div>
      </div> */}
      {/* <div className={styles.uploadPicList}> */}
      {/* <div className={styles.uploadPicListItem}>
          <img
            className={styles.posterPic}
            src={`${process.env.PUBLIC_URL}/images/movies/test-poster.jpg`}
            alt=""
          />
          <button className="btn">
            poster.jpg
            <img
              className={styles.trashIcon}
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt=""
            />
          </button>
        </div> */}
      {/* <div className={styles.uploadPicListItem}>
          <img
            className={styles.thumbnailPic}
            src={`${process.env.PUBLIC_URL}/images/movies/test-thumbnail.jpg`}
            alt=""
          />
          <button className="btn">
            thumbnail.jpg
            <img
              className={styles.trashIcon}
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt=""
            />
          </button>
        </div> */}
      {/* </div> */}
    </>
  );
}
