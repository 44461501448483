import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDeleteCollectionQuery } from "hooks/query/useCollectionQuery";
import { updateSelectAllCollections } from "../../../redux/actions/collections";
import toasts from "components/common/toasts/toasts";
import DeleteMultiModal from "../../common/modals/deleteMultiModal/DeleteMultiModal";
import styles from "./Header.module.scss";

function Header({ data }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { mutateAsync: deleteCollection } =
    useDeleteCollectionQuery("multiType");
  const { selectedCollection, isAllSelected } = useSelector(
    (state) => state.collectionListReducer
  );

  const [allChecked, setAllChecked] = useState(false);
  // Modals State
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (data?.length) {
      if (selectedCollection.length === data.length) {
        dispatch(
          updateSelectAllCollections({
            allChecked: true,
            collectionList: data,
          })
        );
        setAllChecked(true);
      } else if (isAllSelected && selectedCollection.length < data.length) {
        dispatch(
          updateSelectAllCollections({
            allChecked: false,
            collectionList: [],
          })
        );
        setAllChecked(false);
      } else {
        setAllChecked(false);
      }
    }
  }, [data, dispatch, isAllSelected, selectedCollection]);

  const handleSelectAll = (allChecked) => {
    dispatch(
      updateSelectAllCollections({
        allChecked: allChecked,
        collectionList: data,
      })
    );
    setAllChecked(allChecked);
  };
  const handleDeleteCollection = async () => {
    for (const item of selectedCollection) {
      const { status } = await deleteCollection(item.id);
      if (status === 200) {
        toasts.success(`کالکشن ${item.title} باموفقیت حذف شد.`);
      }
      setShowDeleteModal(false);
      dispatch(
        updateSelectAllCollections({
          allChecked: false,
          collectionList: [],
        })
      );
    }
  };

  return (
    <>
      <div className={styles.headerWrapper}>
        <div
          onClick={() => handleSelectAll(!allChecked)}
          className={`btn ${styles.btn} ${styles.primary} ${
            allChecked ? styles.activeBtn : ""
          }`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/movies/chose-all.svg`}
            alt=""
          />
          انتخاب همه
        </div>
        {/* {!selectedCollection.length && <Filter />} */}
        {selectedCollection.length > 0 && (
          <div
            onClick={() => setShowDeleteModal(true)}
            className={`btn ${styles.btn} ${styles.danger}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt=""
            />
            حذف موارد انتخاب شد
          </div>
        )}
        {!selectedCollection.length && (
          <div
            onClick={() => history.push("collections/addCollection")}
            className={`btn ${styles.btn} ${styles.primary}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/collections.svg`}
              alt="+"
              className={styles.logoActive}
            />
            افزودن کالکشن
          </div>
        )}
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={styles.modal}>
        <DeleteMultiModal
          titleData={{
            deleteObject:
              selectedCollection.length === 1 ? selectedCollection[0].name : "",
            isMoreThanOne: selectedCollection.length > 1,
            placeName: "کالکشن",
          }}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={handleDeleteCollection}
        />
      </Modal>
    </>
  );
}

export default Header;
