import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  fetchMoviesByImdb,
  updateMovieInformation,
} from "../../../redux/actions/movies";
import { Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Input } from "../../common/input/Input";
import moment from "jalali-moment";
import styles from "./AddMovieModal.module.scss";

export default function AddMovieModal({ onHide }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [movieId, setMovieId] = useState("");

  const { data, isLoading, error } = useSelector(
    (state) => state.getMovieByImdbReducer
  );

  const postMovie = () => {
    if (!isLoading && data?.title) {
      // updating movie information with fetched data to reduce to the changes
      const payload = {
        ...data,
        content_rating: {
          id: data.content_rating,
          title: data.content_rating,
        },
      };
      dispatch(updateMovieInformation(payload));
      history.push("/movies/addMovies");
    } else {
      dispatch(fetchMoviesByImdb(movieId));
    }
  };

  return (
    <>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>
          برای افزودن ساده تر فیلم، ابتدا آی دی{" "}
          <span className={styles.bold}>TMDB</span> و یا{" "}
          <span className={styles.bold}>IMDB</span> مربوط به آنرا وارد نمایید تا
          اطلاعات فیلم بصورت خودکار وارد شود، همچنین میتوانید نام لاتین آنرا
          وارد کرده و جستجو کنید
        </Modal.Title>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Input
          label="آی دی TMDB و یا IMDB"
          placeholder="به طور مثال: tt181652"
          name="movieId"
          value={movieId}
          onChange={(e) => setMovieId(e.target.value)}
          clearOnClick={() => setMovieId("")}
          clear={!isLoading && movieId.length > 0}
          errors={error}
          errorsText="آی دی وارد شده معتبر نیست!"
          disabled={isLoading}
        />
        {!isLoading && data && (
          <div className={styles.searchedList}>
            {data.cover?.length ? (
              <img
                className={styles.searchedListPoster}
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
                alt={data.title}
              />
            ) : (
              <img
                className={styles.searchedListPoster}
                src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
                alt={data.title}
              />
            )}
            <p>
              {data.title} {moment(data.release).format("YYYY")}
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          borderTop: !isLoading && data && "1px solid #eff1fe",
        }}
        className={styles.modalFooter}>
        <Link
          to="/movies/addMovies"
          className={`${styles.btn} ${styles.outline} btn`}>
          وارد کردن فیلم به صورت دستی
        </Link>
        <button
          onClick={postMovie}
          className={`${styles.btn} ${styles.primary} btn`}
          disabled={isLoading}>
          {data?.title ? (
            <span>مرحله بعد</span>
          ) : (
            <>
              <span>جستجو</span>
              {isLoading && <Spinner animation="border" as="i" />}
            </>
          )}
        </button>
      </Modal.Footer>
    </>
  );
}
