import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useCategoriesQuery } from "hooks/query/useCategoryQuery";
import { actions } from "constants/react-select";
import { Modal } from "react-bootstrap";
import DropDown from "../dropDown/DropDown";
import AddModal from "./addModal/AddModal";
import styles from "./CategoryDropdown.module.scss";

function CategoryDropdown({ label, updateCb, value }) {
  const { data: categories } = useCategoriesQuery();
  const { contactInfo } = useSelector((state) => state.profileReducer);

  const [showModal, setShowModal] = useState(false);

  const onChangeCategoryId = (option, meta) => {
    const { action } = meta;

    if (action === actions.SELECT_OPTION) {
      updateCb(option.id);
    }

    if (action === actions.CLEAR) {
      updateCb(null);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <div className={styles.fieldGroup}>
          {label && <label className={styles.label}>{label}</label>}
          <DropDown
            placeholder={"دسته بندی"}
            options={categories}
            getOptionLabel={(option) => option["name"]}
            getOptionValue={(option) => option["id"]}
            value={categories?.find((c) => c.id === value?.category_id)}
            onChange={onChangeCategoryId}
            isClearable
          />
        </div>

        <button
          className={styles.textButton}
          onClick={() => setShowModal(true)}>
          افزودن دسته بندی جدید
        </button>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <AddModal onHide={() => setShowModal(false)} userId={contactInfo?.id} />
      </Modal>
    </React.Fragment>
  );
}

export default CategoryDropdown;
