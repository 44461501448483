import { useState } from "react";
import { useTelevisionsQuery } from "hooks/query/useTelevisionQuery";
import { Modal, Table, Spinner } from "react-bootstrap";
import AddModal from "./addModal/AddModal";
import Row from "./row/Row";
import Paginate from "components/common/paginate/Paginate";
import styles from "./Television.module.scss";

export default function Television() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = useTelevisionsQuery(currentPage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onOpenModal = () => {
    setIsModalOpen(true);
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Table className={styles.table}>
          <thead>
            <tr className={styles.headRow}>
              <th>نام شبکه</th>
              <th colSpan={2}>
                لینک شبکه
                <button
                  className={`${styles.button} ${styles.button__primary}`}
                  onClick={onOpenModal}>
                  <img src={"/images/categories/add-square.svg"} alt="+" />
                  <span>افزودن شبکه جدید</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {isPreviousData ? (
              <div className="spinnerCenter">
                <Spinner animation="border" as="i" />
              </div>
            ) : (
              data?.results?.map((item) => <Row key={item.id} data={item} />)
            )}
          </tbody>
        </Table>
      </div>
      {data?.results.length > 0 && (
        <Paginate
          type="television"
          paginateState={[currentPage, setCurrentPage]}
          total={data?.pagination.count}
        />
      )}
      <Modal
        show={isModalOpen}
        onHide={onCloseModal}
        dialogClassName={styles.modal}
        centered>
        <AddModal type="create" onHide={onCloseModal} />
      </Modal>
    </>
  );
}
