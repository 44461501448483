import { useSelector } from "react-redux";
import HeadTitle from "new-components/head-title";
import styles from "./styles.module.scss";
export default function FinalReview() {
  const liveStreaming = useSelector((state) => state.setLiveStreamingReducer);
  return (
    <>
      <HeadTitle title="اطلاعات پخش" />
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <div className={styles.info}>
            <div>
              <strong>Server URL:</strong>
              <span>{liveStreaming.server_url}</span>
            </div>
            <div>
              <strong>Stream Key:</strong>
              <span>{liveStreaming.stream_key}</span>
            </div>
            <div>
              <strong>Stream URL:</strong>
              <span>{liveStreaming.hls_url}</span>
            </div>
          </div>
          <div className={styles.resolutions}>
            <strong>Resolutions:</strong>
            <div className={styles.resolutionsList}>
              {liveStreaming.resolutions.map((item) => (
                <div className={styles.row}>
                  <div className={styles.badge}>
                    <span className={styles.gray}>Resolitions:</span>
                    <span className={styles.black}>{item.resolution.name}</span>
                  </div>
                  <div className={styles.badge}>
                    <span className={styles.gray}>Audio bitrate:</span>
                    <span className={styles.black}>{item.audio_bitrate}</span>
                  </div>
                  <div className={styles.badge}>
                    <span className={styles.gray}>Video bitrate:</span>
                    <span className={styles.black}>{item.video_bitrate}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.watermark}>
            <strong>Watermark:</strong>
            <div className={styles.badge}>
              <span className={styles.gray}>Position:</span>
              <span className={styles.black}>
                {liveStreaming?.watermark_position
                  ? liveStreaming.watermark_position.name
                  : "بالا چپ"}
              </span>
            </div>
          </div>
          <div className={styles.fps}>
            <strong>FPS:</strong>
            <div className={styles.badge}>
              <span className={styles.gray}>FPS:</span>
              <span className={styles.black}>
                {liveStreaming?.fps ? liveStreaming.fps : 30}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}