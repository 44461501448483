const initialState = {
	genres: [],

	boxOffice: [],
	error: null,
	isLoading: false,

	isFindingMovieFromImdb: false,
};

export const boxOfficeReducer = (state = initialState, action) => {
	switch (action.type) {
		// get box office
		case "FETCH_GET_BOX_OFFICE":
			return { ...state, isLoading: true };
		case "FETCH_GET_BOX_OFFICE_SUCCESS":
			return { ...state, boxOffice: action.payload, isLoading: false };
		case "FETCH_GET_BOX_OFFICE_FAIL":
			return { ...state, error: action.payload, isLoading: false };

		// genres
		case "FETCH_GENRES_FOR_BOX_OFFICE_SUCCESS":
			return { ...state, genres: action.payload };

		// get full data from imdb - just used for showing full screen loading
		case "FETCH_MOVIE_FROM_IMDB":
			return { ...state, isFindingMovieFromImdb: true };
		case "FETCH_MOVIE_FROM_IMDB_SUCCESS":
			return { ...state, isFindingMovieFromImdb: false };
		case "FETCH_MOVIE_FROM_IMDB_FAIL":
			return { ...state, isFindingMovieFromImdb: false };

		case "SET_LATEST_BOX_OFFICE":
			return { ...state, boxOffice: action.payload };

		case "REMOVE_BOX_OFFICE_MOVIE":
			return {
				...state,
				boxOffice: state.boxOffice.filter(
					(item) => item.id !== action.payload.boxOfficeId
				),
			};

		case "UPDATE_BOX_OFFICE_MOVIE":
			return {
				...state,
				boxOffice: state.boxOffice.map((item) => {
					if (item.id === action.payload.boxOfficeId) {
						return {
							...item,
							...action.payload.moviePayload,
						};
					}

					return item;
				}),
			};

		default:
			return state;
	}
};
