import http from "../httpService";
export const getComments = (page, signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/comments/get-all?page=${
      page ? page : ""
    }&limit=10`,
    { signal }
  );
};
export const patchConfirmComment = (id) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/comments/confirm?id=${id}`
  );
};
export const patchVisibilityComment = (body) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/comments/visible`,
    body
  );
};
export const deleteComment = (id) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/comments/delete?id=${id}`
  );
};
