import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteExpert,
  getExperts,
  postExpert,
  putExpert,
} from "services/experts/expertsServices";
import toasts from "components/common/toasts/toasts";

export const useExpertsQuery = (page) => {
  return useQuery(["experts", page], ({ signal }) => getExperts(page, signal), {
    keepPreviousData: true,
    select: (data) => {
      return data.data.data;
    },
  });
};

export const usePostExpertQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(postExpert, {
    onSuccess: () => {
      queryClient.invalidateQueries("experts");
      toasts.success(`کارشناس ${title} با موفقیت ثبت شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت کارشناس پیش آمده");
    },
  });
};
export const usePutExpertQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(putExpert, {
    onSuccess: () => {
      queryClient.invalidateQueries("experts");

      toasts.success(`کارشناس ${title} با موفقیت بروزرسانی شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی کارشناس پیش آمده");
    },
  });
};
export const useDeleteExpertQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteExpert, {
    onSuccess: () => {
      queryClient.invalidateQueries("experts");
      toasts.success(`${title} کارشناس حذف شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
