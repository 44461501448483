import { useMemo } from "react";
import { useArtistsQuery } from "hooks/query/useArtistQuery";
import {
  useMusicGenresQuery,
  useMusicKindsQuery,
} from "hooks/query/useMusicQuery";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { musicTypes } from "../../../constants/musics";
import {
  addPersonToAppendList,
  removePersonFromAppendList,
  updateMusicForm,
} from "../../../redux/actions/musics";
import {
  patchDeleteArrangements,
  patchDeleteArtist,
  patchDeleteSinger,
  patchDeleteSongwriter,
} from "../../../services/musics/albums";
import { Input } from "../../common/input/Input";
import Dropdown from "../../common/dropDown/DropDown";
import MultiTag from "../../common/multiTag/MultiTag";
import TextArea from "../../common/textArea/TextArea";
import toasts from "../../common/toasts/toasts";
import InputCalender from "../inputCalender/InputCalender";

import styles from "./Form.module.scss";

function Form() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { type, form, appendedPeople } = useSelector(
    (state) => state.musicFormReducer
  );
  const { data: kinds } = useMusicKindsQuery();
  const { data: genres } = useMusicGenresQuery(form?.kind?.id); // test it
  //   const { albums } = useSelector((state) => state.musicsReducer);
  const { data: artists } = useArtistsQuery();

  // Handle Changes
  const handleCalender = (stringDate) => {
    dispatch(updateMusicForm({ release: stringDate }));
  };
  const handleChangeInput = (e) => {
    dispatch(updateMusicForm({ [e.target.name]: e.target.value }));
  };
  const handleChangeDropdown = (option, meta) => {
    // maybe need to change only storing id cuz im getting id from stored data in backend
    dispatch(updateMusicForm({ [meta.name]: option }));
  };
  const handleChangeMultiDropdown = (option, meta) => {
    if (meta.action === "select-option") {
      dispatch(addPersonToAppendList(meta.name, meta.option));
    } else if (meta.action === "remove-value") {
      if (id) {
        handleDeleteAppendedPeople(meta.name, meta.removedValue, +id);
      } else {
        dispatch(removePersonFromAppendList(meta.name, meta.removedValue.id));
      }
    }
  };

  // removing the connection
  const handleDeleteAppendedPeople = async (
    deleteSectionName,
    people,
    albumId
  ) => {
    try {
      const mappedApiObject = {
        artists: patchDeleteArtist,
        singers: patchDeleteSinger,
        songwriters: patchDeleteSongwriter,
        arrangements: patchDeleteArrangements,
      };

      const payload = {
        id: albumId,
        [deleteSectionName]: [people.id],
      };

      await mappedApiObject[deleteSectionName](payload);
      dispatch(removePersonFromAppendList(deleteSectionName, people.id));

      toasts.success(`${people.name} با موفقیت از آلبوم حذف شد`);
    } catch (err) {
      console.error(err);
    }
  };

  // filtering artists by occupation
  const singer = useMemo(
    () => artists?.results.filter((item) => item.occupation.includes("singer")),
    [artists]
  );
  const musicProducer = useMemo(
    () =>
      artists?.results.filter((item) => item.occupation.includes("music_producer")),
    [artists]
  );
  const songwriter = useMemo(
    () => artists?.results.filter((item) => item.occupation.includes("songwriter")),
    [artists]
  );
  const arrangement = useMemo(
    () =>
      artists?.results.filter((item) => item.occupation.includes("arrangement")),
    [artists]
  );
  const musician = useMemo(
    () =>
      artists?.results.filter((item) =>
        item.occupation.includes(
          "singer" || "performer" || "songwriter" || "conductor"
        )
      ),
    [artists]
  );

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.formTitle} ${
          musicTypes.album === type ? styles.album : styles.single
        }`}>
        {musicTypes.album === type ? "اطلاعات آلبوم" : "اطلاعات موسیقی"}
      </div>

      {musicTypes.album === type ? (
        <div className={`${styles.form} ${styles.album}`}>
          <Input
            placeholder="نام آلبوم (فارسی)"
            name="name_in_persian"
            onChange={handleChangeInput}
            value={form.name_in_persian}
          />
          <Input
            placeholder="نام آلبوم (انگلیسی)"
            name="name"
            onChange={handleChangeInput}
            value={form.name}
          />
          {/* dont know who is art owner ??  */}
          <Input
            placeholder="نام صاحب اثر"
            name="owner_name"
            onChange={handleChangeInput}
            value={form.owner_name}
          />
          <InputCalender
            placeholder="تاریخ پخش"
            onChange={handleCalender}
            value={form.release}
          />
          <Dropdown
            placeholder="نوع آهنگ"
            name="kind"
            getOptionLabel={(option) => option["name"]}
            getOptionValue={(option) => option["id"]}
            options={kinds}
            onChange={handleChangeDropdown}
            value={form.kind}
          />
          <Dropdown
            placeholder="سبک"
            name="genre"
            getOptionLabel={(option) => option["name"]}
            getOptionValue={(option) => option["id"]}
            options={genres}
            onChange={handleChangeDropdown}
            value={form.genre}
          />
          <Dropdown
            placeholder="تهییه کننده "
            name="producer"
            getOptionLabel={(option) => option["name"]}
            getOptionValue={(option) => option["id"]}
            options={musicProducer}
            onChange={handleChangeDropdown}
            value={form.producer}
          />
          <Input
            placeholder="منتشر کننده"
            name="publisher"
            onChange={handleChangeInput}
            value={form.publisher}
          />
          <MultiTag
            placeholder="هنرمندان"
            options={musician}
            name="artists"
            onChange={handleChangeMultiDropdown}
            selectedOptions={appendedPeople.artists}
          />
        </div>
      ) : (
        <div className={`${styles.form} ${styles.single}`}>
          <Input
            placeholder="نام آهنگ (فارسی)"
            name="name_in_persian"
            onChange={handleChangeInput}
            value={form.name_in_persian}
          />
          <Input
            placeholder="نام آهنگ (انگلیسی)"
            name="name"
            onChange={handleChangeInput}
            value={form.name}
          />
          <MultiTag
            placeholder="خواننده"
            options={singer}
            name="singers"
            onChange={handleChangeMultiDropdown}
            selectedOptions={appendedPeople.singers}
          />
          <InputCalender
            placeholder="تاریخ پخش"
            onChange={handleCalender}
            value={form.release}
          />
          <TextArea
            placeholder="متن آهنگ"
            name="lyrics"
            onChange={handleChangeInput}
            value={form.lyrics}
          />
          <Dropdown
            placeholder="نوع آهنگ"
            name="kind"
            getOptionLabel={(option) => option["name"]}
            getOptionValue={(option) => option["id"]}
            options={kinds}
            onChange={handleChangeDropdown}
            value={form.kind}
          />
          <Dropdown
            placeholder="سبک"
            name="genre"
            getOptionLabel={(option) => option["name"]}
            getOptionValue={(option) => option["id"]}
            options={genres}
            onChange={handleChangeDropdown}
            value={form.genre}
          />
          <MultiTag
            placeholder="شاعر، ترانه سرا"
            options={songwriter}
            name="songwriters"
            onChange={handleChangeMultiDropdown}
            selectedOptions={appendedPeople.songwriters}
          />
          <MultiTag
            placeholder="تنظیم"
            options={arrangement}
            name="arrangements"
            onChange={handleChangeMultiDropdown}
            selectedOptions={appendedPeople.arrangements}
          />
          {/* <Dropdown placeholder="افزودن به آلبوم" options={albums} /> */}
        </div>
      )}
    </div>
  );
}

export default Form;
