import { useDispatch } from "react-redux";
import { updateEpisode } from "redux/actions/series";
import { Form, Modal } from "react-bootstrap";
import styles from "../../../common/movieFileUploader/MovieFileUploader.module.scss";

function AddVideoModal({
  linkState,
  inputProps,
  rootProps,
  onHide,
  modalProps,
  setVideoSrc,
  setInformation,
}) {
  const dispatch = useDispatch();
  const [linkUrl, setLinkUrl] = linkState;

  // add series link Url
  const handleAddLinkURL = () => {
    dispatch(
      updateEpisode({
        file: "",
        linkURL: linkUrl,
        seasonId: modalProps?.seasonId,
        episodeId: modalProps?.episodeId,
      })
    );
    setVideoSrc("");
    setInformation("");
    onHide();
  };
  const handleRemove = () => {
    dispatch(
      updateEpisode({
        file: "",
        linkURL: "",
        seasonId: modalProps?.seasonId,
        episodeId: modalProps?.episodeId,
      })
    );
    setLinkUrl("")
    setVideoSrc("");
    setInformation("");
    onHide();
  };

  return (
    <>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>
          برای افزودن قسمت به سریال،{" "}
          <span className={styles.bold}>لینک مستقیم</span> آنرا وارد کرده و یا
          از حافظه خود آنرا بارگذاری کنید
        </Modal.Title>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={() => onHide()}
        />
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Form.Group className={styles.group}>
          <input
            type="text"
            className={`form-control ${styles.formControl}`}
            placeholder={`به طور مثال: https://www.upload.com/watchvDHtsdLzHp8U.mkv`}
            name="link"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <button
          onClick={handleRemove}
          className={`${styles.btn} ${styles.delete} btn`}>
          حذف
        </button>

        <button
          {...rootProps}
          className={`${styles.btn} ${styles.outline} btn`}>
          <input {...inputProps} />
          انتخاب فایل
        </button>

        <button
          onClick={handleAddLinkURL}
          className={`${styles.btn} ${styles.primary} btn`}>
          افزودن
        </button>
      </Modal.Footer>
    </>
  );
}

export default AddVideoModal;
