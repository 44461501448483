import MainSection from "./mainSection/MainSection";
import CompatibleSection from "./compatibleSection/CompatibleSection";
import TimeSection from "./timeSection/TimeSection";
import AdaptiveSection from "./adaptiveSection/AdaptiveSection";
import LiveSection from "./liveSection/LiveSection";
import FeaturesSection from "./featuresSection/FeaturesSection";

export default function Landing() {
  return (
    <>
      <MainSection />
      <CompatibleSection />
      <TimeSection />
      <AdaptiveSection />
      <LiveSection />
      <FeaturesSection />
    </>
  );
}
