import { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Spinner } from "react-bootstrap";
import { useArticlesQuery } from "hooks/query/useArticleQuery";
import Row from "./row/Row";
import Paginate from "components/common/paginate/Paginate";
import styles from "./Articles.module.scss";

function Articles() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = useArticlesQuery(currentPage);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.bgWrapper}>
          <Table className={styles.table} responsive>
            <thead>
              <tr>
                <th>تصویر شاخص</th>
                <th>کد مقاله</th>
                <th>تاریخ انتشار</th>
                <th width="320px">تیتر مقاله</th>
                <th>وضعیت</th>
                <th className={styles.newArticle}>
                  <Link to="/articles/addArticle">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/common/plus.svg`}
                      alt=""
                    />
                    <span>افزودن مقاله جدید</span>
                  </Link>
                </th>
              </tr>
            </thead>
            {isPreviousData ? (
              <div className="spinnerCenter">
                <Spinner animation="border" as="i" />
              </div>
            ) : (
              data?.results?.length > 0 && (
                <tbody>
                  {data.results.map((item) => (
                    <Row key={item.id} data={item} />
                  ))}
                </tbody>
              )
            )}
          </Table>

          {!data?.results?.length && (
            <div className={styles.empty}>
              <img
                src={`${process.env.PUBLIC_URL}/images/common/articles-empty.svg`}
                alt=""
              />
              <p>
                شما هیچ مقاله ای تا کنون اضافه نکردید جهت ساخت مقاله جدید بر روی
                کلید (افزودن مقاله جدید) کلیک کنید.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="paginationWrapper">
        {data?.results?.length > 0 && (
          <Paginate
            type="articles"
            paginateState={[currentPage, setCurrentPage]}
            total={data?.pagination.count}
          />
        )}
      </div>
    </>
  );
}

export default Articles;
