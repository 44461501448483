const initialState = {
  experts: [],
};
export const expertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_EXPERT":
      return {
        ...state,
        experts: state.experts.map((item) => {
          if (item.id === action.payload.expertId) {
            return {
              ...item,
              ...action.payload.expertData,
            };
          }

          return item;
        }),
      };

    default:
      return state;
  }
};