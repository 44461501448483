import styles from "./Stepper.module.scss";

export default function Stepper({ steps, stepNow }) {
  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        {steps.map((item, index) => (
          <div key={index} className={styles.step}>
            {stepNow > index + 1 ? (
              <img
                src={`${process.env.PUBLIC_URL}/images/stepper/done.svg`}
                alt="done"
                className={`${styles.img} ${styles.imgActive}`}
              />
            ) : (
              <div
                className={
                  stepNow > index
                    ? `${styles.img} ${styles.imgActive}`
                    : styles.img
                }>
                <img
                  src={`${process.env.PUBLIC_URL}${item.img}`}
                  alt={item.title}
                  className={stepNow > index ? styles.imgActive : ""}
                />
              </div>
            )}
            <span
              className={
                stepNow > index + 1
                  ? `${styles.title} ${styles.green}`
                  : stepNow > index
                  ? `${styles.title} ${styles.titleActive}`
                  : styles.title
              }>
              {item.title}
            </span>
            <span
              className={
                stepNow > index + 1
                  ? `${styles.content} ${styles.green}`
                  : stepNow > index
                  ? `${styles.content} ${styles.contentActive}`
                  : styles.content
              }>
              {item.content}
            </span>
            <i
              className={
                stepNow > index
                  ? `${styles.line} ${styles.greenLine}`
                  : styles.line
              }></i>
          </div>
        ))}
      </div>
    </section>
  );
}