export const updateLiveStreamField = (data) => ({
  type: "UPDATE_LIVE_STREAM",
  payload: data,
});
export const resetLiveStream = () => ({ type: "RESET_LIVE_STREAM" });
export const addLiveResolution = () => ({ type: "ADD_LIVE_RESOLUTIONS" });
export const changeLiveResolution = (data) => ({
  type: "CHANGE_LIVE_RESOLUTIONS",
  payload: data,
});
export const removeLiveResolution = (data) => ({
  type: "REMOVE_LIVE_RESOLUTIONS",
  payload: data,
});

export const addSelectedStream = (data) => ({
  type: "ADD_SELECTED_STREAM_LIST",
  payload: data,
});
export const removeSelectedStream = (streamID) => ({
  type: "REMOVE_SELECTED_STREAM_LIST",
  payload: streamID,
});
export const updateSelectAllStream = (data) => ({
  type: "UPDATE_SELECT_ALL_STREAM",
  payload: data,
});
