import CryptoJS from "crypto-js";

export default {
  readFileAsText: async (url) => {
    const file = await fetch(url);
    return await file.text();
  },

  decryptFile: (file, decryptKey, ivKey) => {
    let raw = CryptoJS.enc.Base64.parse(file);
    let key = CryptoJS.enc.Latin1.parse(decryptKey);
    let iv = CryptoJS.enc.Latin1.parse(ivKey);

    let plain = CryptoJS.AES.decrypt({ ciphertext: raw }, key, { iv: iv });
    return plain.toString(CryptoJS.enc.Latin1);
  },
};
