import videojs from "video.js";
import "./watermark.css";

const Plugin = videojs.getPlugin("plugin");

class Watermark extends Plugin {
  constructor(player, options) {
    super(player);
    this.div = null;
    this.options = {
      file: false,
      position: "top_left",
      opacity: 80,
      clickable: false,
      url: null,
      text: null,
      color: "#fff",
      width: "20%",
      height: "20%",
    };
    this.player.ready(() => {
      this.options = { ...this.options, ...options };
      this.watermark();
    });
  }
  toggleActive() {
    this.div.classList.toggle("hide");
  }
  watermark() {
    const player = this.player.el();
    if (!this.div) {
      this.div = document.createElement("div");
      this.div.className = "vjs-watermark";
    } else {
      this.div.innerHTML = "";
    }
    if (!this.options.text && !this.options.file) return;
    // if type is text
    if (this.options.text) {
      this.div.textContent = this.options.text;
      this.div.style.color = this.options.color;
    }
    // if type is image
    if (this.options.file) {
      let img = document.createElement("img");
      this.div.appendChild(img);
      img.src = this.options.file;
    }
    this.div.style.width = this.options.width;
    this.div.style.height = this.options.height;
    // Position of watermark
    this.div.classList.add(this.options.position);
    // Check if watermark is an image
    if (this.options.clickable && this.options.url) {
      let link = document.createElement("a");
      link.href = this.options.url;
      link.target = "_blank";
      link.appendChild(this.div);
      player.appendChild(link);
    } else {
      player.appendChild(this.div);
    }
  }
}

videojs.registerPlugin("watermark", Watermark);

export default Watermark;
