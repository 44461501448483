import { useEffect, useState } from "react";
import { convertMilliSecondToMinutes } from "../../../util/millisecondConvertor";
import {
  registerConfirmCode,
  registerUser,
} from "../../../services/entry/entryService";
import { getNumberOnly } from "./../../../util/number";
import { useHistory } from "react-router-dom";
import CustomInput from "../../common/customInput/CustomInput";
import toasts from "../../common/toasts/toasts";
 // import { setUser } from "./../../../redux/actions/user";
// import { useDispatch } from "react-redux";
import styles from "../RegisterAndLogin.module.scss";
import { handleCookie } from "util/auth";

//? SHOW and Use this component after user registered

function EnterPlatformWithOTP({ registerEntity }) {
  const BASE_MILLISECOND = 120;
  // const dispatch = useDispatch();
  const history = useHistory();
  const [timeCounter, setTimeCounter] = useState(BASE_MILLISECOND);
  const [classValidationInput, setClassValidationInput] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    const myTimeOut = setTimeout(() => {
      setTimeCounter((prev) => prev - 1);
    }, 1000);

    if (timeCounter <= 0) {
      setTimeCounter(0);
      clearTimeout(myTimeOut);
    }
    return () => {
      clearTimeout(myTimeOut);
    };
  }, [timeCounter]);

  //! use case ???
  const register = () => {
    confirmUser();
  };

  const reRegister = async () => {
    const register = {
      email: registerEntity.email,
      password: registerEntity.password,
      mobile: registerEntity.mobile,
      country_code: registerEntity.country_code,
      verification: "sms",
      sms_provider: "ippanel",
    };
    try {
      const { status } = await registerUser(register);
      if (status === 200) {
        toasts.success("کد تایید به شمراه همراه شما ارسال شد");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          toasts.error("ایمیل و یا شماره همراه موردنظر قبلا ثبت شده است");
          setClassValidationInput("danger");
        }
        if (error.response.status === 406) {
          toasts.error("فرمت ایمیل وارد شده صحیح نمی باشد");
          setClassValidationInput("danger");
        }
        if (error.response.status !== 409 && error.response.status !== 406) {
          toasts.error("خطایی پیش آمده است");
          setClassValidationInput("danger");
        }
      }
    }
  };
  const handleOTP = () => {
    if (timeCounter === 0) {
      // re-send the request
      setTimeCounter(BASE_MILLISECOND);
      reRegister();
    } else {
      return;
    }
  };
  const confirmUser = async () => {
    const result = isValidInput();
    if (result) {
      const userCode = { code: code, mobile: registerEntity.mobile };
      await registerConfirmCode(userCode)
        .then((res) => {
          if (res.status === 200) {
            setClassValidationInput("verify");
            handleCookie(res);
            // dispatch(setUser());
            history.replace("/overView");
            toasts.success("فرایند ثبت نام با موفقیت انجام شد");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              toasts.error("کد وارد شده معتبر نمی باشد");
              setClassValidationInput("danger");
            }
            if (error.response.status === 409) {
              toasts.error("ایمیل و یا شماره همراه موردنظر قبلا ثبت شده است");
              setClassValidationInput("danger");
            }
            if (
              error.response.status !== 409 &&
              error.response.status !== 400
            ) {
              toasts.error("خطایی پیش آمده است");
              setClassValidationInput("danger");
            }
          }
        });
    }
  };
  const isValidInput = () => {
    if (code !== "") {
      // setClassValidationInput("");
      return true;
    }
    setClassValidationInput("danger");
    toasts.error("کد را وارد کنید");
    return false;
  };

  return (
    <>
      <CustomInput
        type="text"
        label="کد تأیید"
        handleChange={setCode}
        value={code}
        classValidation={classValidationInput}
        onKeyPress={(e) => getNumberOnly(e)}
      />
      <div className={`btn ${styles.btn}`} onClick={register}>
        ثبت نام و ورود به پلتفرم
      </div>
      <div className={styles.greyBtn} onClick={handleOTP}>
        ارسال مجدد:{" "}
        <span className={styles.primary}>
          {convertMilliSecondToMinutes(timeCounter)}
        </span>
      </div>
    </>
  );
}

export default EnterPlatformWithOTP;
