import { useState, useCallback, useMemo } from "react";
import { usePackagesQuery } from "hooks/query/usePackageQuery";
import Flickity from "react-flickity-component";
import PackagesSummary from "./packagesSummery/PackagesSummary";
import PaymentGateway from "./paymentGateway/PaymentGateway";
import AddPackageCard from "./addPackageCard/AddPackageCard";
import PackageCard from "./packageCard/PackageCard";
import styles from "./PackageAndFeatures.module.scss";

const CustomTab = ({ title, eventKey, sortState }) => {
  const [sort, setSort] = sortState;
  return (
    <div
      className={sort === eventKey ? styles.active : styles.inActive}
      onClick={() => setSort(eventKey)}>
      {title}
    </div>
  );
};

// static data
const flickityOptions = {
  cellAlign: "right",
  rightToLeft: true,
  pageDots: false,
  groupCells: true,
  dragThreshold: 10,
};

export default function PackageAndFeatures() {
  const { data: packages } = usePackagesQuery();

  const [sort, setSort] = useState("allPackages");

  // it's not necessary to have this func here can pass it to PackagesSummary CP
  const { activePackages, inActivePackages } = useMemo(() => {
    const activePackages = packages?.filter((item) => item.active === true);
    const inActivePackages = packages?.filter(
      (item) => item.active === false
    );

    return {
      activePackages,
      inActivePackages,
    };
  }, [packages]);

  const filterPackages = useCallback(() => {
    if (sort === "allPackages") return packages;
    if (sort === "activePackages")
      return packages?.filter((item) => item.active === true);
    if (sort === "inActivePackages")
      return packages?.filter((item) => item.active === false);
  }, [packages, sort]);

  return (
    <div>
      <div className={styles.header}>
        <PackagesSummary
          listOfPackages={{ packages, activePackages, inActivePackages }}
        />

        <PaymentGateway />
      </div>

      <div className={styles.body}>
        <div className={styles.addPackageWrapper}>
          <AddPackageCard />
        </div>

        <div className={styles.packages}>
          <div className={styles.filterNav}>
            <CustomTab
              title="همه بسته ها"
              eventKey="allPackages"
              sortState={[sort, setSort]}
            />
            <CustomTab
              title="بسته های فعال"
              eventKey="activePackages"
              sortState={[sort, setSort]}
            />
            <CustomTab
              title="بسته های غیر فعال"
              eventKey="inActivePackages"
              sortState={[sort, setSort]}
            />
          </div>

          <div className={styles.sliderWrapper}>
            {filterPackages()?.length ? (
              <Flickity
                className={`packagesCarousel ${styles.carousel}`}
                options={flickityOptions}>
                {filterPackages().map((item) => (
                  <PackageCard key={item.id} data={item} />
                ))}
              </Flickity>
            ) : (
              <div className={styles.emptySlider}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/packageAndFeatures/empty-package.svg`}
                  alt=""
                />
                <span> شما هیچ بسته ای ندارید</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
