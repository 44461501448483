import { useQuery } from "react-query";
import {
  getAllCountrie,
  getAllDistributors,
  getAllGenre,
  getAllLanguage,
} from "services/casts/castsServices";
import { getTranscodeServers } from "services/transcode/transcodeServices";

// can change cacheTime of datas

export const useGenresQuery = () => {
  return useQuery(["genres"], ({ signal }) => getAllGenre(signal), {
    select: (data) => {
      return data.data.data.results;
    },
  });
};
export const useLanguagesQuery = () => {
  return useQuery(["languages"], ({ signal }) => getAllLanguage(signal), {
    select: (data) => {
      return data.data.data.results;
    },
  });
};
export const useCountriesQuery = () => {
  return useQuery(["countries"], ({ signal }) => getAllCountrie(signal), {
    select: (data) => {
      return data.data.data.results;
    },
  });
};
export const useDistributorsQuery = () => {
  return useQuery(
    ["distributors"],
    ({ signal }) => getAllDistributors(signal),
    {
      select: (data) => {
        return data.data.data.results;
      },
    }
  );
};

// Transcode Servers
export const useTranscodeServersQuery = () => {
  return useQuery(
    ["transcodeServers"],
    ({ signal }) => getTranscodeServers(signal),
    {
      select: (data) => {
        return data.data.data.results;
      },
    }
  );
};
