export const transcodeManagerReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_TRANSCODE_QUEUE":
      return [...state, action.payload];

    case "UPDATE_TRANSCODE":
      return state.map((queue) => {
        if (queue.id === action.payload.id) {
          return {
            ...queue,
            resolutions: queue.resolutions.map((item) => {
              if (item.resolution === action.payload.resolution) {
                return { ...item, ...action.payload.transcodeResponse };
              }

              return item;
            }),
            downloadInfo: action.payload.transcodeResponse,
            event: action.payload.transcodeResponse.event,
          };
        }

        return queue;
      });

    case "REMOVE_TRANSCODE_FROM_QUEUE":
      return state.filter((item) => item.id !== action.payload);

    default:
      return state;
  }
};
