import styles from "./styles.module.scss";
export default function Status({ status }) {
  const handleStatus = () => {
    switch (status) {
      case "open":
        return (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/messages-blue.svg`}
              alt=""
            />
            <span className={`${styles.statusText} ${styles.blue}`}>
              تیکت باز
            </span>
          </>
        );
      case "answered":
        return (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/messages-green.svg`}
              alt=""
            />
            <span className={`${styles.statusText} ${styles.green}`}>
              پاسخ داده شده
            </span>
          </>
        );
      case "in-progress":
        return (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/sms-tracking-orange.svg`}
              alt=""
            />
            <span className={`${styles.statusText} ${styles.orange}`}>
              در حال بررسی
            </span>
          </>
        );
      case "closed":
        return (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/close-square-red.svg`}
              alt=""
            />
            <span className={`${styles.statusText} ${styles.red}`}>
              بسته شده
            </span>
          </>
        );
      default:
        return (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/messages-blue.svg`}
              alt=""
            />
            <span className={`${styles.statusText} ${styles.blue}`}>
              تیکت باز
            </span>
          </>
        );
    }
  };
  return <>{handleStatus()}</>;
}