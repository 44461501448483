import { nanoid } from "nanoid";
import { useDispatch } from "react-redux";
import {
  addTranscodeToQueue,
  removeTranscodeFromQueue,
  updateTranscode,
} from "../redux/actions/transcodeManager";
import { createTranscodeQueueBody } from "../util/uploadManager";

export const useTranscodeWebsocket = ({ transcodeType }) => {
  const dispatch = useDispatch();

  const convertArrayBufferToJSON = (arraybuffer) => {
    return JSON.parse(
      String.fromCharCode.apply(null, new Uint8Array(arraybuffer))
    );
  };

  const connectToTranscodeWebsocket = ({
    title,
    server,
    transcodeId,
    transcodeResolutions,
  }) => {
    const [protocol, domain] = server.split("//");
    const fakeId = nanoid();

    const socket = new WebSocket(
      `wss://${domain}/api/transcoder/progress?base=${transcodeId}`
    );
    socket.binaryType = "arraybuffer";

    const transcodeBody = createTranscodeQueueBody({
      id: fakeId,
      title,
      type: transcodeType,
      resolutions: transcodeResolutions,
      event: "download",
    });
    dispatch(addTranscodeToQueue(transcodeBody));

    socket.addEventListener("message", (event) => {
      const message = convertArrayBufferToJSON(event.data);

      dispatch(
        updateTranscode({
          id: fakeId,
          resolution: message.resolution,
          transcodeResponse: message,
          event: message.event,
        })
      );
    });

    socket.addEventListener("close", (event) => {
      dispatch(removeTranscodeFromQueue(fakeId));
    });

    socket.addEventListener("error", (event) => {
      console.log("Error: ", event);
    });
  };

  return { connectToTranscodeWebsocket };
};
