const profileState = {
  contactInfo: null,
  passwordInfo: {
    currentPass: "",
    newPass: "",
    rePass: "",
  },
};

export const profileReducer = (state = profileState, action) => {
  switch (action.type) {
    case "GET_PROFILE":
      return { ...state, contactInfo: action.payload };

    case "UPDATE_PROFILE_INFO":
      return {
        ...state,
        contactInfo: { ...state.contactInfo, ...action.payload },
      };

    case "UPDATE_PROFILE_PASSWORD_CHANGE":
      return {
        ...state,
        passwordInfo: { ...state.passwordInfo, ...action.payload },
      };

    case "RESET_PROFILE_PASSWORD_CHANGE":
      return {
        ...state,
        passwordInfo: {
          currentPass: "",
          newPass: "",
          rePass: "",
        },
      };

    case "RESET_PROFILE":
      return {
        contactInfo: null,
        passwordInfo: {
          currentPass: "",
          newPass: "",
          rePass: "",
        },
      };

    default:
      return state;
  }
};
