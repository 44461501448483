// import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadCrumb/Breadcrumb";
import AdvancedSearch from "./advanceSearch/AdvanceSearch";
import Wallet from "./dropDowns/wallet/Wallet";
import Notification from "./dropDowns/notification/Notification";
import Profile from "./dropDowns/profile/Profile";
import styles from "./TopBar.module.scss";

function TopBar() {
  const breadcrumbLinks = [
    { name: "پنل استریمر ایمن آریا" },
    // { name: "پروژه ها" },
    // { name: "ایجاد پروژه پخش زنده" },
  ];

  // const [width, setWidth] = useState("");
  // useEffect(() => {
  //   function handleResize() {
  //     setWidth(window.document.getElementById("content").clientWidth);
  //   }
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);
  // console.log(width);

  return (
    <header className={styles.main}>
      <div className={styles.headerList}>
        {/* <img
					src={`${process.env.PUBLIC_URL}/images/menu/menu-mobile.svg`}
					className={styles.mobileMenu}
					onClick={handelClick}
					alt=""
				/> */}

        <div className={styles.breadcrumbWrapper}>
          <Breadcrumb links={breadcrumbLinks} />
        </div>

        {window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE !== "sermovie" && (
          <div className={styles.blackButtonWrapper}>
            <Link to="/packageAndFeatures" className={`btn ${styles.crown}`}>
              <img
                src={`${process.env.PUBLIC_URL}/images/topBar/crown.svg`}
                alt=""
              />
              پکیج ها و امکانات ویژه
            </Link>
          </div>
        )}

        <div
          className={`${styles.searchWrapper} ${
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              ? styles.marginRightAuto
              : ""
          } `}>
          <AdvancedSearch />
        </div>

        <div className={styles.iconWrapper}>
          <div>
            <Wallet />
          </div>
          <div>
            <Notification />
          </div>
          <div>
            <Profile />
          </div>
        </div>
      </div>
    </header>
  );
}

export default TopBar;
