import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  deleteEpisodesDownloadLink,
  deleteOneSeries,
  getEpisodesDownloadLink,
  getListOfSeries,
  getOneSeries,
  postSeries,
  putUpdateOneSeries,
} from "services/series/series";
import toasts from "components/common/toasts/toasts";

export const useSeriesQuery = (page, limit) => {
  return useQuery(
    ["series", page],
    ({ signal }) => getListOfSeries(page, limit, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
export const useSerieQuery = (id) => {
  return useQuery(["serie", id], ({ signal }) => getOneSeries(id, signal), {
    enabled: !!id,
    select: (data) => {
      return data.data.data;
    },
  });
};
export const usePostSeriesQuery = (title) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(postSeries, {
    onSuccess: () => {
      queryClient.invalidateQueries("series");
      history.push("/series");
      toasts.success(`سریال ${title} با موفقیت ثبت شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای ساخت سریال پیش آمده");
    },
  });
};
export const usePutSeriesQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(putUpdateOneSeries, {
    onSuccess: () => {
      queryClient.invalidateQueries("series");
      history.push("/series");
      toasts.success("سریال با موفقیت آپدیت شد.");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی سریال پیش آمده");
    },
  });
};
export const useDeleteSeriesQuery = (type,page, title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteOneSeries, {
    onSuccess: () => {
      queryClient.invalidateQueries([`advanced-search-series`, page]);
      // queryClient.invalidateQueries("series");
      if (type === "single") {
        toasts.success(`سریال ${title} باموفقیت حذف شد.`);
      }
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
//Download Links
export const useEpisodesDownloadLinkQuery = (id) => {
  return useQuery(
    ["episodes-download-link", id],
    ({ signal }) => getEpisodesDownloadLink(id, signal),
    {
      enabled: !!id,
      select: (data) => {
        return data.data.data.results;
      },
    }
  );
};
export const useDeleteEpisodesDownloadLinkQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteEpisodesDownloadLink, {
    onSuccess: () => {
      queryClient.invalidateQueries("episodes-download-link");
      toasts.success(`لینک موردنظر باموفقیت حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
