// helper function for remove item
const removeFromArrayWithID = (array, id) =>
  array.filter((item) => item.id !== id);

const movieListState = {
  selectedMovies: [],
  isAllSelected: false,
};
export const moviesListReducer = (state = movieListState, action) => {
  switch (action.type) {
    case "ADD_SELECTED_MOVIES_LIST":
      return {
        ...state,
        selectedMovies: [...state.selectedMovies, action.payload],
      };
    case "REMOVE_SELECTED_MOVIES_LIST":
      return {
        ...state,
        selectedMovies: removeFromArrayWithID(
          state.selectedMovies,
          action.payload
        ),
        isAllSelected:
          removeFromArrayWithID(state.selectedMovies, action.payload) === [],
      };
    case "UPDATE_SELECT_ALL_MOVIES":
      return {
        ...state,
        selectedMovies: action.payload.allChecked
          ? action.payload.moviesList
          : [],
        isAllSelected: action.payload.allChecked,
      };
    case "RESET_MOVIES_LIST":
      return movieListState;
    default:
      return state;
  }
};

// Add Movie State by IMDB ID
const imdbMovieInitialState = {
  data: null,
  error: null,
  isLoading: false,
};
export const getMovieByImdbReducer = (
  state = imdbMovieInitialState,
  action
) => {
  switch (action.type) {
    case "FETCH_MOVIE_BY_IMDB_ID":
      return { ...state, isLoading: true };

    case "FETCH_MOVIE_BY_IMDB_ID_SUCCESS":
      return { ...state, data: action.payload.imdbMovie, isLoading: false };

    case "FETCH_MOVIE_BY_IMDB_ID_FAILED":
      return { ...state, error: action.payload.errorMsg, isLoading: false };

    case "RESET_MOVIE_BY_IMDB_ID":
      return imdbMovieInitialState;
    default:
      return state;
  }
};

// Create Movie reducer
const movieInitialState = {
  imdb_id: "",
  id: null,
  additional_id: "",
  cover: "",
  banner: "",
  title: "",
  title_in_persian: "",
  summary: "",
  stars: [],
  directors: [],
  writers: [],
  countries: [],
  genres: [],
  languages: [],
  subtitles: [],
  keywords: [],
  duration: "",
  release: "",
  rate_value: "",
  rate_total_votes: "",
  meta_rate_value: "",
  metacritic: "",
  rotten: "",
  budget: "",
  revenue: "",
  content_rating: "",
  awards: [],
  movie_url: "",
  category_id: null,
};
export const createMovieReducer = (state = movieInitialState, action) => {
  switch (action.type) {
    case "GET_MOVIE_INFO":
      return {
        imdb_id: action.payload.imdb_id,
        id: action.payload.id,
        additional_id: action.payload.additional_id,
        cover: action.payload.cover,
        banner: action.payload.banner,
        title: action.payload.title,
        title_in_persian: action.payload.title_in_persian,
        rate_value: action.payload.rate_value,
        rate_total_votes: action.payload.rate_total_votes,
        meta_rate_value: action.payload.meta_rate_value,
        summary: action.payload.summary,
        stars: action.payload.stars,
        writers: action.payload.writers,
        directors: action.payload.directors,
        countries: action.payload.countries,
        genres: action.payload.genres,
        languages: action.payload.languages,
        subtitles: action.payload.subtitles,
        keywords: action.payload.keywords,
        duration: action.payload.duration,
        release: action.payload.release,
        movie_url: action.payload.movie_url,
        category_id: action.payload.category_id,
        content_rating: {
          id: action.payload.content_rating,
          title: action.payload.content_rating,
        },
      };
    case "UPDATE_MOVIE_INFO":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_MOVIE_INFO":
      return movieInitialState;
    case "ADD_MOVIE_AWARD":
      return {
        ...state,
        awards: [...state.awards, action.payload],
      };
    case "REMOVE_MOVIE_AWARD":
      return {
        ...state,
        awards: state.awards.filter((a, index) => index !== action.payload),
      };
    case "REMOVE_MOVIE_KEYWORDS":
      return {
        ...state,
        keywords: removeFromArrayWithID(state.keywords, action.payload),
      };
    default:
      return state;
  }
};

// FILES
const movieFileUploadState = {
  sourceFile: [], // only get one file in array
  subtitlesFile: [],
  server: null,
  linkURL: "",
};
export const movieFileUploadReducer = (
  state = movieFileUploadState,
  action
) => {
  switch (action.type) {
    // MOVIE FILE
    case "SELECT_MOVIE_FILE":
      return { ...state, linkURL: "", sourceFile: action.payload };
    case "UN_SELECT_MOVIE_FILE":
      return { ...state, sourceFile: [] };
    case "RESET_MOVIE_FILE":
      return movieFileUploadState;

    // SERVER
    case "SELECT_MOVIE_SERVER":
      return { ...state, server: action.payload };

    // SUBTITLE
    case "ADD_SUBTITLE_TO_MOVIE":
      return {
        ...state,
        subtitlesFile: [...state.subtitlesFile, action.payload],
      };
    case "REMOVE_SUBTITLE_FROM_MOVIE":
      return {
        ...state,
        subtitlesFile: state.subtitlesFile.filter(
          (_, index) => index !== action.payload.index
        ),
      };
    case "RESET_MOVIE_SUBTITLE":
      return {
        ...state,
        subtitlesFile: [],
      };

    // LinkURL
    case "ADD_MOVIE_LINKURL":
      return { ...state, sourceFile: [], linkURL: action.payload };

    default:
      return state;
  }
};

// Download Files
const movieDownLoadLinkState = [
  {
    quality: "",
    size: "",
    encoder: "",
    extra_data: [],
    download_link: "",
    link_screenshot: "",
  },
];
export const movieDownloadLinkReducer = (
  state = movieDownLoadLinkState,
  action
) => {
  switch (action.type) {
    case "GET_MOVIE_DOWNLOADLINK":
      return action.payload.map((item) => ({
        id: item.id,
        movie_id: item.movie_id,
        size: item.size,
        extra_data: item.extra_data ? item.extra_data : [],
        download_link: item.download_link,
        link_screenshot: item.link_screenshot,
        quality: {
          id: item.quality,
          name: item.quality,
        },
        encoder: {
          id: item.encoder,
          name: item.encoder,
        },
      }));
    case "ADD_MOVIE_DOWNLOADLINK":
      return [
        ...state,
        {
          quality: "",
          size: "",
          encoder: "",
          extra_data: [],
          download_link: "",
          link_screenshot: "",
        },
      ];
    case "UPDATE_MOVIE_DOWNLOADLINK":
      return state.map((item, index) => {
        if (index === action.payload.index) {
          return {
            ...item,
            ...action.payload.data,
          };
        } else {
          return item;
        }
      });
    case "DELETE_MOVIE_DOWNLOADLINK_BY_INDEX":
      return state.filter((_, index) => index !== action.payload);
    case "RESET_MOVIE_DOWNLOADLINK":
      return movieDownLoadLinkState;
    default:
      return state;
  }
};
