import { Link } from "react-router-dom";
import Empty from "../empty/Empty";
import styles from "../OverView.module.scss";

export default function UsersList({ data }) {
  return (
    <div className={styles.container}>
      <h6>کاربران سایت</h6>

      {data?.length > 0 ? (
        <>
          <div className={`${styles.list} ${styles.usersList}`}>
            {data.map((item) => (
              <div key={item.id} className={styles.userListItem}>
                {item.avatar ? (
                  <img
                  loading="lazy"
                    src={`${process.env.PUBLIC_URL}${data.avatar}`}
                    alt={item.fullname}
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/users/user-default.svg`}
                    alt={item.fullname}
                  />
                )}
                <div>
                  <span>{item.fullname}</span>
                  <span>{item.email}</span>
                </div>
              </div>
            ))}
          </div>
          <Link to="/members" className={`${styles.seeMoreBtn} btn`}>
            مشاهده بیشتر
          </Link>
        </>
      ) : (
        <Empty
          icon="/images/overView/profile-circle.svg"
          title="تا کنون کاربری در سایت شما عضو نشده است."
        />
      )}
    </div>
  );
}
