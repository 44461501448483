export const removeFileFromQueue = (id) => ({
	type: "REMOVE_FILE_FROM_QUEUE",
	payload: id,
});

export const updateVideoProgress = (id, progress) => ({
	type: "UPDATE_FILE_UPLOAD_PROGRESS",
	payload: { id, progress },
});

export const addFileToQueue = (data) => ({
	type: "ADD_FILE_TO_QUEUE",
	payload: data,
});
