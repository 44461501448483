import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { numberFormater } from "util/number";
import {
  removeMoviesKeywords,
  resetMovieByImdb,
  resetMovieFile,
  resetMovieLinkDownload,
  resetMovieInformation,
  updateMovieInformation,
} from "../../../../../redux/actions/movies";
import { deleteMoviesKeywords } from "services/movies/moviesServices";
import { days, months, years } from "../../../../../util/dateNumber";
import { Input } from "../../../../common/input/Input";
import { useMovieQuery } from "hooks/query/useMovieQuery";
import {
  useCountriesQuery,
  useGenresQuery,
  useLanguagesQuery,
} from "hooks/query/useCastsQuery";
import { loadPaginateOptions, useArtistsQuery } from "hooks/query/useArtistQuery";
import { moviesContentRating } from "constants/contentRating";
import CategoryDropdown from "../../../../common/categoryDropdown/CategoryDropdown";
import DropDown from "../../../../common/dropDown/DropDown";
import TagInput from "components/common/tagInput/TagInput";
import TagMultiSelect from "../../../../common/tagMultiSelect/TagMultiSelect";
import TextArea from "../../../../common/textArea/TextArea";
import toasts from "components/common/toasts/toasts";
import styles from "./MovieInformation.module.scss";
import AsyncMultiSelect from "components/common/asyncMultiSelect/AsyncMultiSelect";
import { getArtistsBySearch } from "services/search/searchService";

export default function MovieInformation() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const createMovie = useSelector((state) => state.createMovieReducer);

  // GET Movie Info data by ID
  const { data } = useMovieQuery(id);
  useEffect(() => {
    if (data && id) {
      const payload = {
        ...data,
        content_rating: {
          id: data.content_rating,
          title: data.content_rating,
        },
      };
      dispatch(updateMovieInformation(payload));
    }
  }, [data, id, dispatch]);
  // GET Casts data
  const { data: genres } = useGenresQuery();
  const { data: languages } = useLanguagesQuery();
  const { data: countries } = useCountriesQuery();
  const { data: artists } = useArtistsQuery();

  // Reset
  useEffect(() => {
    return () => {
      dispatch(resetMovieByImdb());
      dispatch(resetMovieInformation());
      dispatch(resetMovieFile());
      dispatch(resetMovieLinkDownload());
    };
  }, [dispatch]);

  // releaseDate
  const [releaseDate, setReleaseDate] = useState({
    year: null,
    month: null,
    day: null,
  });
  useEffect(() => {
    if (releaseDate.year && releaseDate.month && releaseDate.day) {
      const { year, month, day } = releaseDate;

      dispatch(
        updateMovieInformation({
          release: `${year.id}-${month.id}-${day.id}`,
        })
      );
    }
  }, [dispatch, releaseDate]);
  useEffect(() => {
    if (createMovie.release) {
      const [year, month, day] = createMovie.release.split("-");

      setReleaseDate({
        ...releaseDate,
        year: { id: year, title: year },
        month: { id: month, title: month },
        day: { id: day, title: day },
      });
    }
  }, [createMovie.release]);

  // dropdown options
  const directors = artists?.results.filter(
    (i) => i.occupation.includes("director") // || i.occupation.includes('others')
  );
  const writers = artists?.results.filter(
    (i) => i.occupation.includes("writer") // || i.occupation.includes('others')
  );
  const stars = artists?.results.filter(
    (i) => i.occupation.includes("actor") // || i.occupation.includes('others')
  );

  // movie information states
  const handleChangeInput = (e) => {
    dispatch(
      updateMovieInformation({
        [e.target.name]: e.target.value,
      })
    );
  };
  const handleChangeReleaseDate = (option, action) => {
    setReleaseDate({ ...releaseDate, [action.name]: option });
  };
  const handleChangeMultiSelect = (options, action) => {
    dispatch(
      updateMovieInformation({
        [action.name]: options,
      })
    );
  };
  const changeMovieDescription = (e) => {
    dispatch(
      updateMovieInformation({
        summary: e.target.value,
      })
    );
  };
  const handleChangeCategory = (categoryId) => {
    dispatch(updateMovieInformation({ category_id: categoryId }));
  };
  const handleChangeContentRating = (rating) => {
    dispatch(updateMovieInformation({ content_rating: rating }));
  };
  // Deleting
  const handleDeleteKeywords = async (keywordID) => {
    try {
      const { status } = await deleteMoviesKeywords({
        id: +id,
        keywords: [keywordID],
      });
      if (status === 200) {
        toasts.success("keyword مورد نظر حذف شد.");
        dispatch(removeMoviesKeywords(keywordID));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className={styles.section}>
      {/* <div className={styles.wrapper}>
        <Input
          label="آی دی TMDB و یا IMDB"
          placeholder="به طور مثال: 181652"
          name="movieId"
          value={createMovie.imdb_id}
          errors={errors}
          errorsText={errors && "آی دی وارد شده معتبر نیست!"}
          onChange={handleChangeInput}
        />
        <div className={styles.getInformationBtn}>دریافت اطلاعات</div>
      </div> */}

      <div className={styles.wrapper}>
        <Input
          placeholder="عنوان فیلم را بصورت لاتین و بدون سال اکران وارد نمایید، به طور مثال: Till Death"
          label="عنوان فیلم"
          name="title"
          value={createMovie.title}
          onChange={handleChangeInput}
          require
        />
        <Input
          placeholder="عنوان فارسی فیلم را در صورت نیاز و بدون سال اکران وارد نمایید"
          label="عنوان فیلم (فارسی)"
          name="title_in_persian"
          value={createMovie.title_in_persian}
          onChange={handleChangeInput}
        />
      </div>

      <div className={styles.wrapper}>
        <Input
          label="امتیاز IMDB"
          placeholder="امتیاز IMDB فیلم را از 1 تا 10 وارد نمایید"
          name="rate_value"
          value={createMovie.rate_value}
          onChange={handleChangeInput}
          type="number"
        />
        <Input
          label="تعداد رای دهندگان IMDB"
          placeholder="تعداد رای دهندگان IMDB فیلم را وارد نمایید"
          name="rate_total_votes"
          value={
            createMovie.rate_total_votes &&
            numberFormater(createMovie.rate_total_votes)
          }
          onChange={handleChangeInput}
        />
      </div>

      <div className={styles.wrapper}>
        <Input
          label="امتیاز Meta"
          placeholder="امتیاز Meta فیلم را از 1 تا 100 وارد نمایید"
          name="meta_rate_value"
          value={createMovie.meta_rate_value}
          onChange={handleChangeInput}
          type="number"
        />
        <Input
          label="Additional_id"
          placeholder="Additional_id"
          name="additional_id"
          value={createMovie.additional_id}
          readOnly
        />
      </div>

      <div className={styles.movieYearWrapper}>
        <label>
          تاریخ اکران (میلادی)
          {/* <span>*</span> */}
        </label>
        <div className={styles.dropdownWrapper}>
          <div>
            <DropDown
              placeholder="روز"
              name="day"
              options={days}
              value={releaseDate.day}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={handleChangeReleaseDate}
              // isRequired
            />
          </div>
          <div>
            <DropDown
              placeholder="ماه"
              name="month"
              options={months}
              value={releaseDate.month}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={handleChangeReleaseDate}
            />
          </div>
          <div>
            <DropDown
              placeholder="سال"
              name="year"
              options={years}
              value={releaseDate.year}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={handleChangeReleaseDate}
              // isRequired
            />
          </div>
        </div>
      </div>

      <div className={styles.groupWrapper}>
        <TextArea
          label="خلاصه فیلم"
          placeholder="خلاصه فارسی و یا انگلیسی فیلم را وارد نمایید"
          value={createMovie.summary}
          onChange={changeMovieDescription}
          isRequired
        />

        <CategoryDropdown
          label={"دسته بندی"}
          value={createMovie}
          updateCb={handleChangeCategory}
        />
      </div>

      <div className={styles.groupWrapper}>
        <AsyncMultiSelect
          label="ستارگان"
          placeholder="هنرمندان، بازیگران و یا صداپیشگان فیلم را وارد و یا انتخاب نمایید"
          selectedOptions={createMovie.stars}
          optionFlags={{ title: "name", value: "id" }}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeMultiSelect}
          fetcher={getArtistsBySearch}
          name="stars"
        />
        <AsyncMultiSelect
          label="کارگردانان"
          placeholder="کارگردان (های) فیلم را وارد و یا انتخاب نمایید"
          // options={directors}
          selectedOptions={createMovie.directors}
          optionFlags={{ title: "name", value: "id" }}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeMultiSelect}
          name="directors"
          fetcher={getArtistsBySearch}
        />
        <AsyncMultiSelect
          label="نویسندگان"
          placeholder="نویسنده (گان) فیلم را وارد و یا انتخاب نمایید"
          // options={writers}
          selectedOptions={createMovie.writers}
          optionFlags={{ title: "name", value: "id" }}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeMultiSelect}
          fetcher={getArtistsBySearch}
          name="writers"
        />
        <TagMultiSelect
          label="کشورها"
          placeholder="کشور (های) تولید کننده فیلم را وارد و یا انتخاب نمایید"
          type="second"
          options={countries}
          selectedOptions={createMovie.countries}
          optionFlags={{ title: "name", value: "name" }}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          // options={movieState.data?.production_countries}
          // selectedOptions={createMovie.production_countries}
          // optionFlags={{ title: "name", value: "unique_id" }}
          // getOptionLabel={(option) => option["name"]}
          // getOptionValue={(option) => option["unique_id"]}
          onChange={handleChangeMultiSelect}
          name="countries"
        />
        <TagMultiSelect
          label="ژانرها"
          placeholder="ژانرهای فیلم را وارد و یا انتخاب نمایید"
          type="second"
          options={genres}
          selectedOptions={createMovie.genres}
          optionFlags={{ title: "name", value: "name" }}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          // options={movieState.data?.genres}
          // selectedOptions={createMovie.genres}
          // optionFlags={{ title: "name", value: "unique_id" }}
          // getOptionLabel={(option) => option["name"]}
          // getOptionValue={(option) => option["unique_id"]}
          onChange={handleChangeMultiSelect}
          name="genres"
        />
        <TagMultiSelect
          label="زبان ها"
          placeholder="زبان (های) مربوط به فیلم را وارد و یا انتخاب نمایید"
          type="second"
          options={languages}
          selectedOptions={createMovie.languages}
          optionFlags={{ title: "name", value: "name" }}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          // options={movieState.data?.languages}
          // selectedOptions={createMovie.languages}
          // optionFlags={{ title: "english_name", value: "unique_id" }}
          // getOptionLabel={(option) => option["english_name"]}
          // getOptionValue={(option) => option["unique_id"]}
          onChange={handleChangeMultiSelect}
          name="languages"
        />
        <DropDown
          label="رده سنی"
          placeholder="رده سنی فیلم را وارد نمایید"
          name="content_rating"
          options={moviesContentRating}
          value={createMovie.content_rating}
          getOptionLabel={(option) => option["title"]}
          getOptionValue={(option) => option["id"]}
          onChange={handleChangeContentRating}
        />
      </div>

      <div className={styles.groupWrapper}>
        <Input
          label="مدت زمان فیلم (دقیقه)"
          placeholder="مدت زمان فیلم را بر حسب دقیقه وارد نمایید. بطور مثال: 94"
          name="duration"
          value={createMovie.duration}
          onChange={handleChangeInput}
        />
      </div>

      <div className={styles.groupWrapper}>
        <TagInput
          propState={[
            createMovie.keywords,
            (e) => dispatch(updateMovieInformation({ keywords: e })),
          ]}
          handleDelete={handleDeleteKeywords}
          label="Keywords"
        />
      </div>
    </section>
  );
}
