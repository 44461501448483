import { useState } from "react";
import { usePostGenerateBoxOfficeQuery } from "hooks/query/useBoxOfficeQuery";
import { Modal, Spinner } from "react-bootstrap";
import { CloseButton } from "../../packageAndFeatures/components/Components";
import Card from "../card/Card";
import styles from "./ConfirmModal.module.scss";

function ConfirmModal({ onHide, latestBoxOfficeMovies }) {
  const { mutate: postGenerateBoxOffice } = usePostGenerateBoxOfficeQuery();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitBoxOffice = () => {
    try {
      setIsLoading(true);
      postGenerateBoxOffice({
        items: latestBoxOfficeMovies,
      });

      onHide();
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <CloseButton position="top" onClick={onHide} />
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>تایید بروز رسانی</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.messageWrapper}>
          <p>
            در بروز رسانی جدید تمام آیتم های قبلی حذف گردیده و ترتیب فیلم ها به
            این شکل نمایداده خواهد شد، در صورت تایید لیست جدید بر روی گرینه
            تایید کلیک کنیدو در غیر این صورت بر روی گزینه انصراف کلیک کنید.
          </p>

          <div className={styles.buttonWrapper}>
            <button
              className={`btn ${styles.reject}`}
              disabled={isLoading}
              onClick={onHide}>
              <img
                src={`${process.env.PUBLIC_URL}/images/commentManagement/close-square.svg`}
                alt=""
              />
              <span>انصراف</span>
            </button>

            <button
              className={`btn ${styles.success}`}
              disabled={isLoading}
              onClick={onSubmitBoxOffice}>
              {!isLoading && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/common/add-white-square.svg`}
                  alt=""
                />
              )}
              <span>تایید ویرایش</span>
              {isLoading && (
                <Spinner animation="border" as="i" color="#ffffff" />
              )}
            </button>
          </div>
        </div>

        <div className={styles.movieWrapper}>
          <div className={styles.movies}>
            {latestBoxOfficeMovies?.map((item) => (
              <Card key={item.imdb_id} data={item} isModal />
            ))}
          </div>
        </div>
      </Modal.Body>
    </div>
  );
}

export default ConfirmModal;
