import { useState } from "react";
import { Modal } from "react-bootstrap";
import AddModal from "./addModal/AddModal";
import Card from "./card/Card";
import styles from "./VideoPlay.module.scss";

export default function VideoPlay() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={styles.wrapper}>
        {/* {mockData.map((item) => (
          <Card key={item.id} data={item} />
        ))} */}
        <Card />
        <Card />
        <Card />
        <Card />
        <div onClick={() => setShowModal(true)} className={styles.addCard}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/video-add.svg`}
            alt=""
          />
          <span>افزودن پخش جدید</span>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={styles.modal}
        centered>
        <AddModal onHide={() => setShowModal(false)} />
      </Modal>
    </>
  );
}
