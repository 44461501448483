import { useState } from "react";
import { useAdvancedSearchQuery } from "hooks/query/useSearchQuery";
import MovieList from "./movieList/MovieList";
import Paginate from "components/common/paginate/Paginate";
export default function Movies() {
  const [currentPage, setCurrentPage] = useState(1);
  // Search
  const [movieName, setMovieName] = useState("");
  const { data, isPreviousData, refetch } = useAdvancedSearchQuery(
    currentPage,
    10,
    movieName,
    "movie"
  );
  return (
    <>
      <section>
        <MovieList
          data={data?.results}
          isPreviousData={isPreviousData}
          refetch={refetch}
          movieName={movieName}
          setMovieName={setMovieName}
          currentPage={currentPage}
        />

        <div className="paginationWrapper">
          {data?.results?.length > 0 && (
            <Paginate
              type="movies"
              name={movieName}
              paginateState={[currentPage, setCurrentPage]}
              total={data?.pagination.count}
            />
          )}
        </div>
      </section>
    </>
  );
}
