import { Link } from "react-router-dom";
import Status from "pages/tickets/status";
import styles from "./styles.module.scss";
export default function Row() {
  // const [showModal, setShowModal] = useState(false);

  return (
    <>
      <tr className={styles.row}>
        <td>2184911212</td>
        <td>پشتیبانی فروش و کاربران</td>
        <td>hello</td>
        <td>
          <Status status={"answered"} />
        </td>
        <td>1399/12/06</td>
        {/* <td>{ToJalaliFormat(data.created_at)}</td> */}
        <td>
          {true && (
            <Link to="/tickets/1">
              <button
                className={`${styles.button} ${styles.blueBtn} btn`}
                // onClick={() => setShowModal(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/message-square.svg`}
                  alt=""
                />
                <span>پاسخ</span>
              </button>
            </Link>
          )}
          {false && (
            <button className={`${styles.button} btn`}>
              <img
                src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/rotate-right.svg`}
                alt=""
              />
            </button>
          )}
          <button
            className={`${styles.button} btn`}
            // onClick={() => setShowModal(true)}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/trash.svg`}
              alt=""
            />
          </button>
        </td>
      </tr>
      {/* <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName={styles.modal}
        centered>
        <SupportModal data={data} onHide={() => setShowModal(false)} />
      </Modal> */}
    </>
  );
}
