import http from "../httpService";

export const getAlbum = (albumId, signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/get?id=${albumId}`,
    {
      signal,
    }
  );
};

export const getAlbums = (page, limit, signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/get-all?page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    { signal }
  );
};

export const postAlbum = (albumPayload) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/new`,
    albumPayload
  );
};

export const putAlbum = (albumPayload) => {
  return http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/update`,
    albumPayload
  );
};

export const deleteAlbum = (albumId) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/delete?id=${albumId}`
  );
};
// append services
export const patchAppendSinger = (singerPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/singers/append`,
    singerPayload
  );
};

export const patchAppendTrack = (trackPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/tracks/append`,
    trackPayload
  );
};

export const patchAppendArtist = (artistPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/artists/append`,
    artistPayload
  );
};

export const patchAppendArrangements = (arrangementPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/arrangements/append`,
    arrangementPayload
  );
};

export const patchAppendSongwriter = (songwriterPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/songwriters/append`,
    songwriterPayload
  );
};

// delete appended services
export const patchDeleteSinger = (singerPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/singers/delete`,
    singerPayload
  );
};

export const patchDeleteTrack = (trackPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/tracks/delete`,
    trackPayload
  );
};

export const patchDeleteArtist = (artistPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/artists/delete`,
    artistPayload
  );
};

export const patchDeleteArrangements = (arrangementPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/arrangements/delete`,
    arrangementPayload
  );
};

export const patchDeleteSongwriter = (songwriterPayload) => {
  return http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/music/albums/songwriters/delete`,
    songwriterPayload
  );
};
