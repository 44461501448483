import { Link } from "react-router-dom";
import styles from "./TabHeader.module.scss";

export default function TabHeader() {
  return (
    <div className={styles.container}>
      <div className={`btn ${styles.btn} ${styles.SeeProject}`}>
        <img
          src={`${process.env.PUBLIC_URL}/images/common/folder.svg`}
          alt=""
        />
        مشاهده پروژه
      </div>
      <Link to="/project" className={`btn ${styles.btn} ${styles.removeDeal}`}>
        انصراف
      </Link>
      <div className={`btn ${styles.btn} ${styles.addDeal}`}>
        <img src={`${process.env.PUBLIC_URL}/images/common/save.svg`} alt="" />
        ثبت تغییرات
      </div>
    </div>
  );
}
