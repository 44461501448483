import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  deleteLiveStream,
  getAllLiveStreams,
  getLiveConfigs,
  getLiveStream,
  postLiveStream,
  updateLiveStream,
} from "services/stream/stream";
import toasts from "components/common/toasts/toasts";

export const useStreamsQuery = (page) => {
  return useQuery(
    ["streams", page],
    ({ signal }) => getAllLiveStreams(page, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
export const useLiveConfigsQuery = () => {
  return useQuery(["live-configs"], ({ signal }) => getLiveConfigs(signal), {
    select: (data) => {
      return data.data.data?.resolution_configs;
    },
  });
};
export const useStreamQuery = (id) => {
  return useQuery(["stream", id], ({ signal }) => getLiveStream(id, signal), {
    enabled: !!id,
    select: (data) => {
      return data.data.data;
    },
  });
};

export const usePostStreamQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(postLiveStream, {
    onSuccess: () => {
      queryClient.invalidateQueries("streams");
      history.push("/live-stream");
      toasts.success("پخش زنده با موفقیت ایجاد شد");
    },
    onError: () => {
      toasts.error("مشکلی برای ایجاد پخش زنده پیش آمده");
    },
  });
};
export const usePutStreamQuery = (mood) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(updateLiveStream, {
    onSuccess: () => {
      queryClient.invalidateQueries("streams");
      if (mood === "update") {
        history.push("/live-stream");
        toasts.success("پخش زنده با موفقیت آپدیت شد");
      }
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی پخش زنده پیش آمده");
    },
  });
};
export const useDeleteStreamQuery = (type, title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteLiveStream, {
    onSuccess: () => {
      queryClient.invalidateQueries("streams");
      if (type === "single") {
        toasts.success(`پخش زنده ${title} باموفقیت حذف شد.`);
      }
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
