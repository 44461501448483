import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDeleteSubtitleQuery } from "hooks/query/useFileQuery";
import { updateEpisode } from "../.../../../../../../../redux/actions/series";
import SubtitleFileUploader from "../../../../../common/subtitleFileUploader/SubtitleFileUploader";

export default function SubtitlePart({ data }) {
  const { mutate: deleteSubtitle } = useDeleteSubtitleQuery("series");

  // local state
  const [subtitleFile, setSubtitleFile] = useState([]);
  useEffect(() => {
    if (data) {
      setSubtitleFile(data?.data.subtitles ? data?.data.subtitles : []);
    } else {
      setSubtitleFile([]);
    }
  }, [data]);
  const handleDelete = (file) => {
    if (file.id) {
      deleteSubtitle(file.id);
      const array = subtitleFile.filter((item) => item.title !== file.title);
      setSubtitleFile(array);
    } else {
      const array = subtitleFile.filter((item) => item.name !== file.title);
      setSubtitleFile(array);
    }
  };

  // redux state
  const dispatch = useDispatch();
  const handleChangeFile = () => {
    dispatch(
      updateEpisode({
        subtitles: subtitleFile,
        seasonId: data?.seasonId,
        episodeId: data?.data.imdb_id,
      })
    );
  };
  useEffect(() => {
    handleChangeFile();
  }, [subtitleFile]);

  return (
    <>
      <SubtitleFileUploader
        dropzonePlaceholder="فایل زیرنویس این قسمت را بکشید و رها کنید."
        fileState={[subtitleFile, (file) => setSubtitleFile(file)]}
        handleDelete={handleDelete}
        data={data}
      />
    </>
  );
}
