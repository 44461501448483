import { useState } from "react";
import { useGenresQuery } from "hooks/query/useCastsQuery";
import { usePutBoxOfficeMovieQuery } from "hooks/query/useBoxOfficeQuery";
import { Modal } from "react-bootstrap";
import { Input } from "../../common/input/Input";
import { postFile } from "../../../services/files/files";
import { CloseButton } from "../../packageAndFeatures/components/Components";
import MovieCoverDropzone from "../movieCoverDropzone/MovieCoverDropzone";
import TextArea from "../../common/textArea/TextArea";
import MultiTag from "../../common/multiTag/MultiTag";
import toasts from "../../common/toasts/toasts";
import styles from "./CardEditModal.module.scss";

//! IMPORTANT
//TODO: check dropzone after backend fixed for sync api

function CardEditModal({ onHide, data }) {
  const { mutate: putBoxOfficeMovie } = usePutBoxOfficeMovieQuery();
  const { data: listOfGenres } = useGenresQuery();

  const [inputs, setInputs] = useState({
    cover: data.cover,
    title: data.title,
    gross: data.gross,
    weekend: data.weekend,
    weeks: data.weeks,
    summary: data.summary,
    directors: data.directors?.map((item) => item.name).join(" ,"),
    genres: data.genres,
  });

  const onChangeInput = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  const onChangeMulti = (options, meta) => {
    setInputs({ ...inputs, [meta.name]: options });
  };
  const onChangeCover = (file) => {
    setInputs({ ...inputs, cover: file });
  };

  const onSubmit = async () => {
    try {
      const { directors, genres, weeks, ...rest } = inputs;

      const payload = {
        ...rest,
        id: data.id,
        weeks: parseInt(weeks),
        genres: genres.map((item) => ({ id: item.id })),
      };

      if (inputs.cover.hasOwnProperty("path")) {
        const imageId = await handlePostFile(inputs.cover);
        payload["cover"] = imageId;
      }

      putBoxOfficeMovie(payload);
      onHide();
    } catch (err) {
      console.error(err);
    }
  };
  const handlePostFile = async (file) => {
    try {
      toasts.warn("در حال آپلود کاور.");
      const { data } = await postFile(file);

      toasts.success("کاور باموفقیت آپدیت شد.");
      return data.data;
    } catch (err) {
      toasts.error("مشکلی برای آپدیت کاور پیش آمده.");
    }
  };

  return (
    <div className={styles.wrapper}>
      <CloseButton position="top" onClick={onHide} />

      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>ویرایش اطلاعات</Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <div className={styles.imageUploader}>
          <MovieCoverDropzone
            initialImage={inputs?.cover}
            setCover={onChangeCover}
          />
        </div>
        <div className={styles.fromField}>
          <Input
            label="نام  فیلم"
            placeholder="نام  فیلم"
            value={inputs.title}
            name="title"
            onChange={onChangeInput}
          />
          <Input
            label="کل فروش"
            placeholder="کل فروش"
            value={inputs.gross}
            name="gross"
            onChange={onChangeInput}
          />
          <Input
            label="فروش اخرین هفته"
            placeholder="فروش اخرین هفته"
            value={inputs.weekend}
            name="weekend"
            onChange={onChangeInput}
          />
          <Input
            label="هفته"
            placeholder="هفته"
            value={inputs.weeks}
            name="weeks"
            onChange={onChangeInput}
          />
          <Input
            label="کارگردان"
            placeholder="کارگردان"
            name="directors"
            value={inputs.directors}
            onChange={onChangeInput}
            readOnly
          />
          <MultiTag
            label="ژانر"
            placeholder="ژانر"
            options={listOfGenres}
            selectedOptions={inputs.genres}
            getOptionLabel={(option) => option["name"]}
            getOptionValue={(option) => option["id"]}
            onChange={onChangeMulti}
            name="genres"
          />

          <TextArea
            label="خلاصه"
            placeholder="خلاصه"
            value={inputs.summary}
            onChange={onChangeInput}
            name="summary"
          />
        </div>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <button className={`btn ${styles.reject}`} onClick={onHide}>
          <img
            src={`${process.env.PUBLIC_URL}/images/commentManagement/close-square.svg`}
            alt=""
          />
          <span>انصراف</span>
        </button>
        <button className={`btn ${styles.success}`} onClick={onSubmit}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/add-white-square.svg`}
            alt=""
          />
          <span>تایید ویرایش</span>
        </button>
      </Modal.Footer>
    </div>
  );
}

export default CardEditModal;
