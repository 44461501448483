import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { convertMilliSecondToMinutes } from "../../../util/millisecondConvertor";
import CustomInput from "../../common/customInput/CustomInput";
import {
  loginConfirmCode,
  loginUserWithPassword,
  loginUserWithVerificationCode,
} from "./../../../services/entry/entryService";
import { getNumberOnly } from "./../../../util/number";
// import { useDispatch } from "react-redux";
// import { setUser } from "./../../../redux/actions/user";
import toasts from "../../common/toasts/toasts";
import styles from "../RegisterAndLogin.module.scss";
import { handleCookie } from "util/auth";

//? SHOW and Use this component when user want to login

function EnterPlatform({ isOtp, setIsOtp, loginObj }) {
  const history = useHistory();
  // const dispatch = useDispatch();
  const BASE_MILLISECOND = 120;

  const [timeCounter, setTimeCounter] = useState(BASE_MILLISECOND);
  const [input, setInput] = useState("");
  const [classValidationInput, setClassValidationInput] = useState("");

  const handleOnKeyPress = (e) => {
    if (e.charCode === 13 || e.which === 13) {
      submit();
    }
    if (isOtp) {
      getNumberOnly(e);
    }
  };

  useEffect(() => {
    setInput("");
    setClassValidationInput("");
  }, [isOtp]);

  useEffect(() => {
    if (isOtp) {
      const myTimeOut = setTimeout(() => {
        setTimeCounter((prev) => prev - 1);
      }, 1000);

      if (timeCounter <= 0) {
        setTimeCounter(0);
        clearTimeout(myTimeOut);
      }

      return () => {
        clearTimeout(myTimeOut);
      };
    }
  }, [timeCounter, isOtp]);

  const handleOTP = () => {
    if (timeCounter === 0) {
      // re-send the request
      setTimeCounter(BASE_MILLISECOND);
      sendVerificationCode();
    } else {
      return;
    }
  };

  const handleChangeEnterMethod = () => {
    setTimeCounter(BASE_MILLISECOND);
    if (isOtp) {
      setIsOtp(false);
    } else {
      //  re-send the request
      sendVerificationCode();
      setIsOtp(true);
    }
  };
  const isValidInput = () => {
    if (input !== "") {
      // setClassValidationInput("");
      return true;
    }
    setClassValidationInput("danger");
    if (isOtp) {
      toasts.error("کد را وارد کنید");
    } else {
      toasts.error("رمزعبور را وارد کنید");
    }
    return false;
  };

  const sendVerificationCode = async () => {
    const temp = {
      email: loginObj.email,
      mobile: loginObj.mobile,
      sms_provider: "magfa",
    };
    try {
      const { status } = await loginUserWithVerificationCode(temp);
      if (status === 200) {
        toasts.success("کد تایید به شمراه همراه شما ارسال شد");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 429) {
          toasts.error("کد تایید برای شما کمی پیش ارسال شده است ");
        }
        if (error.response.status !== 429) {
          toasts.error("خطایی پیش آمده است");
        }
      }
    }
  };

  const submit = () => {
    const isvalid = isValidInput();
    if (isvalid) {
      if (isOtp) {
        loginWithCode();
      } else {
        loginWithPassword();
      }
    }
  };
  const loginWithCode = async () => {
    const userCode = {
      code: input,
      mobile: loginObj.mobile,
      email: loginObj.email,
      sms_provider: "magfa",
    };
    await loginConfirmCode(userCode)
      .then((res) => {
        if (res.status === 200) {
          setClassValidationInput("verify");
          handleCookie(res);
          // dispatch(setUser());
          history.push("/overView");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toasts.error("کد وارد شده معتبر نمی باشد");
            setClassValidationInput("danger");
          }

          if (error.response.status !== 400) {
            toasts.error("خطایی پیش آمده است");
            setClassValidationInput("danger");
          }
        }
      });
  };
  const loginWithPassword = async () => {
    const userCode = {
      password: input,
      email: loginObj.email,
      mobile: loginObj.mobile,
    };
    await loginUserWithPassword(userCode)
      .then((res) => {
        if (res.status === 200) {
          setClassValidationInput("verify");
          handleCookie(res);
          // toasts.success("success");
          // dispatch(setUser());
          history.push("/overView");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            toasts.error("یوزر مورد نظر یافت نشد");
            setClassValidationInput("danger");
          }

          if (error.response.status === 400) {
            toasts.error("کلمه عبور اشتباه است");
            setClassValidationInput("danger");
          }
          if (
            error.response.status !== 400 &&
            error.response.status !== 404
          ) {
            toasts.error("خطایی پیش آمد");
            setClassValidationInput("danger");
          }
        }
      });
  };

  return (
    <Fragment>
      <CustomInput
        type={isOtp ? "text" : "password"}
        label={isOtp ? "کد تأیید" : "رمز عبور"}
        handleChange={setInput}
        value={input}
        classValidation={classValidationInput}
        onKeyPress={handleOnKeyPress}
      />

      <div onClick={submit} className={`btn ${styles.btn}`}>
        ورود به پلتفرم
      </div>

      <div className={styles.greyBtnWrapper}>
        {isOtp && (
          <Fragment>
            <div className={styles.greyBtn} onClick={handleChangeEnterMethod}>
              ورود با رمز عبور
            </div>
            <div className={styles.greyBtn} onClick={handleOTP}>
              ارسال مجدد {timeCounter === 0 ? "" : ":"}
              <span className={styles.primary}>
                {timeCounter === 0
                  ? ""
                  : convertMilliSecondToMinutes(timeCounter)}
              </span>
            </div>
          </Fragment>
        )}

        {!isOtp && (
          <Fragment>
            <div className={styles.greyBtn} onClick={handleChangeEnterMethod}>
              ورود با کد تایید
            </div>
            <Link to="/resetPassword" className={styles.greyBtn}>
              فراموشی رمز عبور
            </Link>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default EnterPlatform;
