import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Wallet.module.scss";

export default function Wallet() {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="header">
      کیف پول
    </Tooltip>
  );

  return (
    <Link to="/wallet">
      <Dropdown>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}>
          <Dropdown.Toggle
            variant="link"
            id="dropdown-basic"
            className={styles.Btn}>
            <img
              src={`${process.env.PUBLIC_URL}/images/topBar/wallet.svg`}
              alt=""
              // className={styles.notificationImg}
            />
          </Dropdown.Toggle>
        </OverlayTrigger>
      </Dropdown>
    </Link>
  );
}
