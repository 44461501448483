import http from "../httpService";

// get
export const getListOfSeries = (page, limit, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/get-all?page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    { signal }
  );
export const getOneSeries = (id, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/get?id=${id}`,
    {
      signal,
    }
  );

export const getEpisodesFromImdb = (id) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/imdb/get?id=${id}`
  );

// get movie from IMDB
export const getSerieByImdb = (id) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/imdb/get?id=${id}`
  );

// post
export const postSeries = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/new`, data);

// put
export const putUpdateOneSeries = (data) =>
  http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/update`,
    data
  );

export const putUpdateSeason = (data) =>
  http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/update`,
    data
  );

export const putUpdateEpisodeOfSeason = (data) =>
  http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/update`,
    data
  );

// delete
export const deleteOneSeries = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/delete?id=${id}`
  );

// patch append data
export const patchAppendSeasonToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/append`,
    data
  );

export const patchAppendEpisodeToSeasonOfSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/append`,
    data
  );

export const patchAppendSubtitles = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/subtitles/append`,
    data
  );

export const patchAppendGenresToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/genres/append`,
    data
  );

export const patchAppendLanguageToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/languages/append`,
    data
  );

export const patchAppendCountriesToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/countries/append`,
    data
  );

export const patchAppendStarsToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/stars/append`,
    data
  );

export const patchAppendDirectorsToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/directors/append`,
    data
  );

export const patchAppendWritersToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/writers/append`,
    data
  );

export const patchAppendDirectorsToEpisode = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/directors/append`,
    data
  );

export const patchAppendWritersToEpisode = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/writers/append`,
    data
  );

export const patchAppendStarsToEpisode = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/stars/append`,
    data
  );

export const patchAppendSeriesKeywords = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/keywords/append`,
    data
  );

// patch DELETE for appended data

export const patchDeleteSeasonToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/delete`,
    data
  );

export const patchDeleteEpisodeToSeasonOfSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episode/delete`,
    data
  );

export const patchDeleteGenresToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/genres/delete`,
    data
  );

export const patchDeleteLanguageToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/languages/delete`,
    data
  );

export const patchDeleteCountriesToSeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/countries/delete`,
    data
  );

export const patchDeleteDirectorsToEpisode = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/directors/delete`,
    data
  );

export const patchDeleteWritersToEpisode = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/writers/delete`,
    data
  );

export const patchDeleteStarsToEpisode = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/episodes/stars/delete`,
    data
  );

export const deleteSeasonFromSeeries = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/seasons/delete`,
    data
  );

export const deleteSeriesKeywords = (data) =>
  http.patch(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/vod/series/keywords/delete`,
    data
  );

// download links
export const postEpisodesDownloadLink = (data) =>
  http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/episodes/links/new`,
    data
  );
export const getEpisodesDownloadLink = (id) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/episodes/links/list-by-series?series_id=${id}`
  );
export const deleteEpisodesDownloadLink = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/episodes/links/delete?id=${id}`
  );
export const updateEpisodesDownloadFile = (data) =>
  http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/episodes/links/update`,
    data
  );
export const deleteEpisodesDownloadLinkExtraData = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/episodes/links/extras/delete?id=${id}`
  );
