import http from "../httpService";
export const getLiveEvents = (signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/live-events/list`, {
    signal,
  });
export const deleteLiveEvent = (id) =>
  http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/live-events/delete?id=${id}`
  );
export const postLiveEvent = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/live-events/new`, data);
// export const putLiveEvent = (data) =>
//   http.put(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/live-events/update`, data);
