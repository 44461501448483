import { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import AddPerson from "../../../common/modals/addPerson/AddPerson";
import TableRow from "../../tableRow/TableRow";
import styles from "./PersonalPresent.module.scss";

const memberList = [
  {
    name: "امید آشنایی",
    email: "omidashnayi@gmail.com",
    access: "مدیر پروژه",
  },
  {
    name: "امید آشنایی",
    email: "omidashnayi@gmail.com",
    access: "مدیر پروژه",
  },
  {
    name: "امید آشنایی",
    email: "omidashnayi@gmail.com",
    access: "مدیر محتوا و مالتی مدیا",
  },
  {
    name: "امید آشنایی",
    email: "omidashnayi@gmail.com",
    access: "مدیر محتوا و مالتی مدیا",
  },
  {
    name: "امید آشنایی",
    email: "omidashnayi@gmail.com",
    access: "مدیر محتوا",
  },
];

export default function PersonalPresent() {
  const [showAddPersonModal, setShowAddPersonModal] = useState(false);

  return (
    <>
      <Table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            <th className={styles.primary}>افراد حاضر در پروژه</th>
            <th>ایمیل</th>
            <th>سطح دسترسی</th>
            <th></th>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {memberList.map((item, index) => (
            <TableRow
              key={index}
              data={item}
              //   handleDeleteMember={handleDeleteMember}
            />
          ))}
        </tbody>
      </Table>

      <div
        className={styles.addPerson}
        onClick={() => setShowAddPersonModal(true)}>
        <img
          src={`${process.env.PUBLIC_URL}/images/common/add-user-icon.svg`}
          alt=""
        />
        افزودن افراد به پروژه
      </div>
      <Modal
        show={showAddPersonModal}
        onHide={() => setShowAddPersonModal(false)}
        dialogClassName={styles.addPersonModal}>
        <AddPerson />
      </Modal>
    </>
  );
}
