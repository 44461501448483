import { handleGenerateStringWithComma } from "util/generator";
import styles from "./Card.module.scss";

export default function Card({ data }) {
  return (
    <div className={styles.card}>
      <div className={styles.col}>
        {data.cover?.length ? (
          <img
            loading="lazy"
            src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
            alt=""
          />
        ) : (
          <img
            loading="lazy"
            src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
            alt=""
          />
        )}
      </div>
      <div className={styles.col}>
        <h2>{data?.title}</h2>
        <div className={styles.sellInfoWrapper}>
          <div>
            ژانر:{" "}
            {data?.genres?.length
              ? handleGenerateStringWithComma(data?.genres, "name")
              : "-"}
          </div>
          <div>
            سال ساخت:{" "}
            {data?.release?.slice(0, 4) || data?.start_date?.slice(0, 4)}
          </div>
          <div>
            زبان:{" "}
            {data?.languages?.length
              ? handleGenerateStringWithComma(data?.languages, "name")
              : "-"}
          </div>
          <div>
            کارگردان:{" "}
            {data?.directors?.length
              ? handleGenerateStringWithComma(data?.directors, "name")
              : "-"}
          </div>
          <div>
            بازیگران:{" "}
            {data?.stars?.length
              ? handleGenerateStringWithComma(data?.stars, "name")
              : "-"}
          </div>
        </div>
      </div>
    </div>
  );
}
