import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addSelectedSeries,
  removeSelectedSeries,
} from "../../../../redux/actions/series";
import { Modal } from "react-bootstrap";
import { useDeleteSeriesQuery } from "hooks/query/useSerieQuery";
import DeleteMultiModal from "../../../common/modals/deleteMultiModal/DeleteMultiModal";
import TableCheckBox from "../../../common/TableCheckBox/TableCheckBox";
import styles from "../SeriesList.module.scss";

export default function SeriesRow({ data, currentPage, index }) {
  const dispatch = useDispatch();
  const { mutate: deleteOneSeries } = useDeleteSeriesQuery(
    "single",
    currentPage,
    data.title
  );
  const { selectedSeries, isAllSelected } = useSelector(
    (state) => state.seriesListReducer
  );
  const [checked, setChecked] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (!isAllSelected && selectedSeries.length) {
      setChecked(checked);
    } else {
      setChecked(isAllSelected);
    }
  }, [isAllSelected]);

  const handleSelectSeries = (checked) => {
    if (checked) {
      dispatch(addSelectedSeries(data));
    } else {
      dispatch(removeSelectedSeries(data.id));
    }
    setChecked(checked);
  };

  const handleDeleteSeries = () => {
    deleteOneSeries(data.id);
  };

  const handleStatus = () => {
    switch (data?.status) {
      case "active":
        return "در حال پخش";
      case "canceled":
        return "متوقف شده";
      case "finished":
        return "به پایان رسیده";
      default:
        return "-";
    }
  };

  return (
    <>
      <tr className={checked ? styles.active : styles.rowCell}>
        <td
          onClick={() => handleSelectSeries(!checked)}
          className={checked ? styles.typeCell : ""}>
          {selectedSeries.length > 0 && (
            <TableCheckBox
              checkBoxState={[checked, setChecked]}
              place="tableRow"
            />
          )}
          {data.cover?.length ? (
            <img
              loading="lazy"
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
              alt={data.title}
              className={`${styles.moviePoster}`}
            />
          ) : (
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
              alt={data.title}
              className={`${styles.moviePoster} ${
                checked && styles.moviePosterActive
              }`}
            />
          )}
          {data.title.length > 30
            ? "..." + data.title.slice(0, 20)
            : data.title}
        </td>
        <td onClick={() => handleSelectSeries(!checked)}>{index + 1}</td>
        <td onClick={() => handleSelectSeries(!checked)}>
          {data.release ? data.release : "-"}
        </td>
        <td onClick={() => handleSelectSeries(!checked)}>
          {data.seasons?.length} فصل (
          {data.seasons?.flatMap((s) => s.episodes).length} قسمت)
        </td>
        <td onClick={() => handleSelectSeries(!checked)}>{handleStatus()}</td>
        {/* <td onClick={() => handleSelectSeries(!checked)}>-</td> */}
        {data.status ? (
          <td
            onClick={() => handleSelectSeries(!checked)}
            className={styles.green}>
            منتشر شده
          </td>
        ) : (
          <td
            onClick={() => handleSelectSeries(!checked)}
            className={styles.orange}>
            منتشر نشده
          </td>
        )}
        <td>
          <Link
            to={`/series/addSeries/${data.id}`}
            className={`${styles.seeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/movies/see.svg`}
              alt="see"
            />
            مشاهده و ویرایش
          </Link>
          <button
            onClick={() => setShowDeleteModal(true)}
            className={`${styles.removeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt="see"
            />
            حذف سریال
          </button>
        </td>
      </tr>
      {showDeleteModal && (
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          dialogClassName={styles.deleteMovieModal}>
          <DeleteMultiModal
            titleData={{
              deleteObject: data.title,
              isMoreThanOne: false,
              placeName: "سریال",
            }}
            onHide={() => setShowDeleteModal(false)}
            handleDelete={handleDeleteSeries}
          />
        </Modal>
      )}
    </>
  );
}
