import { useState } from "react";
import { Modal } from "react-bootstrap";
import EmptyState from "../common/emptyState/EmptyState";
import Card from "./components/Card/Card";
import AddProjectModal from "./components/AddProjectModal/AddProjectModal";
import styles from "./Project.module.scss";

// test Data
const workList = [
  {
    id: 0,
    title: "استریمر",
    description: "4500 محتوای مالتی مدیا وارد شده",
    tags: ["فیلم", "سریال"],
  },
  {
    id: 1,
    title: "طرفداری",
    description: "3500 محتوای مالتی مدیا وارد شده",
    tags: ["ویدئو کلیپ"],
  },
  {
    id: 2,
    title: "طرفداری",
    description: "3500 محتوای مالتی مدیا وارد شده",
    tags: [, "فیلم", "سریال", "ویدئو کلیپ", "پخش زنده"],
  },
  {
    id: 3,
    title: "طرفداری",
    description: "3500 محتوای مالتی مدیا وارد شده",
    tags: ["ویدئو کلیپ", "فیلم", "سریال"],
  },
  {
    id: 4,
    title: "طرفداری",
    description: "3500 محتوای مالتی مدیا وارد شده",
    tags: ["ویدئو کلیپ", "پخش زنده", "سریال"],
  },
];

//TODO: can use AddProject modal for empty state

function Project() {
  const [showAddProjectModal, setshowAddProjectModal] = useState(false);

  const emptyState = true;

  return (
    <>
      {emptyState ? (
        <section className={emptyState ? styles.section : ""}>
          <div className={styles.cards}>
            <div
              className={styles.newProjectCard}
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/productAndServices/t.jpg)`,
              }}>
              <button
                onClick={() => setshowAddProjectModal(true)}
                className={`${styles.btn} ${styles.newProjectCardBtn}`}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/productAndServices/plus.svg`}
                  alt=""
                />
                ایجاد کارلیست جدید
              </button>
            </div>

            {workList.map((item) => (
              <Card key={item.id} item={item} />
            ))}
          </div>
        </section>
      ) : (
        <EmptyState
          // subHeader={true}
          buttonOptions={{
            link: "/project/newProject",
            text: "ایجاد پروژه جدید",
            icon: "/images/productAndServices/newProject.svg",
          }}>
          <p className={styles.emptyStateText}>
            برای افزودن محتواهای مالتی مدیا مانند فیلم، سریال و ویدئو کلیپ و یا
            پخش زنده، ابتداباید یک <span>پروژه</span> ایجاد کرده و دسته بندی
            (های) آنرا مطابق با محتوای خود انتخاب نمایید
          </p>
        </EmptyState>
      )}
      {showAddProjectModal && (
        <Modal
          show={showAddProjectModal}
          onHide={() => setshowAddProjectModal(false)}
          dialogClassName={styles.addProjectModal}>
          <AddProjectModal onHide={() => setshowAddProjectModal(false)} />{" "}
        </Modal>
      )}
    </>
  );
}

export default Project;
