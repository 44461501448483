export const smsProvider = [
  {
    id: "magfa",
    title: "magfa",
  },
  {
    id: "ippanel",
    title: "ippanel",
  },
];
