import { useEffect } from "react";
import { resetCollectionInformation } from "redux/actions/collections";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import TabHeader from "./TabHeader/TabHeader";
import CollectionIormation from "./Tabs/collectionIormation/CollectionIormation";
import UploadFiles from "./Tabs/UploadFiles/UploadFiles";
import Settings from "./Tabs/Settings/Settings";
import styles from "./AddCollection.module.scss";

export default function AddCollection() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(resetCollectionInformation());
  }, [dispatch]);

  return (
    <>
      <TabHeader />
      <div className={`filter ${styles.filterBody}`}>
        <Tabs defaultActiveKey="collectionInformation" className={styles.tab}>
          <Tab
            eventKey="collectionInformation"
            title="اطلاعات کالکشن"
            className={styles.tabItem}>
            <CollectionIormation />
          </Tab>
          <Tab
            eventKey="uploadFiles"
            title="بارگذاری تصاویر"
            className={styles.tabItem}>
            <UploadFiles />
          </Tab>
          <Tab
            eventKey="settings"
            title="تنظیمات پخش و دانلود"
            className={styles.tabItem}>
            <Settings />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
