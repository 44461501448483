import { useQuery } from "react-query";
import { getMembers } from "services/members/membersServices";

export const useMembersQuery = (page) => {
  return useQuery(["members", page], ({ signal }) => getMembers(page, signal), {
    keepPreviousData: true,
    select: (data) => {
      return data.data.data;
    },
  });
};
