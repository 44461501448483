import http from "../httpService";
export const getExperts = (page, signal) => {
  return http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/experts/list?page=${
      page ? page : ""
    }&limit=10`,
    { signal }
  );
};
export const postExpert = (data) => {
  return http.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/experts/new`,
    data
  );
};
export const deleteExpert = (expertId) => {
  return http.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/experts/delete?id=${expertId}`
  );
};
export const putExpert = (data) => {
  return http.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/experts/update`,
    data
  );
};
