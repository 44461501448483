import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  deleteTrack,
  getTrack,
  getTracks,
  postTrack,
  putTrack,
} from "services/musics/tracks";
import {
  deleteAlbum,
  getAlbum,
  getAlbums,
  patchDeleteTrack,
  postAlbum,
  putAlbum,
} from "services/musics/albums";
import { getKinds } from "services/musics/kinds";
import { getGenresByKind } from "services/musics/genres";
import toasts from "components/common/toasts/toasts";

// Tracks
export const useTracksQuery = (page, limit) => {
  return useQuery(
    ["tracks", page],
    ({ signal }) => getTracks(page, limit, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
export const useTrackQuery = (id) => {
  return useQuery(["track", id], ({ signal }) => getTrack(id, signal), {
    enabled: !!id,
    select: (data) => {
      return data.data.data;
    },
  });
};
export const usePostTrackQuery = (title) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(postTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries("tracks");
      history.push("/musics");
      // toasts.success(`تک آهنگ ${title} با موفقیت ثبت شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای ساخت تک آهنگ پیش آمده");
    },
  });
};
export const usePutTrackQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(putTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries("tracks");
      history.push("/musics");
      // toasts.success("تک آهنگ با موفقیت آپدیت شد.");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی تک آهنگ پیش آمده");
    },
  });
};
export const useDeleteTrackQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries("tracks");
      toasts.success(`تک آهنگ ${title} باموفقیت حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};

// Albums
export const useAlbumsQuery = (page, limit) => {
  return useQuery(
    ["albums", page],
    ({ signal }) => getAlbums(page, limit, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
export const useAlbumQuery = (id) => {
  return useQuery(["album", id], ({ signal }) => getAlbum(id, signal), {
    enabled: !!id,
    select: (data) => {
      return data.data.data;
    },
  });
};
export const usePostAlbumQuery = (title) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(postAlbum, {
    onSuccess: () => {
      queryClient.invalidateQueries("albums", "tracks");
      history.push("/musics");
      // toasts.success(`آلبوم ${title} با موفقیت ثبت شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای ساخت آلبوم پیش آمده");
    },
  });
};
export const usePutAlbumQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(putAlbum, {
    onSuccess: () => {
      queryClient.invalidateQueries("albums");
      history.push("/musics");
      // toasts.success("آلبوم با موفقیت آپدیت شد.");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی آلبوم پیش آمده");
    },
  });
};
export const useDeleteAlbumQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteAlbum, {
    onSuccess: () => {
      queryClient.invalidateQueries("albums");
      toasts.success(`آلبوم ${title} باموفقیت حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
export const usePatchDeleteTrackQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(patchDeleteTrack, {
    onSuccess: () => {
      queryClient.invalidateQueries("album");
      toasts.success(`موزیک باموفقیت حذف شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف موزیک پیش آمده");
    },
  });
};

// Music Genre
export const useMusicGenresQuery = (id) => {
  return useQuery(["music-genres", id], () => getGenresByKind(id), {
    enabled: !!id,
    select: (data) => {
      return data.data.data;
    },
  });
};
// Music Kind
export const useMusicKindsQuery = () => {
  return useQuery(["music-kinds"], getKinds, {
    select: (data) => {
      return data.data.data;
    },
  });
};
