import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchAlbums } from "../../../../redux/actions/musics";
import AudioPlayer from "../../audioPlayer/AudioPlayer";

import styles from "./SearchTrackDropdown.module.scss";

//TODO: check for cache data

// sub components for dropdown
const SearchInput = ({ value, onChange, ...props }) => {
  return (
    <div className={styles.inputWrapper}>
      {/* NOTE: Give functionalities to search input and if needed make it separated component */}
      <input
        type="text"
        placeholder="آهنگ مورد نظر را جستو جو کنید"
        onChange={onChange}
        value={value}
        autoFocus
        {...props}
      />
      <img src={`${process.env.PUBLIC_URL}/images/icons/search.svg`} alt="" />
    </div>
  );
};

const Option = ({ item, onSelectOption }) => {
  return (
    <div className={styles.optionWrapper}>
      <AudioPlayer
        trackLabel={item.name}
        trackUrl={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${item.url}`}
      />
      <button
        className={styles.selectOptionBtn}
        onClick={() => onSelectOption(item)}>
        انتخاب موسیقی
      </button>
    </div>
  );
};

// main component
function SearchTrackDropdown({ value, onChange, placeholder }) {
  const dispatch = useDispatch();
  const { singleAlbums, singleAlbumTracks } = useSelector((state) => {
    return {
      singleAlbums: state.musicsReducer.tracks,
      singleAlbumTracks: state.musicsReducer.tracks.flatMap(
        (item) => item.tracks
      ),
    };
  });

  useEffect(() => {
    if (!singleAlbums.length) {
      dispatch(fetchAlbums());
    }
  }, [singleAlbums, dispatch]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSelectOption = (option) => {
    onChange(option);
    setSearchValue("");
    onCloseMenu();
  };

  const onOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const onCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredList = useMemo(() => {
    return singleAlbumTracks.filter(
      (item) =>
        item.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      // search for persian name
      // 	||
      // item.name_in_persian
      // 	.toLocaleLowerCase()
      // 	.includes(searchValue.toLocaleLowerCase())
    );
  }, [searchValue, singleAlbumTracks]);

  return (
    <div className={styles.wrapper}>
      <Dropdown bsPrefix={styles.dropdown} show={isMenuOpen}>
        <Dropdown.Toggle
          bsPrefix={styles.toggle}
          childBsPrefix={styles.button}
          onClick={isMenuOpen ? onCloseMenu : onOpenMenu}>
          <div
            className={`${styles.valueContainer} ${
              value ? styles.withValue : styles.withOutValue
            }`}>
            {value ? value.name : placeholder}
          </div>
          <div className={styles.indicatorContainer}>
            <FaChevronDown color="#909090" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix={styles.menu}>
          {/* SEARCH INPUT */}
          <div className={styles.searchContainer}>
            <SearchInput value={searchValue} onChange={onChangeSearchValue} />
          </div>

          {/* MENU LIST */}
          <div className={styles.optionContainer}>
            {singleAlbumTracks.length && filteredList.length ? (
              filteredList.map((item) => (
                <Option
                  key={item.id}
                  item={item}
                  onSelectOption={handleSelectOption}
                />
              ))
            ) : (
              <div className={styles.notFoundedSection}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/musics/music-square-remove.svg`}
                  alt=""
                />
                <span>
                  {singleAlbumTracks.length
                    ? " هیچ نتیجه ای یافت نشد لطفا دوباره سعی کنید"
                    : "تک آهنگ ثبت شده‌ای یافت نشد"}
                </span>
              </div>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>

      {/* PREVIEW RESULT */}
      {value && (
        <div className={styles.playerWrapper}>
          <AudioPlayer
            trackUrl={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${value.url}`}
            trackLabel={value.name}
          />
        </div>
      )}
    </div>
  );
}

export default SearchTrackDropdown;
