export const gender = [
  { title: "مرد", value: "male" },
  { title: "زن", value: "female" },
  // { title: 'سایر', value: 'other' },
];
export const occupation = [
  { fa: "بازیگر", en: "actor" },
  { fa: "صدا پیشه", en: "voice_actor" },
  { fa: "خواننده", en: "singer" },
  { fa: "کارگردان", en: "director" },
  { fa: "تهیه کننده", en: "producer" },
  { fa: "تهیه کننده موسیقی", en: "music_producer" },
  { fa: "نویسنده", en: "writer" },
  { fa: "شاعر", en: "songwriter" },
  { fa: "نوازنده", en: "performer" },
  { fa: "رهبر ارکستر", en: "conductor" },
  { fa: "آهنگساز", en: "composer" },
  { fa: "حرفه های دیگر", en: "others" },
];
