import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { usePlaylistQuery } from "hooks/query/usePlaylistQuery";
import { Spinner } from "react-bootstrap";
import Card from "./card/Card";
import styles from "./PlaylistInfo.module.scss";

export default function PlaylistInfo() {
  const { id } = useParams();
  const { data, isLoading } = usePlaylistQuery(id);
  const [allMovies, setAllMovies] = useState([]);

  useEffect(() => {
    if (data) {
      setAllMovies([...data.movies, ...data.series]);
    }
  }, [data]);

  return (
    <section className={styles.container}>
      <div className={styles.info}>
        <div className={styles.box}>
          <div className={styles.postersWrapper}>
            <div className={styles.posters}>
              {data?.cover?.length ? (
                <img
                  loading="lazy"
                  src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
                  alt=""
                />
              ) : (
                <img
                  loading="lazy"
                  src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
                  alt=""
                />
              )}
              {allMovies?.slice(0, 2).map(({ cover }, i) => (
                <img
                  loading="lazy"
                  alt=""
                  src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${cover}`}
                  key={i}
                />
              ))}
            </div>
          </div>
          <div className={styles.title}>
            <span>{data?.title_in_persian || data?.title}</span>
            {/* <span>1400/08/15</span> */}
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/eye.svg`}
              alt=""
            />
          </div>
          <div className={styles.detail}>
            <span>{data?.movies?.length + data?.series?.length}</span>
            <span>فایل در این لیست وجود دارد</span>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/eye.svg`}
              alt=""
            />
          </div>
          <div className={styles.detail}>
            <span>0</span>
            <span>بار مشاهده شده</span>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/heart.svg`}
              alt=""
            />
          </div>
          <div className={styles.detail}>
            <span>{data?.likes}</span>
            <span>نفر پسندیده اند</span>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.icon}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/save2.svg`}
              alt=""
            />
          </div>
          <div className={styles.detail}>
            <span>0</span>
            <span>نفر ذخیره کرده اند</span>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className={styles.loading}>
          <Spinner animation="border" as="i" />
        </div>
      ) : (
        <div className={styles.cardsWrapper}>
          {allMovies?.map((item) => (
            <Card key={item.id} data={item} />
          ))}
        </div>
      )}
    </section>
  );
}
