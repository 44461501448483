import { useState } from "react";
import { Modal } from "react-bootstrap";
import MovieFileUploader from "../MovieFileUploader";
import styles from "./MovieFileUploaderCard.module.scss";

export default function MovieFileUploaderCard({
  height,
  btnPlace,
  text,
  maxFiles,
  type,
  fileState,
}) {
  const [showMovieFileUploader, setShowMovieFileUploader] = useState(false);

  return (
    <>
      <div
        onClick={() => setShowMovieFileUploader(true)}
        style={{ height: `${height}px` }}
        className={styles.zone}>
        {btnPlace === "top" && (
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/upload-plus.svg`}
            className={styles.plus}
            alt=""
          />
        )}
        <p>
          {btnPlace === "right" && (
            <img
              className={styles.plusBlue}
              src={`${process.env.PUBLIC_URL}/images/common/plus-blue.svg`}
              alt=""
            />
          )}
          {text}
        </p>
      </div>
      <Modal
        show={showMovieFileUploader}
        onHide={() => setShowMovieFileUploader(false)}
        dialogClassName={styles.modal}>
        <MovieFileUploader
          type={type}
          maxFiles={maxFiles}
          onHide={() => setShowMovieFileUploader(false)}
          fileState={fileState}
        />
      </Modal>
    </>
  );
}
