import { Tab, Tabs } from "react-bootstrap";
import TabHeader from './TabHeader/TabHeader';
import MovieInformation from './Tabs/MovieInformation/MovieInformation';
// import Awards from './Tabs/Awards/Awards';
import UploadFiles from './Tabs/UploadFiles/UploadFiles';
// import Settings from './Tabs/Settings/Settings';
import styles from "./AddMovies.module.scss";

export default function AddMovies() {
  return (
    <>
      <TabHeader />
      <div className={`filter ${styles.filterBody}`}>
        <Tabs defaultActiveKey="movieInformation" className={styles.tab}>
          <Tab
            eventKey="movieInformation"
            title="اطلاعات فیلم"
            className={styles.tabItem}>
            <MovieInformation />
          </Tab>
          {/* <Tab
            eventKey="awards"
            title="امتیازات و جوایز"
            className={styles.tabItem}>
            <Awards />
          </Tab> */}
          <Tab
            eventKey="uploadFiles"
            title="بارگذاری فایل ها"
            className={styles.tabItem}>
            <UploadFiles />
          </Tab>
          {/* <Tab
            eventKey="settings"
            title="تنظیمات پخش و دانلود"
            className={styles.tabItem}>
            <Settings />
          </Tab> */}
        </Tabs>
      </div>
    </>
  );
}
