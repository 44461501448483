import { Input } from "components/common/input/Input";
import styles from "./styles.module.scss";
//TODO: handle attachment file
export default function Send() {
  return (
    <div className={styles.wrapper}>
      <Input placeholder="متن پیام" />
      <button className="btn">
        <img
          src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/attachment.svg`}
          alt=""
        />
      </button>
      <button className="btn">
        <img
          src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/message-square.svg`}
          alt=""
        />
        <span>پاسخ</span>
      </button>
    </div>
  );
}
