import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAudioDropzone } from "../../../hooks/dropzone";
import AlbumModal from "../albumModal/AlbumModal";
import AudioPlayer from "../audioPlayer/AudioPlayer";

import styles from "./AudioPreview.module.scss";

function AudioPreview({
  data,
  audioChangeCb,
  audioDeleteCb,
  isAlbum,
  isReadonly,
}) {
  const [show, setShow] = useState(false);
  // need to check useAudioDropzone with backend data
  const { getRootProps, getInputProps, audioFiles } = useAudioDropzone(data);

  useEffect(() => {
    if (!isAlbum && audioChangeCb) {
      audioChangeCb(audioFiles); // on change the content of audio dispatch an update to store to have latest audio file
    }
  }, [audioChangeCb, audioFiles, isAlbum]);

  const onShow = () => {
    setShow(true);
  };

  const onHide = () => {
    setShow(false);
  };

  const rootPropsConfig = {
    className: styles.iconButton,
  };

  /*
		Condition to have track urls:
			singleTrack: 1. only audio 2.has audio body from backend
			album: 1. has id that shows stored in backend 2. hasn't id thats is stored in redux
 */
  const audioInfo = useMemo(() => {
    // config.imageApi means get any file from db
    // if (isAlbum) {
    if (data.hasOwnProperty("url")) {
      return {
        trackUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.url}`,
        trackName: data.name,
      };
    } else if (data.hasOwnProperty("audio")) {
      return {
        trackUrl: URL.createObjectURL(data.audio),
        trackName: data.name,
      };
    } else {
      return {
        trackUrl: URL.createObjectURL(data),
        trackName: data.name,
      };
    }
    // }
  }, [data]);

  return (
    <>
      <div className={styles.audioRow}>
        <AudioPlayer
          trackLabel={audioInfo.trackName}
          trackUrl={audioInfo.trackUrl}
        />

        {!isReadonly && (
          <div className={styles.buttonWrapper}>
            {/* NOTE: if its Album we ganna show modal for editing otherwise use btn to edit the file  */}
            {isAlbum ? (
              <button className={styles.iconButton} onClick={onShow}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/articles/edit.svg`}
                  alt=""
                />
              </button>
            ) : (
              <button {...getRootProps(rootPropsConfig)}>
                <input {...getInputProps()} />
                <img
                  src={`${process.env.PUBLIC_URL}/images/articles/edit.svg`}
                  alt=""
                />
              </button>
            )}
            <button
              className={styles.iconButton}
              // think on audioDeleteCb here to be separated func or not
              onClick={() => audioDeleteCb(data)}>
              <img
                src={`${process.env.PUBLIC_URL}/images/articles/trash.svg`}
                alt=""
              />
            </button>
          </div>
        )}
      </div>

      <Modal
        show={show}
        onHide={onHide}
        dialogClassName={styles.modal}
        centered>
        <AlbumModal onHide={onHide} data={data} />
      </Modal>
    </>
  );
}

export default AudioPreview;
