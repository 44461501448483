import styles from "./AdaptiveSection.module.scss";
export default function AdaptiveSection() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.wrapperContent}>
        <figure className="image-cover">
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/adaptive.svg`}
            alt="Adaptive Section"
          />
        </figure>
        <div className={styles.info}>
          <h1 className="text__black">کیفیت پخش، متناسب با سرعت اینترنت شما</h1>
          <h5 className="text__black">Adaptive Bitrate Streaming</h5>
          <p className="text__black">
            تبدیل خودکار محتوای ویدئویی به چندین کیفیت بصورت دستی و یا خودکار با
            در نظر گرفتن سرعت و پهنای باند کاربر در هر لحظه
          </p>
        </div>
      </div>
    </section>
  );
}
