import styles from "./styles.module.scss";
export default function Stepper({ step, stepsList }) {
  return (
    <div className={styles.wrapper}>
      {stepsList.map((item, index) => (
        <div className={styles.step}>
          <span
            className={`${styles.numberBox} ${
              step >= index + 1 ? styles.numberBox__active : ""
            } ${step > index + 1 ? styles.line : ""}`}>
            {index + 1}
          </span>
          {step === index + 1 && (
            <span className={styles.text}>{item.text}</span>
          )}
        </div>
      ))}
    </div>
  );
}