import { Fragment, useState } from "react";
import { Input } from "../../common/input/Input";
// import { IsValidEmail } from "../../../util/validEmail";
import { IsValidMobile } from "../../../util/validMobile";
import { resetPasswordWithMobile } from "services/entry/entryService";
import toasts from "components/common/toasts/toasts";
import styles from "../ResetPassword.module.scss";

// NOTE: The related funcs for sending request for emails are in passwordRecovery

function PhoneNumberSection({ showNextSection, recoveryObj }) {
  // const [isMethodOTP, setIsMethodOTP] = useState(true);
  const [text, setText] = useState("");
  const [type, setType] = useState("regex:^(0)?9\\d{9}$");
  const [classValidationInput, setClassValidationInput] = useState("");

  // useEffect(() => setText(""), [isMethodOTP]);
  // const history = useHistory();

  const isValidInput = () => {
    if (type !== "email") {
      const isvalidPhone = IsValidMobile(text);
      isvalidPhone
        ? setClassValidationInput("verify")
        : setClassValidationInput("danger");
      if (!isvalidPhone) {
        toasts.error("شماره همراه وارد شده صحیح نمی باشد");
      }
      return isvalidPhone;
    }
  };
  const checkType = () => {
    let data = text;
    if (!isNaN(parseInt(data))) setType("regex:^(0)?9\\d{9}$");
  };
  const handleChange = (e) => {
    setText(e.target.value);
    checkType();
  };
  const submit = () => {
    let isValid = isValidInput();
    if (isValid) {
      if (type !== "email") {
        sendCodeToUser();
      }
      // else {
      //   resetPasswordLink();
      // }
    }
  };

  const sendCodeToUser = async () => {
    let temp = { mobile: text };
    try {
      const { status } = await resetPasswordWithMobile(temp);

      if (status === 200) {
        toasts.success("کد به شماره همراه شما ارسال شد");
        recoveryObj(temp);
        showNextSection();
      } else {
        toasts.error("مشکلی پیش آمده");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status == 429) {
          toasts.error(
            "کد تایید قبلا برای شما ارسال شده است. لطفا پس از 2دقیقه مجددا امتحان کنید"
          );
        }
        if (error.response.status == 404 || error.response.status == 409) {
          toasts.error("یوزر مورد نظر وجود ندارد و ثبت نام کنید ");
        }
        if (error.response.status != 404) {
          toasts.error("خطایی پیش آمده است");
        }
      }
    }
  };

  // const resetPasswordLink = async () => {
  //   if (text.length && IsValidEmail(text)) {
  //     try {
  //       const { status } = await postForgetPassword({ email: text });

  //       if (status === 200) {
  //         toast.success("لطفا ایمیل خود را چک کنید.");
  //         // setClassValidation('verify');
  //       } else {
  //         toast.error("مشکلی پیش آمده");
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   } else {
  //     // setClassValidation('danger');

  //     if (text.length && !IsValidEmail(text))
  //       toast.error("ایمیل معتبر نمی باشد");
  //     if (!text.length) toast.error("فیلد مورد نظر را پر نمایید");
  //   }
  // };

  return (
    <Fragment>
      <Input
        placeholder={"جهت بازیابی رمز عبور، شماره همراه خود را وارد نمایید"}
        value={text}
        onChange={handleChange}
        classValidation={classValidationInput}
      />

      <div className={styles.btnWrapper}>
        <div className={`btn ${styles.btn}`} onClick={submit}>
          {/* {type !== "email" ? "دریافت کد تأیید" : "دریافت لینک فعالسازی"} */}
          دریافت کد تأیید
        </div>
      </div>
    </Fragment>
  );
}

export default PhoneNumberSection;
