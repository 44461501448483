import React, { useState } from "react";
import { strengthIndicator, strengthColor } from "./strenghPassword";
import styles from "./PasswordInput.module.scss";

export default function PasswordInput({
  label,
  value,
  classValidation,
  onChange,
  ...props
}) {
  const [click, setClick] = useState(false);

  const strength = strengthIndicator(value);
  const color = strengthColor(strength);
  const renderPart = () => {
    if (strength < 2) {
      return (
        <>
          {" "}
          <span style={{ background: color }}></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>{" "}
        </>
      );
    }
    if (strength < 3) {
      return (
        <>
          {" "}
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>
          <span></span>
          <span></span>
          <span></span>{" "}
        </>
      );
    }
    if (strength < 4) {
      return (
        <>
          {" "}
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>
          <span></span>
          <span></span>{" "}
        </>
      );
    }
    if (strength < 5) {
      return (
        <>
          {" "}
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>
          <span></span>{" "}
        </>
      );
    }
    if (strength < 6) {
      return (
        <>
          {" "}
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>
          <span style={{ background: color }}></span>{" "}
        </>
      );
    }
  };

  return (
    <div
      className={
        click
          ? `form-group ${styles.formGroup} ${styles.labelAnimate}`
          : `form-group ${styles.formGroup}`
      }>
      <input
        type="password"
        value={value}
        className={`form-control ${styles.formControl} ${classValidation} ${styles.passwordInput}`}
        onChange={onChange}
        onClick={() => setClick(true)}
        {...props}
      />
      <label>{label}</label>
      <div className={styles.rateContainer}>{renderPart()}</div>
    </div>
  );
}
