import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { resetArticle } from "../../redux/actions/articles";
import Header from "./header/Header";
import Form from "./form/Form";
import styles from "./NewArticles.module.scss";

function NewArticles() {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetArticle());
    };
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <Header id={id} />
      <div className={styles.form}>
        <Form id={id} />
      </div>
    </div>
  );
}

export default NewArticles;
