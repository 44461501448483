import styles from "./Card.module.scss";

function Card({ data }) {
  return (
    <div className={styles.card}>
      <div className={styles.col}>
        {/* <img src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`} alt="" /> */}
        <img
          src={`${process.env.PUBLIC_URL}/images/test/movie-poster.png`}
          alt=""
        />
      </div>
      <div className={styles.col}>
        <div className={styles.flexRow}>
          <h2>Thor: Love And Thunder (2022)</h2>
        </div>

        <div className={styles.InfoWrapper}>
          <div>
            ساعت شروع: <span>12:26</span>
          </div>
          <div>
            بازدید کنندگان: <span>461</span>
          </div>
          <div>
            زمان کل فیلم: <span>183 دقیقه</span>
          </div>
          <div>
            زمان فعلی فیلم: <span>16 دقیقه</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
