import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./CustomInput.module.scss";
function CustomInput({
  type,
  label,
  value,
  handleChange,
  classValidation,
  isRequired,
  ...props
}) {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div
      className={
        isClicked || value.length
          ? `form-group ${styles.formGroup} ${styles.labelAnimate}`
          : `form-group ${styles.formGroup}`
      }>
      {classValidation === "danger" ? (
        <img
          src={`${process.env.PUBLIC_URL}/images/common/warning.svg`}
          alt=""
          className={`${styles.alertIcon} ${styles.error}`}
        />
      ) : (
        classValidation === "verify" && (
          <img
            src={`${process.env.PUBLIC_URL}/images/common/success.svg`}
            alt=""
            className={`${styles.alertIcon} ${styles.success}`}
          />
        )
      )}

      <input
        type={type}
        className={`form-control ${styles.formControl} ${
          classValidation === "danger" ? styles.danger : ""
        }`}
        value={value}
        onClick={() => setIsClicked(!isClicked)}
        onChange={(e) => handleChange(e.target.value)}
        {...props}
      />
      <label>
        {label} {isRequired && <span className={styles.required}>*</span>}
      </label>
    </div>
  );
}

CustomInput.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classValidation: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
};

export default CustomInput;
