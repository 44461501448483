import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyProfile } from "../redux/actions/profile";
import { Scrollbars } from "rc-scrollbars";
import { Slide, ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
import HeadTag from "./HeadTag";
import cookies from "react-cookies";
import SideMenu from "../components/sideMenu/SideMenu.js";
import TopBar from "../components/topBar/TopBar";

function PanelLayout({ children, isRestricted }) {
  const reduxToken = useSelector((store) => store.profileReducer);
  const dispatch = useDispatch();

  const cookieToken = cookies.load("token");
  const cookieRefreshTokens = cookies.load("panel-refreshToken");

  const isLogin = reduxToken.contactInfo !== null || cookieToken || cookieRefreshTokens;

  useEffect(() => {
    if (cookieToken && reduxToken.contactInfo === null) {
      dispatch(getMyProfile());
    }
  }, [reduxToken]);

  if (isRestricted && isLogin) {
    return (
      <>
        <HeadTag />
        <div className="wrapper">
          <Scrollbars style={{ height: "100vh" }} autoHide>
            <div id="content">
              <TopBar />
              {children}
            </div>
          </Scrollbars>
          <SideMenu />
        </div>
        <ToastContainer
          position="top-center"
          hideProgressBar
          transition={Slide}
          closeOnClick={false}
        />
      </>
    );
  } else {
    return <Redirect to={{ pathname: "/registerAndLogin" }} />;
  }
}

export default PanelLayout;
