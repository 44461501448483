import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteBoxOfficeMovie,
  getBoxOfficeMovies,
  postGenerateBoxOffice,
  postSyncBoxOffice,
  putBoxOfficeMovie,
} from "services/boxOffice/boxOfficeService";
import toasts from "components/common/toasts/toasts";

export const useBoxOfficeQuery = () => {
  return useQuery(["boxoffice"], ({ signal }) => getBoxOfficeMovies(signal), {
    select: (data) => {
      return data.data.data;
    },
  });
};

export const usePostSyncBoxOfficeQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postSyncBoxOffice, {
    onSuccess: () => {
      queryClient.invalidateQueries("boxoffice");
    },
    // onError: () => {
    //   toasts.error("مشکلی برای ثبت باکس آفیس پیش آمده");
    // },
  });
};
export const usePostGenerateBoxOfficeQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postGenerateBoxOffice, {
    onSuccess: () => {
      queryClient.invalidateQueries("boxoffice");
    },
    // onError: () => {
    //   toasts.error("مشکلی برای ثبت باکس آفیس پیش آمده");
    // },
  });
};
export const usePutBoxOfficeMovieQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(putBoxOfficeMovie, {
    onSuccess: () => {
      queryClient.invalidateQueries("boxoffice");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی باکس آفیس پیش آمده");
    },
  });
};
export const useDeleteBoxOfficeMovieQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteBoxOfficeMovie, {
    onSuccess: () => {
      queryClient.invalidateQueries("boxoffice");
      toasts.success(`فیلم ${title} از باکس آفیس شما حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
