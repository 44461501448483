import { Link } from "react-router-dom";
import TooltipMenu from "../toolTipMenu/ToolTipMenu";

import styles from "../styles.module.scss";

function MenuLink({
	title,
	linkUrl,
	iconUrl,
	menuIsOpen,
	isLinkActive,
	changedBg,
}) {
	return (
		<li>
			<Link
				style={{
					backgroundColor: changedBg ? "#0a0022" : "",
				}}
				to={linkUrl}
				className={`${styles.menuItem}   ${isLinkActive && styles.active} ${
					!menuIsOpen && styles.isClosed
				}`}
			>
				{menuIsOpen ? (
					<>
						<img src={`${process.env.PUBLIC_URL}${iconUrl}`} alt="" />
						<span>{title}</span>
					</>
				) : (
					<TooltipMenu text={title}>
						<img src={`${process.env.PUBLIC_URL}${iconUrl}`} alt="" />
					</TooltipMenu>
				)}
			</Link>
		</li>
	);
}

export default MenuLink;
