import "bootstrap/dist/css/bootstrap.min.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./scss/global.scss";
import { Route, Switch } from "react-router-dom";
import { routes } from "./routes";
import PanelLayout from "./layouts/PanelLayout";
import LandingLayout from "./layouts/LandingLayout";
import EmptyLayout from "./layouts/EmptyLayout";

function App() {
  const getPathByLayout = (layoutName) =>
    routes
      .filter((item) => item.layout === layoutName)
      .map((item) => item.path);
  console.log(window.__RUNTIME_CONFIG__.NODE_ENV);
  console.log(window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE);
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_WEBSITE_TYPE);
  return (
    <>
      <Switch>
        <Route path={getPathByLayout("landing")} exact>
          <LandingLayout>
            {routes
              .filter((item) => item.layout === "landing")
              .map((item) => (
                <Route
                  key={item.path}
                  exact
                  path={item.path}
                  component={item.component}
                />
              ))}
          </LandingLayout>
        </Route>

        <Route path={getPathByLayout("panel")}>
          <PanelLayout isRestricted>
            {routes
              .filter((item) => item.layout === "panel")
              .map((item) => (
                <Route
                  key={item.path}
                  exact
                  path={item.path}
                  component={item.component}
                />
              ))}
          </PanelLayout>
        </Route>

        <Route path={getPathByLayout("empty")}>
          <EmptyLayout>
            {routes
              .filter((item) => item.layout === "empty")
              .map((item) => (
                <Route
                  key={item.path}
                  exact
                  path={item.path}
                  component={item.component}
                />
              ))}
          </EmptyLayout>
        </Route>

        {/* can add 404 page here */}
      </Switch>
    </>
  );
}

export default App;