import { useDispatch } from "react-redux";
import { updateLiveStreamField } from "redux/actions/stream";
import Input from "new-components/fields/input";
import TextArea from "new-components/fields/text-area";
import HeadTitle from "new-components/head-title";
import styles from "./styles.module.scss";
export default function Information({ liveStreaming }) {
  const dispatch = useDispatch();
  const handleChangeInput = (e) => {
    dispatch(updateLiveStreamField({ [e.target.name]: e.target.value }));
  };
  return (
    <>
      <HeadTitle title="اطلاعات کلی" />
      <div className={styles.wrapper}>
        <Input
          label="عنوان پخش"
          name="title"
          value={liveStreaming.title}
          onChange={handleChangeInput}
        />
        <Input
          label="نامک"
          name="name"
          value={liveStreaming.name}
          onChange={handleChangeInput}
        />
        <TextArea
          label="توضیحات"
          name="summary"
          value={liveStreaming.summary}
          onChange={handleChangeInput}
        />
      </div>
    </>
  );
}