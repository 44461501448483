import { Scrollbars } from "rc-scrollbars";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ExtendMenuButton from "./extendMenuButton/ExtendMenuButton.js";
import MainMenu from "./mainMenu/MainMenu.js";
import TranscodePreview from "./transcodePreview/TranscodePreview.js";
import UploaderPreview from "./uploaderPreview/UploaderPreview.js";
import styles from "./styles.module.scss";
//TODO: dynamic - get profile details

function SideMenu() {
  const videoManager = useSelector((state) => state.uploadVideoManagerReducer);
  const transcodeManager = useSelector(
    (state) => state.transcodeManagerReducer
  );

  const [isMenuOpen, setIsOpenMenu] = useState(true);
  const [managerSidebar, setManagerSidebar] = useState({
    isOpen: false,
    section: undefined,
  }); // section: upload | transcode

  useEffect(() => {
    if (!videoManager.length && managerSidebar.section === "upload") {
      setManagerSidebar({ ...managerSidebar, isOpen: false });
    }

    if (!transcodeManager.length && managerSidebar.section === "transcode") {
      setManagerSidebar({ ...managerSidebar, isOpen: false });
    }
  }, [videoManager.length, transcodeManager.length]);

  const handleOpenMenu = () => {
    setIsOpenMenu((prevOpen) => !prevOpen);
  };

  const handleToggleMangerSidebar = (section) => {
    setManagerSidebar((prev) => ({
      isOpen:
        !prev.section ||
        prev.section === section ||
        (!prev.isOpen && prev.section !== section)
          ? !prev.isOpen
          : prev.isOpen,
      section: prev.section !== section ? section : prev.section,
    }));
  };

  return (
    <aside className={styles.sidebarWrapper}>
      <nav
        style={{
          backgroundColor: `${
            window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
              ? "#160442"
              : "#091018"
          }`,
        }}
        className={`${styles.sidebar} ${
          isMenuOpen ? styles.expand : styles.shrink
        }`}>
        <div
          className={`${styles.sidebarLogo} ${
            isMenuOpen ? styles.large : styles.small
          }`}>
          {isMenuOpen ? (
            <img
              src={
                window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
                  ? `${process.env.PUBLIC_URL}/images/icons/sermovie-logo.svg`
                  : `${process.env.PUBLIC_URL}/images/icons/logo.svg`
              }
              alt=""
            />
          ) : (
            <img
              src={
                window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
                  ? `${process.env.PUBLIC_URL}/images/icons/sermovie-small-logo.svg`
                  : `${process.env.PUBLIC_URL}/images/icons/small-logo.svg`
              }
              alt=""
            />
          )}
        </div>

        <Scrollbars style={{ height: "calc(100vh - 124px)" }}>
          <ul className={styles.list}>
            <MainMenu menuOpen={isMenuOpen} />
            {/* NOT A BAD IDEA TO EXTRACT ALL RELATED THING ABOUT EXTEND-MENU_BUTTON INTO SEPARATED FILE TO REDUCER RE-RENDERING IN SIDEBAR */}
            {videoManager.length > 0 && (
              <ExtendMenuButton
                title="مدیریت آپلود ها"
                iconUrl="/images/menu/upload-menu.svg"
                sectionName="upload"
                menuIsOpen={isMenuOpen}
                onToggleButton={handleToggleMangerSidebar}
                isExtendMenuOpen={
                  managerSidebar.section === "upload" && managerSidebar.isOpen
                }
              />
            )}
            {transcodeManager.length > 0 && (
              <ExtendMenuButton
                title="ترنس کد فیلم ها"
                iconUrl="/images/menu/transcode.svg"
                sectionName="transcode"
                menuIsOpen={isMenuOpen}
                onToggleButton={handleToggleMangerSidebar}
                isExtendMenuOpen={
                  managerSidebar.section === "transcode" &&
                  managerSidebar.isOpen
                }
              />
            )}
          </ul>
        </Scrollbars>

        {/* EXPAND AND SHRINK BUTTON */}
        <div
          style={{
            backgroundColor: `${
              window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
                ? "#0A0022"
                : "#151c26"
            }`,
          }}
          className={`${isMenuOpen ? styles.open : styles.close} ${
            styles.linkClose
          }`}
          onClick={handleOpenMenu}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/close-menu.svg`}
            alt=""
          />
          {isMenuOpen && <span>بستن منو</span>}
        </div>
      </nav>

      {/* UPLOAD SIDEBAR */}
      <div
        className={`${styles.uploadSidebar} ${
          managerSidebar.isOpen ? styles.open : styles.close
        }`}>
        <h6>لیست محصولات در حال بارگزاری</h6>
        {managerSidebar.section === "upload" ? (
          <UploaderPreview />
        ) : (
          <TranscodePreview />
        )}
      </div>
    </aside>
  );
}

export default SideMenu;
