import { useState } from "react";
import { useSearchArtistsQuery } from "hooks/query/useSearchQuery";
import ActorsList from "./actorsList/ActorsList";
import Paginate from "components/common/paginate/Paginate";

export default function Actors() {
  const [currentPage, setCurrentPage] = useState(1);
  // Search
  const [artistName, setArtistName] = useState("");
  const { data, isPreviousData, refetch } = useSearchArtistsQuery(
    currentPage,
    10,
    artistName
  );

  return (
    <>
      <section>
        <ActorsList
          data={data?.results}
          isPreviousData={isPreviousData}
          refetch={refetch}
          artistName={artistName}
          setArtistName={setArtistName}
          currentPage={currentPage}
        />
        <div className="paginationWrapper">
          {data?.results?.length > 0 && (
            <Paginate
              type="actors"
              name={artistName}
              paginateState={[currentPage, setCurrentPage]}
              total={data?.pagination.count}
            />
          )}
        </div>
      </section>
    </>
  );
}
