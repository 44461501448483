export const uploadVideoManagerReducer = (state = [], action) => {
	switch (action.type) {
		case "ADD_FILE_TO_QUEUE":
			return [...state, action.payload];

		case "UPDATE_FILE_UPLOAD_PROGRESS":
			return state.map((item) => {
				if (item.id === action.payload.id) {
					return {
						...item,
						progress: action.payload.progress,
					};
				}

				return item;
			});

		case "REMOVE_FILE_FROM_QUEUE":
			return state.filter((item) => item.id !== action.payload);

		default:
			return state;
	}
};
