import videojs from "video.js";

const Plugin = videojs.getPlugin("plugin");

/**
 * Default plugin options
 *
 * @param {String} url
 *        Sprite location. Must be set by user.
 * @param {Integer} width
 *        Width in pixels of a thumbnail. Must be set by user.
 * @param {Integer} height
 *        Height in pixels of a thumbnail. Must be set by user.
 * @param {Number} interval
 *        Interval between thumbnail frames in seconds. Default: 1.
 * @param {Integer} responsive
 *        Width of player below which thumbnails are reponsive. Default: 600.
 */
const defaults = {
  url: "",
  width: 0,
  height: 0,
  interval: 1,
  responsive: 600,
};

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class SpriteThumbnails extends Plugin {
  /**
   * Create a SpriteThumbnails plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   */
  constructor(player, options) {
    // the parent class will add player under this.player
    super(player);

    this.options = videojs.mergeOptions(defaults, options);

    this.player.ready(() => {
      this.spriteThumbs();
    });
    this.defaultStyle = null;
    this.mouseTimeDisplay = null;
  }

  disable() {
    this.dispose();
    this.mouseTimeDisplay.timeTooltip.el().style = this.defaultStyle;
  }

  spriteThumbs() {
    const _this = this;
    const url = this.options.url;
    const height = this.options.height;
    const width = this.options.width;
    const responsive = this.options.responsive;

    const dom = videojs.dom || videojs;
    const controls = this.player.controlBar;
    const progress = controls.progressControl;
    const seekBar = progress.seekBar;
    this.mouseTimeDisplay = seekBar.mouseTimeDisplay;

    if (url && height && width && this.mouseTimeDisplay) {
      _this.img = dom.createEl("img", {
        src: url,
      });

      const tooltipStyle = (obj) => {
        Object.keys(obj).forEach((key) => {
          const val = obj[key];
          _this.defaultStyle = _this.mouseTimeDisplay.timeTooltip.el().style
            ? _this.defaultStyle === null
            : _this.defaultStyle;
          const ttstyle = _this.mouseTimeDisplay.timeTooltip.el().style;

          if (val !== "") {
            ttstyle.setProperty(key, val);
          } else {
            ttstyle.removeProperty(key);
          }
        });
      };

      const hijackMouseTooltip = () => {
        const imgWidth = _this.img.naturalWidth;
        const imgHeight = _this.img.naturalHeight;
        if (_this.player && _this.player.controls() && imgWidth && imgHeight) {
          let hoverPosition = parseFloat(
            _this.mouseTimeDisplay.el().style.left
          );

          hoverPosition =
            _this.player.duration() * (hoverPosition / seekBar.currentWidth());
          if (!isNaN(hoverPosition)) {
            hoverPosition = hoverPosition / _this.options.interval;

            const playerWidth = _this.player.currentWidth();
            const scaleFactor =
              responsive && playerWidth < responsive
                ? playerWidth / responsive
                : 1;
            const columns = imgWidth / width;
            const scaledWidth = width * scaleFactor;
            const scaledHeight = height * scaleFactor;
            const cleft = Math.floor(hoverPosition % columns) * -scaledWidth;
            const ctop = Math.floor(hoverPosition / columns) * -scaledHeight;
            const bgSize =
              imgWidth * scaleFactor + "px " + imgHeight * scaleFactor + "px";
            const controlsTop = dom.getBoundingClientRect(controls.el()).top;
            const seekBarTop = dom.getBoundingClientRect(seekBar.el()).top;
            // top of seekBar is 0 position
            const topOffset =
              -scaledHeight - Math.max(0, seekBarTop - controlsTop);

            tooltipStyle({
              width: scaledWidth + "px",
              height: scaledHeight + "px",
              "background-image": "url(" + url + ")",
              "background-repeat": "no-repeat",
              "background-position": cleft + "px " + ctop + "px",
              "background-size": bgSize,
              top: topOffset + "px",
              color: "#fff",
              "text-shadow": "1px 1px #000",
              border: "1px solid #000",
              margin: "0 1px",
            });
          }
        }
      };

      tooltipStyle({
        width: "",
        height: "",
        "background-image": "",
        "background-repeat": "",
        "background-position": "",
        "background-size": "",
        top: "",
        color: "",
        "text-shadow": "",
        border: "",
        margin: "",
      });

      progress.on("mousemove", hijackMouseTooltip);
      progress.on("touchmove", hijackMouseTooltip);
      this.player.addClass("vjs-sprite-thumbnails");
    }
  }
}

// Register the plugin with video.js.
videojs.registerPlugin("spriteThumbnails", SpriteThumbnails);

export default SpriteThumbnails;
