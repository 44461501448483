import { Modal } from "react-bootstrap";
import styles from "./typeProjectModal.module.scss";

export default function TypeProjectModal({ onHide, type }) {
  return (
    <>
      <Modal.Header className={styles.header}>
        {type === "فیلم" && (
          <Modal.Title className={styles.title}>
            امکان حذف انتخاب <span className={styles.bold}> فیلم </span> به
            عنوان نوع پروژه وجود ندارد زیرا در این پروژه محتوای فیلم وارد شده
            است!
          </Modal.Title>
        )}
        {type === "پخش زنده" && (
          <Modal.Title className={styles.title}>
            امکان حذف انتخاب <span className={styles.bold}> پخش زنده </span> به
            عنوان نوع پروژه وجود ندارد زیرا در این پروژه یک پخش زنده در جریان
            است!
          </Modal.Title>
        )}

        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Footer className={styles.footer}>
        <div className={`${styles.btn} ${styles.outline}`}>
          مشاهده و ویرایش محتوای پروژه
        </div>
        <div className={`${styles.btn} ${styles.primary}`}>انصراف</div>
      </Modal.Footer>
    </>
  );
}
