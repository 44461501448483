import toasts  from "../components/common/toasts/toasts";

export const copyToClipboard = async (text, toastAlert) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard
      .writeText(text)
      .then(() => toasts.success(toastAlert));
  } else {
    document.execCommand("copy", true, text);
    toasts.success(toastAlert);
    return;
  }
};
