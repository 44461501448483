import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { musicTypes } from "../../../constants/musics";
import { setMusicType } from "../../../redux/actions/musics";

import styles from "./CustomTab.module.scss";

function CustomTab() {
	const dispatch = useDispatch();
	const { type } = useSelector((state) => state.musicFormReducer);

	const handleClickSingleTrack = () => {
		if (musicTypes.single === type) return;

		dispatch(setMusicType(musicTypes.single));
	};

	const handleClickAlbum = () => {
		if (musicTypes.album === type) return;

		dispatch(setMusicType(musicTypes.album));
	};

	return (
		<div className={styles.cTab}>
			<button
				className={type === musicTypes.single ? styles.active : ""}
				onClick={handleClickSingleTrack}
			>
				تک آهنگ
			</button>
			<button
				className={type === musicTypes.album ? styles.active : ""}
				onClick={handleClickAlbum}
			>
				آلبوم
			</button>
		</div>
	);
}

export default CustomTab;
