import { useDispatch, useSelector } from "react-redux";
import {
  addLiveResolution,
  changeLiveResolution,
  removeLiveResolution,
} from "redux/actions/stream";
import { useLiveConfigsQuery } from "hooks/query/useStreamQuery";
import { resolutionsConstants } from "constants/stream";
import FPS from "./fps";
import HeadTitle from "new-components/head-title";
import Resolutions from "./resolutions";
export default function ImageOutput() {
  const { data: liveConfigData } = useLiveConfigsQuery();
  const dispatch = useDispatch();
  const liveStreaming = useSelector((state) => state.setLiveStreamingReducer);
  const handleRemoveResolution = (data) => {
    if (data.id) {
      //   handleRemove(data);
      return false;
    } else {
      if (liveStreaming.resolutions.length === 1) {
        return false;
      } else {
        dispatch(removeLiveResolution(data));
      }
    }
  };
  return (
    <>
      <FPS liveStreaming={liveStreaming} />
      <HeadTitle title="رزولوشن" />
      {liveStreaming.resolutions?.map((item, index) => (
        <Resolutions
          key={item.uniqueId}
          data={item}
          liveConfigData={liveConfigData}
          resolutionsConstants={resolutionsConstants}
          handleChangeResolution={(e) =>
            dispatch(
              changeLiveResolution({
                id: index,
                value: e,
              })
            )
          }
          handleAddResolution={() => dispatch(addLiveResolution())}
          handleRemoveResolution={() => handleRemoveResolution(item)}
          length={liveStreaming.resolutions.length}
          index={index}
          maxLength={liveStreaming.resolutions.length === 5}
        />
      ))}
    </>
  );
}