import { useDeleteLiveEventQuery } from "hooks/query/useLiveEventsQuery";
import useCountDownTimer from "hooks/countDownTimer";
import styles from "./EventSlider.module.scss";

export default function EventCard({ data }) {
  const backgroundStyle = {
    background: `url(${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.poster}) no-repeat center`,
    backgroundSize: "cover",
  };
  const { mutate: deleteEvent } = useDeleteLiveEventQuery(data.title);
  const handleSeeEvent = () => {
    window.location = data.url;
  };

  const { countdownTime } = useCountDownTimer({
    end: data.publish_date,
  });

  return (
    <div style={backgroundStyle} className={styles.card}>
      <button
        onClick={() => deleteEvent(data.id)}
        className={`${styles.trashBtn} btn`}>
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/trash-event.svg`}
          alt=""
        />
      </button>
      <h6 className={styles.title}>{data.title}</h6>
      <p className={styles.description}>{data.description}</p>
      <div className={styles.bottom}>
        <div className={styles.right}>
          <button onClick={handleSeeEvent} className="btn">
            مشاهده ایونت
          </button>
          <div>منتشر شده</div>
        </div>
        <div className={styles.left}>
          <div className={styles.timer}>
            <span className={styles.text}>ثانیه</span>
            <span className={styles.box}>{countdownTime?.seconds}</span>
          </div>
          <div className={styles.timer}>
            <span className={styles.text}>دقیقه</span>
            <span className={styles.box}>{countdownTime?.minutes}</span>
          </div>
          <div className={styles.timer}>
            <span className={styles.text}>ساعت</span>
            <span className={styles.box}>{countdownTime?.hours}</span>
          </div>
          <div className={styles.timer}>
            <span className={styles.text}>روز</span>
            <span className={styles.box}>{countdownTime?.days}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
