import { useState, useEffect } from "react";
import { useTranscodeServersQuery } from "hooks/query/useCastsQuery";
import { useDispatch } from "react-redux";
import { updateEpisode } from "../../../../../../redux/actions/series";
import { Input } from "../../../../../common/input/Input";
import DropDown from "../../../../../common/dropDown/DropDown";
import VideoPart from "./VideoPart";
import SubtitlePart from "./SubtitlePart";
import DownloadLinkPart from "./DownloadLinkPart";
import styles from "./EpisodeInfo.module.scss";

export default function EpisodeInfo({ data }) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [server, setServer] = useState("");

  // GET transcode servers
  const { data: servers } = useTranscodeServersQuery();

  useEffect(() => {
    if (data) {
      setTitle(data.data.title);
      setServer(data?.data?.server);
    } else {
      setTitle("");
      setServer("");
    }
  }, [data]);

  const handleChangeMultiSelect = () => {
    if (data?.seasonId) {
      dispatch(
        updateEpisode({
          server: server,
          seasonId: data.seasonId,
          episodeId: data.data.imdb_id,
        })
      );
    }
  };
  useEffect(() => {
    handleChangeMultiSelect();
  }, [server]);

  const handleEdit = () => {
    dispatch(
      updateEpisode({
        title: title,
        seasonId: data.seasonId,
        episodeId: data.data.imdb_id,
      })
    );
  };

  return (
    <>
      <div className={styles.input}>
        <Input
          placeholder="نام این قسمت از سریال را وارد کنید."
          name="name"
          value={title}
          onBlur={handleEdit}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className={styles.dropdown}>
        <DropDown
          placeholder="انتخاب سرور ترنسکد سریال"
          options={servers}
          value={server ? server : ""}
          getOptionLabel={(option) => option["server_name"]}
          getOptionValue={(option) => option["id"]}
          onChange={setServer}
          isRequired
          type="server"
        />
      </div>
      <VideoPart data={data} />
      <SubtitlePart data={data} />
      <DownloadLinkPart data={data} />
    </>
  );
}
