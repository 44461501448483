import { useState } from "react";
import { useDeleteCategoryQuery } from "hooks/query/useCategoryQuery";
import { Modal } from "react-bootstrap";
import AddCategoryModal from "../addCategoryModal/AddCategoryModal";
import styles from "./Row.module.scss";

function Row({ data }) {
  const { mutate: deleteCategory } = useDeleteCategoryQuery(data.name);
  const handleDeleteCategory = () => {
    deleteCategory(data.id);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onOpenModal = () => {
    setIsModalOpen(true);
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <tr className={styles[`row`]}>
        <td>
          <div className={styles[`title-wrapper`]}>
            <div
              className={`${styles[`logo-wrapper`]} ${
                !Boolean(data.avatar) ? styles[`logo-wrapper__placeholder`] : ""
              }`}>
              {data.avatar && (
                <img
                  loading="lazy"
                  src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.avatar}`}
                  alt=""
                />
              )}
            </div>
            <span>{data.name}</span>
          </div>
        </td>
        <td className={styles[`description`]}>
          {data.description ? data.description : "---"}
        </td>
        <td>
          <div className={styles[`button-wrapper`]}>
            <button
              className={`${styles[`button`]} ${styles[`button__icon`]}`}
              onClick={onOpenModal}>
              <img src={"/images/categories/edit.svg"} alt="" />
            </button>
            <button
              className={`${styles[`button`]} ${styles[`button__icon`]}`}
              onClick={handleDeleteCategory}>
              <img src={"/images/categories/trash.svg"} alt="" />
            </button>
          </div>
        </td>
      </tr>
      <Modal
        show={isModalOpen}
        onHide={onCloseModal}
        dialogClassName={styles[`modal`]}
        centered>
        <AddCategoryModal onHide={onCloseModal} type="update" data={data} />
      </Modal>
    </>
  );
}

export default Row;
