import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteRecommendedMovies,
  deleteRecommendedSeries,
  getRecommendedMovies,
  getRecommendedSeries,
  postRecommendedMovies,
  postRecommendedSeries,
} from "services/recommended/recommendedServices";
import toasts from "components/common/toasts/toasts";

export const useRecommendedMoviesQuery = () => {
  return useQuery(
    ["recommended-movies"],
    ({ signal }) => getRecommendedMovies(signal),
    {
      select: (data) => {
        return data.data.data.results;
      },
    }
  );
};
export const useRecommendedSeriesQuery = () => {
  return useQuery(
    ["recommended-series"],
    ({ signal }) => getRecommendedSeries(signal),
    {
      select: (data) => {
        return data.data.data.results;
      },
    }
  );
};

export const usePostRecommendedMoviesQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postRecommendedMovies, {
    onSuccess: () => {
      queryClient.invalidateQueries("recommended-movies");
      toasts.success(`فیلم پیشنهادی با موفقیت ثبت شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت فیلم پیشنهادی پیش آمده");
    },
  });
};
export const usePostRecommendedSeriesQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(postRecommendedSeries, {
    onSuccess: () => {
      queryClient.invalidateQueries("recommended-series");
      toasts.success(`سریال پیشنهادی با موفقیت ثبت شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت سریال پیشنهادی پیش آمده");
    },
  });
};

export const useDeleteRecommendedMoviesQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteRecommendedMovies, {
    onSuccess: () => {
      queryClient.invalidateQueries("recommended-movies");
      toasts.success(`فیلم پیشنهادی با موفقیت حذف شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};
export const useDeleteRecommendedSeriesQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteRecommendedSeries, {
    onSuccess: () => {
      queryClient.invalidateQueries("recommended-series");
      toasts.success(`سریال پیشنهادی با موفقیت حذف شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};