import { useState } from "react";
import { usePlaylistsQuery } from "hooks/query/usePlaylistQuery";
import { Spinner } from "react-bootstrap";
// import Header from "./header/Header";
import Paginate from "components/common/paginate/Paginate";
import List from "./list/List";
import styles from "./Playlists.module.scss";

export default function Playlists() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isPreviousData } = usePlaylistsQuery(currentPage);

  return (
    <>
      <section className={styles.container}>
        {/* <Header /> */}
        {isPreviousData ? (
          <div className="spinnerCenter">
            <Spinner animation="border" as="i" />
          </div>
        ) : (
          <List data={data?.results} />
        )}
      </section>
      <div className="paginationWrapper">
        {data?.results?.length > 0 && (
          <Paginate
            type="playlists"
            paginateState={[currentPage, setCurrentPage]}
            total={data?.pagination.count}
          />
        )}
      </div>
    </>
  );
}
