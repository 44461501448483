import DotsLoading from "../../loader/dots/DotsLoading";
import styles from "../Accordion.module.scss";

export default function Item({
  data,
  index,
  activeBorder,
  handleActiveBorder,
  handleGetEpisodesFromImdb,
  showLoading,
}) {
  return (
    <>
      <div
        key={data.id}
        onClick={handleActiveBorder}
        className={`${styles.accordion_items} ${
          activeBorder ? styles.redBorder : ""
        }`}>
        {activeBorder && (
          <img
            className={styles.triangle}
            src={`${process.env.PUBLIC_URL}/images/common/triangle.svg`}
            alt="triangle"
          />
        )}
        <div className={styles.accordion_content}>
          <div className={styles.accordion_title}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/dots.svg`}
              alt={data.title}
            />
            <span>
              <span className={styles.bold700}>{index + 1}.</span>{" "}
              {data.title ? data.title : "نام قسمت را وارد کنید."}
              <span className={styles.gray}>
                ( {data.time ? data.time : "--"} -- )
              </span>
              {data.imdb_id?.startsWith("tt") && (
                <button
                  onClick={handleGetEpisodesFromImdb}
                  className={`${activeBorder && styles.activeRed} btn`}>
                  <span className={styles.text}>همگام سازی IMDB</span>
                  {showLoading && <DotsLoading />}
                </button>
              )}
            </span>
          </div>
          <div className={styles.accordion_info}>
            <div className={styles.video}>
              <span className={styles.darkGray}>ویدیو :</span>
              <span>
                {/* <img
                  className={styles.flag}
                  src={`${process.env.PUBLIC_URL}/images/flags/britain.svg`}
                  alt="britain"
                />
                <span className={styles.quality}>۱۰۸۰p (HD)</span> */}
                -- --
              </span>
            </div>
            <div className={styles.subtitle}>
              <span className={styles.darkGray}>زیرنویس :</span>
              <span>
                {/* <img
                  className={styles.flag}
                  src={`${process.env.PUBLIC_URL}/images/flags/iran.svg`}
                  alt="iran"
                />
                <img
                  className={styles.flag}
                  src={`${process.env.PUBLIC_URL}/images/flags/britain.svg`}
                  alt="britain"
                /> */}
                -- --
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
