import http from "../httpService";
export const getTvLives = (page, limit, signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/tv-lives/list?page=${
      page ? page : ""
    }&limit=${limit ? limit : ""}`,
    { signal }
  );
export const getTvLive = (id, signal) =>
  http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/tv-lives/get?id=${id}`, { signal });
export const deleteTvLive = (id) =>
  http.delete(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/tv-lives/delete?id=${id}`);
export const postTvLive = (data) =>
  http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/tv-lives/new`, data);
export const putTvLive = (data) =>
  http.put(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/tv-lives/update`, data);
