import { useState } from "react";
import { Link } from "react-router-dom";
import Login from "./login/Login";
import Register from "./register/Register";
import EnterPlatform from "./enterPlatform/EnterPlatform";
import EnterPlatformWithOTP from "./enterPlatformWithOTP/EnterPlatformWithOTP";
import styles from "./RegisterAndLogin.module.scss";
function RegisterAndLogin() {
  // steps : login, enterPlatform, enterPlatformWithOTP, register
  const [step, setStep] = useState("login");
  const [loginEntity, setLoginEntity] = useState("");
  const [registerObj, setRegisterObj] = useState("");
  const [loginWithOtp, setloginWithOtp] = useState(false);

  const showRegister = () => setStep("register");

  const showEnterPlatformWithOTP = () => setStep("enterPlatformWithOTP");

  const showEnterPlatform = () => setStep("enterPlatform");

  const createFooter = (step, loginResponse) => {
    // Used for sermovie
    if (window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie") {
      return "";
    }

    // if in login response , user didn't exist -> loginResponse === false
    if (!loginResponse && (step === "register" || step === "enterPlatform")) {
      return (
        <p>
          قبلا با ایمیل یا شماره دیگری ثبت نام کرده اید؟{" "}
          <span className={styles.primary} onClick={() => setStep("login")}>
            وارد شوید
          </span>
        </p>
      );
    } else if (step === "register" || step === "enterPlatform") {
      return (
        <p>
          قبلا ثبت نام کرده اید؟{" "}
          <span className={styles.primary} onClick={() => setStep("login")}>
            وارد شوید
          </span>
        </p>
      );
    } else {
      return (
        <p>
          حساب کاربری ندارید؟{" "}
          <span className={styles.primary} onClick={() => setStep("register")}>
            ثبت نام کنید
          </span>
        </p>
      );
    }
  };

  const handleStepHeight = (step) => {
    switch (step) {
      case "login":
        return styles.loginSection;
      case "register":
        return styles.registerSection;
      case "enterPlatform":
        return styles.enterPlatformSection;
      case "enterPlatformWithOTP":
        return styles.enterPlatformOTPSection;

      default:
        return;
    }
  };

  const IsOtp = (e) => {
    setloginWithOtp(e);
  };
  return (
    <section className={`${styles.main} ${handleStepHeight(step)}`}>
      <div className={styles.container}>
        <div className={styles.imageWrapper}>
          <img
            src={
              window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
                ? `${process.env.PUBLIC_URL}/images/registerAndLogin/sermovie-poster.svg`
                : `${process.env.PUBLIC_URL}/images/registerAndLogin/register2.svg`
            }
            className={styles.image}
            alt=""
          />
        </div>

        <div className={styles.content}>
          <Link to="/">
            <div className={styles.logoWrapper}>
              <img
                src={
                  window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE ===
                  "sermovie"
                    ? `${process.env.PUBLIC_URL}/images/icons/sermovie-logo-black.svg`
                    : `${process.env.PUBLIC_URL}/images/registerAndLogin/logo.svg`
                }
                className={styles.logo}
                alt=""
              />
            </div>
          </Link>

          {step === "login" && (
            <Login
              handleRegister={showRegister}
              handleEnterPlatform={showEnterPlatform}
              isOtp={IsOtp}
              setLoginObj={(e) => setLoginEntity(e)}
            />
          )}
          {step === "register" && (
            <Register
              changeStep={showEnterPlatformWithOTP}
              changeRegisterOBj={(e) => setRegisterObj(e)}
            />
          )}
          {step === "enterPlatform" && (
            <EnterPlatform
              isOtp={loginWithOtp}
              loginObj={loginEntity}
              setIsOtp={IsOtp}
            />
          )}
          {step === "enterPlatformWithOTP" && (
            <EnterPlatformWithOTP registerEntity={registerObj} />
          )}

          <div className={styles.footer}>{createFooter(step, true)}</div>
        </div>
      </div>
    </section>
  );
}

export default RegisterAndLogin;
