import { getBoxOfficeMovies } from "../../services/boxOffice/boxOfficeService";
import { getAllGenre } from "../../services/casts/castsServices";
import { getMovieByImdb } from "../../services/movies/moviesServices";
import { updateMovieInformation } from "./movies";

export const fetchGetBoxOffice = () => async (dispatch) => {
	try {
		dispatch({ type: "FETCH_GET_BOX_OFFICE" });

		const { data } = await getBoxOfficeMovies();
		dispatch(fetchBoxOfficeSuccess(data.data));
	} catch (err) {
		dispatch(fetchBoxOfficeFail(err.message));
		console.error(err);
	}
};

const fetchBoxOfficeSuccess = (boxOffice) => ({
	type: "FETCH_GET_BOX_OFFICE_SUCCESS",
	payload: boxOffice,
});

const fetchBoxOfficeFail = (error) => ({
	type: "FETCH_GET_BOX_OFFICE_FAIL",
	payload: error,
});

export const fetchGenres = () => async (dispatch) => {
	try {
		const { data } = await getAllGenre();

		dispatch({
			type: "FETCH_GENRES_FOR_BOX_OFFICE_SUCCESS",
			payload: data.data,
		});
	} catch (err) {
		console.error(err);
	}
};

export const fetchMovieFromImdb = (imdbId, cb) => async (dispatch) => {
	try {
		dispatch({ type: "FETCH_MOVIE_FROM_IMDB" });
		const { data } = await getMovieByImdb(imdbId);
		dispatch(updateMovieInformation(data.data));
		dispatch({ type: "FETCH_MOVIE_FROM_IMDB_SUCCESS" });
		cb();
	} catch (err) {
		console.error(err);
		dispatch({ type: "FETCH_MOVIE_FROM_IMDB_FAIL" });
	}
};

export const removeBoxOfficeMovie = (boxOfficeId) => ({
	type: "REMOVE_BOX_OFFICE_MOVIE",
	payload: { boxOfficeId },
});

export const updateBoxOfficeMovie = (moviePayload, boxOfficeId) => ({
	type: "UPDATE_BOX_OFFICE_MOVIE",
	payload: { moviePayload, boxOfficeId },
});

export const setLatestBoxOffice = (boxOffice) => ({
	type: "SET_LATEST_BOX_OFFICE",
	payload: boxOffice,
});
