import http from "../httpService";
export const getTranscodeServers = (signal) =>
  http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API}/transcode-servers/get-all`,
    {
      signal,
    }
  );
export const postTranscodeFiles = (server, data) =>
  http.post(`${server}/api/transcoder/transcode`, data);
