// NOTE: Style for single option dropdown
export const defaultSelectStyle = {
  control: (styles, { isFocused }) => ({
    ...styles,
    direction: "rtl",
    textAlign: "right",
    fontFamily: "IRANSans",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "43.2px",
    height: 56,
    border: "1px solid #C1C1C1!important",
    borderRadius: 12,
    color: "#000033",

    "&:hover": {
      borderColor: isFocused ? "#112df2" : "#c0c7fc",
    },
  }),
  menu: (styles) => ({
    ...styles,
    fontSize: 16,
    lineHeight: "43.2px",
    color: "#000033",
    background: "#fff",
    border: "none",
    borderRadius: "0 0 12px 12px",
    boxShadow: "4px 8px 10px rgba(28, 28, 74, 0.1)",
    paddingTop: 0,
    marginTop: 0,
  }),
  menuList: (styles) => ({
    ...styles,
    padding: "16px 0",
    borderRadius: "0 0 12px 12px",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 14,
    lineHeight: "43.2px",
    textAlign: "right",
    color: "#aaaabb",
    paddingRight: "6px",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    fontSize: 16,
    backgroundColor: "#fff",
    borderRadius: 12,
    color: "#555577",
    cursor: isDisabled ? "not-allowed" : "pointer",

    "&:hover": {
      ...styles[":hover"],
      backgroundColor: "#F6F9FB",
      borderRadius: 12,
      color: "#000033",
      margin: "0 14px",

      "& > span": {
        marginRight: "15px !important",
      },
    },

    "&:active": {
      ...styles[":active"],
      backgroundColor: !isDisabled && (isSelected ? "#F6F9FB" : "#F6F9FB"),
      margin: "4px 0px",
    },
  }),
};
// NOTE : Style for multiSelect for projectCurrency in NewProject
export const projectCurrencyStyle = {
  control: (styles, { isFocused }) => ({
    ...styles,
    direction: "rtl",
    textAlign: "right",
    fontFamily: "IRANSans",
    fontSize: 14,
    // fontWeight: 600,
    lineHeight: "40px",
    height: 52,
    border: "1px solid #C1C1C1!important",
    borderRadius: 10,
    color: "#202020",
    "&:hover": {
      borderColor: isFocused ? "#03060a66!important" : "#03060a66!important",
    },
  }),
  option: () => ({
    display: "flex",
    justifyContent: "space-between",
    fontSize: 14,
    color: "#555577",
    padding: "7px 22px",
    borderRadius: 12,
    maxHeight: 56,
    cursor: "pointer",

    ":hover": {
      color: "#000033;",
      background: "#f6f9fb",
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 14,
    color: "#606060",
  }),
  menuList: (styles) => ({
    ...styles,
    paddingTop: 12,
  }),
  multiValue: (styles) => ({
    ...styles,
    color: "#202020",
    background: "#F5F5F5",
    borderRadius: 8,
    maxHeight: 32,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    color: "#202020",
    padding: "0 12px !important",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    borderRadius: "8px 0 0 8px",
    cursor: "pointer",

    "&:hover": {
      color: "#202020",
      background: "#C1C1C1",
    },
  }),
};
// NOTE: Style for multiSelect option without row calculation
export const multiSelectStyle = {
  control: (base, { hasValue }) => ({
    ...base,
    direction: "rtl",
    textAlign: "right",
    fontFamily: "IRANSans",
    fontSize: 16,
    lineHeight: "43.2px",
    minHeight: 56,
    border: "1px solid #C1C1C1!important",
    borderRadius: 12,
    color: "#000033",
    marginBottom: hasValue ? 80 : 0,
  }),
  menu: (base) => ({
    ...base,
    top: 58,
    fontSize: 16,
    lineHeight: "43.2px",
    color: "#000033",
    background: "#fff",
    border: "none",
    borderRadius: "0 0 12px 12px",
    boxShadow: "4px 8px 10px rgba(28, 28, 74, 0.1)",
    paddingTop: 0,
    marginTop: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 14,
    lineHeight: "43.2px",
    textAlign: "right",
    color: "#aaaabb",
    transform: "translateY(-65%)",
  }),
  option: (base, { isDisabled, isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? "#F6F9FB"
      : isFocused
      ? "#F6F9FB"
      : null,
    color: isDisabled
      ? "#ccc"
      : isSelected
      ? " #03060a"
        ? "#000033"
        : "black"
      : "#555577",
    cursor: isDisabled ? "not-allowed" : "default",
    borderRadius: 12,

    ":active": {
      ...base[":active"],
      backgroundColor: !isDisabled && (isSelected ? "#F6F9FB" : "#F6F9FB"),
    },
  }),
  valueContainer: (base) => ({
    ...base,
    minHeight: 56,
    paddingTop: 68,
  }),
  multiValue: (base) => ({
    ...base,
    border: `1px solid #F0F2F5`,
    background: "#F6F9FB",
    width: 210,
    height: 46,
    borderRadius: 12,
    margin: "5px 0 5px 10px",
  }),
  multiValueRemove: (base) => ({
    ...base,
    marginRight: "auto",
    marginLeft: 16,
    height: "100%",
    ":hover": {
      background: "#F6F9FB",
      cursor: "pointer",
    },
  }),
};
// NOTE: Style for multiSelect option with row calculation
export const createMultiSelectStyle = (numberOfRows = 0, chipsWidth) => ({
  control: (base, { hasValue }) => ({
    ...base,
    direction: "rtl",
    textAlign: "right",
    fontFamily: "IRANSans",
    fontSize: 16,
    lineHeight: "43.2px",
    minHeight: 56,
    border: "1px solid #C1C1C1!important",
    borderRadius: 12,
    color: "#000033",
    marginBottom: hasValue ? 32 + numberOfRows * 55 : 0,
  }),
  menu: (base) => ({
    ...base,
    top: 58,
    fontSize: 16,
    lineHeight: "43.2px",
    color: "#000033",
    background: "#fff",
    border: "none",
    borderRadius: "0 0 12px 12px",
    boxShadow: "4px 8px 10px rgba(28, 28, 74, 0.1)",
    paddingTop: 0,
    marginTop: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 14,
    lineHeight: "43.2px",
    textAlign: "right",
    color: "#aaaabb",
    transform: "translateY(-110%)",
  }),
  option: (base, { isDisabled, isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? "#F6F9FB"
      : isFocused
      ? "#F6F9FB"
      : null,
    color: isDisabled
      ? "#ccc"
      : isSelected
      ? " #03060a"
        ? "#000033"
        : "black"
      : "#555577",
    cursor: isDisabled ? "not-allowed" : "default",
    borderRadius: 12,

    ":active": {
      ...base[":active"],
      backgroundColor: !isDisabled && (isSelected ? "#F6F9FB" : "#F6F9FB"),
    },
  }),
  valueContainer: (base) => ({
    ...base,
    minHeight: 56,
    paddingTop: 56,
  }),
  multiValue: (base) => ({
    ...base,
    border: `1px solid #F0F2F5`,
    lineHeight: chipsWidth ? "" : "28px",
    background: "#EFF1FE",
    width: chipsWidth ? chipsWidth : "auto",
    height: chipsWidth ? 46 : 32,
    fontWeight: 600,
    borderRadius: 12,
    margin: "10px 0 15px 10px",
  }),
  multiValueRemove: (base) => ({
    ...base,
    marginRight: "auto",
    marginLeft: 16,
    height: "100%",
    ":hover": {
      background: "transparent",
      cursor: "pointer",
    },
  }),
  input: (base) => ({
    ...base,
    alignSelf: "baseline",
    position: "absolute",
    top: "0%",
  }),
});

export const calculateNumberOfRows = (
  numberOfItems,
  containerWidth,
  chipsWidth
) => Math.ceil((numberOfItems * chipsWidth) / containerWidth);

export const newReactSelectStyle = {
  control: (styles) => ({
    ...styles,
    fontFamily: "IRANYekan",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "30px",
    height: 42,
    border: "none!important",
    borderRadius: "5px!important",
    background: "#F4F4F4",
    color: "#8D8D8D",
  }),
  menu: (styles) => ({
    ...styles,
    fontSize: 16,
    lineHeight: "30px",
    color: "#000033",
    background: "#fff",
    border: "none",
    borderRadius: "0 0 5px 5px!important",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
  }),
  menuList: (styles) => ({
    ...styles,
    "::-webkit-scrollbar": {
      width: "4px",
    },
    "::-webkit-scrollbar-track": {
      background: "#e3e3e3",
      borderRadius: "10px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#ef1b2b",
      borderRadius: "10px",
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 14,
    lineHeight: "30px",
    color: "#8D8D8D",
  }),
  option: (styles) => ({
    ...styles,
    fontSize: 12,
    backgroundColor: "#fff",
    color: "#202020",
    cursor: "pointer",
    "&:hover": {
      fontWeight: 600,
      margin: "0!important",
    },
    "&:active": {
      background: "#fff!important",
    },
  }),
};
