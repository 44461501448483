import { Modal } from "react-bootstrap";
import { CloseButton } from "../../packageAndFeatures/components/Components";
import { Input } from "../../common/input/Input";
import { ToJalaliFormat } from "util/toJalali";
import TextArea from "../../common/textArea/TextArea";
import styles from "../Support.module.scss";

function SupportModal({ data, onHide }) {
  return (
    <div className={styles.modalWrapper}>
      <CloseButton position="top" onClick={onHide} />
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>اطلاعات تیکت</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.inputWrapper}>
          <Input value={data.name} readOnly />
          <Input value={data.mobile_number ? data.mobile_number : "-"} readOnly />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            value={data.created_at ? ToJalaliFormat(data.created_at) : "-"}
            readOnly
          />
          <Input value={data.title} readOnly />
        </div>
        <TextArea value={data.description} readOnly />
      </Modal.Body>
    </div>
  );
}

export default SupportModal;
