import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { flags } from "../../../constants/flags";
import styles from "./AreaCodeDropDown.module.scss";

export default function AreaCodeDropDown({
  selectedCountry,
  setSelectedCountry,
}) {
  // we also handle Flags data here...
  const ImgURL = flags.filter((c) => c.code === selectedCountry);

  // Search
  const [flagsQuery, setFlagsQuery] = useState("");
  const flagsDataItem = flags
    .filter((f) => {
      if (f.name.includes(flagsQuery) || f.code.includes(flagsQuery)) {
        return f;
      }
    })
    .splice(0, 1)
    .map((c) => (
      <Dropdown.Item
        className={`${styles.dropdownItem}`}
        key={c.code}
        eventKey={c.code}>
        <img className={styles.dropdownItemImg} src={c.flag} alt={c.name} />
        {c.name} <span>({c.code})</span>
      </Dropdown.Item>
    ));

  return (
    <>
      <Dropdown
        className={styles.dropdown}
        onSelect={(e) => setSelectedCountry(e)}>
        <Dropdown.Toggle className={styles.dropdownToggle}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/dropdown-icon.svg`}
          />
          <img
            className={styles.dropdownToggleSelectedImg}
            src={ImgURL[0] ? ImgURL[0].flag : flags[0].flag}
            alt={ImgURL[0] ? ImgURL[0].name : flags[0].name}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.dropdownMenu}>
          <div className={styles.dropdownMenuSearch}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/magnifier.svg`}
              className={styles.magnifier}
            />
            <input
              type="text"
              placeholder="جستجوی کشور"
              onChange={(e) => setFlagsQuery(e.target.value)}
            />
            <Dropdown.Toggle className={styles.dropdownMenuSearchCloseIcon}>
              <img
                src={`${process.env.PUBLIC_URL}/images/common/dropdown-icon.svg`}
              />
            </Dropdown.Toggle>
          </div>
          <div className={styles.dropdownMenuItems}>{flagsDataItem}</div>
        </Dropdown.Menu>
      </Dropdown>
      {/* <span className={styles.areaCode}>
        {selectedCountry && `${selectedCountry} -`}
      </span> */}
    </>
  );
}
