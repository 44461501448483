import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  useEmailConfigQuery,
  usePostEmailConfigQuery,
  usePutEmailConfigQuery,
} from "hooks/query/useEmailConfigQuery";
import {
  usePostSmsConfigQuery,
  usePutSmsConfigQuery,
  useSmsConfigQuery,
} from "hooks/query/useSmsConfigQuery";
import { Input } from "components/common/input/Input";
import { CloseButton } from "components/packageAndFeatures/components/Components";
import { smsProvider } from "constants/smsConfig";
import DropDown from "components/common/dropDown/DropDown";
import toasts from "components/common/toasts/toasts";
import styles from "./ConfigModal.module.scss";

export default function ConfigModal({ onHide }) {
  const [type, setType] = useState("sms");
  // Email Config
  const [emailConfig, setEmailConfig] = useState({
    email_addr: "",
    server_host: "",
    server_password: "",
    server_port: "",
    server_username: "",
  });
  const { data: emailData } = useEmailConfigQuery();
  const { mutate: postEmailConfig } = usePostEmailConfigQuery();
  const { mutate: putEmailConfig } = usePutEmailConfigQuery();
  useEffect(() => {
    if (emailData) {
      setEmailConfig({
        email_addr: emailData.email_addr,
        server_host: emailData.server_host,
        server_password: emailData.server_password,
        server_port: emailData.server_port,
        server_username: emailData.server_username,
      });
    }
  }, [emailData]);
  const onChangeEmailConfigInputs = (e) => {
    setEmailConfig({ ...emailConfig, [e.target.name]: e.target.value });
  };
  const handleSubmitEmailConfig = () => {
    if (emailData) {
      putEmailConfig({
        ...emailConfig,
        id: emailData.id,
      });
    } else {
      postEmailConfig(emailConfig);
    }
  };

  // Sms Config
  const [smsConfig, setSmsConfig] = useState({
    sender_phone_number: "",
    url: "",
    api_key: "",
    sms_provider_name: "",
  });
  const { data: smsData } = useSmsConfigQuery();
  const { mutate: postSmsConfig } = usePostSmsConfigQuery();
  const { mutate: putSmsConfig } = usePutSmsConfigQuery();
  useEffect(() => {
    if (smsData) {
      setSmsConfig({
        sender_phone_number: smsData.sender_phone_number,
        url: smsData.url,
        api_key: smsData.api_key,
        sms_provider_name: {
          id: smsData.sms_provider_name,
          title: smsData.sms_provider_name,
        },
      });
    }
  }, [smsData]);
  const onChangeSmsConfigInputs = (e) => {
    setSmsConfig({ ...smsConfig, [e.target.name]: e.target.value });
  };
  const onChangeSmsConfigProvider = (e) => {
    setSmsConfig({
      ...smsConfig,
      sms_provider_name: e,
    });
  };
  const handleSubmitSmsConfig = () => {
    const { sms_provider_name, ...rest } = smsConfig;
    if (smsData) {
      putSmsConfig({
        ...rest,
        sms_provider_name: sms_provider_name.title,
        id: smsData.id,
      });
    } else {
      postSmsConfig({
        ...rest,
        sms_provider_name: sms_provider_name.title,
      });
    }
  };

  // Submit
  const onSubmit = () => {
    if (type === "sms") {
      if (
        [
          smsConfig.api_key.length,
          smsConfig.sender_phone_number.length,
          smsConfig.url.length,
        ].every(Boolean)
      ) {
        handleSubmitSmsConfig();
        onHide();
      } else {
        toasts.error("لطفا اطلاعات را تکمیل نمایید.");
      }
    }
    if (type === "email") {
      if (
        [
          emailConfig.email_addr.length,
          emailConfig.server_host.length,
          emailConfig.server_password.length,
          emailConfig.server_port.length,
          emailConfig.server_username.length,
        ].every(Boolean)
      ) {
        handleSubmitEmailConfig();
        onHide();
      } else {
        toasts.error("لطفا اطلاعات را تکمیل نمایید.");
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <Modal.Header className={styles.header}>
        <CloseButton position="top" onClick={onHide} />
        <Modal.Title className={styles.header__title}>
          تنظیمات کد تایید
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.body}>
        <div className={styles.tabs}>
          <button
            onClick={() => setType("sms")}
            className={type === "sms" ? styles.active : ""}>
            sms provider
          </button>
          <button
            onClick={() => setType("email")}
            className={type === "email" ? styles.active : ""}>
            email center
          </button>
        </div>
        {type === "sms" ? (
          <>
            <Input
              name="sender_phone_number"
              placeholder="Sender"
              onChange={onChangeSmsConfigInputs}
              value={smsConfig.sender_phone_number}
            />
            <Input
              name="url"
              placeholder="Endpoint"
              onChange={onChangeSmsConfigInputs}
              value={smsConfig.url}
            />
            <Input
              name="api_key"
              placeholder="API Key"
              onChange={onChangeSmsConfigInputs}
              value={smsConfig.api_key}
            />
            <DropDown
              placeholder="Sms Provider"
              options={smsProvider}
              value={smsConfig.sms_provider_name}
              getOptionLabel={(option) => option["title"]}
              getOptionValue={(option) => option["id"]}
              onChange={onChangeSmsConfigProvider}
            />
          </>
        ) : (
          <>
            <Input
              name="server_host"
              placeholder="SMTP"
              onChange={onChangeEmailConfigInputs}
              value={emailConfig.server_host}
            />
            <Input
              name="server_port"
              placeholder="SMTP - Port"
              onChange={onChangeEmailConfigInputs}
              value={emailConfig.server_port}
            />
            <Input
              name="server_username"
              placeholder="Server Username"
              onChange={onChangeEmailConfigInputs}
              value={emailConfig.server_username}
            />
            <Input
              name="email_addr"
              placeholder="Email"
              onChange={onChangeEmailConfigInputs}
              value={emailConfig.email_addr}
            />
            <Input
              name="server_password"
              placeholder="Password"
              onChange={onChangeEmailConfigInputs}
              value={emailConfig.server_password}
            />
          </>
        )}
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <button onClick={onSubmit} className={styles.button}>
          ثبت اطلاعات
        </button>
      </Modal.Footer>
    </div>
  );
}
