import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  deleteArtist,
  getArtist,
  getArtists,
  postNewArtist,
  putArtist,
} from "services/artists/artists";
import toasts from "components/common/toasts/toasts";

export const useArtistsQuery = (page, limit) => {
  return useQuery(
    ["artists", page],
    ({ signal }) => getArtists(page, limit, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
export const useArtistQuery = (id) => {
  return useQuery(["artist", id], ({ signal }) => getArtist(id, signal), {
    enabled: !!id,
    select: (data) => {
      return data.data.data;
    },
  });
};

export const usePostArtistQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(postNewArtist, {
    onSuccess: () => {
      queryClient.invalidateQueries("artists");
      history.push("/actors");
      toasts.success("هنرمند با موفقیت ایجاد شد");
    },
    onError: () => {
      toasts.error("مشکلی برای ایجاد هنرمند پیش آمده");
    },
  });
};
export const usePutArtistQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(putArtist, {
    onSuccess: () => {
      queryClient.invalidateQueries("artists");
      history.push("/actors");
      toasts.success("هنرمند با موفقیت بروزرسانی شد");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی هنرمند پیش آمده");
    },
  });
};
export const useDeleteArtistQuery = (type, page, title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteArtist, {
    onSuccess: () => {
      queryClient.invalidateQueries(["artists-by-search", page]);
      // queryClient.invalidateQueries(["artists", page]);
      if (type === "single") {
        toasts.success(`هنرمند ${title} با موفقیت حذف شد`);
      }
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};