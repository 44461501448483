import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
// import List from "./list/List";
import toasts from "../toasts/toasts";
import styles from "./SubtitleFileUploader.module.scss";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllLanguages } from "../../../redux/actions/casts";
// import { uniqBy } from "lodash-es";

export default function SubtitleFileUploader({
  fileState,
  handleDelete,
  maxFiles,
  accept,
  dropzonePlaceholder,
  data,
}) {
  const [files, setFiles] = fileState;
  const [organizedFiles, setOrganizedFiles] = useState([]);
  // const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    const mappedFiles = files.map((item) => {
      if (!item.hasOwnProperty("id")) {
        return {
          title: item.name,
          path: item.path,
          language_id: item.language_id,
        };
      } else {
        return {
          ...item,
        };
      }
    });

    setOrganizedFiles(mappedFiles);
  }, [files]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const newAcceptedFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        language_id: 237,
      })
    );

    setFiles((prev) => [...prev, ...newAcceptedFiles]);

    rejectedFiles.forEach((file) => {
      file.errors.forEach((error) => {
        if (error.code === "file-invalid-type") {
          toasts.warn(`فقط مجاز به استفاده از فرمت های : ${accept}`);
        }

        if (error.code === "too-many-files") {
          toasts.warn(`فقط مجاز به ارسال ${maxFiles} فایل هستید`);
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    maxFiles: maxFiles,
    onDrop,
  });

  return (
    <>
      <div
        {...getRootProps({
          className: `${styles.theme} ${styles.dropzone}`,
        })}>
        <input {...getInputProps()} />
        <section className={styles.section}>
          <div className={styles.fileUploaderImage}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/subtitle-upload.svg`}
              alt="+"
            />
          </div>
          <p>
            {dropzonePlaceholder
              ? dropzonePlaceholder
              : "فایل را بکشید و رها کنید."}
            <br />
            <span>و یا آن را انتخاب نمایید</span>
          </p>
        </section>
      </div>
      {organizedFiles?.length > 0 && (
        <ul className={styles.subtitleList}>
          {organizedFiles?.map((item, index) => (
            <li key={item.id || item.title} className={styles.subtitleItem}>
              <div className={styles.right}>
                <img
                  onClick={() => handleDelete(item)}
                  src={`${process.env.PUBLIC_URL}/images/icons/trash.svg`}
                  alt="trash"
                />
                {item.title || item.name}
              </div>
              {/* <div className={styles.left}>
                <List
                  data={data}
                  flag={item}
                  index={index}
                  toggle={[isToggled, setIsToggled]}
                />
              </div> */}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

SubtitleFileUploader.defaultProps = {
  accept: ".srt,.vtt",
  // accept: ".srt,.vtt,.ass",
  maxFiles: 20,
};
