import { useEffect, useState } from "react";

export default function useCountDownTimer({ end }) {
  const [expiryTime, setExpiryTime] = useState(end);
  const [countdownTime, setCountdownTime] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });

  const countdowner = () => {
    const timeInterval = setInterval(() => {
      const countdownDateTime = new Date(expiryTime).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        days: totalDays > 0 ? totalDays : "",
        hours: totalHours > 0 ? totalHours : "",
        minutes: totalMinutes > 0 ? totalMinutes : "",
        seconds: totalSeconds > 0 ? totalSeconds : "",
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime < 0) {
        clearInterval(timeInterval);
        setExpiryTime(false);
      }
    }, 1000);
  };

  // For Adding "0" to timer
  if (countdownTime.days < 10) {
    countdownTime.days = "0" + countdownTime.days;
  }
  if (countdownTime.hours < 10) {
    countdownTime.hours = "0" + countdownTime.hours;
  }
  if (countdownTime.minutes < 10) {
    countdownTime.minutes = "0" + countdownTime.minutes;
  }
  if (countdownTime.seconds < 10) {
    countdownTime.seconds = "0" + countdownTime.seconds;
  }

  useEffect(() => {
    countdowner();
  });

  return { countdownTime };
}
