import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { resetLiveStream } from "../../../redux/actions/stream";
import { Tab, Tabs } from "react-bootstrap";
import { useStreamQuery } from "hooks/query/useStreamQuery";
import TabHeader from "./tabHeader/TabHeader";
import MainSetting from "./tabs/mainSetting/MainSetting";
// import PlayBackSetting from "./tabs/playBackSetting/PlayBackSetting";
// import Share from "./tabs/share/Share";
import styles from "./LiveStreaming.module.scss";

function LiveStreaming() {
  const { id } = useParams();
  const { data } = useStreamQuery(id);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(resetLiveStream());
  }, [dispatch]);

  return (
    <div>
      <TabHeader />
      <div className={`filter ${styles.filterBody}`}>
        <Tabs defaultActiveKey="mainSetting" className={styles.tab}>
          <Tab
            eventKey="mainSetting"
            title="تنظیمات اصلی"
            className={styles.tabItem}>
            <MainSetting data={data} id={id} />
          </Tab>
          {/* <Tab
            eventKey="playBackSetting"
            title="تنظیمات پخش"
            className={styles.tabItem}>
            <PlayBackSetting
              handleRandomStreamKey={handleRandomStreamKey}
              streamKey={liveStreaming.stream_key}
              id={id}
            />
          </Tab>
          <Tab eventKey="share" title="اشتراک گذاری" className={styles.tabItem}>
            <Share streamKey={liveStreaming.stream_key} id={id} />
          </Tab> */}
        </Tabs>
      </div>
    </div>
  );
}

export default LiveStreaming;
