import { useEffect, useState } from "react";
import { useMoviesQuery } from "hooks/query/useMovieQuery";
import { useMembersQuery } from "hooks/query/useMemberQuery";
import { useCollectionsQuery } from "hooks/query/useCollectionQuery";
import { useRequestsQuery } from "hooks/query/useRequestQuery";
import { useSeriesQuery } from "hooks/query/useSerieQuery";
import { useTracksQuery } from "hooks/query/useMusicQuery";
import { useStreamsQuery } from "hooks/query/useStreamQuery";
import { shuffle } from "lodash-es";
import Header from "./header/Header";
import FilesList from "./filesList/FilesList";
import RequestList from "./requestList/RequestList";
import UsersList from "./usersList/UsersList";
import styles from "./OverView.module.scss";

export default function OverView() {
  const [files, setFiles] = useState({
    movies: [],
    series: [],
    collection: [],
    tracks: [],
  });

  // Collection
  const { data: collectionList } = useCollectionsQuery();
  const { data: moviesList } = useMoviesQuery();
  const { data: seriesList } = useSeriesQuery();
  const { data: tracksList } = useTracksQuery();
  const { data: liveList } = useStreamsQuery();

  useEffect(() => {
    if (moviesList) {
      setFiles((prev) => ({
        ...prev,
        movies: moviesList.results,
      }));
    }
    if (seriesList) {
      setFiles((prev) => ({
        ...prev,
        series: seriesList.results,
      }));
    }
    if (collectionList) {
      setFiles((prev) => ({
        ...prev,
        collection: collectionList.results,
      }));
    }
    if (tracksList) {
      setFiles((prev) => ({
        ...prev,
        tracks: tracksList.results,
      }));
    }
  }, [collectionList, moviesList, seriesList, tracksList]);

  // Members
  const { data: membersList } = useMembersQuery(1);
  // Request
  const { data: requestedContents } = useRequestsQuery("all", 1);

  // ALL Files
  const shuffledFiles = shuffle(Object.values(files).flat());

  return (
    <section className={styles.wrapper}>
      <div className={styles.right}>
        <Header
          dataLength={{
            movies: moviesList?.pagination.count,
            series: seriesList?.pagination.count,
            musics: tracksList?.pagination.count,
            collections: collectionList?.pagination.count,
            live: liveList?.pagination.count,
          }}
        />
        <div className={styles.rightBottom}>
          <FilesList data={shuffledFiles} />
          <RequestList data={requestedContents?.results} />
        </div>
      </div>
      <div className={styles.left}>
        <UsersList data={membersList?.results} />
      </div>
    </section>
  );
}
