import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import toasts from "../../common/toasts/toasts";

import styles from "./ImageUploader.module.scss";

function ImageUploader({ imageState }) {
  //* NOTE: replace repetitive logic with custom hook similar hook is available in album cover component
  const [image, setImage] = imageState;

  const [selectedImage, setSelectedImage] = useState("");

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setImage(acceptedFiles[0]);

      const [file] = acceptedFiles;
      const fileReader = new FileReader();
      fileReader.addEventListener(
        "load",
        () => {
          setSelectedImage(fileReader.result);
        },
        false
      );
      fileReader.readAsDataURL(file);
    },
    [setImage]
  );

  const onDropRejected = (rejectedFiles) => {
    toasts.error("شما فقط می توانید از فرمت های PNG و JPG استفاده کنید");
  };

  // TODO: Only get jpg not other jpg formats like jpeg etc
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
    // noDrag: true,
  });

  // need view state for contained with image

  const imageUrl = image?.length
    ? `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${image}`
    : selectedImage;

  const rootPropsConfig = {
    className: styles.dropzoneWrapper,
  };

  return (
    <div
      className={`${styles.uploaderWrapper} ${
        image ? styles.hasImage : styles.noImage
      }`}>
      {image ? (
        <>
          <div {...getRootProps(rootPropsConfig)}>
            <img src={imageUrl} alt="" />
            <input {...getInputProps()} />
            <button className={`btn ${styles.editButton}`}>ویرایش تصویر</button>
          </div>
        </>
      ) : (
        <div {...getRootProps(rootPropsConfig)}>
          <input {...getInputProps()} />

          <div className={styles.section}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/gallery.svg`}
              alt=""
            />
            <p>افزودن تصویر مقاله</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageUploader;
