import React, { Fragment, useEffect } from "react";
import { Slide, ToastContainer } from "react-toastify";
import { useLocation } from "react-router";
import HeadTag from "./HeadTag";
import LandingHeader from "../components/Landing/landingHeader/LandingHeader";
import LandingFooter from "../components/Landing/landingFooter/LandingFooter";

function LandingLayout({ children, ...props }) {
  const { pathname, hash } = useLocation();
  // using for scrolling to a target section
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash]);

  return (
    <Fragment>
      <HeadTag />
      <LandingHeader />
      <main className="sections-wrapper-landing">{children}</main>
      <LandingFooter />
      <ToastContainer
        position="top-center"
        hideProgressBar
        transition={Slide}
        closeOnClick={false}
      />
    </Fragment>
  );
}

export default LandingLayout;
