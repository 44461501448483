//* custom toast
import { toast } from "react-toastify";

const success = (message) => {
  toast.success(message, {
    icon: () => (
      <img
        style={{ width: "22px", height: "22px" }}
        src={`${process.env.PUBLIC_URL}/images/common/toast-success.svg`}
        alt="Success"
      />
    ),
  });
};

const error = (message) => {
  toast.error(message, {
    icon: () => (
      <img
        style={{ width: "22px", height: "22px" }}
        src={`${process.env.PUBLIC_URL}/images/common/toast-error.svg`}
        alt="Error"
      />
    ),
  });
};

const warn = (message) => {
  toast.warn(message);
};

const toasts = {
  success,
  error,
  warn,
};

export default toasts;
