import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Table, Spinner } from "react-bootstrap";
import {
  resetMovieByImdb,
  resetMovieInformation,
  updateSelectAllMovies,
} from "../../../redux/actions/movies";
import { useDeleteMovieQuery } from "hooks/query/useMovieQuery";
import { Input } from "components/common/input/Input";
import MovieRow from "./movieRow/MovieRow";
// import Filter from "../Filter/Filter";
import AddMovieModal from "../addMovieModal/AddMovieModal";
import toasts from "components/common/toasts/toasts";
import DeleteMultiModal from "../../common/modals/deleteMultiModal/DeleteMultiModal";
import EmptyState from "../../common/emptyState/EmptyState";
import styles from "./MovieList.module.scss";

export default function MovieList({
  data,
  isPreviousData,
  refetch,
  movieName,
  setMovieName,
  currentPage
}) {
  const dispatch = useDispatch();
  const { mutateAsync: deleteMovies } = useDeleteMovieQuery("multiType");
  const { selectedMovies, isAllSelected } = useSelector(
    (state) => state.moviesListReducer
  );
  const [allChecked, setAllChecked] = useState(false);
  // Modals State
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddMovieModal, setShowAddMovieModal] = useState(false);

  useEffect(() => {
    if (data?.length) {
      if (selectedMovies.length === data.length) {
        dispatch(
          updateSelectAllMovies({
            allChecked: true,
            moviesList: data,
          })
        );
        setAllChecked(true);
      } else if (isAllSelected && selectedMovies.length < data.length) {
        dispatch(
          updateSelectAllMovies({
            allChecked: false,
            moviesList: [],
          })
        );
        setAllChecked(false);
      } else {
        setAllChecked(false);
      }
    }
  }, [data, dispatch, isAllSelected, selectedMovies]);

  const handleSelectAll = (allChecked) => {
    dispatch(
      updateSelectAllMovies({
        allChecked: allChecked,
        moviesList: data,
      })
    );
    setAllChecked(allChecked);
  };
  const handleDeleteMovies = async () => {
    for (const item of selectedMovies) {
      const { status } = await deleteMovies(item.id);
      if (status === 200) {
        toasts.success(`فیلم ${item.title} باموفقیت حذف شد.`);
      }
      setShowDeleteModal(false);
      dispatch(
        updateSelectAllMovies({
          allChecked: false,
          moviesList: [],
        })
      );
    }
  };

  const handleOnHide = () => {
    dispatch(resetMovieByImdb());
    dispatch(resetMovieInformation());
    setShowAddMovieModal(false);
  };

  // Search
  const [showInput, setShowInput] = useState(false);
  const ref = useRef();
  const handleCloseInput = (e) => {
    if (!ref.current.contains(e.target)) {
      setShowInput(false);
    }
  };

  return (
    <section onClick={handleCloseInput}>
      <div className={styles.headerWrapper}>
        <div
          onClick={() => handleSelectAll(!allChecked)}
          className={`btn ${styles.btn} ${styles.primary} ${
            allChecked ? styles.activeBtn : ""
          }`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/movies/chose-all.svg`}
            alt=""
          />
          انتخاب همه
        </div>
        {/* {!selectedMovies.length && <Filter />} */}
        {selectedMovies.length > 0 && (
          <div
            onClick={() => setShowDeleteModal(true)}
            className={`btn ${styles.btn} ${styles.danger}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt=""
            />
            حذف موارد انتخاب شد
          </div>
        )}
        {!selectedMovies.length && (
          <div
            onClick={() => setShowAddMovieModal(true)}
            className={`btn ${styles.btn} ${styles.primary}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/movies.svg`}
              alt=""
              className={styles.logoActive}
            />
            افزودن فیلم
          </div>
        )}
      </div>
      <section className={styles.tableWrapper}>
        <Table className={styles.table} responsive>
          <thead>
            <tr className={styles.THead}>
              <th
                ref={ref}
                onClick={() => setShowInput(true)}
                className={styles.customHeader}>
                {showInput ? (
                  <Input
                    placeholder="جستجو فیلم"
                    value={movieName}
                    onBlur={refetch}
                    onChange={(e) => setMovieName(e.target.value)}
                    image={{
                      enable: true,
                      icon: "/images/icons/search.svg",
                      clickHandler: refetch,
                    }}
                  />
                ) : (
                  <>
                    پوستر و عنوان فیلم
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/search.svg`}
                      alt=""
                    />
                  </>
                )}
              </th>
              <th>ردیف ({data?.length} مورد)</th>
              <th>سال انتشار</th>
              <th>زمان افزودن فیلم به پروژه</th>
              <th>وضعیت انتشار</th>
              <th>عملیات</th>
            </tr>
          </thead>
          {data?.length > 0 && (
            <tbody>
              {isPreviousData ? (
                <div className="spinnerCenter">
                  <Spinner animation="border" as="i" />
                </div>
              ) : (
                data.map((movie, index) => (
                  <MovieRow key={movie.id} data={movie} index={index} currentPage={currentPage} />
                ))
              )}
            </tbody>
          )}
        </Table>
        {!data?.length > 0 && (
          <div className={styles.emptyState}>
            <EmptyState
              subHeader
              buttonOptions={{
                link: "/movies/addMovies",
                text: "افزودن فیلم",
                icon: "/images/productAndServices/newProject.svg",
              }}>
              <p className={styles.emptyStateText}>نتیجه ای یافت نشد!</p>
            </EmptyState>
          </div>
        )}
      </section>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={styles.deleteMovieModal}>
        <DeleteMultiModal
          titleData={{
            deleteObject:
              selectedMovies.length === 1 ? selectedMovies[0].name : "",
            isMoreThanOne: selectedMovies.length > 1,
            placeName: "فیلم",
          }}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={handleDeleteMovies}
        />
      </Modal>

      <Modal
        show={showAddMovieModal}
        onHide={handleOnHide}
        dialogClassName={styles.deleteMovieModal}>
        <AddMovieModal onHide={handleOnHide} />
      </Modal>
    </section>
  );
}
