import { Link } from "react-router-dom";
import CustomDropdown from "../CustomDropDown/CustomDropDown";

import styles from "./Card.module.scss";

export default function Card({ item }) {
	return (
		<div className={styles.card}>
			<div className={styles.dropdown}>
				<CustomDropdown id={item.id} />
			</div>
			<img
				className={styles.activityPic}
				src={
					item.avatar?.length > 0
						? ``
						: `${process.env.PUBLIC_URL}/images/productAndServices/default-img.svg`
				}
				alt=""
			/>
			<h3>{item.title}</h3>
			<p>{item.description}</p>
			<div className={styles.cardTags}>
				{item.tags.map((t) => (
					<span key={t}>{t}</span>
				))}
			</div>
			<Link to={`/project`} className={`${styles.btn} ${styles.cardBtn}`}>
				مشاهده پروژه
				<img
					src={`${process.env.PUBLIC_URL}/images/productAndServices/chevron-project.svg`}
					alt=""
				/>
			</Link>
		</div>
	);
}
