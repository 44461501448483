import { useDispatch } from "react-redux";
import { updateLiveStreamField } from "redux/actions/stream";
import { fpsConstants } from "constants/stream";
import HeadTitle from "new-components/head-title";
import styles from "./styles.module.scss";
export default function FPS({ liveStreaming }) {
  const dispatch = useDispatch();
  return (
    <>
      <HeadTitle title="فریم بر ثانیه" />
      <div className={styles.wrapper}>
        {fpsConstants.map((item) => (
          <div
            onClick={() =>
              dispatch(
                updateLiveStreamField({
                  fps: item.name,
                })
              )
            }
            className={`${styles.box} ${
              liveStreaming.fps == item.name ? styles.box__active : ""
            }`}>
            <span>{item.name}</span>
            <span>فریم بر ثانیه</span>
          </div>
        ))}
      </div>
    </>
  );
}