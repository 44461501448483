// helper function for remove item
const removeFromArrayWithID = (array, id) =>
  array.filter((item) => item.id !== id);

const collectionState = {
  selectedCollection: [],
  isAllSelected: false,
};
export const collectionListReducer = (state = collectionState, action) => {
  switch (action.type) {
    case "ADD_SELECTED_COLLECTION_LIST":
      return {
        ...state,
        selectedCollection: [...state.selectedCollection, action.payload],
      };
    case "REMOVE_SELECTED_COLLECTION_LIST":
      return {
        ...state,
        selectedCollection: removeFromArrayWithID(
          state.selectedCollection,
          action.payload
        ),
        isAllSelected:
          removeFromArrayWithID(state.selectedCollection, action.payload) ===
          [],
      };
    case "UPDATE_SELECT_ALL_COLLECTION":
      return {
        ...state,
        selectedCollection: action.payload.allChecked
          ? action.payload.collectionList
          : [],
        isAllSelected: action.payload.allChecked,
      };
    default:
      return state;
  }
};

// Create Collection reducer
const initState = {
  cover: "",
  title: "",
  title_in_persian: "",
  summary: "",
  category_id: null,
};
export const createCollectionReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_COLLECTION_INFO":
      return { ...state, ...action.payload };
    case "RESET_COLLECTION_INFO":
      return initState;
    default:
      return state;
  }
};
