import React, { useRef, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";

import styles from "./AudioPlayer.module.scss";

function AudioPlayer({ trackLabel, trackUrl, size = "lg" }) {
	const [isPlaying, setIsPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);

	const audioPlayer = useRef();
	const progressBar = useRef();
	const animationRef = useRef();

	const onLoadedMetadata = () => {
		const seconds = Math.floor(audioPlayer.current.duration);

		setDuration(seconds);
		progressBar.current.max = seconds;
	};

	const togglePlayPause = () => {
		const prevValue = isPlaying;
		setIsPlaying(!prevValue);

		if (!prevValue) {
			audioPlayer.current.play();
			animationRef.current = requestAnimationFrame(whilePlaying);
		} else {
			audioPlayer.current.pause();
			cancelAnimationFrame(animationRef.current);
		}
	};

	const changeRange = () => {
		audioPlayer.current.currentTime = progressBar.current.value;
		changePlayerCurrentTime();
	};

	const changePlayerCurrentTime = () => {
		progressBar.current.style.setProperty(
			"--seek-before-width",
			`${(progressBar.current.value / duration) * 100}%`
		);
		setCurrentTime(progressBar.current.value);
	};

	const whilePlaying = () => {
		progressBar.current.value = audioPlayer.current.currentTime;
		changePlayerCurrentTime();
		animationRef.current = requestAnimationFrame(whilePlaying);
	};

	const calculateTime = (time) => {
		const seconds = Math.floor(time % 60);
		const formattedSec = seconds < 10 ? `0${seconds}` : `${seconds}`;
		const minutes = Math.floor(time / 60);
		const formattedMin = minutes < 10 ? `0${minutes}` : `${minutes}`;

		return `${formattedMin}:${formattedSec}`;
	};

	return (
		<div className={styles.customAudio}>
			{size === "lg" && <span>{trackLabel}</span>}

			<div className={`${styles.audio} ${styles[size]}`}>
				<audio
					ref={audioPlayer}
					// src="https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3"
					src={trackUrl}
					preload="metadata"
					onLoadedMetadata={onLoadedMetadata}
				></audio>

				{/* play and pause */}
				<button className={styles.playPause} onClick={togglePlayPause}>
					{isPlaying ? <FaPause width={24} /> : <FaPlay width={24} />}
				</button>

				{/* progress or range */}
				<div className={styles.rangeContainer}>
					<input
						ref={progressBar}
						className={styles.range}
						onChange={changeRange}
						type="range"
						defaultValue="0"
					/>
				</div>

				{/* duration container */}
				<div className={styles.durationWrapper}>
					<span className={styles.total}>
						{duration && !isNaN(duration) && calculateTime(duration)}
					</span>
					|<span className={styles.current}>{calculateTime(currentTime)}</span>
				</div>
			</div>
		</div>
	);
}

export default AudioPlayer;
