import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  useDeleteSliderQuery,
  usePutSliderQuery,
} from "hooks/query/useSliderQuery";
import SwitchTwoLabel from "../../../common/switchTwoLabel/SwitchTwoLabel";
import DeleteMultiModal from "../../../common/modals/deleteMultiModal/DeleteMultiModal";
import styles from "../SliderList.module.scss";

export default function SliderRow({ data, index }) {
  const { mutate: deleteSlider } = useDeleteSliderQuery(data.title);
  const { mutate: updateSlider } = usePutSliderQuery(data.title);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteSlide = () => {
    deleteSlider(data.id);
  };

  const handleChangeShowSlide = async (bool) => {
    updateSlider({ id: data.id, show: bool });
  };

  const handleTranslatePositionName = () => {
    switch (data.position) {
      case "top":
        return "بالا";

      case "bottom":
        return "پایین";

      default:
        return;
    }
  };

  return (
    <>
      <tr className={styles.rowCell}>
        <td>
          {data.cover?.length ? (
            <img
              loading="lazy"
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
              alt={data.title}
              className={`${styles.moviePoster}`}
            />
          ) : (
            <img
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
              alt={data.title}
              className={`${styles.moviePoster}`}
            />
          )}
          {data.title}
        </td>
        <td>{index + 1}</td>
        <td>{handleTranslatePositionName()}</td>
        <td>{data.priority + 1}</td>

        <td>
          <SwitchTwoLabel
            switchState={[data.show, handleChangeShowSlide]}
            rightLabel={"درحال نمایش"}
            leftLabel={"عدم نمایش"}
            theme="colorful"
          />
        </td>
        <td>
          <Link
            to={`/sliders/addSlider/${data.id}`}
            className={`${styles.seeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/movies/see.svg`}
              alt="see"
            />
            مشاهده و ویرایش
          </Link>
          <button
            onClick={() => setShowDeleteModal(true)}
            className={`${styles.removeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt="see"
            />
            حذف اسلاید
          </button>
        </td>
      </tr>

      <Modal
        dialogClassName={styles.modal}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}>
        {/* DeleteMultiModal kinda similar to this component */}
        <DeleteMultiModal
          titleData={{
            deleteObject: data.title,
            isMoreThanOne: false,
            placeName: "اسلایدر",
          }}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={handleDeleteSlide}
        />
      </Modal>
    </>
  );
}
