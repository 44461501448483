import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router";
import { useSliderQuery } from "hooks/query/useSliderQuery";
import {
  resetInformationSlide,
  resetSettingSlide,
  setSlide,
} from "../../../redux/actions/slider";
import TabHeader from "./tabHeader/TabHeader";
import Information from "./tabs/information/Information";
import Settings from "./tabs/settings/Settings";
import styles from "./AddSlider.module.scss";

export default function AddSlider() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { data } = useSliderQuery(id);
  useEffect(() => {
    if (data) {
      dispatch(setSlide(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    return () => {
      // rest slide store
      dispatch(resetInformationSlide());
      dispatch(resetSettingSlide());
    };
  }, [dispatch, id]);

  return (
    <>
      <TabHeader />
      <div className={`filter ${styles.filterBody}`}>
        <Tabs defaultActiveKey="information" className={styles.tab}>
          <Tab
            eventKey="information"
            title="تصاویر و لینک ها"
            className={styles.tabItem}>
            <Information data={data} />
          </Tab>
          <Tab
            eventKey="settings"
            title="تنظیمات اسلاید"
            className={styles.tabItem}>
            <Settings />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
