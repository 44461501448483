import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import styles from "./EditAccessLevel.module.scss";

//! dynamic : get name of User & Project & AccessLevel

export default function EditAccessLevel({ onHide, user, defaultAccessLevel }) {
  //   const [accessLevel, setAccessLevel] = useState(
  //     defaultAccessLevel ? defaultAccessLevel : "ordinary_user"
  //   );

  return (
    <div className={styles.wrapper}>
      <Modal.Header className={styles.header}>
        <Modal.Title>
          سطح دسترسی <span className={styles.bold}> امید آشنایی </span> در پروژه
          <span className={styles.bold}> استریمر </span>
          را انتخاب نمایید
        </Modal.Title>

        <img
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt=""
          onClick={() => onHide()}
        />
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.radioWrapper}>
          <Form.Check
            name="accessLvl"
            type="radio"
            label="مدیر پروژه"
            className={styles.radio}
            // onClick={() => setAccessLevel("worklist_manager")}
            // checked={accessLevel === "worklist_manager" ? true : false}
            // custom
          />
          <p>
            مدیر پروژه در همه ی موارد اعم از افزودن محتوا، بارگذاری فیلم و سریال
            و ویدئو کلیپ و نیز افراد حاضر در پروژه میتواند تغییر ایجاد کند اما
            حذف پروژه ای که خودش ایجاد نکرده ممکن نیست
          </p>
        </div>
        <div className={styles.radioWrapper}>
          <Form.Check
            name="accessLvl"
            type="radio"
            label="مدیر محتوا و مالتی مدیا"
            className={styles.radio}
            // onClick={() => setAccessLevel("internal_manager")}
            // checked={accessLevel === "internal_manager" ? true : false}
            // custom
          />
          <p>
            مدیر محتوا و مالتی مدیا میتواند فیلم، سریال و هر گونه مالتی مدیا را
            بر اساس نوع پروژه بارگذاری کرده و محتوای آن را نیز اضافه و یا ویرایش
            کند اما امکان ویرایش افراد حاضر در پروژه را ندارد
          </p>
        </div>
        <div className={styles.radioWrapper}>
          <Form.Check
            name="accessLvl"
            type="radio"
            label="مدیر محتوا"
            className={styles.radio}
            // onClick={() => setAccessLevel("ordinary_user")}
            // checked={accessLevel === "ordinary_user" ? true : false}
            // custom
          />
          <p>
            مدیر متحوا میتواند محتوای متنی و جزئیات فیلم، سریال، و ویدئو کلیپ را
            اضافه کند، مانند توضیحات و خلاصه داستان، هنرمندان و عوامل،
            نویسندگان، زیرنویس، جوایز و افتخارات، تصاویر و ویدئوها و ...
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <div
          className={`${styles.btn} ${styles.outline}`}
          onClick={() => onHide()}>
          انصراف
        </div>
        <div className={`${styles.btn} ${styles.primary}`}>ذخیره</div>
      </Modal.Footer>
    </div>
  );
}
