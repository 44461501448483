import { useSlidersQuery } from "hooks/query/useSliderQuery";
import EmptyState from "../common/emptyState/EmptyState";
import SliderList from "./sliderList/SliderList";

export default function Sliders() {
  const { data } = useSlidersQuery();

  return (
    <>
      {data?.length ? (
        <SliderList data={data} />
      ) : (
        <EmptyState
          buttonOptions={{
            link: "/sliders/addSlider",
            text: "افزودن اسلاید",
            icon: "/images/menu/sliders.svg",
            class: true,
          }}>
          <p>شما هنوز اسلایدی به پروژه خود اضافه نکرده اید</p>
        </EmptyState>
      )}
    </>
  );
}
