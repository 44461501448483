import { useState } from "react";
import { Modal } from "react-bootstrap";
import EditAccessLevel from "../editAccessLevel/EditAccessLevel";
import styles from "./TableRow.module.scss";

// For dynamic, should handle Disable style & "دعوتنامه ارسال شد"

export default function TableRow({ data }) {
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);

  return (
    <>
      <tr className={styles.row}>
        <td className={styles.user}>
          <img src={`${process.env.PUBLIC_URL}/images/person.jpg`} alt="" />
          {data?.name?.length ? data.name : ""}
        </td>
        <td>{data?.email}</td>
        {/* <td>{handleAccessLevel(data.user_access_level)}</td> */}
        <td>{data?.access}</td>
        {/* {handleInvitation(data.invited, data.active, data.is_answered)} */}
        <td
          className={`${styles.btn} ${styles.primary} ${
            data.user_access_level === "worklist_manager" ? styles.disabled : ""
          }`}
          onClick={() => setShowAccessLevelModal(true)}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/edit-active.svg`}
            alt=""
          />
          ویرایش سطح دسترسی
        </td>
        <td
          //   onClick={() => handleDeleteMember(data.id, data.user.email)}
          className={`${styles.btn} ${styles.danger}`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
            alt=""
          />
          حذف مخاطب
        </td>
      </tr>
      <Modal
        show={showAccessLevelModal}
        onHide={() => setShowAccessLevelModal(false)}
        dialogClassName={styles.accessLevelModal}>
        <EditAccessLevel onHide={() => setShowAccessLevelModal(false)} />
      </Modal>
    </>
  );
}
