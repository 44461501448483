import { Input } from "../../../../common/input/Input";
import styles from "./Imdb.module.scss";

export default function Imdb({ inputState }) {
  const [imdbId, setImdbId] = inputState;

  return (
    <div className={styles.wrapper}>
      <ul>
        <li>
          <span className={styles.bold}>۱ .</span> وارد سایت IMDB شوید؛ برای
          ورود به سایت IMDB
          <a
            className={styles.link}
            href="https://www.imdb.com/"
            target="_blank"
            rel="noreferrer">
            اینجا
          </a>
          کلیک کنید.
        </li>
        <li>
          <span className={styles.bold}>۲ .</span> سریال مورد نظر خود را جستجو
          کنید.
        </li>
        <li>
          <span className={styles.bold}>۳ .</span> آی دی ۷ رقمی متعلق به سریال
          که داخل باکس جستجو است را در بخش زیر وارد کنید.
        </li>
      </ul>
      <img
        src={`${process.env.PUBLIC_URL}/images/common/imdb-helper.svg`}
        alt="imdb-helper"
      />
      <Input
        placeholder="آی دی TMDB و یا IMDB"
        name="imdbId"
        value={imdbId}
        onChange={(e) => setImdbId(e.target.value)}
      />
    </div>
  );
}
