import { Tab, Tabs } from "react-bootstrap";
import ChangePass from "./tabs/changePass/ChangePass";
import PersonalInfo from "./tabs/personalInfo/PersonalInfo";
import styles from "./Profile.module.scss";

export default function Profile() {
  return (
    <>
      <div className={`filter ${styles.filterBody}`}>
        <Tabs defaultActiveKey="personalInfo" className={styles.tab}>
          <Tab
            title="مشاهده و ویرایش پروفایل"
            eventKey="personalInfo"
            className={styles.tabItem}
            children={<PersonalInfo />}
          />
          <Tab
            title="تغییر رمز عبور"
            eventKey="changePass"
            className={styles.tabItem}
            children={<ChangePass />}
          />
        </Tabs>
      </div>
    </>
  );
}
