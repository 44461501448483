export const formatToTwoDigitsNumber = (number) => {
  if (number < 10) {
    return `0${number}`;
  } else {
    return number;
  }
};

export const convertMilliSecondToMinutes = (millisecond) => {
  const minutes = Math.floor(millisecond / 60);
  const secondes = millisecond % 60;

  return (
    formatToTwoDigitsNumber(minutes) + ":" + formatToTwoDigitsNumber(secondes)
  );
};

export const isCurrentDateInRange = (startDate, endDate) => {
  const currentTime = new Date().getTime();

  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  const isCurrentDate = currentTime >= start && currentTime <= end;

  return isCurrentDate;
};
