import { Link } from "react-router-dom";
import styles from "./EmptyState.module.scss";

function EmptyState({ buttonOptions, subHeader, children }) {
  return (
    <div className={subHeader ? styles.subHeader : styles.wrapper}>
      <figure className={styles.imgWrapper}>
        <img src={`${process.env.PUBLIC_URL}/images/common/empty.svg`} alt="" />
      </figure>
      <div className={styles.text}>
        {children}
        {buttonOptions && buttonOptions.link && (
          <Link to={buttonOptions.link} className={styles.btn}>
            <img
              src={`${process.env.PUBLIC_URL}${buttonOptions.icon}`}
              alt=""
              className={buttonOptions.class ? styles.logoActive : ""}
            />
            {buttonOptions.text}
          </Link>
        )}
      </div>
    </div>
  );
}

export default EmptyState;
