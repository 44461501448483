import styles from "./styles.module.scss";
export default function Receiver() {
  return (
    <div className={styles.receiverWrapper}>
      <div className={`${styles.receiver} ${styles.box}`}>
        <span>
          با سلام و احترام کاربر گرامی مجید خدابنده با شما جهت یاد آوری تمدید
          سرویس هاست تان تماس حاصل شد که متاسفانه موفقیت آمیز نبود. سرویس شما
          روز گذشته منقضی شده و در صورتی که ماهانه باشد تا 7 روز و سالانه تا 30
          روز امکان پرداخت را دارید و با توجه به اینکه اطلاعات هاست های سالانه
          تا 30 روز و ماهانه تا 7 روز نگهداری میشود و پس از ان حذف میشود لطفا
          جهت جلوگیری از حذف اطلاعات خود هرچه سریعتر اقدام به پرداخت فاکتور
          نمایید "فروش پایان یک معامله نیست آغاز یک تعهد است." موفق و مانا باشید
        </span>
      </div>
      <div className={styles.support}>
        <img
          src={`${process.env.PUBLIC_URL}/images/new-icons/tickets/default-avatar.svg`}
          alt=""
        />{" "}
        <span>پشتیبان</span>
      </div>
    </div>
  );
}
