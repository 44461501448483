import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import styles from "./NoticeModal.module.scss";

function NoticeModal({ title, onHide, onNextStep }) {
  return (
    <Fragment>
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>
          شما دسترسی لازم جهت <span className={styles.bold}> {title} </span> را
          ندارید!
        </Modal.Title>
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Footer className={styles.footer}>
        <div onClick={onNextStep} className={`${styles.btn} ${styles.primary}`}>
          تأیید
        </div>
      </Modal.Footer>
    </Fragment>
  );
}

export default NoticeModal;
