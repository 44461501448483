import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  addSubtitleToMovie,
  removeSubtitleFromMovie,
  resetMovideSubtitle,
} from "redux/actions/movies";
import { useDeleteSubtitleQuery } from "hooks/query/useFileQuery";
import { useLanguagesQuery } from "hooks/query/useCastsQuery";
import DropDown from "../../../../../common/dropDown/DropDown";
import FileUploader from "../../../../../common/fileUploader";
import ProgressBar from "../../../../../common/progressBar/ProgressBar";
import MovieTable from "../../../../movieTable/MovieTable";
import styles from "./UploadSubtitles.module.scss";

export default function UploadSubtitles() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { mutate: deleteSubtitle } = useDeleteSubtitleQuery("movie");
  const { data: languages } = useLanguagesQuery();
  const [lang, setLang] = useState("");
  const [showProgessbar, setShowProgessbar] = useState(false);
  const [files, setFiles] = useState("");
  const createMovie = useSelector((state) => state.createMovieReducer);

  // GET SUBTITLES FROM MOVIE DATA
  useEffect(() => {
    if (createMovie.subtitles && id) {
      dispatch(resetMovideSubtitle());
      for (const item of createMovie.subtitles) {
        dispatch(
          addSubtitleToMovie({
            file: item,
            language: item.language,
          })
        );
      }
    }
  }, [createMovie.subtitles, dispatch, id]);

  // REDUX OF FILES
  const { subtitlesFile } = useSelector(
    (state) => state.movieFileUploadReducer
  );
  const handleSetFile = (file) => {
    setFiles(file);
    setShowProgessbar(true);
  };
  const handleAddSubtitle = () => {
    if (lang.id && files.name) {
      setShowProgessbar(false);
      setLang("");
      setFiles("");
      dispatch(
        addSubtitleToMovie({
          file: files,
          language: lang,
        })
      );
    }
  };
  const handleRemoveSubtitle = (index) => {
    dispatch(
      removeSubtitleFromMovie({
        index: index,
      })
    );
  };
  const handleDeleteSubtitle = (subtitleID) => {
    deleteSubtitle(subtitleID);
  };

  return (
    <>
      <h4>بارگذاری زیرنویس (ها)</h4>
      <div className={styles.flexPill}>
        <div>
          <DropDown
            label="زبان زیرنویس"
            placeholder="زبان زیرنویس را انتخاب نمایید"
            options={languages}
            value={lang}
            getOptionLabel={(option) => option["name"]}
            getOptionValue={(option) => option["id"]}
            onChange={(e) => setLang(e)}
          />
        </div>
        {showProgessbar ? (
          <ProgressBar fileName={files.name} type={"second"} />
        ) : (
          <div className={styles.fileUploaderCustom}>
            <FileUploader
              text="انتخاب فایل"
              maxFiles={1}
              btnPlace={"right"}
              height={56}
              imageState={[files, handleSetFile]}
              type="subtitle"
            />
          </div>
        )}

        <div onClick={handleAddSubtitle} className={styles.addBtn}>
          افزودن زیرنویس
        </div>
      </div>
      {subtitlesFile.length > 0 && (
        <div className={styles.tables}>
          <MovieTable
            handleRemoveSubtitle={handleRemoveSubtitle}
            handleDeleteSubtitle={handleDeleteSubtitle}
            data={subtitlesFile}
            type={"subtitle"}
          />
        </div>
      )}
    </>
  );
}
