import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyProfile, resetProfile } from "../../../../redux/actions/profile";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import { NameFromEmail } from "../../../../util/name";
import cookies from "react-cookies";
import styles from "./Profile.module.scss";

export default function Profile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { contactInfo } = useSelector((store) => store.profileReducer);
  const [latestAvatar, setLatestAvatar] = useState("");

  const cookieRefreshTokens = cookies.load("panel-refreshToken");

  useEffect(() => {
    if (cookieRefreshTokens && contactInfo === null) {
      dispatch(getMyProfile());
    }
  }, [contactInfo]);

  useEffect(() => {
    if (contactInfo?.avatar?.length) {
      setLatestAvatar(contactInfo?.avatar);
    }
  }, [contactInfo?.avatar]);

  const showProfile = () => {
    history.push("/profile");
  };
  const logout = () => {
    cookies.remove("token");
    cookies.remove("panel-refreshToken");
    dispatch(resetProfile());
    history.replace("/");
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="link"
        id="dropdown-basic"
        className={styles.Btn}>
        <div className={styles.imgBox}>
          {latestAvatar?.length ? (
            <img
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${latestAvatar}`}
              alt={contactInfo?.fullname}
              className={styles.notificationImg}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/images/user-pic-default.svg`}
              alt={contactInfo?.fullname}
              className={styles.notificationImg}
            />
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.menu}>
        <Dropdown.Item className={styles.infoItem}>
          {latestAvatar?.length ? (
            <img
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${latestAvatar}`}
              alt={contactInfo?.fullname}
              className={styles.userImage}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/images/user-pic-default.svg`}
              alt={contactInfo?.fullname}
              className={styles.userImage}
            />
          )}
          <div className={styles.userInfo}>
            <div className={styles.name}>
              {contactInfo?.fullname
                ? contactInfo.fullname
                : NameFromEmail(contactInfo?.email)}
            </div>
            <address className={styles.email}>{contactInfo?.email}</address>
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={showProfile} className={styles.item}>
          <img
            src={`${process.env.PUBLIC_URL}/images/topBar/user.svg`}
            alt=""
            className={styles.imgIcon}
          />
          <span className={styles.text}>
            <span> مشاهده پنل کاربری</span>
          </span>
        </Dropdown.Item>
        <Dropdown.Item onClick={logout} href="#" className={styles.exitItem}>
          <img
            src={`${process.env.PUBLIC_URL}/images/topBar/logout.svg`}
            alt=""
            className={styles.imgIcon}
          />
          <span>خروج از حساب کاربری</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
