import React, { useState } from "react";
import { Link } from "react-router-dom";
import OtpSection from "./otpSection/OtpSections";
import PhoneNumberSection from "./phoneNumberSection/PhoneNumberSection";
import ResetPasswordSection from "./resetPasswordSection/ResetPasswordSection";
import styles from "./ResetPassword.module.scss";
function ResetPassword() {
  const [step, setStep] = useState(1);
  const [recoveryEntity, setRecoveryEntity] = useState(1);

  const showOtpSection = () => setStep(2);

  const showResetPassword = () => setStep(3);

  return (
    <div className={styles.wrapper}>
      <Link to="/">
        <div className={styles.logoWrapper}>
          <img
            src={
              window.__RUNTIME_CONFIG__.REACT_APP_WEBSITE_TYPE === "sermovie"
                ? `${process.env.PUBLIC_URL}/images/icons/sermovie-logo-black.svg`
                : `${process.env.PUBLIC_URL}/images/registerAndLogin/logo.svg`
            }
            className={styles.logo}
            alt=""
          />
        </div>
      </Link>

      <div className={styles.context}>
        {step === 1 && (
          <PhoneNumberSection
            showNextSection={showOtpSection}
            recoveryObj={(e) => setRecoveryEntity(e)}
          />
        )}
        {step === 2 && (
          <OtpSection
            showNextSection={showResetPassword}
            RecoverEntity={recoveryEntity}
          />
        )}
        {step === 3 && <ResetPasswordSection />}
      </div>
    </div>
  );
}

export default ResetPassword;
