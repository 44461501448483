import { useState } from "react";
import ReactAutocomplete from "react-autocomplete";

import styles from "./AdvanceSearch.module.scss";

export default function AdvanceSearch() {
	const [values, setValues] = useState("");
	const [close, setClose] = useState(styles.closeDeactive);
	const [input, setInput] = useState(styles.input);

	const focusInput = () => {
		setClose(styles.closeActive);
	};

	const blurInput = () => {
		if (values === "") {
			setClose(styles.closeDeactive);
		}
	};

	const handleClose = () => {
		setValues("");
	};

	// const isMenuOpen = (isOpen) => {
	//     if (isOpen) {
	//         this.setState({ input: `${styles.input} ${styles.inputMenuOpen}` })
	//     }
	//     else {
	//         this.setState({ input: styles.input })

	//     }
	// }

	// ReactAutocomplete configs
	const createMenuItem = (item, highlighted) => (
		<div
			key={item.id}
			style={{
				backgroundColor: highlighted ? "#F7F7F8" : "transparent",
			}}
		>
			<a className={styles.resultItem}>
				<div>
					<span className={styles.title}>{item.label}</span>
				</div>
			</a>
		</div>
	);

	const createMenu = (items, value) => (
		<div className={`advanceSearch ${styles.menu}`} children={items} />
	);

	const inputProps = {
		placeholder: "جستجو",
		className: input,
		onFocus: focusInput,
		onBlur: blurInput,
	};

	return (
		<div className={styles.container}>
			<img
				src={`${process.env.PUBLIC_URL}/images/topBar/search.svg`}
				className={styles.magnifier}
				alt=""
			/>
			<ReactAutocomplete
				// open
				wrapperStyle={{ display: "block", width: "100%" }}
				inputProps={inputProps}
				items={[
					{ id: "1", label: "فرم اطلاع رسانی" },
					{ id: "2", label: "فرم ساز ایمیل" },
					{ id: "3", label: "فرم ثبت اطلاعات مالی" },
					{ id: "4", label: "فرم ثبت نام" },
				]}
				shouldItemRender={(item, value) =>
					item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
				}
				getItemValue={(item) => item.label}
				renderItem={createMenuItem}
				renderMenu={createMenu}
				value={values}
				onChange={(e) => setValues(e.target.value)}
				onSelect={(value) => setValues(value)}
			/>

			<img
				src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
				alt=""
				className={close}
				onClick={handleClose}
			/>
		</div>
	);
}
