import { Tab, Tabs } from "react-bootstrap";
import TabHeader from "./TabHeader/TabHeader";
import GeneralInfo from "./Tabs/GeneralInfo/GeneralInfo";
import PersonalPresent from "./Tabs/PersonalPresent/PersonalPresent";
import ProjectType from "./Tabs/ProjectType/ProjectType";
import styles from "./NewProject.module.scss";

export default function NewProject() {
  return (
    <>
      <TabHeader />
      <div className={`filter ${styles.filterBody}`}>
        <Tabs defaultActiveKey="generalInformation" className={styles.tab}>
          <Tab
            eventKey="generalInformation"
            title="اطلاعات کلی پروژه"
            className={styles.tabItem}>
            <GeneralInfo />
          </Tab>
          <Tab
            eventKey="projectType"
            title="نوع پروژه"
            className={styles.tabItem}>
            <ProjectType />
          </Tab>
          <Tab
            eventKey="personalPresent"
            title="افراد حاضر در پروژه"
            className={styles.tabItem}>
            <PersonalPresent />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
