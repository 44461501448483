import { useEffect, useState } from "react";
import DropDown from "new-components/drop-down";
import Input from "new-components/fields/input";
import styles from "./styles.module.scss";

export default function Resolutions({
  data,
  resolutionsConstants,
  liveConfigData,
  handleAddResolution,
  handleRemoveResolution,
  handleChangeResolution,
  maxLength,
  length,
  index,
}) {
  const [info, setInfo] = useState({
    audio_bitrate: {
      max: "",
      min: "",
      recommended: "",
    },
    video_bitrate: {
      max: "",
      min: "",
      recommended: "",
    },
  });
  const handleChangeFields = (options) => {
    const getNeedData = liveConfigData?.filter(
      (f) => f.resolution === options?.name
    )[0];
    if (options?.name) {
      setInfo({
        audio_bitrate: {
          max: getNeedData?.max_audio_bitrate,
          min: getNeedData?.min_audio_bitrate,
          recommended: getNeedData?.recommended_audio_bitrate,
        },
        video_bitrate: {
          max: getNeedData?.max_video_bitrate,
          min: getNeedData?.min_video_bitrate,
          recommended: getNeedData?.recommended_video_bitrate,
        },
      });
      handleChangeResolution({
        ...data,
        resolution: options,
        audio_bitrate: getNeedData?.recommended_audio_bitrate,
        video_bitrate: getNeedData?.recommended_video_bitrate,
        audio: {
          max: getNeedData?.max_audio_bitrate,
          min: getNeedData?.min_audio_bitrate,
        },
        video: {
          max: getNeedData?.max_video_bitrate,
          min: getNeedData?.min_video_bitrate,
        },
      });
    } else {
      setInfo({
        audio_bitrate: {
          max: "",
          min: "",
        },
        video_bitrate: {
          max: "",
          min: "",
        },
      });
      handleChangeResolution({
        ...data,
        audio_bitrate: "",
        video_bitrate: "",
        video: "",
        audio: "",
        resolution: "",
      });
    }
  };
  const handleChangeInputs = (e) => {
    handleChangeResolution({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (info.audio_bitrate.recommended) {
      handleChangeResolution({
        ...data,
        audio_bitrate: info.audio_bitrate.recommended,
        video_bitrate: info.video_bitrate.recommended,
      });
    }
  }, [info]);
  useEffect(() => {
    if (data.resolution.name) {
      setInfo({
        audio_bitrate: {
          max: data?.audio?.max,
          min: data?.audio?.min,
          recommended: data?.audio_bitrate,
        },
        video_bitrate: {
          max: data?.video.max,
          min: data?.video.min,
          recommended: data?.video_bitrate,
        },
      });
    }
  }, [data.resolution.name]);
  return (
    <>
      <div className={styles.row}>
        <DropDown
          placeholder="رزولوشن تصویر"
          options={resolutionsConstants}
          value={data.resolution}
          getOptionLabel={(option) => option["name"]}
          getOptionValue={(option) => option["id"]}
          onChange={(options) => handleChangeFields(options)}
          name="resolution"
        />
        <Input
          type="number"
          title={
            info.video_bitrate.max
              ? `عدد بین ${info.video_bitrate.min} الی ${info.video_bitrate.max} مجاز است`
              : ""
          }
          placeholder="بیت‌ریت تصویر"
          name="video_bitrate"
          value={data.video_bitrate}
          onChange={handleChangeInputs}
        />
        <Input
          type="number"
          title={
            info.audio_bitrate.max
              ? `عدد بین ${info.audio_bitrate.min} الی ${info.audio_bitrate.max} مجاز است`
              : ""
          }
          placeholder="بیت‌ریت صدا"
          name="audio_bitrate"
          value={data.audio_bitrate}
          onChange={handleChangeInputs}
        />
        {maxLength ? (
          <button
            onClick={handleRemoveResolution}
            className={`btn ${styles.delete}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/delete.svg`}
              alt="remove"
            />
          </button>
        ) : index + 1 === length ? (
          <button onClick={handleAddResolution} className={`btn ${styles.add}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/add.svg`}
              alt="add"
            />
          </button>
        ) : (
          <button
            onClick={handleRemoveResolution}
            className={`btn ${styles.delete}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/new-icons/delete.svg`}
              alt="remove"
            />
          </button>
        )}
      </div>
    </>
  );
}
