import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  deleteCollection,
  getCollection,
  getCollections,
  patchAppendMovieToCollection,
  patchDeleteMovieFromCollection,
  postCollection,
  putUpdateCollection,
} from "services/collections/collections";
import toasts from "components/common/toasts/toasts";

export const useCollectionsQuery = (page, limit) => {
  return useQuery(
    ["collections", page],
    ({ signal }) => getCollections(page, limit, signal),
    {
      keepPreviousData: true,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};
export const useCollectionQuery = (id) => {
  return useQuery(
    ["collection", id],
    ({ signal }) => getCollection(id, signal),
    {
      enabled: !!id,
      select: (data) => {
        return data.data.data;
      },
    }
  );
};

export const usePostCollectionQuery = (title) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(postCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries("collections");
      history.push("/collections");
      toasts.success(`کالکشن ${title} با موفقیت ثبت شد`);
    },
    onError: () => {
      toasts.error("مشکلی برای ثبت مقاله پیش آمده");
    },
  });
};
export const usePutCollectionQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(putUpdateCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries("collections");
      history.push("/collections");
      toasts.success("کالشکن با موفقیت آپدیت شد.");
    },
    onError: () => {
      toasts.error("مشکلی برای بروزرسانی کالشکن پیش آمده");
    },
  });
};
export const useDeleteCollectionQuery = (type, title) => {
  const queryClient = useQueryClient();
  return useMutation(deleteCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries("collections");
      if (type === "single") {
        toasts.success(`کالکشن ${title} با موفقیت حذف شد.`);
      }
    },
    onError: () => {
      toasts.error("مشکلی برای حذف پیش آمده.");
    },
  });
};

export const usePatchAppendMovieToCollectionQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(patchAppendMovieToCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries("collection");
      toasts.success(`فیلم ${title} به کالکشن اضافه شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای اضافه شدن فیلم پیش آمده");
    },
  });
};
export const usePatchDeleteMovieFromCollectionQuery = (title) => {
  const queryClient = useQueryClient();
  return useMutation(patchDeleteMovieFromCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries("collection");
      toasts.success(`فیلم ${title} از کالکشن حذف شد.`);
    },
    onError: () => {
      toasts.error("مشکلی برای حذف فیلم پیش آمده");
    },
  });
};
