import { Modal } from "react-bootstrap";
import { CloseButton } from "../../../packageAndFeatures/components/Components";
import styles from "./DetailModal.module.scss";
function DetailModal({ data, onHide }) {
  return (
    <div>
      <CloseButton position="top" onClick={onHide} />
      <Modal.Header className={styles.header}>
        <Modal.Title>اطلاعات پخش زنده</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <div>
          <p>Server URL:</p>
          <span>{data?.server_url}</span>
        </div>
        <div>
          <p>Stream Key:</p>
          <span>{data?.stream_key}</span>
        </div>
        <div>
          <p>Stream URL:</p>
          <span>{data?.hls_url}</span>
        </div>
        <div>
          <p>Resolitions:</p>
          {data?.resolutions.map((i) => (
            <div>
              Resolution: {i.resolution.name} , Audio bitrate: {i.audio_bitrate}{" "}
              , Video bitrate: {i.video_bitrate}
            </div>
          ))}
        </div>
        <div>
          <p>Watermark Position:</p>
          <span>
            {data?.watermark_position
              ? data.watermark_position.name
              : "بالا چپ"}
          </span>
        </div>
        <div>
          <p>Fps:</p>
          <span>{data?.fps ? data.fps.name : 30}</span>
        </div>
      </Modal.Body>
    </div>
  );
}
export default DetailModal;