import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { usePatchDeleteMovieFromCollectionQuery } from "hooks/query/useCollectionQuery";
import DeleteMultiModal from "../../../common/modals/deleteMultiModal/DeleteMultiModal";
import styles from "./CollectionMoviesRow.module.scss";

function CollectionMoviesRow({ data, index }) {
  const { id } = useParams();
  const { mutate: patchDeleteMovieFromCollection } =
    usePatchDeleteMovieFromCollectionQuery(data.title);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteCollection = async () => {
    patchDeleteMovieFromCollection({
      id: parseInt(id),
      movies: [data.id],
    });
    setShowDeleteModal(false);
  };

  return (
    <>
      <tr className={styles.rowCell}>
        <td>
          {data.cover.length ? (
            <img
              src={`${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_API}${data.cover}`}
              alt={data.title}
              className={`${styles.seriesPoster}`}
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/images/movies/default-poster.svg`}
              alt={data.title}
              className={`${styles.seriesPoster}`}
            />
          )}
          {data.title}
        </td>
        <td>{index + 1}</td>
        <td>{data.release}</td>
        <td>-</td>
        {data.release ? (
          <td className={styles.green}>منتشر شده</td>
        ) : (
          <td className={styles.orange}>منتشر نشده</td>
        )}
        <td>
          <Link
            to={`/movies/addMovies/${data.id}`}
            className={`${styles.seeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/movies/see.svg`}
              alt="see"
            />
            مشاهده و ویرایش
          </Link>
          <button
            onClick={() => setShowDeleteModal(true)}
            className={`${styles.removeBtn} btn`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/common/remove-active.svg`}
              alt="see"
            />
            حذف فیلم از کالکشن
          </button>
        </td>
      </tr>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={styles.modals}>
        <DeleteMultiModal
          titleData={{
            deleteObject: data.title,
            isMoreThanOne: false,
            placeName: "کالکشن",
          }}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={handleDeleteCollection}
        />
      </Modal>
    </>
  );
}

export default CollectionMoviesRow;
