import { useCallback, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { addLinkURLToMovie } from "redux/actions/movies";
import toasts from "../../common/toasts/toasts";
import styles from "./MovieFileUploader.module.scss";

function MovieFileUploader({
  fileState,
  maxFiles,
  // handleRemoveFile,
  type,
  onHide,
  // accept,
}) {
  const dispatch = useDispatch();
  const [linkUrl, setLinkUrl] = useState("");
  const [files, setFiles] = fileState;
  // const [organizedFiles, setOrganizedFiles] = useState([]);
  // const [organizedFiles, setOrganizedFiles] = fileInfo;
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // const mappedFiles = files?.map((item) => {
    //   if (!item.hasOwnProperty("id")) {
    //     return {
    //       ...item,
    //       name: item.data ? nameOfFile(item.data.name) : nameOfFile(item.name),
    //       type: item.data ? typeOfFile(item.data.type) : typeOfFile(item.type),
    //       size: item.data
    //         ? changeByteToKByte(item.data.size)
    //         : changeByteToKByte(item.size),
    //       path: item.path,
    //     };
    //   } else {
    //     return {
    //       ...item,
    //       type: typeOfFile(item.type),
    //       size: changeByteToKByte(item.size),
    //     };
    //   }
    // });

    if (files?.length === maxFiles) {
      setIsDisabled(true);
      onHide();
    } else {
      setIsDisabled(false);
    }

    // setOrganizedFiles(files);
  }, [files]);

  const onDropAccepted = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const onDropRejected = (rejectedFiles) => {
    const errors = rejectedFiles.map((item) => item.errors);

    if (rejectedFiles.length > maxFiles) {
      toasts.error(`شما فقط قادر به انتخاب ${maxFiles} عدد فایل هستید`);
    }

    // temp
    errors.forEach((item) => {
      if (item[0].code === "file-invalid-type") {
        // toasts.error(`فرمت مناسب را انتخاب کنید:  ${accept}`);
        toasts.error(`فرمت مناسب را انتخاب کنید: .mkv , .mp4`);
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".mkv , .mp4",
    maxFiles: maxFiles,
    disabled: isDisabled,
    onDropAccepted,
    onDropRejected,
  });

  // add movie link Url
  const handleAddLinkURL = () => {
    dispatch(addLinkURLToMovie(linkUrl));
    setLinkUrl("");
    onHide();
  };

  return (
    <>
      <Modal.Header className={styles.modalHeader}>
        {type === "movie" && (
          <Modal.Title className={styles.modalTitle}>
            برای افزودن فیلم، <span className={styles.bold}>لینک مستقیم</span>{" "}
            آنرا وارد کرده و یا از حافظه خود آنرا بارگذاری کنید
          </Modal.Title>
        )}
        {type === "trailer" && (
          <Modal.Title className={styles.modalTitle}>
            برای افزودن تریلر، لینک{" "}
            <span className={styles.bold}> YouTube</span> آنرا وارد کرده و یا از
            حافظه خود آنرا بارگذاری کنید
          </Modal.Title>
        )}
        {type === "seriesEpisode" && (
          <Modal.Title className={styles.modalTitle}>
            برای افزودن قسمت به سریال،{" "}
            <span className={styles.bold}>لینک مستقیم</span> آنرا وارد کرده و یا
            از حافظه خود آنرا بارگذاری کنید
          </Modal.Title>
        )}
        <img
          className={styles.icon}
          src={`${process.env.PUBLIC_URL}/images/common/close.svg`}
          alt="Close"
          onClick={() => onHide()}
        />
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Form.Group className={styles.group}>
          <input
            type="text"
            className={`form-control ${styles.formControl}`}
            placeholder={`به طور مثال: ${
              type === "movie"
                ? `https://www.drive.google.com/1x1df5gg6cx.mkv`
                : ""
            }${
              type === "trailer"
                ? `https://www.youtube.com/watch?v=DHtsdLzHp8U`
                : ""
            }${
              type === "seriesEpisode"
                ? `https://www.upload.com/watchvDHtsdLzHp8U.mkv`
                : ""
            }`}
            name="link"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
          />
          {/* <img
            className={styles.checkedInputIcon}
            src={`${process.env.PUBLIC_URL}/images/common/checked.svg`}
            alt="clear"
          /> */}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <button className={`${styles.btn} ${styles.outline} btn`}>
            انتخاب فایل
          </button>
        </div>
        <button
          onClick={handleAddLinkURL}
          className={`${styles.btn} ${styles.primary} btn`}>
          افزودن
          {/* {type === "movie" ? `${checkedLink ? "افزودن فیلم" : "بررسی"}` : ""}
          {type === "trailer"
            ? `${checkedLink ? "افزودن تریلر" : "بررسی"}`
            : ""}
          {type === "seriesEpisode" && "افزودن"} */}
        </button>
      </Modal.Footer>
    </>
  );
}

export default MovieFileUploader;
