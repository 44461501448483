import styles from "./TimeSection.module.scss";
export default function TimeSection() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.wrapperContent}>
        <figure className="image-cover">
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/time.svg`}
            alt="Time Section"
          />
        </figure>
        <div className={styles.info}>
          <h1 className="text__black">قابلیت جابجایی زمانی</h1>
          <p className="text__black">
            پلتفرم ایمن آریا علاوه ‌بر پخش زنده مستمر، می‌تواند امکان تماشای
            زمان های گذشته را ‌نیز برای کاربران خود فراهم کند
          </p>
        </div>
      </div>
    </section>
  );
}