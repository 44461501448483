export const expireUnits = [
  {
    id: "day",
    name: "روز",
  },
  {
    id: "month",
    name: "ماه",
  },
  {
    id: "year",
    name: "سال",
  },
];
