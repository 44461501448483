// Days
export const days = Array.from({ length: 31 }, (_, index) => ({
	id: index < 9 ? `0${index + 1}` : `${index + 1}`,
	title: index < 9 ? `0${index + 1}` : `${index + 1}`,
}));
// Months
export const months = Array.from({ length: 12 }, (_, index) => ({
	id: index < 9 ? `0${index + 1}` : `${index + 1}`,
	title: index < 9 ? `0${index + 1}` : `${index + 1}`,
}));
// Years
export const generateArrayOfYears = () => {
	let max = new Date().getFullYear();
	let min = max - 180;
	let years = [];
	for (let i = max; i >= min; i--) {
		years.push(i);
	}
	return years;
};
export const years = generateArrayOfYears().map(i => ({
	id: `${i}`,
	title: `${i}`,
}));
