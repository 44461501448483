import styles from "./TextArea.module.scss";

function TextArea({ label, isRequired, ...props }) {
  return (
    <div className={styles.wrapper}>
      {label && (
        <label className={styles.label}>
          {label} {isRequired && <span className={styles.asterisk}>*</span>}
        </label>
      )}
      <textarea className={styles.input} {...props} />
    </div>
  );
}

export default TextArea;
