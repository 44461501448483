import Countup from "components/common/countup/Countup";
import styles from "../OverView.module.scss";

export default function Header({ dataLength }) {
  return (
    <div className={styles.boxWrapper}>
      <div className={styles.box}>
        <div className={styles.imgBox}>
          <img
            src={`${process.env.PUBLIC_URL}/images/overView/video-over-view.svg`}
            alt="video"
          />
        </div>
        <h6>ویدیو ها</h6>
        <span>
          <Countup count={dataLength.movies} />
        </span>
      </div>
      <div className={styles.box}>
        <div className={styles.imgBox}>
          <img
            src={`${process.env.PUBLIC_URL}/images/overView/series-over-view.svg`}
            alt="series"
          />
        </div>
        <h6>سریال ها</h6>
        <span>
          <Countup count={dataLength.series} />
        </span>
      </div>
      <div className={styles.box}>
        <div className={styles.imgBox}>
          <img
            src={`${process.env.PUBLIC_URL}/images/overView/collection-over-view.svg`}
            alt="collection"
          />
        </div>
        <h6>کالکشن ها</h6>
        <span>
          <Countup count={dataLength.collections} />
        </span>
      </div>
      <div className={styles.box}>
        <div className={styles.imgBox}>
          <img
            src={`${process.env.PUBLIC_URL}/images/overView/video-clip-over-view.svg`}
            alt="clip"
          />
        </div>
        <h6>پخش زنده</h6>
        <span>
          <Countup count={dataLength.live} />
        </span>
      </div>
      <div className={styles.box}>
        <div className={styles.imgBox}>
          <img
            src={`${process.env.PUBLIC_URL}/images/overView/music-playlist-over-view.svg`}
            alt="music"
          />
        </div>
        <h6>موسیقی ها</h6>
        <span>
          <Countup count={dataLength.musics} />
        </span>
      </div>
    </div>
  );
}
